/******************************************************************
Site Name:
Author:

Stylesheet: Mixins Stylesheet

This is where you can take advantage of Sass' great features: Mixins.
I won't go in-depth on how they work exactly,
there are a few articles below that will help do that. What I will
tell you is that this will help speed up simple changes like
changing a color or adding CSS3 techniques gradients.

A WORD OF WARNING: It's very easy to overdo it here. Be careful and
remember less is more.

Helpful:
http://sachagreif.com/useful-sass-mixins/
http://thesassway.com/intermediate/leveraging-sass-mixins-for-cleaner-code
http://web-design-weekly.com/blog/2013/05/12/handy-sass-mixins/

******************************************************************/


/*********************
TRANSITION
*********************/

/*
I totally rewrote this to be cleaner and easier to use.
You'll need to be using Sass 3.2+ for these to work.
Thanks to @anthonyshort for the inspiration on these.
USAGE: @include transition(all 0.2s ease-in-out);
*/

@mixin transition($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes:      ("-webkit-", "" );
  @each $prefix in $prefixes {
    #{$prefix}transition: $transition;
  }
}

@mixin buttonTransiton($direction){
	transition: background-color 0.5s $direction, color 0.5s $direction;
}


/*********************
CSS3 GRADIENTS
Be careful with these since they can
really slow down your CSS. Don't overdo it.
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}

/*********************
BOX SIZING
*********************/

/* @include box-sizing(border-box); */
/* NOTE: value of "padding-box" is only supported in Gecko. So
probably best not to use it. I mean, were you going to anyway? */
@mixin box-sizing($type: border-box) {
	-webkit-box-sizing: $type;
	-moz-box-sizing:    $type;
	-ms-box-sizing:     $type;
	box-sizing:         $type;
}

@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited;
    }
  }
  @if $focus {
    &:focus {
      color: $focus;
    }
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  @if $active {
    &:active {
      color: $active;
    }
  }
}


