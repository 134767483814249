/******************************************************************
Site Name:
Author:

Stylesheet: Typography

Need to import a font or set of icons for your site? Drop them in
here or just use this to establish your typographical grid. Or not.
Do whatever you want to...GOSH!

Helpful Articles:
http://trentwalton.com/2012/06/19/fluid-type/
http://ia.net/blog/responsive-typography-the-basics/
http://alistapart.com/column/responsive-typography-is-a-physical-discipline

******************************************************************/

/*********************
FONT FACE (IN YOUR FACE)
*********************/

/*  To embed your own fonts, use this syntax
  and place your fonts inside the
  library/fonts folder. For more information
  on embedding fonts, go to:
  http://www.fontsquirrel.com/
  Be sure to remove the comment brackets.
*/

@font-face {
    font-family: 'anna-kopp-webfont';
    src: url("../../library/fonts/anna-kopp-webfont.eot");
    src: url("../../library/fonts/anna-kopp-webfont.eot?#iefix") format("embedded-opentype"), url("../../library/fonts/anna-kopp-webfont.woff") format("woff"), url("../../library/fonts/anna-kopp-webfont.ttf") format("truetype"), url("../../library/fonts/anna-kopp-webfont.svg#anna-kopp-webfont") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PPEditorialNew-Regular';
    src: url("../../library/fonts/PPEditorialNew-Regular.woff2") format("woff2"), url("../../library/fonts/PPEditorialNew-Regular.woff") format("woff"), url("../../library/fonts/PPEditorialNew-Regular.ttf") format("truetype"), url("../../library/fonts/PPEditorialNew-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HarmoniaSansProCyr';
    src: url("../../library/fonts/HarmoniaSansProCyr-Regular.woff2") format("woff2"), url("../../library/fonts/HarmoniaSansProCyr-Regular.woff") format("woff"), url("../../library/fonts/HarmoniaSansProCyr-Regular.ttf") format("truetype"), url("../../library/fonts/HarmoniaSansProCyr-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}



/*
The following is based of Typebase:
https://github.com/devinhunt/typebase.css
I've edited it a bit, but it's a nice starting point.
*/

// font stacks

$sans-serif: PPEditorialNew-Regular, sans-serif;

$mono: HarmoniaSansProCyr, mono;

$serif: $sans-serif;

@mixin icons {
    font-family: 'anna-kopp-webfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*
some nice typographical defaults
more here: http://www.newnet-soft.com/blog/csstypography
*/
p {
    // -ms-word-break: break-all;
    // -ms-word-wrap: break-all;
    //     word-break: break-word;
    //    word-break: break-word;
    // cleaning up the ragged lines and breaks
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    // sets a minimum number of characters before and after the break
    -webkit-hyphenate-before: 2;
    -webkit-hyphenate-after: 3;
    hyphenate-lines: 3;

    // enabling fancy ligatures when available
    // -webkit-font-feature-settings: "liga", "dlig";
    // -moz-font-feature-settings: "liga=1, dlig=1";
    // -ms-font-feature-settings: "liga", "dlig";
    // -o-font-feature-settings: "liga", "dlig";
    // font-feature-settings: "liga", "dlig";
}
