/* ==========================================================================
   // Specific Mixins
   ========================================================================== */

@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
  color: $normal;
  @if $visited {
    &:visited {
      color: $visited;
    }
  }
  @if $focus {
    &:focus {
      color: $focus;
    }
  }
  @if $hover {
    &:hover {
      color: $hover;
    }
  }
  @if $active {
    &:active {
      color: $active;
    }
  }
}

@mixin font-size($sizeValue: 1.6){
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
}
@mixin letter-spacing($spaceValue: 0.01){
    letter-spacing: ($spaceValue * 10) + px;
    letter-spacing: $spaceValue + rem;
}
@mixin line-height($spaceValue: 0.01){
    line-height: ($spaceValue * 10) + px;
    line-height: $spaceValue + rem;
}


@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value*16}px;

    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}









@mixin element-reset(){
    margin: 0;
    padding: 0;

}



@mixin user-select($selValue: none){
	-webkit-user-select: $selValue;
	-khtml-user-select: $selValue;
	-moz-user-select: -moz- + $selValue;
	-ms-user-select: $selValue;
	-o-user-select: $selValue;
	user-select: $selValue;
}

@mixin box-sizing($boxValue: border-box){
    -webkit-box-sizing: $boxValue;
    -moz-box-sizing: $boxValue;
    box-sizing: $boxValue;
}


@mixin all-link-colors($first, $second, $third, $fourth){
   &:link{
       color: $first;
   }
   &:visited{
       color: $second;
   }
   &:active{
       color: $third;
   }
   &:hover{
       color: $fourth;
   }
}

@mixin rotated-text($num-letters: 100, $angle-span: 180deg, $angle-offset: 0deg){
    $angle-per-char: $angle-span / $num-letters;
    @for $i from 1 through $num-letters {
        .char#{$i} {
            transform: rotate($angle-offset + $angle-per-char * $i);
        }
    }
}

/* ==========================================================================
   // RGBA BACKGROUND-COLOR
   ========================================================================== */
@mixin background-rgba($red: 0, $green: 0, $blue: 0, $alpha: 0.5){
    background-color: rgb($red, $green, $blue);
    background-color: rgba($red, $green, $blue, $alpha);
    @if ($legacy-support-for-ie) {
        @include has-layout;
        background-color: transparent\9; $color: rgba($red, $green, $blue, $alpha);
        @include filter-gradient($color, $color);
    }
}


// number 2

@mixin alpha-attribute($attribute, $color, $background, $important: no) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);

  @if ($important == important) {
    #{$attribute}: $solid-color !important;
    #{$attribute}: $color !important;
  } @else {
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
  }




}


/* ==========================================================================
   // Animation Bounce Element
   ========================================================================== */

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin animation($animation) {
  -webkit-animation: #{$animation};
  -moz-animation: #{$animation};
  -ms-animation: #{$animation};
  animation: #{$animation};
}

@include keyframes(bounce) {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}


// @include animation(bounce 2s infinite);




@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin hyphenation {
  @include word-break(break-all);
  @include hyphens;
}
@mixin word-break($value: normal) {
  word-break: $value;
}

@mixin hyphens($value: auto) {
  hyphens: $value;
}