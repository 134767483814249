/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/

.wrap {
    width: 1400px;
}

/*********************
UNDERSLIDER
*********************/
#underslider {
    .widget_rpswidget {
        width: 1400px;
    }
}

.entry-content {
    .more-link {
      position: absolute;
        margin-left: -105px;
        left: 44%;
    }
}

/*********************
Sidebar
*********************/

// .woocommerce {

//   #sidebar {

//     // Berni code
//     transform: none;

//     .sidebar_toggle_button {
//       display: none;
//     }
//   }
// }
