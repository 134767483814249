@charset "UTF-8";
/*! Made with Bones: http://themble.com/bones :) */
/******************************************************************
Site Name:
Author:

Stylesheet: Main Stylesheet

Here's where the magic happens. Here, you'll see we are calling in
the separate media queries. The base mobile goes outside any query
and is called at the beginning, after that we call the rest
of the styles inside media queries.

Helpful articles on Sass file organization:
http://thesassway.com/advanced/modular-css-naming-conventions

******************************************************************/
/* ==========================================================================
   // Specific Mixins
   ========================================================================== */
/* ==========================================================================
   // RGBA BACKGROUND-COLOR
   ========================================================================== */
/* ==========================================================================
   // Animation Bounce Element
   ========================================================================== */
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

@-ms-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

/*********************
IMPORTING PARTIALS
These files are needed at the beginning so that we establish all
our mixins, functions, and variables that we'll be using across
the whole project.
*********************/
/* normalize.css 2012-07-07T09:50 UTC - http://github.com/necolas/normalize.css */
/* ==========================================================================
   HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined in IE 8/9.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/**
 * Correct `inline-block` display not defined in IE 8/9.
 */
audio,
canvas,
video {
  display: inline-block; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9.
 * Hide the `template` element in IE, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* ==========================================================================
   Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* ==========================================================================
   Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background: transparent; }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/* ==========================================================================
   Typography
   ========================================================================== */
/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari 5, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Address styling not present in IE 8/9, Safari 5, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome.
 */
b,
strong,
.strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari 5 and Chrome.
 */
dfn,
em,
.em {
  font-style: normal; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/*
 * proper formatting (http://blog.fontdeck.com/post/9037028497/hyphens)
*/
p {
  -webkit-hyphens: auto;
  -epub-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto; }

/*
 * Addresses margins set differently in IE6/7.
 */
pre {
  margin: 0; }

/**
 * Correct font family set oddly in Safari 5 and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre-wrap; }

/**
 * Set consistent quote types.
 */
q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
q:before,
q:after {
  content: '';
  content: none; }

small, .small {
  font-size: 75%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* ==========================================================================
  Lists
========================================================================== */
/*
 * Addresses margins set differently in IE6/7.
 */
dl,
menu,
ol,
ul {
  margin: 1em 0; }

dd {
  margin: 0; }

/*
 * Addresses paddings set differently in IE6/7.
 */
menu {
  padding: 0 0 0 40px; }

ol,
ul {
  padding: 0;
  list-style-type: none; }

/*
 * Corrects list images handled incorrectly in IE7.
 */
nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

/* ==========================================================================
  Embedded content
========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9.
 */
img {
  border: 0; }

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden; }

/* ==========================================================================
   Figures
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari 5.
 */
figure {
  margin: 0; }

/* ==========================================================================
   Forms
   ========================================================================== */
/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * 1. Correct font family not being inherited in all browsers.
 * 2. Correct font size not being inherited in all browsers.
 * 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
 */
button,
input,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* ==========================================================================
   Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.image-replacement,
.ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.clearfix, .sidebar ul:last-child:after,
#top ul:last-child:after, .footer .row2, .cf, .comment-respond {
  zoom: 1; }
  .clearfix:before, .footer .row2:before, .clearfix:after, .sidebar ul:last-child:after,
  #top ul:last-child:after, .footer .row2:after, .cf:before, .comment-respond:before, .cf:after, .comment-respond:after {
    content: "";
    display: table; }
  .clearfix:after, .sidebar ul:last-child:after,
  #top ul:last-child:after, .footer .row2:after, .cf:after, .comment-respond:after {
    clear: both; }

/*
use the best ampersand
http://simplebits.com/notebook/2008/08/14/ampersands-2/
*/
span.amp {
  font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
  font-style: normal; }

/******************************************************************
Site Name:
Author:

Stylesheet: Variables

Here is where we declare all our variables like colors, fonts,
base values, and defaults. We want to make sure this file ONLY
contains variables that way our files don't get all messy.
No one likes a mess.

******************************************************************/
/*********************
COLORS
Need help w/ choosing your colors? Try this site out:
http://0to255.com/
*********************/
/*
Here's a great tutorial on how to
use color variables properly:
http://sachagreif.com/sass-color-variables/
*/
/******************************************************************
Site Name:
Author:

Stylesheet: Typography

Need to import a font or set of icons for your site? Drop them in
here or just use this to establish your typographical grid. Or not.
Do whatever you want to...GOSH!

Helpful Articles:
http://trentwalton.com/2012/06/19/fluid-type/
http://ia.net/blog/responsive-typography-the-basics/
http://alistapart.com/column/responsive-typography-is-a-physical-discipline

******************************************************************/
/*********************
FONT FACE (IN YOUR FACE)
*********************/
/*  To embed your own fonts, use this syntax
  and place your fonts inside the
  library/fonts folder. For more information
  on embedding fonts, go to:
  http://www.fontsquirrel.com/
  Be sure to remove the comment brackets.
*/
@font-face {
  font-family: 'anna-kopp-webfont';
  src: url("../../library/fonts/anna-kopp-webfont.eot");
  src: url("../../library/fonts/anna-kopp-webfont.eot?#iefix") format("embedded-opentype"), url("../../library/fonts/anna-kopp-webfont.woff") format("woff"), url("../../library/fonts/anna-kopp-webfont.ttf") format("truetype"), url("../../library/fonts/anna-kopp-webfont.svg#anna-kopp-webfont") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PPEditorialNew-Regular';
  src: url("../../library/fonts/PPEditorialNew-Regular.woff2") format("woff2"), url("../../library/fonts/PPEditorialNew-Regular.woff") format("woff"), url("../../library/fonts/PPEditorialNew-Regular.ttf") format("truetype"), url("../../library/fonts/PPEditorialNew-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'HarmoniaSansProCyr';
  src: url("../../library/fonts/HarmoniaSansProCyr-Regular.woff2") format("woff2"), url("../../library/fonts/HarmoniaSansProCyr-Regular.woff") format("woff"), url("../../library/fonts/HarmoniaSansProCyr-Regular.ttf") format("truetype"), url("../../library/fonts/HarmoniaSansProCyr-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

/*
The following is based of Typebase:
https://github.com/devinhunt/typebase.css
I've edited it a bit, but it's a nice starting point.
*/
/*
some nice typographical defaults
more here: http://www.newnet-soft.com/blog/csstypography
*/
p {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  -webkit-hyphenate-before: 2;
  -webkit-hyphenate-after: 3;
  hyphenate-lines: 3; }

/******************************************************************
Site Name:
Author:

Stylesheet: Sass Functions

You can do a lot of really cool things in Sass. Functions help you
make repeated actions a lot easier. They are really similar to mixins,
but can be used for so much more.

Anyway, keep them all in here so it's easier to find when you're
looking for one.

For more info on functions, go here:
http://sass-lang.com/documentation/Sass/Script/Functions.html

******************************************************************/
/*********************
COLOR FUNCTIONS
These are helpful when you're working
with shadows and such things. It's essentially
a quicker way to write RGBA.

Example:
box-shadow: 0 0 4px black(0.3);
compiles to:
box-shadow: 0 0 4px rgba(0,0,0,0.3);
*********************/
/*********************
RESPONSIVE HELPER FUNCTION
If you're creating a responsive site, then
you've probably already read
Responsive Web Design: http://www.abookapart.com/products/responsive-web-design

Here's a nice little helper function for calculating
target / context
as mentioned in that book.

Example:
width: cp(650px, 1000px);
or
width: calc-percent(650px, 1000px);
both compile to:
width: 65%;
*********************/
@keyframes shakeX {
  from,
  to,
  30% {
    transform: translateX(calc(100% - 40px)); }
  5%,
  15%,
  25% {
    transform: translateX(calc(100% - 42px)); }
  10%,
  20% {
    transform: translateX(calc(100% - 35px)); } }

@keyframes open {
  from {
    transform: translateX(calc(100% - 40px)); }
  to {
    transform: translateX(0); } }

/******************************************************************
Site Name:
Author:

Stylesheet: Mixins Stylesheet

This is where you can take advantage of Sass' great features: Mixins.
I won't go in-depth on how they work exactly,
there are a few articles below that will help do that. What I will
tell you is that this will help speed up simple changes like
changing a color or adding CSS3 techniques gradients.

A WORD OF WARNING: It's very easy to overdo it here. Be careful and
remember less is more.

Helpful:
http://sachagreif.com/useful-sass-mixins/
http://thesassway.com/intermediate/leveraging-sass-mixins-for-cleaner-code
http://web-design-weekly.com/blog/2013/05/12/handy-sass-mixins/

******************************************************************/
/*********************
TRANSITION
*********************/
/*
I totally rewrote this to be cleaner and easier to use.
You'll need to be using Sass 3.2+ for these to work.
Thanks to @anthonyshort for the inspiration on these.
USAGE: @include transition(all 0.2s ease-in-out);
*/
/*********************
CSS3 GRADIENTS
Be careful with these since they can
really slow down your CSS. Don't overdo it.
*********************/
/* @include css-gradient(#dfdfdf,#f8f8f8); */
/*********************
BOX SIZING
*********************/
/* @include box-sizing(border-box); */
/* NOTE: value of "padding-box" is only supported in Gecko. So
probably best not to use it. I mean, were you going to anyway? */
/*********************
IMPORTING MODULES
Modules are reusable blocks or elements we use throughout the project.
We can break them up as much as we want or just keep them all in one.
I mean, you can do whatever you want. The world is your oyster. Unless
you hate oysters, then the world is your peanut butter & jelly sandwich.
*********************/
/******************************************************************
Site Name:
Author:

Stylesheet: Alert Styles

If you want to use these alerts in your design, you can. If not,
you can just remove this stylesheet.

******************************************************************/
.alert-help, .alert-info, .alert-error, .alert-success {
  margin: 10px;
  padding: 5px 18px;
  border: 1px solid; }

.alert-help {
  border-color: #e8dc59;
  background: #ebe16f; }

.alert-info {
  border-color: #bfe4f4;
  background: #d5edf8; }

.alert-error {
  border-color: #f8cdce;
  background: #fbe3e4; }

.alert-success {
  border-color: #deeaae;
  background: #e6efc2; }

/******************************************************************
Site Name:
Author:

Stylesheet: Button Styles

Buttons are a pretty important part of your site's style, so it's
important to have a consistent baseline for them. Use this stylesheet
to create all kinds of buttons.

Helpful Links:
http://galp.in/blog/2011/08/02/the-ui-guide-part-1-buttons/

******************************************************************/
/*********************
BUTTON DEFAULTS
We're gonna use a placeholder selector here
so we can use common styles. We then use this
to load up the defaults in all our buttons.

Here's a quick video to show how it works:
http://www.youtube.com/watch?v=hwdVpKiJzac

*********************/
.blue-btn, .comment-reply-link, #submit {
  display: inline-block;
  position: relative;
  font-family: PPEditorialNew-Regular, sans-serif;
  text-decoration: none;
  color: #fff;
  font-size: 0.9em;
  font-size: 34px;
  line-height: 34px;
  font-weight: normal;
  padding: 0 24px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  -webkit-transition: background-color 0.14s ease-in-out;
  transition: background-color 0.14s ease-in-out; }
  .blue-btn:hover, .comment-reply-link:hover, #submit:hover, .blue-btn:focus, .comment-reply-link:focus, #submit:focus {
    color: #fff;
    text-decoration: none; }
  .blue-btn:active, .comment-reply-link:active, #submit:active {
    top: 1px; }

/*
An example button.
You can use this example if you want. Just replace all the variables
and it will create a button dependant on those variables.
*/
.blue-btn, .comment-reply-link, #submit {
  background-color: #385a68; }
  .blue-btn:hover, .comment-reply-link:hover, #submit:hover, .blue-btn:focus, .comment-reply-link:focus, #submit:focus {
    background-color: #314f5b; }
  .blue-btn:active, .comment-reply-link:active, #submit:active {
    background-color: #2f4c57; }

/******************************************************************
Site Name:
Author:

Stylesheet: Form Styles

We put all the form and button styles in here to setup a consistent
look. If we need to customize them, we can do this in the main
stylesheets and just override them. Easy Peasy.

You're gonna see a few data-uri thingies down there. If you're not
sure what they are, check this link out:
http://css-tricks.com/data-uris/
If you want to create your own, use this helpful link:
http://websemantics.co.uk/online_tools/image_to_data_uri_convertor/

******************************************************************/
/*********************
INPUTS
*********************/
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
select,
textarea,
.field {
  display: block;
  height: 45px;
  line-height: 45px;
  padding: 0 12px;
  font-size: 1.5em;
  color: black;
  vertical-align: middle;
  box-shadow: none;
  border: 1px solid black;
  width: 100%;
  max-width: 400px;
  -webkit-transition: background-color 0.24s ease-in-out;
  transition: background-color 0.24s ease-in-out;
  -webkit-appearance: none;
  border-radius: 0; }
  input[type="text"]:focus, input[type="text"]:active,
  input[type="password"]:focus,
  input[type="password"]:active,
  input[type="datetime"]:focus,
  input[type="datetime"]:active,
  input[type="datetime-local"]:focus,
  input[type="datetime-local"]:active,
  input[type="date"]:focus,
  input[type="date"]:active,
  input[type="month"]:focus,
  input[type="month"]:active,
  input[type="time"]:focus,
  input[type="time"]:active,
  input[type="week"]:focus,
  input[type="week"]:active,
  input[type="number"]:focus,
  input[type="number"]:active,
  input[type="email"]:focus,
  input[type="email"]:active,
  input[type="url"]:focus,
  input[type="url"]:active,
  input[type="search"]:focus,
  input[type="search"]:active,
  input[type="tel"]:focus,
  input[type="tel"]:active,
  input[type="color"]:focus,
  input[type="color"]:active,
  select:focus,
  select:active,
  textarea:focus,
  textarea:active,
  .field:focus,
  .field:active {
    background-color: #f7f8fa; }
  input[type="text"].error, input[type="text"].is-invalid,
  input[type="password"].error,
  input[type="password"].is-invalid,
  input[type="datetime"].error,
  input[type="datetime"].is-invalid,
  input[type="datetime-local"].error,
  input[type="datetime-local"].is-invalid,
  input[type="date"].error,
  input[type="date"].is-invalid,
  input[type="month"].error,
  input[type="month"].is-invalid,
  input[type="time"].error,
  input[type="time"].is-invalid,
  input[type="week"].error,
  input[type="week"].is-invalid,
  input[type="number"].error,
  input[type="number"].is-invalid,
  input[type="email"].error,
  input[type="email"].is-invalid,
  input[type="url"].error,
  input[type="url"].is-invalid,
  input[type="search"].error,
  input[type="search"].is-invalid,
  input[type="tel"].error,
  input[type="tel"].is-invalid,
  input[type="color"].error,
  input[type="color"].is-invalid,
  select.error,
  select.is-invalid,
  textarea.error,
  textarea.is-invalid,
  .field.error,
  .field.is-invalid {
    color: #fbe3e4;
    border-color: #fbe3e4;
    background-color: #fff;
    background-position: 99% center;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDM0NDREQkYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDM0NDREQ0YwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ3ODRGRkE2RjA0QTExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0MzQ0NERBRjA0QjExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+U8iT5wAAAedJREFUeNqk1U9I02Ecx/HtV3aIFAc1hcRDUoGXRAq0oNLA2CrsEFmHbikZu0iQYIFGYAiegkCpLipBxPpDEBMUzB0EhSG2LhG7hMR2GviPgUTvB57Bw8P3+U23B16HPX8+e/b8nt93wezZSMCnhXETF3AcB5BDCnH8Dq98ExcGHcFn8Ah3cdDni+fxnPDv9oAnTB7CKu6VCFXtChZy56LxUjt+jfuB8toSOth9wd7xWAWhqrUjYR/FRTwWJm+iIPT/w7bQf5ljiZnBg45dtKFX6H+LU8gIY8OEV6vgTkStwXWE8BPTGDHGPqNPz2mCfSOOYkA99TvCt1bhGPL68zMcwmncMuape10jrI+q4BbHi/FLn31S9z2x5tRhTc+1W506ipM+T3oRD4X+8+qtc4SqFvL0z/Fr14S+Szjis8bz9Lvvaq8cwS/wwGfdlqfPSWqTiFlX77o13u9Ym1PBs8JAytpRoy44X9Ft9E/gvbA+rYKn8NcaaMVc8UHgBw4b9/iqUQZ6hOAJFbyDcUflmsEX4a6+wTtHGfhAIUqa1U29Zc2BytouThD8x6xuN5CtMPi2CrXLZkZf/HyZoRFCP7n+QVR4PV7uI/AjGghN7OU/r1ilnqILtfpNC+o6vIFljBKYlhb/F2AAgaBsWR5wRiIAAAAASUVORK5CYII=);
    outline-color: #fbe3e4; }
  input[type="text"].success, input[type="text"].is-valid,
  input[type="password"].success,
  input[type="password"].is-valid,
  input[type="datetime"].success,
  input[type="datetime"].is-valid,
  input[type="datetime-local"].success,
  input[type="datetime-local"].is-valid,
  input[type="date"].success,
  input[type="date"].is-valid,
  input[type="month"].success,
  input[type="month"].is-valid,
  input[type="time"].success,
  input[type="time"].is-valid,
  input[type="week"].success,
  input[type="week"].is-valid,
  input[type="number"].success,
  input[type="number"].is-valid,
  input[type="email"].success,
  input[type="email"].is-valid,
  input[type="url"].success,
  input[type="url"].is-valid,
  input[type="search"].success,
  input[type="search"].is-valid,
  input[type="tel"].success,
  input[type="tel"].is-valid,
  input[type="color"].success,
  input[type="color"].is-valid,
  select.success,
  select.is-valid,
  textarea.success,
  textarea.is-valid,
  .field.success,
  .field.is-valid {
    color: #e6efc2;
    border-color: #e6efc2;
    background-color: #fff;
    background-position: 99% center;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDM0NDRERkYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDM0NDRFMEYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY0MzQ0NERERjA0QjExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0MzQ0NERFRjA0QjExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+7olkTQAAAfhJREFUeNqklU9oE0EUhzdroWjw0tBeWlDxkEaIp55TsCU9VKIgCrHBelA8CQ1Kr1WPbZrQ3gqtQqvGqxpQc2jBk6BIIaAGD4qNCKURpDSKiPi98gLDsJt//uBjsztvfnk7895sIPAw6/joGMThFJyAXn2+A+9gA57/TaY/eU0OeBgfhGm4DiGnsb7DAszxBz/NAdcKjMJLuNWCqagHbsscN5+L+hmH4QkMOe1L5jzFfNA2PgT34ajTuY7AGuZB0/hmh5m+gS0r8xv1zRvg+gGCHZiOwnF4DP3iB3sQkYxPd2C6CWfhB9Xwlus5+K1j4jXuaq3a+gM1H9OPcAa+7q9lPidZJqHbiIm7Wg22rsEI7FrPSzAMX/T+ADyAKSsu7Fr1KplehLvwCs5DvfBf65p+MypqRbO1FXK9utH4/QKuaqYTsG3E3INJv00Q46px3+XxanJ/Ute2/vqP4FKDza2KcdljIKdnhS0xXYULTaqmLMZFn8FFSFtvt6x70ExFCS5oUXspq2ssa7oEl1swFa+CGFdgtkHgPDyDKy02zyxNU6lXRUZb1EuHYayNNs+Yh5B0WQo+/8fpJnNTZFuzz2OpjoQ2QruSOQlMy35fEGmEGMxY9e1brxobw7TkWA1h6xfckUPb+JhGoE/Hpfvew7qUld/H9J8AAwDpw3WYrxcZ3QAAAABJRU5ErkJggg==);
    outline-color: #e6efc2; }
  input[type="text"][disabled], input[type="text"].is-disabled,
  input[type="password"][disabled],
  input[type="password"].is-disabled,
  input[type="datetime"][disabled],
  input[type="datetime"].is-disabled,
  input[type="datetime-local"][disabled],
  input[type="datetime-local"].is-disabled,
  input[type="date"][disabled],
  input[type="date"].is-disabled,
  input[type="month"][disabled],
  input[type="month"].is-disabled,
  input[type="time"][disabled],
  input[type="time"].is-disabled,
  input[type="week"][disabled],
  input[type="week"].is-disabled,
  input[type="number"][disabled],
  input[type="number"].is-disabled,
  input[type="email"][disabled],
  input[type="email"].is-disabled,
  input[type="url"][disabled],
  input[type="url"].is-disabled,
  input[type="search"][disabled],
  input[type="search"].is-disabled,
  input[type="tel"][disabled],
  input[type="tel"].is-disabled,
  input[type="color"][disabled],
  input[type="color"].is-disabled,
  select[disabled],
  select.is-disabled,
  textarea[disabled],
  textarea.is-disabled,
  .field[disabled],
  .field.is-disabled {
    cursor: not-allowed;
    border-color: #cfcfcf;
    opacity: 0.6; }
    input[type="text"][disabled]:focus, input[type="text"][disabled]:active, input[type="text"].is-disabled:focus, input[type="text"].is-disabled:active,
    input[type="password"][disabled]:focus,
    input[type="password"][disabled]:active,
    input[type="password"].is-disabled:focus,
    input[type="password"].is-disabled:active,
    input[type="datetime"][disabled]:focus,
    input[type="datetime"][disabled]:active,
    input[type="datetime"].is-disabled:focus,
    input[type="datetime"].is-disabled:active,
    input[type="datetime-local"][disabled]:focus,
    input[type="datetime-local"][disabled]:active,
    input[type="datetime-local"].is-disabled:focus,
    input[type="datetime-local"].is-disabled:active,
    input[type="date"][disabled]:focus,
    input[type="date"][disabled]:active,
    input[type="date"].is-disabled:focus,
    input[type="date"].is-disabled:active,
    input[type="month"][disabled]:focus,
    input[type="month"][disabled]:active,
    input[type="month"].is-disabled:focus,
    input[type="month"].is-disabled:active,
    input[type="time"][disabled]:focus,
    input[type="time"][disabled]:active,
    input[type="time"].is-disabled:focus,
    input[type="time"].is-disabled:active,
    input[type="week"][disabled]:focus,
    input[type="week"][disabled]:active,
    input[type="week"].is-disabled:focus,
    input[type="week"].is-disabled:active,
    input[type="number"][disabled]:focus,
    input[type="number"][disabled]:active,
    input[type="number"].is-disabled:focus,
    input[type="number"].is-disabled:active,
    input[type="email"][disabled]:focus,
    input[type="email"][disabled]:active,
    input[type="email"].is-disabled:focus,
    input[type="email"].is-disabled:active,
    input[type="url"][disabled]:focus,
    input[type="url"][disabled]:active,
    input[type="url"].is-disabled:focus,
    input[type="url"].is-disabled:active,
    input[type="search"][disabled]:focus,
    input[type="search"][disabled]:active,
    input[type="search"].is-disabled:focus,
    input[type="search"].is-disabled:active,
    input[type="tel"][disabled]:focus,
    input[type="tel"][disabled]:active,
    input[type="tel"].is-disabled:focus,
    input[type="tel"].is-disabled:active,
    input[type="color"][disabled]:focus,
    input[type="color"][disabled]:active,
    input[type="color"].is-disabled:focus,
    input[type="color"].is-disabled:active,
    select[disabled]:focus,
    select[disabled]:active,
    select.is-disabled:focus,
    select.is-disabled:active,
    textarea[disabled]:focus,
    textarea[disabled]:active,
    textarea.is-disabled:focus,
    textarea.is-disabled:active,
    .field[disabled]:focus,
    .field[disabled]:active,
    .field.is-disabled:focus,
    .field.is-disabled:active {
      background-color: #d5edf8; }

input[type="password"] {
  letter-spacing: 0.3em; }

textarea {
  max-width: 100%;
  min-height: 120px;
  line-height: 1.5em; }

select {
  -webkit-appearance: none;
  /* 1 */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAHCAYAAADXhRcnAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpEOEZCMjYxMEYwNUUxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpEOEZCMjYxMUYwNUUxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ4RkIyNjBFRjA1RTExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ4RkIyNjBGRjA1RTExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Vxkp9gAAAI9JREFUeNpidHFxucHAwKAOxE+AmJmBMPgLxDJAfJMFSKwD4kqoAClgA+P///8ZXF1dPaCGcBKh6QcQB+3evXs7WDMIAA2QB1I7gFgDj0aQFz2BGh+AOEwwUaDAQyBlCMR7cGjcC5KHaQQBuM3IAOiKTiBVhiTUDdRUhq4Oq2aoAelAahIQ5wM1zsCmBiDAADhYMJXVZ9u9AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 97.5% center; }

/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Vendor prefix no longer required.
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
/**
 * Objects
 */
p.demo_store,
.woocommerce-store-notice {
  position: fixed;
  top: auto !important;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  font-size: 2em;
  padding: 1em 0;
  text-align: center;
  background-color: #eae1de;
  color: black;
  z-index: 99998;
  box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
  display: block; }
  p.demo_store a,
  p.demo_store a:visited,
  .woocommerce-store-notice a,
  .woocommerce-store-notice a:visited {
    color: black;
    text-decoration: underline; }

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; }

.admin-bar p.demo_store {
  top: auto !important; }

/**
 * Utility classes
 */
.clear {
  clear: both; }

/**
 * Main WooCommerce styles
 */
.woocommerce,
.woocommerce-page,
.xoo-wsc-modal,
.footer_btn,
.block_startseite {
  /**
     * Product Page
     */
  /**
     * Product loops
     */
  /**
     * Buttons
     */
  /**
     * Reviews
     */
  /**
     * Star ratings
     */
  /**
     * Tables
     */
  /**
     * Cart sidebar
     */
  /**
     * Forms
     */
  /**
     * Order page
     */
  /**
     * Layered nav widget
     */
  /**
     * Price filter widget
     */
  /**
     * Rating Filter Widget
     */ }
  .woocommerce .blockUI.blockOverlay,
  .woocommerce-page .blockUI.blockOverlay,
  .xoo-wsc-modal .blockUI.blockOverlay,
  .footer_btn .blockUI.blockOverlay,
  .block_startseite .blockUI.blockOverlay {
    position: relative; }
  .woocommerce a.remove,
  .woocommerce-page a.remove,
  .xoo-wsc-modal a.remove,
  .footer_btn a.remove,
  .block_startseite a.remove {
    display: block;
    font-size: 30px;
    height: 1em;
    width: 1em;
    text-align: center;
    line-height: 1.1;
    border-radius: 100%;
    color: #5a333d !important;
    text-decoration: none;
    font-weight: 700;
    border: 0; }
    .woocommerce a.remove:hover,
    .woocommerce-page a.remove:hover,
    .xoo-wsc-modal a.remove:hover,
    .footer_btn a.remove:hover,
    .block_startseite a.remove:hover {
      color: #fff !important;
      background: #5a333d;
      font-size: 30px;
      font-weight: 300; }
  .woocommerce small.note,
  .woocommerce-page small.note,
  .xoo-wsc-modal small.note,
  .footer_btn small.note,
  .block_startseite small.note {
    display: block;
    color: black;
    font-size: 0.857em;
    margin-top: 10px; }
  .woocommerce .woocommerce-breadcrumb,
  .woocommerce-page .woocommerce-breadcrumb,
  .xoo-wsc-modal .woocommerce-breadcrumb,
  .footer_btn .woocommerce-breadcrumb,
  .block_startseite .woocommerce-breadcrumb {
    *zoom: 1;
    margin: 0 0 1em;
    padding: 0;
    font-size: 1.1rem;
    color: black;
    font-family: HarmoniaSansProCyr, mono;
    margin-bottom: 50px; }
    .woocommerce .woocommerce-breadcrumb::before, .woocommerce .woocommerce-breadcrumb::after,
    .woocommerce-page .woocommerce-breadcrumb::before,
    .woocommerce-page .woocommerce-breadcrumb::after,
    .xoo-wsc-modal .woocommerce-breadcrumb::before,
    .xoo-wsc-modal .woocommerce-breadcrumb::after,
    .footer_btn .woocommerce-breadcrumb::before,
    .footer_btn .woocommerce-breadcrumb::after,
    .block_startseite .woocommerce-breadcrumb::before,
    .block_startseite .woocommerce-breadcrumb::after {
      content: ' ';
      display: table; }
    .woocommerce .woocommerce-breadcrumb::after,
    .woocommerce-page .woocommerce-breadcrumb::after,
    .xoo-wsc-modal .woocommerce-breadcrumb::after,
    .footer_btn .woocommerce-breadcrumb::after,
    .block_startseite .woocommerce-breadcrumb::after {
      clear: both; }
    @media screen and (max-width: 768px) {
      .woocommerce .woocommerce-breadcrumb,
      .woocommerce-page .woocommerce-breadcrumb,
      .xoo-wsc-modal .woocommerce-breadcrumb,
      .footer_btn .woocommerce-breadcrumb,
      .block_startseite .woocommerce-breadcrumb {
        padding: 0 20px; } }
    .woocommerce .woocommerce-breadcrumb a,
    .woocommerce-page .woocommerce-breadcrumb a,
    .xoo-wsc-modal .woocommerce-breadcrumb a,
    .footer_btn .woocommerce-breadcrumb a,
    .block_startseite .woocommerce-breadcrumb a {
      color: black; }
  .woocommerce .quantity .qty,
  .woocommerce-page .quantity .qty,
  .xoo-wsc-modal .quantity .qty,
  .footer_btn .quantity .qty,
  .block_startseite .quantity .qty {
    font-size: 20px;
    text-align: center; }
  .woocommerce .quantity-label,
  .woocommerce-page .quantity-label,
  .xoo-wsc-modal .quantity-label,
  .footer_btn .quantity-label,
  .block_startseite .quantity-label {
    display: none; }
  .woocommerce div.product,
  .woocommerce-page div.product,
  .xoo-wsc-modal div.product,
  .footer_btn div.product,
  .block_startseite div.product {
    margin-bottom: 0;
    position: relative; }
    .woocommerce div.product .product_title,
    .woocommerce-page div.product .product_title,
    .xoo-wsc-modal div.product .product_title,
    .footer_btn div.product .product_title,
    .block_startseite div.product .product_title {
      clear: none;
      margin-top: 0;
      padding: 0; }
    .woocommerce div.product span.price,
    .woocommerce div.product p.price,
    .woocommerce-page div.product span.price,
    .woocommerce-page div.product p.price,
    .xoo-wsc-modal div.product span.price,
    .xoo-wsc-modal div.product p.price,
    .footer_btn div.product span.price,
    .footer_btn div.product p.price,
    .block_startseite div.product span.price,
    .block_startseite div.product p.price {
      color: black;
      font-size: 30px;
      letter-spacing: 0.01em;
      margin: 0; }
      .woocommerce div.product span.price .woocommerce-Price-currencySymbol,
      .woocommerce div.product p.price .woocommerce-Price-currencySymbol,
      .woocommerce-page div.product span.price .woocommerce-Price-currencySymbol,
      .woocommerce-page div.product p.price .woocommerce-Price-currencySymbol,
      .xoo-wsc-modal div.product span.price .woocommerce-Price-currencySymbol,
      .xoo-wsc-modal div.product p.price .woocommerce-Price-currencySymbol,
      .footer_btn div.product span.price .woocommerce-Price-currencySymbol,
      .footer_btn div.product p.price .woocommerce-Price-currencySymbol,
      .block_startseite div.product span.price .woocommerce-Price-currencySymbol,
      .block_startseite div.product p.price .woocommerce-Price-currencySymbol {
        margin-right: 0.2em; }
      .woocommerce div.product span.price ins,
      .woocommerce div.product p.price ins,
      .woocommerce-page div.product span.price ins,
      .woocommerce-page div.product p.price ins,
      .xoo-wsc-modal div.product span.price ins,
      .xoo-wsc-modal div.product p.price ins,
      .footer_btn div.product span.price ins,
      .footer_btn div.product p.price ins,
      .block_startseite div.product span.price ins,
      .block_startseite div.product p.price ins {
        background: inherit;
        font-weight: 700;
        display: inline-block; }
      .woocommerce div.product span.price del,
      .woocommerce div.product p.price del,
      .woocommerce-page div.product span.price del,
      .woocommerce-page div.product p.price del,
      .xoo-wsc-modal div.product span.price del,
      .xoo-wsc-modal div.product p.price del,
      .footer_btn div.product span.price del,
      .footer_btn div.product p.price del,
      .block_startseite div.product span.price del,
      .block_startseite div.product p.price del {
        opacity: 0.5;
        display: inline-block; }
    .woocommerce div.product p.stock,
    .woocommerce-page div.product p.stock,
    .xoo-wsc-modal div.product p.stock,
    .footer_btn div.product p.stock,
    .block_startseite div.product p.stock {
      font-size: 0.92em; }
    .woocommerce div.product .stock,
    .woocommerce-page div.product .stock,
    .xoo-wsc-modal div.product .stock,
    .footer_btn div.product .stock,
    .block_startseite div.product .stock {
      color: black; }
    .woocommerce div.product .out-of-stock,
    .woocommerce-page div.product .out-of-stock,
    .xoo-wsc-modal div.product .out-of-stock,
    .footer_btn div.product .out-of-stock,
    .block_startseite div.product .out-of-stock {
      color: red; }
    .woocommerce div.product .woocommerce-product-rating,
    .woocommerce-page div.product .woocommerce-product-rating,
    .xoo-wsc-modal div.product .woocommerce-product-rating,
    .footer_btn div.product .woocommerce-product-rating,
    .block_startseite div.product .woocommerce-product-rating {
      margin-bottom: 1.618em; }
    .woocommerce div.product div.images,
    .woocommerce-page div.product div.images,
    .xoo-wsc-modal div.product div.images,
    .footer_btn div.product div.images,
    .block_startseite div.product div.images {
      margin-bottom: 2em; }
      @media screen and (min-width: 768px) and (max-width: 1240px) {
        .woocommerce div.product div.images,
        .woocommerce-page div.product div.images,
        .xoo-wsc-modal div.product div.images,
        .footer_btn div.product div.images,
        .block_startseite div.product div.images {
          width: 31% !important; } }
      .woocommerce div.product div.images img,
      .woocommerce-page div.product div.images img,
      .xoo-wsc-modal div.product div.images img,
      .footer_btn div.product div.images img,
      .block_startseite div.product div.images img {
        display: block;
        width: 100%;
        height: auto;
        box-shadow: none; }
        @media screen and (max-width: 1030px) {
          .woocommerce div.product div.images img.wp-post-image,
          .woocommerce-page div.product div.images img.wp-post-image,
          .xoo-wsc-modal div.product div.images img.wp-post-image,
          .footer_btn div.product div.images img.wp-post-image,
          .block_startseite div.product div.images img.wp-post-image {
            height: 300px !important;
            width: auto;
            margin: 0 auto 30px auto; } }
      .woocommerce div.product div.images div.thumbnails,
      .woocommerce-page div.product div.images div.thumbnails,
      .xoo-wsc-modal div.product div.images div.thumbnails,
      .footer_btn div.product div.images div.thumbnails,
      .block_startseite div.product div.images div.thumbnails {
        padding-top: 1em; }
      .woocommerce div.product div.images.woocommerce-product-gallery,
      .woocommerce-page div.product div.images.woocommerce-product-gallery,
      .xoo-wsc-modal div.product div.images.woocommerce-product-gallery,
      .footer_btn div.product div.images.woocommerce-product-gallery,
      .block_startseite div.product div.images.woocommerce-product-gallery {
        position: relative; }
      .woocommerce div.product div.images .woocommerce-product-gallery__wrapper,
      .woocommerce-page div.product div.images .woocommerce-product-gallery__wrapper,
      .xoo-wsc-modal div.product div.images .woocommerce-product-gallery__wrapper,
      .footer_btn div.product div.images .woocommerce-product-gallery__wrapper,
      .block_startseite div.product div.images .woocommerce-product-gallery__wrapper {
        transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
        margin: 0;
        padding: 0; }
      .woocommerce div.product div.images .woocommerce-product-gallery__wrapper .zoomImg,
      .woocommerce-page div.product div.images .woocommerce-product-gallery__wrapper .zoomImg,
      .xoo-wsc-modal div.product div.images .woocommerce-product-gallery__wrapper .zoomImg,
      .footer_btn div.product div.images .woocommerce-product-gallery__wrapper .zoomImg,
      .block_startseite div.product div.images .woocommerce-product-gallery__wrapper .zoomImg {
        background-color: #fff;
        opacity: 0; }
      .woocommerce div.product div.images .woocommerce-product-gallery__image--placeholder,
      .woocommerce-page div.product div.images .woocommerce-product-gallery__image--placeholder,
      .xoo-wsc-modal div.product div.images .woocommerce-product-gallery__image--placeholder,
      .footer_btn div.product div.images .woocommerce-product-gallery__image--placeholder,
      .block_startseite div.product div.images .woocommerce-product-gallery__image--placeholder {
        border: 1px solid #f2f2f2; }
      .woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2),
      .woocommerce-page div.product div.images .woocommerce-product-gallery__image:nth-child(n+2),
      .xoo-wsc-modal div.product div.images .woocommerce-product-gallery__image:nth-child(n+2),
      .footer_btn div.product div.images .woocommerce-product-gallery__image:nth-child(n+2),
      .block_startseite div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
        width: 25%;
        display: inline-block; }
      .woocommerce div.product div.images .woocommerce-product-gallery__trigger,
      .woocommerce-page div.product div.images .woocommerce-product-gallery__trigger,
      .xoo-wsc-modal div.product div.images .woocommerce-product-gallery__trigger,
      .footer_btn div.product div.images .woocommerce-product-gallery__trigger,
      .block_startseite div.product div.images .woocommerce-product-gallery__trigger {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: 2em;
        z-index: 9;
        width: 36px;
        height: 36px;
        background: #fff;
        text-indent: -9999px;
        border-radius: 100%;
        box-sizing: content-box; }
        .woocommerce div.product div.images .woocommerce-product-gallery__trigger::before,
        .woocommerce-page div.product div.images .woocommerce-product-gallery__trigger::before,
        .xoo-wsc-modal div.product div.images .woocommerce-product-gallery__trigger::before,
        .footer_btn div.product div.images .woocommerce-product-gallery__trigger::before,
        .block_startseite div.product div.images .woocommerce-product-gallery__trigger::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border: 2px solid black;
          border-radius: 100%;
          position: absolute;
          top: 9px;
          left: 9px;
          box-sizing: content-box; }
        .woocommerce div.product div.images .woocommerce-product-gallery__trigger::after,
        .woocommerce-page div.product div.images .woocommerce-product-gallery__trigger::after,
        .xoo-wsc-modal div.product div.images .woocommerce-product-gallery__trigger::after,
        .footer_btn div.product div.images .woocommerce-product-gallery__trigger::after,
        .block_startseite div.product div.images .woocommerce-product-gallery__trigger::after {
          content: "";
          display: block;
          width: 2px;
          height: 8px;
          background: black;
          border-radius: 6px;
          position: absolute;
          top: 19px;
          left: 22px;
          transform: rotate(-45deg);
          box-sizing: content-box; }
      .woocommerce div.product div.images .flex-control-thumbs,
      .woocommerce-page div.product div.images .flex-control-thumbs,
      .xoo-wsc-modal div.product div.images .flex-control-thumbs,
      .footer_btn div.product div.images .flex-control-thumbs,
      .block_startseite div.product div.images .flex-control-thumbs {
        overflow: hidden;
        zoom: 1;
        margin: 0;
        padding: 0; }
        .woocommerce div.product div.images .flex-control-thumbs li,
        .woocommerce-page div.product div.images .flex-control-thumbs li,
        .xoo-wsc-modal div.product div.images .flex-control-thumbs li,
        .footer_btn div.product div.images .flex-control-thumbs li,
        .block_startseite div.product div.images .flex-control-thumbs li {
          width: 25%;
          float: left;
          margin: 0;
          list-style: none; }
          .woocommerce div.product div.images .flex-control-thumbs li img,
          .woocommerce-page div.product div.images .flex-control-thumbs li img,
          .xoo-wsc-modal div.product div.images .flex-control-thumbs li img,
          .footer_btn div.product div.images .flex-control-thumbs li img,
          .block_startseite div.product div.images .flex-control-thumbs li img {
            cursor: pointer;
            opacity: 0.5;
            margin: 0; }
            .woocommerce div.product div.images .flex-control-thumbs li img.flex-active, .woocommerce div.product div.images .flex-control-thumbs li img:hover,
            .woocommerce-page div.product div.images .flex-control-thumbs li img.flex-active,
            .woocommerce-page div.product div.images .flex-control-thumbs li img:hover,
            .xoo-wsc-modal div.product div.images .flex-control-thumbs li img.flex-active,
            .xoo-wsc-modal div.product div.images .flex-control-thumbs li img:hover,
            .footer_btn div.product div.images .flex-control-thumbs li img.flex-active,
            .footer_btn div.product div.images .flex-control-thumbs li img:hover,
            .block_startseite div.product div.images .flex-control-thumbs li img.flex-active,
            .block_startseite div.product div.images .flex-control-thumbs li img:hover {
              opacity: 1; }
    .woocommerce div.product .woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1),
    .woocommerce-page div.product .woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1),
    .xoo-wsc-modal div.product .woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1),
    .footer_btn div.product .woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1),
    .block_startseite div.product .woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1) {
      clear: left; }
    .woocommerce div.product .woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1),
    .woocommerce-page div.product .woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1),
    .xoo-wsc-modal div.product .woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1),
    .footer_btn div.product .woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1),
    .block_startseite div.product .woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1) {
      clear: left; }
    .woocommerce div.product .woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1),
    .woocommerce-page div.product .woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1),
    .xoo-wsc-modal div.product .woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1),
    .footer_btn div.product .woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1),
    .block_startseite div.product .woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1) {
      clear: left; }
    .woocommerce div.product div.summary,
    .woocommerce-page div.product div.summary,
    .xoo-wsc-modal div.product div.summary,
    .footer_btn div.product div.summary,
    .block_startseite div.product div.summary {
      margin-bottom: 2em; }
      @media screen and (min-width: 768px) and (max-width: 1240px) {
        .woocommerce div.product div.summary,
        .woocommerce-page div.product div.summary,
        .xoo-wsc-modal div.product div.summary,
        .footer_btn div.product div.summary,
        .block_startseite div.product div.summary {
          width: 65% !important; } }
      @media screen and (max-width: 768px) {
        .woocommerce div.product div.summary,
        .woocommerce-page div.product div.summary,
        .xoo-wsc-modal div.product div.summary,
        .footer_btn div.product div.summary,
        .block_startseite div.product div.summary {
          padding: 20px; } }
    .woocommerce div.product div.social,
    .woocommerce-page div.product div.social,
    .xoo-wsc-modal div.product div.social,
    .footer_btn div.product div.social,
    .block_startseite div.product div.social {
      text-align: right;
      margin: 0 0 1em; }
      .woocommerce div.product div.social span,
      .woocommerce-page div.product div.social span,
      .xoo-wsc-modal div.product div.social span,
      .footer_btn div.product div.social span,
      .block_startseite div.product div.social span {
        margin: 0 0 0 2px; }
        .woocommerce div.product div.social span span,
        .woocommerce-page div.product div.social span span,
        .xoo-wsc-modal div.product div.social span span,
        .footer_btn div.product div.social span span,
        .block_startseite div.product div.social span span {
          margin: 0; }
        .woocommerce div.product div.social span .stButton .chicklets,
        .woocommerce-page div.product div.social span .stButton .chicklets,
        .xoo-wsc-modal div.product div.social span .stButton .chicklets,
        .footer_btn div.product div.social span .stButton .chicklets,
        .block_startseite div.product div.social span .stButton .chicklets {
          padding-left: 16px;
          width: 0; }
      .woocommerce div.product div.social iframe,
      .woocommerce-page div.product div.social iframe,
      .xoo-wsc-modal div.product div.social iframe,
      .footer_btn div.product div.social iframe,
      .block_startseite div.product div.social iframe {
        float: left;
        margin-top: 3px; }
    .woocommerce div.product .woocommerce-tabs ul.tabs,
    .woocommerce-page div.product .woocommerce-tabs ul.tabs,
    .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs,
    .footer_btn div.product .woocommerce-tabs ul.tabs,
    .block_startseite div.product .woocommerce-tabs ul.tabs {
      list-style: none;
      padding: 0 0 0 1em;
      margin: 0 0 1.618em;
      overflow: hidden;
      position: relative; }
      .woocommerce div.product .woocommerce-tabs ul.tabs li,
      .woocommerce-page div.product .woocommerce-tabs ul.tabs li,
      .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li,
      .footer_btn div.product .woocommerce-tabs ul.tabs li,
      .block_startseite div.product .woocommerce-tabs ul.tabs li {
        background-color: #5a333d;
        display: inline-block;
        position: relative;
        z-index: 0;
        border-radius: 4px 4px 0 0;
        margin: 0 -5px;
        padding: 0 1em;
        background: #eae1de;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-top: 1px solid black;
        border-bottom: 0;
        border-radius: 0; }
        .woocommerce div.product .woocommerce-tabs ul.tabs li a,
        .woocommerce-page div.product .woocommerce-tabs ul.tabs li a,
        .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li a,
        .footer_btn div.product .woocommerce-tabs ul.tabs li a,
        .block_startseite div.product .woocommerce-tabs ul.tabs li a {
          font-family: HarmoniaSansProCyr, mono;
          display: inline-block;
          padding: 0.5em 0;
          font-weight: 700;
          color: black;
          text-decoration: none;
          font-weight: 300;
          font-size: 14px; }
          .woocommerce div.product .woocommerce-tabs ul.tabs li a:hover,
          .woocommerce-page div.product .woocommerce-tabs ul.tabs li a:hover,
          .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li a:hover,
          .footer_btn div.product .woocommerce-tabs ul.tabs li a:hover,
          .block_startseite div.product .woocommerce-tabs ul.tabs li a:hover {
            text-decoration: none;
            color: #fff; }
          .woocommerce div.product .woocommerce-tabs ul.tabs li a:focus,
          .woocommerce-page div.product .woocommerce-tabs ul.tabs li a:focus,
          .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li a:focus,
          .footer_btn div.product .woocommerce-tabs ul.tabs li a:focus,
          .block_startseite div.product .woocommerce-tabs ul.tabs li a:focus {
            outline: 0; }
        .woocommerce div.product .woocommerce-tabs ul.tabs li.active,
        .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active,
        .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li.active,
        .footer_btn div.product .woocommerce-tabs ul.tabs li.active,
        .block_startseite div.product .woocommerce-tabs ul.tabs li.active {
          background: #fff;
          z-index: 2;
          border-bottom-color: #fff;
          border-radius: 0; }
          .woocommerce div.product .woocommerce-tabs ul.tabs li.active a,
          .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active a,
          .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li.active a,
          .footer_btn div.product .woocommerce-tabs ul.tabs li.active a,
          .block_startseite div.product .woocommerce-tabs ul.tabs li.active a {
            color: black;
            text-shadow: inherit; }
          .woocommerce div.product .woocommerce-tabs ul.tabs li.active::before,
          .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active::before,
          .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li.active::before,
          .footer_btn div.product .woocommerce-tabs ul.tabs li.active::before,
          .block_startseite div.product .woocommerce-tabs ul.tabs li.active::before {
            box-shadow: 2px 2px 0 #fff;
            display: none; }
          .woocommerce div.product .woocommerce-tabs ul.tabs li.active::after,
          .woocommerce-page div.product .woocommerce-tabs ul.tabs li.active::after,
          .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li.active::after,
          .footer_btn div.product .woocommerce-tabs ul.tabs li.active::after,
          .block_startseite div.product .woocommerce-tabs ul.tabs li.active::after {
            box-shadow: -2px 2px 0 #fff;
            display: none; }
        .woocommerce div.product .woocommerce-tabs ul.tabs li::before, .woocommerce div.product .woocommerce-tabs ul.tabs li::after,
        .woocommerce-page div.product .woocommerce-tabs ul.tabs li::before,
        .woocommerce-page div.product .woocommerce-tabs ul.tabs li::after,
        .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li::before,
        .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li::after,
        .footer_btn div.product .woocommerce-tabs ul.tabs li::before,
        .footer_btn div.product .woocommerce-tabs ul.tabs li::after,
        .block_startseite div.product .woocommerce-tabs ul.tabs li::before,
        .block_startseite div.product .woocommerce-tabs ul.tabs li::after {
          border: 1px solid #392127;
          position: absolute;
          bottom: -1px;
          width: 5px;
          height: 5px;
          content: " ";
          box-sizing: border-box; }
        .woocommerce div.product .woocommerce-tabs ul.tabs li::before,
        .woocommerce-page div.product .woocommerce-tabs ul.tabs li::before,
        .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li::before,
        .footer_btn div.product .woocommerce-tabs ul.tabs li::before,
        .block_startseite div.product .woocommerce-tabs ul.tabs li::before {
          left: -5px;
          border-bottom-right-radius: 4px;
          border-width: 0 1px 1px 0;
          box-shadow: 2px 2px 0 #5a333d;
          display: none; }
        .woocommerce div.product .woocommerce-tabs ul.tabs li::after,
        .woocommerce-page div.product .woocommerce-tabs ul.tabs li::after,
        .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs li::after,
        .footer_btn div.product .woocommerce-tabs ul.tabs li::after,
        .block_startseite div.product .woocommerce-tabs ul.tabs li::after {
          right: -5px;
          border-bottom-left-radius: 4px;
          border-width: 0 0 1px 1px;
          box-shadow: -2px 2px 0 #5a333d;
          display: none; }
      .woocommerce div.product .woocommerce-tabs ul.tabs::before,
      .woocommerce-page div.product .woocommerce-tabs ul.tabs::before,
      .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs::before,
      .footer_btn div.product .woocommerce-tabs ul.tabs::before,
      .block_startseite div.product .woocommerce-tabs ul.tabs::before {
        position: absolute;
        content: " ";
        width: 100%;
        bottom: 0;
        left: 0;
        border-bottom: none;
        z-index: 1; }
    .woocommerce div.product .woocommerce-tabs .panel,
    .woocommerce-page div.product .woocommerce-tabs .panel,
    .xoo-wsc-modal div.product .woocommerce-tabs .panel,
    .footer_btn div.product .woocommerce-tabs .panel,
    .block_startseite div.product .woocommerce-tabs .panel {
      margin: 0 0 2em;
      padding: 15px;
      border: 1px solid black; }
      @media screen and (max-width: 768px) {
        .woocommerce div.product .woocommerce-tabs .panel,
        .woocommerce-page div.product .woocommerce-tabs .panel,
        .xoo-wsc-modal div.product .woocommerce-tabs .panel,
        .footer_btn div.product .woocommerce-tabs .panel,
        .block_startseite div.product .woocommerce-tabs .panel {
          border-left-width: 0;
          border-right-width: 0; } }
      .woocommerce div.product .woocommerce-tabs .panel h2,
      .woocommerce-page div.product .woocommerce-tabs .panel h2,
      .xoo-wsc-modal div.product .woocommerce-tabs .panel h2,
      .footer_btn div.product .woocommerce-tabs .panel h2,
      .block_startseite div.product .woocommerce-tabs .panel h2 {
        font-size: 20px !important;
        margin-bottom: 10px; }
      .woocommerce div.product .woocommerce-tabs .panel th,
      .woocommerce-page div.product .woocommerce-tabs .panel th,
      .xoo-wsc-modal div.product .woocommerce-tabs .panel th,
      .footer_btn div.product .woocommerce-tabs .panel th,
      .block_startseite div.product .woocommerce-tabs .panel th {
        font-size: 15px !important; }
      .woocommerce div.product .woocommerce-tabs .panel p,
      .woocommerce-page div.product .woocommerce-tabs .panel p,
      .xoo-wsc-modal div.product .woocommerce-tabs .panel p,
      .footer_btn div.product .woocommerce-tabs .panel p,
      .block_startseite div.product .woocommerce-tabs .panel p {
        line-height: 25px;
        font-size: 15px; }
      .woocommerce div.product .woocommerce-tabs .panel th,
      .woocommerce-page div.product .woocommerce-tabs .panel th,
      .xoo-wsc-modal div.product .woocommerce-tabs .panel th,
      .footer_btn div.product .woocommerce-tabs .panel th,
      .block_startseite div.product .woocommerce-tabs .panel th {
        text-align: left; }
      .woocommerce div.product .woocommerce-tabs .panel td p,
      .woocommerce-page div.product .woocommerce-tabs .panel td p,
      .xoo-wsc-modal div.product .woocommerce-tabs .panel td p,
      .footer_btn div.product .woocommerce-tabs .panel td p,
      .block_startseite div.product .woocommerce-tabs .panel td p {
        padding: 8px; }
    .woocommerce div.product p.cart,
    .woocommerce-page div.product p.cart,
    .xoo-wsc-modal div.product p.cart,
    .footer_btn div.product p.cart,
    .block_startseite div.product p.cart {
      margin-bottom: 2em;
      *zoom: 1; }
      .woocommerce div.product p.cart::before, .woocommerce div.product p.cart::after,
      .woocommerce-page div.product p.cart::before,
      .woocommerce-page div.product p.cart::after,
      .xoo-wsc-modal div.product p.cart::before,
      .xoo-wsc-modal div.product p.cart::after,
      .footer_btn div.product p.cart::before,
      .footer_btn div.product p.cart::after,
      .block_startseite div.product p.cart::before,
      .block_startseite div.product p.cart::after {
        content: ' ';
        display: table; }
      .woocommerce div.product p.cart::after,
      .woocommerce-page div.product p.cart::after,
      .xoo-wsc-modal div.product p.cart::after,
      .footer_btn div.product p.cart::after,
      .block_startseite div.product p.cart::after {
        clear: both; }
    .woocommerce div.product form.cart,
    .woocommerce-page div.product form.cart,
    .xoo-wsc-modal div.product form.cart,
    .footer_btn div.product form.cart,
    .block_startseite div.product form.cart {
      margin-bottom: 2em;
      *zoom: 1; }
      .woocommerce div.product form.cart::before, .woocommerce div.product form.cart::after,
      .woocommerce-page div.product form.cart::before,
      .woocommerce-page div.product form.cart::after,
      .xoo-wsc-modal div.product form.cart::before,
      .xoo-wsc-modal div.product form.cart::after,
      .footer_btn div.product form.cart::before,
      .footer_btn div.product form.cart::after,
      .block_startseite div.product form.cart::before,
      .block_startseite div.product form.cart::after {
        content: ' ';
        display: table; }
      .woocommerce div.product form.cart::after,
      .woocommerce-page div.product form.cart::after,
      .xoo-wsc-modal div.product form.cart::after,
      .footer_btn div.product form.cart::after,
      .block_startseite div.product form.cart::after {
        clear: both; }
      .woocommerce div.product form.cart div.quantity,
      .woocommerce-page div.product form.cart div.quantity,
      .xoo-wsc-modal div.product form.cart div.quantity,
      .footer_btn div.product form.cart div.quantity,
      .block_startseite div.product form.cart div.quantity {
        float: left;
        margin: 0 4px 0 0; }
      .woocommerce div.product form.cart .button,
      .woocommerce-page div.product form.cart .button,
      .xoo-wsc-modal div.product form.cart .button,
      .footer_btn div.product form.cart .button,
      .block_startseite div.product form.cart .button {
        margin-top: 36px; }
        @media screen and (max-width: 1029px) {
          .woocommerce div.product form.cart .button,
          .woocommerce-page div.product form.cart .button,
          .xoo-wsc-modal div.product form.cart .button,
          .footer_btn div.product form.cart .button,
          .block_startseite div.product form.cart .button {
            margin-top: 0; } }
      .woocommerce div.product form.cart table,
      .woocommerce-page div.product form.cart table,
      .xoo-wsc-modal div.product form.cart table,
      .footer_btn div.product form.cart table,
      .block_startseite div.product form.cart table {
        border-width: 0 0 1px; }
        .woocommerce div.product form.cart table td,
        .woocommerce-page div.product form.cart table td,
        .xoo-wsc-modal div.product form.cart table td,
        .footer_btn div.product form.cart table td,
        .block_startseite div.product form.cart table td {
          padding-left: 0; }
        .woocommerce div.product form.cart table div.quantity,
        .woocommerce-page div.product form.cart table div.quantity,
        .xoo-wsc-modal div.product form.cart table div.quantity,
        .footer_btn div.product form.cart table div.quantity,
        .block_startseite div.product form.cart table div.quantity {
          float: none;
          margin: 0; }
        .woocommerce div.product form.cart table small.stock,
        .woocommerce-page div.product form.cart table small.stock,
        .xoo-wsc-modal div.product form.cart table small.stock,
        .footer_btn div.product form.cart table small.stock,
        .block_startseite div.product form.cart table small.stock {
          display: block;
          float: none; }
      .woocommerce div.product form.cart .variations,
      .woocommerce-page div.product form.cart .variations,
      .xoo-wsc-modal div.product form.cart .variations,
      .footer_btn div.product form.cart .variations,
      .block_startseite div.product form.cart .variations {
        margin-bottom: 1em;
        border: 0;
        width: 100%; }
        .woocommerce div.product form.cart .variations td,
        .woocommerce div.product form.cart .variations th,
        .woocommerce-page div.product form.cart .variations td,
        .woocommerce-page div.product form.cart .variations th,
        .xoo-wsc-modal div.product form.cart .variations td,
        .xoo-wsc-modal div.product form.cart .variations th,
        .footer_btn div.product form.cart .variations td,
        .footer_btn div.product form.cart .variations th,
        .block_startseite div.product form.cart .variations td,
        .block_startseite div.product form.cart .variations th {
          border: 0;
          vertical-align: top;
          line-height: 2em; }
        .woocommerce div.product form.cart .variations label,
        .woocommerce-page div.product form.cart .variations label,
        .xoo-wsc-modal div.product form.cart .variations label,
        .footer_btn div.product form.cart .variations label,
        .block_startseite div.product form.cart .variations label {
          font-weight: 700; }
        .woocommerce div.product form.cart .variations select,
        .woocommerce-page div.product form.cart .variations select,
        .xoo-wsc-modal div.product form.cart .variations select,
        .footer_btn div.product form.cart .variations select,
        .block_startseite div.product form.cart .variations select {
          max-width: 100%;
          min-width: 75%;
          display: inline-block;
          margin-right: 1em; }
        .woocommerce div.product form.cart .variations td.label,
        .woocommerce-page div.product form.cart .variations td.label,
        .xoo-wsc-modal div.product form.cart .variations td.label,
        .footer_btn div.product form.cart .variations td.label,
        .block_startseite div.product form.cart .variations td.label {
          padding-right: 1em; }
      .woocommerce div.product form.cart .woocommerce-variation-description p,
      .woocommerce-page div.product form.cart .woocommerce-variation-description p,
      .xoo-wsc-modal div.product form.cart .woocommerce-variation-description p,
      .footer_btn div.product form.cart .woocommerce-variation-description p,
      .block_startseite div.product form.cart .woocommerce-variation-description p {
        margin-bottom: 1em;
        display: none; }
      .woocommerce div.product form.cart .reset_variations,
      .woocommerce-page div.product form.cart .reset_variations,
      .xoo-wsc-modal div.product form.cart .reset_variations,
      .footer_btn div.product form.cart .reset_variations,
      .block_startseite div.product form.cart .reset_variations {
        visibility: hidden;
        font-size: 0.83em; }
      .woocommerce div.product form.cart .wc-no-matching-variations,
      .woocommerce-page div.product form.cart .wc-no-matching-variations,
      .xoo-wsc-modal div.product form.cart .wc-no-matching-variations,
      .footer_btn div.product form.cart .wc-no-matching-variations,
      .block_startseite div.product form.cart .wc-no-matching-variations {
        display: none; }
      .woocommerce div.product form.cart .button,
      .woocommerce-page div.product form.cart .button,
      .xoo-wsc-modal div.product form.cart .button,
      .footer_btn div.product form.cart .button,
      .block_startseite div.product form.cart .button {
        vertical-align: middle;
        float: left; }
      .woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label,
      .woocommerce-page div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label,
      .xoo-wsc-modal div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label,
      .footer_btn div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label,
      .block_startseite div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label {
        padding-right: 1em;
        padding-left: 1em; }
      .woocommerce div.product form.cart .group_table td,
      .woocommerce-page div.product form.cart .group_table td,
      .xoo-wsc-modal div.product form.cart .group_table td,
      .footer_btn div.product form.cart .group_table td,
      .block_startseite div.product form.cart .group_table td {
        vertical-align: top;
        padding-bottom: 0.5em;
        border: 0; }
      .woocommerce div.product form.cart .group_table td:first-child,
      .woocommerce-page div.product form.cart .group_table td:first-child,
      .xoo-wsc-modal div.product form.cart .group_table td:first-child,
      .footer_btn div.product form.cart .group_table td:first-child,
      .block_startseite div.product form.cart .group_table td:first-child {
        width: 4em;
        text-align: center; }
      .woocommerce div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox,
      .woocommerce-page div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox,
      .xoo-wsc-modal div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox,
      .footer_btn div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox,
      .block_startseite div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox {
        display: inline-block;
        width: auto;
        margin: 0 auto;
        transform: scale(1.5, 1.5); }
  .woocommerce span.onsale,
  .woocommerce-page span.onsale,
  .xoo-wsc-modal span.onsale,
  .footer_btn span.onsale,
  .block_startseite span.onsale {
    min-height: 3.236em;
    min-width: 3.236em;
    padding: 0.202em;
    font-size: 1em;
    font-weight: 700;
    position: absolute;
    text-align: center;
    line-height: 3.236;
    top: -0.5em;
    left: -0.5em;
    margin: 0;
    border-radius: 100%;
    background-color: black;
    color: #fff;
    font-size: 0.857em;
    z-index: 9; }
  .woocommerce .onsale,
  .woocommerce-page .onsale,
  .xoo-wsc-modal .onsale,
  .footer_btn .onsale,
  .block_startseite .onsale {
    width: 80px;
    height: 80px;
    font-size: 16px !important;
    line-height: 72px !important;
    font-weight: normal !important;
    background-color: #5a333d !important; }
  .woocommerce .products ul,
  .woocommerce ul.products,
  .woocommerce-page .products ul,
  .woocommerce-page ul.products,
  .xoo-wsc-modal .products ul,
  .xoo-wsc-modal ul.products,
  .footer_btn .products ul,
  .footer_btn ul.products,
  .block_startseite .products ul,
  .block_startseite ul.products {
    margin: 0 0 1em;
    padding: 0;
    list-style: none outside;
    clear: both;
    *zoom: 1; }
    .woocommerce .products ul::before, .woocommerce .products ul::after,
    .woocommerce ul.products::before,
    .woocommerce ul.products::after,
    .woocommerce-page .products ul::before,
    .woocommerce-page .products ul::after,
    .woocommerce-page ul.products::before,
    .woocommerce-page ul.products::after,
    .xoo-wsc-modal .products ul::before,
    .xoo-wsc-modal .products ul::after,
    .xoo-wsc-modal ul.products::before,
    .xoo-wsc-modal ul.products::after,
    .footer_btn .products ul::before,
    .footer_btn .products ul::after,
    .footer_btn ul.products::before,
    .footer_btn ul.products::after,
    .block_startseite .products ul::before,
    .block_startseite .products ul::after,
    .block_startseite ul.products::before,
    .block_startseite ul.products::after {
      content: ' ';
      display: table; }
    .woocommerce .products ul::after,
    .woocommerce ul.products::after,
    .woocommerce-page .products ul::after,
    .woocommerce-page ul.products::after,
    .xoo-wsc-modal .products ul::after,
    .xoo-wsc-modal ul.products::after,
    .footer_btn .products ul::after,
    .footer_btn ul.products::after,
    .block_startseite .products ul::after,
    .block_startseite ul.products::after {
      clear: both; }
    .woocommerce .products ul li,
    .woocommerce ul.products li,
    .woocommerce-page .products ul li,
    .woocommerce-page ul.products li,
    .xoo-wsc-modal .products ul li,
    .xoo-wsc-modal ul.products li,
    .footer_btn .products ul li,
    .footer_btn ul.products li,
    .block_startseite .products ul li,
    .block_startseite ul.products li {
      list-style: none outside; }
  .woocommerce ul.products li.product .shipping-costs-info,
  .woocommerce-page ul.products li.product .shipping-costs-info,
  .xoo-wsc-modal ul.products li.product .shipping-costs-info,
  .footer_btn ul.products li.product .shipping-costs-info,
  .block_startseite ul.products li.product .shipping-costs-info {
    display: none; }
  .woocommerce ul.products li.product .onsale,
  .woocommerce-page ul.products li.product .onsale,
  .xoo-wsc-modal ul.products li.product .onsale,
  .footer_btn ul.products li.product .onsale,
  .block_startseite ul.products li.product .onsale {
    top: 0;
    right: 0;
    left: auto;
    margin: -0.5em -0.5em 0 0; }
  .woocommerce ul.products li.product h2.woocommerce-loop-product_title,
  .woocommerce-page ul.products li.product h2.woocommerce-loop-product_title,
  .xoo-wsc-modal ul.products li.product h2.woocommerce-loop-product_title,
  .footer_btn ul.products li.product h2.woocommerce-loop-product_title,
  .block_startseite ul.products li.product h2.woocommerce-loop-product_title {
    font-size: 18px !important;
    line-height: 1.1em;
    font-family: HarmoniaSansProCyr, mono; }
  .woocommerce ul.products li.product h3,
  .woocommerce ul.products li.product .woocommerce-loop-product__title,
  .woocommerce ul.products li.product .woocommerce-loop-category__title,
  .woocommerce-page ul.products li.product h3,
  .woocommerce-page ul.products li.product .woocommerce-loop-product__title,
  .woocommerce-page ul.products li.product .woocommerce-loop-category__title,
  .xoo-wsc-modal ul.products li.product h3,
  .xoo-wsc-modal ul.products li.product .woocommerce-loop-product__title,
  .xoo-wsc-modal ul.products li.product .woocommerce-loop-category__title,
  .footer_btn ul.products li.product h3,
  .footer_btn ul.products li.product .woocommerce-loop-product__title,
  .footer_btn ul.products li.product .woocommerce-loop-category__title,
  .block_startseite ul.products li.product h3,
  .block_startseite ul.products li.product .woocommerce-loop-product__title,
  .block_startseite ul.products li.product .woocommerce-loop-category__title {
    padding: 0.5em 0;
    margin: 0;
    font-size: 1.2rem !important;
    hyphens: auto;
    height: 4em; }
    .woocommerce ul.products li.product h3 mark,
    .woocommerce ul.products li.product .woocommerce-loop-product__title mark,
    .woocommerce ul.products li.product .woocommerce-loop-category__title mark,
    .woocommerce-page ul.products li.product h3 mark,
    .woocommerce-page ul.products li.product .woocommerce-loop-product__title mark,
    .woocommerce-page ul.products li.product .woocommerce-loop-category__title mark,
    .xoo-wsc-modal ul.products li.product h3 mark,
    .xoo-wsc-modal ul.products li.product .woocommerce-loop-product__title mark,
    .xoo-wsc-modal ul.products li.product .woocommerce-loop-category__title mark,
    .footer_btn ul.products li.product h3 mark,
    .footer_btn ul.products li.product .woocommerce-loop-product__title mark,
    .footer_btn ul.products li.product .woocommerce-loop-category__title mark,
    .block_startseite ul.products li.product h3 mark,
    .block_startseite ul.products li.product .woocommerce-loop-product__title mark,
    .block_startseite ul.products li.product .woocommerce-loop-category__title mark {
      vertical-align: super;
      font-size: smaller;
      color: #385a68;
      font-family: PPEditorialNew-Regular, sans-serif;
      background: none; }
  .woocommerce ul.products li.product a,
  .woocommerce-page ul.products li.product a,
  .xoo-wsc-modal ul.products li.product a,
  .footer_btn ul.products li.product a,
  .block_startseite ul.products li.product a {
    text-decoration: none; }
  .woocommerce ul.products li.product a img,
  .woocommerce-page ul.products li.product a img,
  .xoo-wsc-modal ul.products li.product a img,
  .footer_btn ul.products li.product a img,
  .block_startseite ul.products li.product a img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 1em;
    box-shadow: none;
    max-height: 200px;
    width: auto;
    margin: auto; }
  .woocommerce ul.products li.product strong,
  .woocommerce-page ul.products li.product strong,
  .xoo-wsc-modal ul.products li.product strong,
  .footer_btn ul.products li.product strong,
  .block_startseite ul.products li.product strong {
    display: block; }
  .woocommerce ul.products li.product .woocommerce-placeholder,
  .woocommerce-page ul.products li.product .woocommerce-placeholder,
  .xoo-wsc-modal ul.products li.product .woocommerce-placeholder,
  .footer_btn ul.products li.product .woocommerce-placeholder,
  .block_startseite ul.products li.product .woocommerce-placeholder {
    border: 1px solid #f2f2f2; }
  .woocommerce ul.products li.product .star-rating,
  .woocommerce-page ul.products li.product .star-rating,
  .xoo-wsc-modal ul.products li.product .star-rating,
  .footer_btn ul.products li.product .star-rating,
  .block_startseite ul.products li.product .star-rating {
    font-size: 0.857em; }
  .woocommerce ul.products li.product .button,
  .woocommerce-page ul.products li.product .button,
  .xoo-wsc-modal ul.products li.product .button,
  .footer_btn ul.products li.product .button,
  .block_startseite ul.products li.product .button {
    margin-top: 1em; }
  .woocommerce ul.products li.product .price,
  .woocommerce-page ul.products li.product .price,
  .xoo-wsc-modal ul.products li.product .price,
  .footer_btn ul.products li.product .price,
  .block_startseite ul.products li.product .price {
    color: #eae1de;
    display: block;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 0.01em; }
    .woocommerce ul.products li.product .price del,
    .woocommerce-page ul.products li.product .price del,
    .xoo-wsc-modal ul.products li.product .price del,
    .footer_btn ul.products li.product .price del,
    .block_startseite ul.products li.product .price del {
      color: inherit;
      opacity: 0.5;
      display: inline-block; }
    .woocommerce ul.products li.product .price ins,
    .woocommerce-page ul.products li.product .price ins,
    .xoo-wsc-modal ul.products li.product .price ins,
    .footer_btn ul.products li.product .price ins,
    .block_startseite ul.products li.product .price ins {
      background: none;
      font-weight: 700;
      display: inline-block; }
    .woocommerce ul.products li.product .price .from,
    .woocommerce-page ul.products li.product .price .from,
    .xoo-wsc-modal ul.products li.product .price .from,
    .footer_btn ul.products li.product .price .from,
    .block_startseite ul.products li.product .price .from {
      font-size: 0.67em;
      margin: -2px 0 0 0;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.5); }
  .woocommerce .woocommerce-result-count,
  .woocommerce-page .woocommerce-result-count,
  .xoo-wsc-modal .woocommerce-result-count,
  .footer_btn .woocommerce-result-count,
  .block_startseite .woocommerce-result-count {
    margin: 0 0 3em;
    font-size: 12px; }
  .woocommerce .woocommerce-ordering,
  .woocommerce-page .woocommerce-ordering,
  .xoo-wsc-modal .woocommerce-ordering,
  .footer_btn .woocommerce-ordering,
  .block_startseite .woocommerce-ordering {
    margin: 0 0 3em;
    font-size: 15px;
    float: left; }
    .woocommerce .woocommerce-ordering select.orderby,
    .woocommerce-page .woocommerce-ordering select.orderby,
    .xoo-wsc-modal .woocommerce-ordering select.orderby,
    .footer_btn .woocommerce-ordering select.orderby,
    .block_startseite .woocommerce-ordering select.orderby {
      vertical-align: top;
      font-size: 1.8rem;
      background-color: #eae1de;
      margin-top: 1em; }
  .woocommerce .aws-container .aws-search-form .aws-form-btn,
  .woocommerce-page .aws-container .aws-search-form .aws-form-btn,
  .xoo-wsc-modal .aws-container .aws-search-form .aws-form-btn,
  .footer_btn .aws-container .aws-search-form .aws-form-btn,
  .block_startseite .aws-container .aws-search-form .aws-form-btn {
    background-color: #eae1de; }
  .woocommerce .aws-container .aws-search-field,
  .woocommerce-page .aws-container .aws-search-field,
  .xoo-wsc-modal .aws-container .aws-search-field,
  .footer_btn .aws-container .aws-search-field,
  .block_startseite .aws-container .aws-search-field {
    color: black;
    font-size: 1.6rem;
    border: 1px solid #eae1de;
    outline: 0; }
  .woocommerce nav.woocommerce-pagination,
  .woocommerce-page nav.woocommerce-pagination,
  .xoo-wsc-modal nav.woocommerce-pagination,
  .footer_btn nav.woocommerce-pagination,
  .block_startseite nav.woocommerce-pagination {
    text-align: center; }
    .woocommerce nav.woocommerce-pagination ul,
    .woocommerce-page nav.woocommerce-pagination ul,
    .xoo-wsc-modal nav.woocommerce-pagination ul,
    .footer_btn nav.woocommerce-pagination ul,
    .block_startseite nav.woocommerce-pagination ul {
      display: inline-block;
      white-space: nowrap;
      padding: 0;
      clear: both;
      border: none;
      border-right: 0;
      margin: 1px; }
      .woocommerce nav.woocommerce-pagination ul li,
      .woocommerce-page nav.woocommerce-pagination ul li,
      .xoo-wsc-modal nav.woocommerce-pagination ul li,
      .footer_btn nav.woocommerce-pagination ul li,
      .block_startseite nav.woocommerce-pagination ul li {
        border-right: 1px solid #392127;
        padding: 0;
        margin: 0;
        float: left;
        display: inline;
        overflow: hidden; }
        .woocommerce nav.woocommerce-pagination ul li a,
        .woocommerce nav.woocommerce-pagination ul li span,
        .woocommerce-page nav.woocommerce-pagination ul li a,
        .woocommerce-page nav.woocommerce-pagination ul li span,
        .xoo-wsc-modal nav.woocommerce-pagination ul li a,
        .xoo-wsc-modal nav.woocommerce-pagination ul li span,
        .footer_btn nav.woocommerce-pagination ul li a,
        .footer_btn nav.woocommerce-pagination ul li span,
        .block_startseite nav.woocommerce-pagination ul li a,
        .block_startseite nav.woocommerce-pagination ul li span {
          margin: 0;
          text-decoration: none;
          padding: 0;
          line-height: 1;
          font-size: 1em;
          font-weight: normal;
          padding: 0.5em;
          min-width: 1em;
          display: block; }
        .woocommerce nav.woocommerce-pagination ul li span.current,
        .woocommerce nav.woocommerce-pagination ul li a:hover,
        .woocommerce nav.woocommerce-pagination ul li a:focus,
        .woocommerce-page nav.woocommerce-pagination ul li span.current,
        .woocommerce-page nav.woocommerce-pagination ul li a:hover,
        .woocommerce-page nav.woocommerce-pagination ul li a:focus,
        .xoo-wsc-modal nav.woocommerce-pagination ul li span.current,
        .xoo-wsc-modal nav.woocommerce-pagination ul li a:hover,
        .xoo-wsc-modal nav.woocommerce-pagination ul li a:focus,
        .footer_btn nav.woocommerce-pagination ul li span.current,
        .footer_btn nav.woocommerce-pagination ul li a:hover,
        .footer_btn nav.woocommerce-pagination ul li a:focus,
        .block_startseite nav.woocommerce-pagination ul li span.current,
        .block_startseite nav.woocommerce-pagination ul li a:hover,
        .block_startseite nav.woocommerce-pagination ul li a:focus {
          background: #5a333d;
          color: #fff; }
  .woocommerce a.button,
  .woocommerce button.button,
  .woocommerce input.button,
  .woocommerce .button_block a,
  .woocommerce #respond input#submit,
  .woocommerce .sidebar [type="submit"],
  .woocommerce-page a.button,
  .woocommerce-page button.button,
  .woocommerce-page input.button,
  .woocommerce-page .button_block a,
  .woocommerce-page #respond input#submit,
  .woocommerce-page .sidebar [type="submit"],
  .xoo-wsc-modal a.button,
  .xoo-wsc-modal button.button,
  .xoo-wsc-modal input.button,
  .xoo-wsc-modal .button_block a,
  .xoo-wsc-modal #respond input#submit,
  .xoo-wsc-modal .sidebar [type="submit"],
  .footer_btn a.button,
  .footer_btn button.button,
  .footer_btn input.button,
  .footer_btn .button_block a,
  .footer_btn #respond input#submit,
  .footer_btn .sidebar [type="submit"],
  .block_startseite a.button,
  .block_startseite button.button,
  .block_startseite input.button,
  .block_startseite .button_block a,
  .block_startseite #respond input#submit,
  .block_startseite .sidebar [type="submit"] {
    font-size: 1.25rem;
    margin: 0;
    line-height: 1;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    overflow: visible;
    padding: 0.618em 1em;
    left: auto;
    color: #fff;
    background-color: #5a333d;
    border: 0;
    display: inline-block;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0; }
    @media screen and (max-width: 768px) {
      .woocommerce a.button,
      .woocommerce button.button,
      .woocommerce input.button,
      .woocommerce .button_block a,
      .woocommerce #respond input#submit,
      .woocommerce .sidebar [type="submit"],
      .woocommerce-page a.button,
      .woocommerce-page button.button,
      .woocommerce-page input.button,
      .woocommerce-page .button_block a,
      .woocommerce-page #respond input#submit,
      .woocommerce-page .sidebar [type="submit"],
      .xoo-wsc-modal a.button,
      .xoo-wsc-modal button.button,
      .xoo-wsc-modal input.button,
      .xoo-wsc-modal .button_block a,
      .xoo-wsc-modal #respond input#submit,
      .xoo-wsc-modal .sidebar [type="submit"],
      .footer_btn a.button,
      .footer_btn button.button,
      .footer_btn input.button,
      .footer_btn .button_block a,
      .footer_btn #respond input#submit,
      .footer_btn .sidebar [type="submit"],
      .block_startseite a.button,
      .block_startseite button.button,
      .block_startseite input.button,
      .block_startseite .button_block a,
      .block_startseite #respond input#submit,
      .block_startseite .sidebar [type="submit"] {
        font-size: 1.6rem !important; } }
    .woocommerce a.button.loading,
    .woocommerce button.button.loading,
    .woocommerce input.button.loading,
    .woocommerce .button_block a.loading,
    .woocommerce #respond input#submit.loading,
    .woocommerce .sidebar [type="submit"].loading,
    .woocommerce-page a.button.loading,
    .woocommerce-page button.button.loading,
    .woocommerce-page input.button.loading,
    .woocommerce-page .button_block a.loading,
    .woocommerce-page #respond input#submit.loading,
    .woocommerce-page .sidebar [type="submit"].loading,
    .xoo-wsc-modal a.button.loading,
    .xoo-wsc-modal button.button.loading,
    .xoo-wsc-modal input.button.loading,
    .xoo-wsc-modal .button_block a.loading,
    .xoo-wsc-modal #respond input#submit.loading,
    .xoo-wsc-modal .sidebar [type="submit"].loading,
    .footer_btn a.button.loading,
    .footer_btn button.button.loading,
    .footer_btn input.button.loading,
    .footer_btn .button_block a.loading,
    .footer_btn #respond input#submit.loading,
    .footer_btn .sidebar [type="submit"].loading,
    .block_startseite a.button.loading,
    .block_startseite button.button.loading,
    .block_startseite input.button.loading,
    .block_startseite .button_block a.loading,
    .block_startseite #respond input#submit.loading,
    .block_startseite .sidebar [type="submit"].loading {
      opacity: 0.25;
      padding-right: 2.618em; }
      .woocommerce a.button.loading::after,
      .woocommerce button.button.loading::after,
      .woocommerce input.button.loading::after,
      .woocommerce .button_block a.loading::after,
      .woocommerce #respond input#submit.loading::after,
      .woocommerce .sidebar [type="submit"].loading::after,
      .woocommerce-page a.button.loading::after,
      .woocommerce-page button.button.loading::after,
      .woocommerce-page input.button.loading::after,
      .woocommerce-page .button_block a.loading::after,
      .woocommerce-page #respond input#submit.loading::after,
      .woocommerce-page .sidebar [type="submit"].loading::after,
      .xoo-wsc-modal a.button.loading::after,
      .xoo-wsc-modal button.button.loading::after,
      .xoo-wsc-modal input.button.loading::after,
      .xoo-wsc-modal .button_block a.loading::after,
      .xoo-wsc-modal #respond input#submit.loading::after,
      .xoo-wsc-modal .sidebar [type="submit"].loading::after,
      .footer_btn a.button.loading::after,
      .footer_btn button.button.loading::after,
      .footer_btn input.button.loading::after,
      .footer_btn .button_block a.loading::after,
      .footer_btn #respond input#submit.loading::after,
      .footer_btn .sidebar [type="submit"].loading::after,
      .block_startseite a.button.loading::after,
      .block_startseite button.button.loading::after,
      .block_startseite input.button.loading::after,
      .block_startseite .button_block a.loading::after,
      .block_startseite #respond input#submit.loading::after,
      .block_startseite .sidebar [type="submit"].loading::after {
        font-family: "WooCommerce";
        content: "\e01c";
        vertical-align: top;
        font-weight: 400;
        position: absolute;
        top: 0.618em;
        right: 1em;
        animation: spin 2s linear infinite; }
    .woocommerce a.button.added::after,
    .woocommerce button.button.added::after,
    .woocommerce input.button.added::after,
    .woocommerce .button_block a.added::after,
    .woocommerce #respond input#submit.added::after,
    .woocommerce .sidebar [type="submit"].added::after,
    .woocommerce-page a.button.added::after,
    .woocommerce-page button.button.added::after,
    .woocommerce-page input.button.added::after,
    .woocommerce-page .button_block a.added::after,
    .woocommerce-page #respond input#submit.added::after,
    .woocommerce-page .sidebar [type="submit"].added::after,
    .xoo-wsc-modal a.button.added::after,
    .xoo-wsc-modal button.button.added::after,
    .xoo-wsc-modal input.button.added::after,
    .xoo-wsc-modal .button_block a.added::after,
    .xoo-wsc-modal #respond input#submit.added::after,
    .xoo-wsc-modal .sidebar [type="submit"].added::after,
    .footer_btn a.button.added::after,
    .footer_btn button.button.added::after,
    .footer_btn input.button.added::after,
    .footer_btn .button_block a.added::after,
    .footer_btn #respond input#submit.added::after,
    .footer_btn .sidebar [type="submit"].added::after,
    .block_startseite a.button.added::after,
    .block_startseite button.button.added::after,
    .block_startseite input.button.added::after,
    .block_startseite .button_block a.added::after,
    .block_startseite #respond input#submit.added::after,
    .block_startseite .sidebar [type="submit"].added::after {
      font-family: "WooCommerce";
      content: "\e017";
      margin-left: 0.53em;
      vertical-align: bottom; }
    .woocommerce a.button:hover,
    .woocommerce button.button:hover,
    .woocommerce input.button:hover,
    .woocommerce .button_block a:hover,
    .woocommerce #respond input#submit:hover,
    .woocommerce .sidebar [type="submit"]:hover,
    .woocommerce-page a.button:hover,
    .woocommerce-page button.button:hover,
    .woocommerce-page input.button:hover,
    .woocommerce-page .button_block a:hover,
    .woocommerce-page #respond input#submit:hover,
    .woocommerce-page .sidebar [type="submit"]:hover,
    .xoo-wsc-modal a.button:hover,
    .xoo-wsc-modal button.button:hover,
    .xoo-wsc-modal input.button:hover,
    .xoo-wsc-modal .button_block a:hover,
    .xoo-wsc-modal #respond input#submit:hover,
    .xoo-wsc-modal .sidebar [type="submit"]:hover,
    .footer_btn a.button:hover,
    .footer_btn button.button:hover,
    .footer_btn input.button:hover,
    .footer_btn .button_block a:hover,
    .footer_btn #respond input#submit:hover,
    .footer_btn .sidebar [type="submit"]:hover,
    .block_startseite a.button:hover,
    .block_startseite button.button:hover,
    .block_startseite input.button:hover,
    .block_startseite .button_block a:hover,
    .block_startseite #respond input#submit:hover,
    .block_startseite .sidebar [type="submit"]:hover {
      background-color: #4a2a32;
      text-decoration: none;
      background-image: none;
      color: black; }
    .woocommerce a.button.alt,
    .woocommerce button.button.alt,
    .woocommerce input.button.alt,
    .woocommerce .button_block a.alt,
    .woocommerce #respond input#submit.alt,
    .woocommerce .sidebar [type="submit"].alt,
    .woocommerce-page a.button.alt,
    .woocommerce-page button.button.alt,
    .woocommerce-page input.button.alt,
    .woocommerce-page .button_block a.alt,
    .woocommerce-page #respond input#submit.alt,
    .woocommerce-page .sidebar [type="submit"].alt,
    .xoo-wsc-modal a.button.alt,
    .xoo-wsc-modal button.button.alt,
    .xoo-wsc-modal input.button.alt,
    .xoo-wsc-modal .button_block a.alt,
    .xoo-wsc-modal #respond input#submit.alt,
    .xoo-wsc-modal .sidebar [type="submit"].alt,
    .footer_btn a.button.alt,
    .footer_btn button.button.alt,
    .footer_btn input.button.alt,
    .footer_btn .button_block a.alt,
    .footer_btn #respond input#submit.alt,
    .footer_btn .sidebar [type="submit"].alt,
    .block_startseite a.button.alt,
    .block_startseite button.button.alt,
    .block_startseite input.button.alt,
    .block_startseite .button_block a.alt,
    .block_startseite #respond input#submit.alt,
    .block_startseite .sidebar [type="submit"].alt {
      background-color: #eae1de;
      color: black;
      -webkit-font-smoothing: antialiased;
      color: black;
      height: 45px;
      border-radius: 0;
      font-size: 20px;
      font-weight: 300; }
      .woocommerce a.button.alt:hover,
      .woocommerce button.button.alt:hover,
      .woocommerce input.button.alt:hover,
      .woocommerce .button_block a.alt:hover,
      .woocommerce #respond input#submit.alt:hover,
      .woocommerce .sidebar [type="submit"].alt:hover,
      .woocommerce-page a.button.alt:hover,
      .woocommerce-page button.button.alt:hover,
      .woocommerce-page input.button.alt:hover,
      .woocommerce-page .button_block a.alt:hover,
      .woocommerce-page #respond input#submit.alt:hover,
      .woocommerce-page .sidebar [type="submit"].alt:hover,
      .xoo-wsc-modal a.button.alt:hover,
      .xoo-wsc-modal button.button.alt:hover,
      .xoo-wsc-modal input.button.alt:hover,
      .xoo-wsc-modal .button_block a.alt:hover,
      .xoo-wsc-modal #respond input#submit.alt:hover,
      .xoo-wsc-modal .sidebar [type="submit"].alt:hover,
      .footer_btn a.button.alt:hover,
      .footer_btn button.button.alt:hover,
      .footer_btn input.button.alt:hover,
      .footer_btn .button_block a.alt:hover,
      .footer_btn #respond input#submit.alt:hover,
      .footer_btn .sidebar [type="submit"].alt:hover,
      .block_startseite a.button.alt:hover,
      .block_startseite button.button.alt:hover,
      .block_startseite input.button.alt:hover,
      .block_startseite .button_block a.alt:hover,
      .block_startseite #respond input#submit.alt:hover,
      .block_startseite .sidebar [type="submit"].alt:hover {
        background-color: #5a333d;
        color: #fff;
        transition: 0.5s; }
      .woocommerce a.button.alt.disabled, .woocommerce a.button.alt:disabled, .woocommerce a.button.alt:disabled[disabled], .woocommerce a.button.alt.disabled:hover, .woocommerce a.button.alt:disabled:hover, .woocommerce a.button.alt:disabled[disabled]:hover,
      .woocommerce button.button.alt.disabled,
      .woocommerce button.button.alt:disabled,
      .woocommerce button.button.alt:disabled[disabled],
      .woocommerce button.button.alt.disabled:hover,
      .woocommerce button.button.alt:disabled:hover,
      .woocommerce button.button.alt:disabled[disabled]:hover,
      .woocommerce input.button.alt.disabled,
      .woocommerce input.button.alt:disabled,
      .woocommerce input.button.alt:disabled[disabled],
      .woocommerce input.button.alt.disabled:hover,
      .woocommerce input.button.alt:disabled:hover,
      .woocommerce input.button.alt:disabled[disabled]:hover,
      .woocommerce .button_block a.alt.disabled,
      .woocommerce .button_block a.alt:disabled,
      .woocommerce .button_block a.alt:disabled[disabled],
      .woocommerce .button_block a.alt.disabled:hover,
      .woocommerce .button_block a.alt:disabled:hover,
      .woocommerce .button_block a.alt:disabled[disabled]:hover,
      .woocommerce #respond input#submit.alt.disabled,
      .woocommerce #respond input#submit.alt:disabled,
      .woocommerce #respond input#submit.alt:disabled[disabled],
      .woocommerce #respond input#submit.alt.disabled:hover,
      .woocommerce #respond input#submit.alt:disabled:hover,
      .woocommerce #respond input#submit.alt:disabled[disabled]:hover,
      .woocommerce .sidebar [type="submit"].alt.disabled,
      .woocommerce .sidebar [type="submit"].alt:disabled,
      .woocommerce .sidebar [type="submit"].alt:disabled[disabled],
      .woocommerce .sidebar [type="submit"].alt.disabled:hover,
      .woocommerce .sidebar [type="submit"].alt:disabled:hover,
      .woocommerce .sidebar [type="submit"].alt:disabled[disabled]:hover,
      .woocommerce-page a.button.alt.disabled,
      .woocommerce-page a.button.alt:disabled,
      .woocommerce-page a.button.alt:disabled[disabled],
      .woocommerce-page a.button.alt.disabled:hover,
      .woocommerce-page a.button.alt:disabled:hover,
      .woocommerce-page a.button.alt:disabled[disabled]:hover,
      .woocommerce-page button.button.alt.disabled,
      .woocommerce-page button.button.alt:disabled,
      .woocommerce-page button.button.alt:disabled[disabled],
      .woocommerce-page button.button.alt.disabled:hover,
      .woocommerce-page button.button.alt:disabled:hover,
      .woocommerce-page button.button.alt:disabled[disabled]:hover,
      .woocommerce-page input.button.alt.disabled,
      .woocommerce-page input.button.alt:disabled,
      .woocommerce-page input.button.alt:disabled[disabled],
      .woocommerce-page input.button.alt.disabled:hover,
      .woocommerce-page input.button.alt:disabled:hover,
      .woocommerce-page input.button.alt:disabled[disabled]:hover,
      .woocommerce-page .button_block a.alt.disabled,
      .woocommerce-page .button_block a.alt:disabled,
      .woocommerce-page .button_block a.alt:disabled[disabled],
      .woocommerce-page .button_block a.alt.disabled:hover,
      .woocommerce-page .button_block a.alt:disabled:hover,
      .woocommerce-page .button_block a.alt:disabled[disabled]:hover,
      .woocommerce-page #respond input#submit.alt.disabled,
      .woocommerce-page #respond input#submit.alt:disabled,
      .woocommerce-page #respond input#submit.alt:disabled[disabled],
      .woocommerce-page #respond input#submit.alt.disabled:hover,
      .woocommerce-page #respond input#submit.alt:disabled:hover,
      .woocommerce-page #respond input#submit.alt:disabled[disabled]:hover,
      .woocommerce-page .sidebar [type="submit"].alt.disabled,
      .woocommerce-page .sidebar [type="submit"].alt:disabled,
      .woocommerce-page .sidebar [type="submit"].alt:disabled[disabled],
      .woocommerce-page .sidebar [type="submit"].alt.disabled:hover,
      .woocommerce-page .sidebar [type="submit"].alt:disabled:hover,
      .woocommerce-page .sidebar [type="submit"].alt:disabled[disabled]:hover,
      .xoo-wsc-modal a.button.alt.disabled,
      .xoo-wsc-modal a.button.alt:disabled,
      .xoo-wsc-modal a.button.alt:disabled[disabled],
      .xoo-wsc-modal a.button.alt.disabled:hover,
      .xoo-wsc-modal a.button.alt:disabled:hover,
      .xoo-wsc-modal a.button.alt:disabled[disabled]:hover,
      .xoo-wsc-modal button.button.alt.disabled,
      .xoo-wsc-modal button.button.alt:disabled,
      .xoo-wsc-modal button.button.alt:disabled[disabled],
      .xoo-wsc-modal button.button.alt.disabled:hover,
      .xoo-wsc-modal button.button.alt:disabled:hover,
      .xoo-wsc-modal button.button.alt:disabled[disabled]:hover,
      .xoo-wsc-modal input.button.alt.disabled,
      .xoo-wsc-modal input.button.alt:disabled,
      .xoo-wsc-modal input.button.alt:disabled[disabled],
      .xoo-wsc-modal input.button.alt.disabled:hover,
      .xoo-wsc-modal input.button.alt:disabled:hover,
      .xoo-wsc-modal input.button.alt:disabled[disabled]:hover,
      .xoo-wsc-modal .button_block a.alt.disabled,
      .xoo-wsc-modal .button_block a.alt:disabled,
      .xoo-wsc-modal .button_block a.alt:disabled[disabled],
      .xoo-wsc-modal .button_block a.alt.disabled:hover,
      .xoo-wsc-modal .button_block a.alt:disabled:hover,
      .xoo-wsc-modal .button_block a.alt:disabled[disabled]:hover,
      .xoo-wsc-modal #respond input#submit.alt.disabled,
      .xoo-wsc-modal #respond input#submit.alt:disabled,
      .xoo-wsc-modal #respond input#submit.alt:disabled[disabled],
      .xoo-wsc-modal #respond input#submit.alt.disabled:hover,
      .xoo-wsc-modal #respond input#submit.alt:disabled:hover,
      .xoo-wsc-modal #respond input#submit.alt:disabled[disabled]:hover,
      .xoo-wsc-modal .sidebar [type="submit"].alt.disabled,
      .xoo-wsc-modal .sidebar [type="submit"].alt:disabled,
      .xoo-wsc-modal .sidebar [type="submit"].alt:disabled[disabled],
      .xoo-wsc-modal .sidebar [type="submit"].alt.disabled:hover,
      .xoo-wsc-modal .sidebar [type="submit"].alt:disabled:hover,
      .xoo-wsc-modal .sidebar [type="submit"].alt:disabled[disabled]:hover,
      .footer_btn a.button.alt.disabled,
      .footer_btn a.button.alt:disabled,
      .footer_btn a.button.alt:disabled[disabled],
      .footer_btn a.button.alt.disabled:hover,
      .footer_btn a.button.alt:disabled:hover,
      .footer_btn a.button.alt:disabled[disabled]:hover,
      .footer_btn button.button.alt.disabled,
      .footer_btn button.button.alt:disabled,
      .footer_btn button.button.alt:disabled[disabled],
      .footer_btn button.button.alt.disabled:hover,
      .footer_btn button.button.alt:disabled:hover,
      .footer_btn button.button.alt:disabled[disabled]:hover,
      .footer_btn input.button.alt.disabled,
      .footer_btn input.button.alt:disabled,
      .footer_btn input.button.alt:disabled[disabled],
      .footer_btn input.button.alt.disabled:hover,
      .footer_btn input.button.alt:disabled:hover,
      .footer_btn input.button.alt:disabled[disabled]:hover,
      .footer_btn .button_block a.alt.disabled,
      .footer_btn .button_block a.alt:disabled,
      .footer_btn .button_block a.alt:disabled[disabled],
      .footer_btn .button_block a.alt.disabled:hover,
      .footer_btn .button_block a.alt:disabled:hover,
      .footer_btn .button_block a.alt:disabled[disabled]:hover,
      .footer_btn #respond input#submit.alt.disabled,
      .footer_btn #respond input#submit.alt:disabled,
      .footer_btn #respond input#submit.alt:disabled[disabled],
      .footer_btn #respond input#submit.alt.disabled:hover,
      .footer_btn #respond input#submit.alt:disabled:hover,
      .footer_btn #respond input#submit.alt:disabled[disabled]:hover,
      .footer_btn .sidebar [type="submit"].alt.disabled,
      .footer_btn .sidebar [type="submit"].alt:disabled,
      .footer_btn .sidebar [type="submit"].alt:disabled[disabled],
      .footer_btn .sidebar [type="submit"].alt.disabled:hover,
      .footer_btn .sidebar [type="submit"].alt:disabled:hover,
      .footer_btn .sidebar [type="submit"].alt:disabled[disabled]:hover,
      .block_startseite a.button.alt.disabled,
      .block_startseite a.button.alt:disabled,
      .block_startseite a.button.alt:disabled[disabled],
      .block_startseite a.button.alt.disabled:hover,
      .block_startseite a.button.alt:disabled:hover,
      .block_startseite a.button.alt:disabled[disabled]:hover,
      .block_startseite button.button.alt.disabled,
      .block_startseite button.button.alt:disabled,
      .block_startseite button.button.alt:disabled[disabled],
      .block_startseite button.button.alt.disabled:hover,
      .block_startseite button.button.alt:disabled:hover,
      .block_startseite button.button.alt:disabled[disabled]:hover,
      .block_startseite input.button.alt.disabled,
      .block_startseite input.button.alt:disabled,
      .block_startseite input.button.alt:disabled[disabled],
      .block_startseite input.button.alt.disabled:hover,
      .block_startseite input.button.alt:disabled:hover,
      .block_startseite input.button.alt:disabled[disabled]:hover,
      .block_startseite .button_block a.alt.disabled,
      .block_startseite .button_block a.alt:disabled,
      .block_startseite .button_block a.alt:disabled[disabled],
      .block_startseite .button_block a.alt.disabled:hover,
      .block_startseite .button_block a.alt:disabled:hover,
      .block_startseite .button_block a.alt:disabled[disabled]:hover,
      .block_startseite #respond input#submit.alt.disabled,
      .block_startseite #respond input#submit.alt:disabled,
      .block_startseite #respond input#submit.alt:disabled[disabled],
      .block_startseite #respond input#submit.alt.disabled:hover,
      .block_startseite #respond input#submit.alt:disabled:hover,
      .block_startseite #respond input#submit.alt:disabled[disabled]:hover,
      .block_startseite .sidebar [type="submit"].alt.disabled,
      .block_startseite .sidebar [type="submit"].alt:disabled,
      .block_startseite .sidebar [type="submit"].alt:disabled[disabled],
      .block_startseite .sidebar [type="submit"].alt.disabled:hover,
      .block_startseite .sidebar [type="submit"].alt:disabled:hover,
      .block_startseite .sidebar [type="submit"].alt:disabled[disabled]:hover {
        background-color: #eae1de;
        color: black;
        font-size: 20px;
        font-weight: 300; }
    .woocommerce a.button:disabled, .woocommerce a.button.disabled, .woocommerce a.button:disabled[disabled],
    .woocommerce button.button:disabled,
    .woocommerce button.button.disabled,
    .woocommerce button.button:disabled[disabled],
    .woocommerce input.button:disabled,
    .woocommerce input.button.disabled,
    .woocommerce input.button:disabled[disabled],
    .woocommerce .button_block a:disabled,
    .woocommerce .button_block a.disabled,
    .woocommerce .button_block a:disabled[disabled],
    .woocommerce #respond input#submit:disabled,
    .woocommerce #respond input#submit.disabled,
    .woocommerce #respond input#submit:disabled[disabled],
    .woocommerce .sidebar [type="submit"]:disabled,
    .woocommerce .sidebar [type="submit"].disabled,
    .woocommerce .sidebar [type="submit"]:disabled[disabled],
    .woocommerce-page a.button:disabled,
    .woocommerce-page a.button.disabled,
    .woocommerce-page a.button:disabled[disabled],
    .woocommerce-page button.button:disabled,
    .woocommerce-page button.button.disabled,
    .woocommerce-page button.button:disabled[disabled],
    .woocommerce-page input.button:disabled,
    .woocommerce-page input.button.disabled,
    .woocommerce-page input.button:disabled[disabled],
    .woocommerce-page .button_block a:disabled,
    .woocommerce-page .button_block a.disabled,
    .woocommerce-page .button_block a:disabled[disabled],
    .woocommerce-page #respond input#submit:disabled,
    .woocommerce-page #respond input#submit.disabled,
    .woocommerce-page #respond input#submit:disabled[disabled],
    .woocommerce-page .sidebar [type="submit"]:disabled,
    .woocommerce-page .sidebar [type="submit"].disabled,
    .woocommerce-page .sidebar [type="submit"]:disabled[disabled],
    .xoo-wsc-modal a.button:disabled,
    .xoo-wsc-modal a.button.disabled,
    .xoo-wsc-modal a.button:disabled[disabled],
    .xoo-wsc-modal button.button:disabled,
    .xoo-wsc-modal button.button.disabled,
    .xoo-wsc-modal button.button:disabled[disabled],
    .xoo-wsc-modal input.button:disabled,
    .xoo-wsc-modal input.button.disabled,
    .xoo-wsc-modal input.button:disabled[disabled],
    .xoo-wsc-modal .button_block a:disabled,
    .xoo-wsc-modal .button_block a.disabled,
    .xoo-wsc-modal .button_block a:disabled[disabled],
    .xoo-wsc-modal #respond input#submit:disabled,
    .xoo-wsc-modal #respond input#submit.disabled,
    .xoo-wsc-modal #respond input#submit:disabled[disabled],
    .xoo-wsc-modal .sidebar [type="submit"]:disabled,
    .xoo-wsc-modal .sidebar [type="submit"].disabled,
    .xoo-wsc-modal .sidebar [type="submit"]:disabled[disabled],
    .footer_btn a.button:disabled,
    .footer_btn a.button.disabled,
    .footer_btn a.button:disabled[disabled],
    .footer_btn button.button:disabled,
    .footer_btn button.button.disabled,
    .footer_btn button.button:disabled[disabled],
    .footer_btn input.button:disabled,
    .footer_btn input.button.disabled,
    .footer_btn input.button:disabled[disabled],
    .footer_btn .button_block a:disabled,
    .footer_btn .button_block a.disabled,
    .footer_btn .button_block a:disabled[disabled],
    .footer_btn #respond input#submit:disabled,
    .footer_btn #respond input#submit.disabled,
    .footer_btn #respond input#submit:disabled[disabled],
    .footer_btn .sidebar [type="submit"]:disabled,
    .footer_btn .sidebar [type="submit"].disabled,
    .footer_btn .sidebar [type="submit"]:disabled[disabled],
    .block_startseite a.button:disabled,
    .block_startseite a.button.disabled,
    .block_startseite a.button:disabled[disabled],
    .block_startseite button.button:disabled,
    .block_startseite button.button.disabled,
    .block_startseite button.button:disabled[disabled],
    .block_startseite input.button:disabled,
    .block_startseite input.button.disabled,
    .block_startseite input.button:disabled[disabled],
    .block_startseite .button_block a:disabled,
    .block_startseite .button_block a.disabled,
    .block_startseite .button_block a:disabled[disabled],
    .block_startseite #respond input#submit:disabled,
    .block_startseite #respond input#submit.disabled,
    .block_startseite #respond input#submit:disabled[disabled],
    .block_startseite .sidebar [type="submit"]:disabled,
    .block_startseite .sidebar [type="submit"].disabled,
    .block_startseite .sidebar [type="submit"]:disabled[disabled] {
      color: #fff;
      cursor: not-allowed;
      opacity: 0.5;
      padding: 0.618em 1em; }
      .woocommerce a.button:disabled:hover, .woocommerce a.button.disabled:hover, .woocommerce a.button:disabled[disabled]:hover,
      .woocommerce button.button:disabled:hover,
      .woocommerce button.button.disabled:hover,
      .woocommerce button.button:disabled[disabled]:hover,
      .woocommerce input.button:disabled:hover,
      .woocommerce input.button.disabled:hover,
      .woocommerce input.button:disabled[disabled]:hover,
      .woocommerce .button_block a:disabled:hover,
      .woocommerce .button_block a.disabled:hover,
      .woocommerce .button_block a:disabled[disabled]:hover,
      .woocommerce #respond input#submit:disabled:hover,
      .woocommerce #respond input#submit.disabled:hover,
      .woocommerce #respond input#submit:disabled[disabled]:hover,
      .woocommerce .sidebar [type="submit"]:disabled:hover,
      .woocommerce .sidebar [type="submit"].disabled:hover,
      .woocommerce .sidebar [type="submit"]:disabled[disabled]:hover,
      .woocommerce-page a.button:disabled:hover,
      .woocommerce-page a.button.disabled:hover,
      .woocommerce-page a.button:disabled[disabled]:hover,
      .woocommerce-page button.button:disabled:hover,
      .woocommerce-page button.button.disabled:hover,
      .woocommerce-page button.button:disabled[disabled]:hover,
      .woocommerce-page input.button:disabled:hover,
      .woocommerce-page input.button.disabled:hover,
      .woocommerce-page input.button:disabled[disabled]:hover,
      .woocommerce-page .button_block a:disabled:hover,
      .woocommerce-page .button_block a.disabled:hover,
      .woocommerce-page .button_block a:disabled[disabled]:hover,
      .woocommerce-page #respond input#submit:disabled:hover,
      .woocommerce-page #respond input#submit.disabled:hover,
      .woocommerce-page #respond input#submit:disabled[disabled]:hover,
      .woocommerce-page .sidebar [type="submit"]:disabled:hover,
      .woocommerce-page .sidebar [type="submit"].disabled:hover,
      .woocommerce-page .sidebar [type="submit"]:disabled[disabled]:hover,
      .xoo-wsc-modal a.button:disabled:hover,
      .xoo-wsc-modal a.button.disabled:hover,
      .xoo-wsc-modal a.button:disabled[disabled]:hover,
      .xoo-wsc-modal button.button:disabled:hover,
      .xoo-wsc-modal button.button.disabled:hover,
      .xoo-wsc-modal button.button:disabled[disabled]:hover,
      .xoo-wsc-modal input.button:disabled:hover,
      .xoo-wsc-modal input.button.disabled:hover,
      .xoo-wsc-modal input.button:disabled[disabled]:hover,
      .xoo-wsc-modal .button_block a:disabled:hover,
      .xoo-wsc-modal .button_block a.disabled:hover,
      .xoo-wsc-modal .button_block a:disabled[disabled]:hover,
      .xoo-wsc-modal #respond input#submit:disabled:hover,
      .xoo-wsc-modal #respond input#submit.disabled:hover,
      .xoo-wsc-modal #respond input#submit:disabled[disabled]:hover,
      .xoo-wsc-modal .sidebar [type="submit"]:disabled:hover,
      .xoo-wsc-modal .sidebar [type="submit"].disabled:hover,
      .xoo-wsc-modal .sidebar [type="submit"]:disabled[disabled]:hover,
      .footer_btn a.button:disabled:hover,
      .footer_btn a.button.disabled:hover,
      .footer_btn a.button:disabled[disabled]:hover,
      .footer_btn button.button:disabled:hover,
      .footer_btn button.button.disabled:hover,
      .footer_btn button.button:disabled[disabled]:hover,
      .footer_btn input.button:disabled:hover,
      .footer_btn input.button.disabled:hover,
      .footer_btn input.button:disabled[disabled]:hover,
      .footer_btn .button_block a:disabled:hover,
      .footer_btn .button_block a.disabled:hover,
      .footer_btn .button_block a:disabled[disabled]:hover,
      .footer_btn #respond input#submit:disabled:hover,
      .footer_btn #respond input#submit.disabled:hover,
      .footer_btn #respond input#submit:disabled[disabled]:hover,
      .footer_btn .sidebar [type="submit"]:disabled:hover,
      .footer_btn .sidebar [type="submit"].disabled:hover,
      .footer_btn .sidebar [type="submit"]:disabled[disabled]:hover,
      .block_startseite a.button:disabled:hover,
      .block_startseite a.button.disabled:hover,
      .block_startseite a.button:disabled[disabled]:hover,
      .block_startseite button.button:disabled:hover,
      .block_startseite button.button.disabled:hover,
      .block_startseite button.button:disabled[disabled]:hover,
      .block_startseite input.button:disabled:hover,
      .block_startseite input.button.disabled:hover,
      .block_startseite input.button:disabled[disabled]:hover,
      .block_startseite .button_block a:disabled:hover,
      .block_startseite .button_block a.disabled:hover,
      .block_startseite .button_block a:disabled[disabled]:hover,
      .block_startseite #respond input#submit:disabled:hover,
      .block_startseite #respond input#submit.disabled:hover,
      .block_startseite #respond input#submit:disabled[disabled]:hover,
      .block_startseite .sidebar [type="submit"]:disabled:hover,
      .block_startseite .sidebar [type="submit"].disabled:hover,
      .block_startseite .sidebar [type="submit"]:disabled[disabled]:hover {
        color: #fff;
        background-color: #eae1de; }
  .woocommerce .cart .button,
  .woocommerce .cart input.button,
  .woocommerce-page .cart .button,
  .woocommerce-page .cart input.button,
  .xoo-wsc-modal .cart .button,
  .xoo-wsc-modal .cart input.button,
  .footer_btn .cart .button,
  .footer_btn .cart input.button,
  .block_startseite .cart .button,
  .block_startseite .cart input.button {
    float: none; }
  .woocommerce a.added_to_cart,
  .woocommerce-page a.added_to_cart,
  .xoo-wsc-modal a.added_to_cart,
  .footer_btn a.added_to_cart,
  .block_startseite a.added_to_cart {
    display: inline-block;
    background-color: #eae1de;
    color: black;
    margin-top: 10px;
    padding: .618em 1em; }
    @media screen and (min-width: 768px) {
      .woocommerce a.added_to_cart,
      .woocommerce-page a.added_to_cart,
      .xoo-wsc-modal a.added_to_cart,
      .footer_btn a.added_to_cart,
      .block_startseite a.added_to_cart {
        min-width: 227px; } }
    .woocommerce a.added_to_cart:hover, .woocommerce a.added_to_cart:active, .woocommerce a.added_to_cart:focus,
    .woocommerce-page a.added_to_cart:hover,
    .woocommerce-page a.added_to_cart:active,
    .woocommerce-page a.added_to_cart:focus,
    .xoo-wsc-modal a.added_to_cart:hover,
    .xoo-wsc-modal a.added_to_cart:active,
    .xoo-wsc-modal a.added_to_cart:focus,
    .footer_btn a.added_to_cart:hover,
    .footer_btn a.added_to_cart:active,
    .footer_btn a.added_to_cart:focus,
    .block_startseite a.added_to_cart:hover,
    .block_startseite a.added_to_cart:active,
    .block_startseite a.added_to_cart:focus {
      color: black; }
  .woocommerce #reviews h2 small,
  .woocommerce-page #reviews h2 small,
  .xoo-wsc-modal #reviews h2 small,
  .footer_btn #reviews h2 small,
  .block_startseite #reviews h2 small {
    float: right;
    color: black;
    font-size: 15px;
    margin: 10px 0 0; }
    .woocommerce #reviews h2 small a,
    .woocommerce-page #reviews h2 small a,
    .xoo-wsc-modal #reviews h2 small a,
    .footer_btn #reviews h2 small a,
    .block_startseite #reviews h2 small a {
      text-decoration: none;
      color: black; }
  .woocommerce #reviews h3,
  .woocommerce-page #reviews h3,
  .xoo-wsc-modal #reviews h3,
  .footer_btn #reviews h3,
  .block_startseite #reviews h3 {
    margin: 0; }
  .woocommerce #reviews #respond,
  .woocommerce-page #reviews #respond,
  .xoo-wsc-modal #reviews #respond,
  .footer_btn #reviews #respond,
  .block_startseite #reviews #respond {
    margin: 0;
    border: 0;
    padding: 0; }
  .woocommerce #reviews #comment,
  .woocommerce-page #reviews #comment,
  .xoo-wsc-modal #reviews #comment,
  .footer_btn #reviews #comment,
  .block_startseite #reviews #comment {
    height: 75px; }
  .woocommerce #reviews #comments .add_review,
  .woocommerce-page #reviews #comments .add_review,
  .xoo-wsc-modal #reviews #comments .add_review,
  .footer_btn #reviews #comments .add_review,
  .block_startseite #reviews #comments .add_review {
    *zoom: 1; }
    .woocommerce #reviews #comments .add_review::before, .woocommerce #reviews #comments .add_review::after,
    .woocommerce-page #reviews #comments .add_review::before,
    .woocommerce-page #reviews #comments .add_review::after,
    .xoo-wsc-modal #reviews #comments .add_review::before,
    .xoo-wsc-modal #reviews #comments .add_review::after,
    .footer_btn #reviews #comments .add_review::before,
    .footer_btn #reviews #comments .add_review::after,
    .block_startseite #reviews #comments .add_review::before,
    .block_startseite #reviews #comments .add_review::after {
      content: ' ';
      display: table; }
    .woocommerce #reviews #comments .add_review::after,
    .woocommerce-page #reviews #comments .add_review::after,
    .xoo-wsc-modal #reviews #comments .add_review::after,
    .footer_btn #reviews #comments .add_review::after,
    .block_startseite #reviews #comments .add_review::after {
      clear: both; }
  .woocommerce #reviews #comments h2,
  .woocommerce-page #reviews #comments h2,
  .xoo-wsc-modal #reviews #comments h2,
  .footer_btn #reviews #comments h2,
  .block_startseite #reviews #comments h2 {
    clear: none; }
  .woocommerce #reviews #comments ol.commentlist,
  .woocommerce-page #reviews #comments ol.commentlist,
  .xoo-wsc-modal #reviews #comments ol.commentlist,
  .footer_btn #reviews #comments ol.commentlist,
  .block_startseite #reviews #comments ol.commentlist {
    *zoom: 1;
    margin: 0;
    width: 100%;
    background: none;
    list-style: none; }
    .woocommerce #reviews #comments ol.commentlist::before, .woocommerce #reviews #comments ol.commentlist::after,
    .woocommerce-page #reviews #comments ol.commentlist::before,
    .woocommerce-page #reviews #comments ol.commentlist::after,
    .xoo-wsc-modal #reviews #comments ol.commentlist::before,
    .xoo-wsc-modal #reviews #comments ol.commentlist::after,
    .footer_btn #reviews #comments ol.commentlist::before,
    .footer_btn #reviews #comments ol.commentlist::after,
    .block_startseite #reviews #comments ol.commentlist::before,
    .block_startseite #reviews #comments ol.commentlist::after {
      content: ' ';
      display: table; }
    .woocommerce #reviews #comments ol.commentlist::after,
    .woocommerce-page #reviews #comments ol.commentlist::after,
    .xoo-wsc-modal #reviews #comments ol.commentlist::after,
    .footer_btn #reviews #comments ol.commentlist::after,
    .block_startseite #reviews #comments ol.commentlist::after {
      clear: both; }
    .woocommerce #reviews #comments ol.commentlist li,
    .woocommerce-page #reviews #comments ol.commentlist li,
    .xoo-wsc-modal #reviews #comments ol.commentlist li,
    .footer_btn #reviews #comments ol.commentlist li,
    .block_startseite #reviews #comments ol.commentlist li {
      padding: 0;
      margin: 0 0 20px;
      border: 0;
      position: relative;
      background: 0;
      border: 0; }
      .woocommerce #reviews #comments ol.commentlist li .meta,
      .woocommerce-page #reviews #comments ol.commentlist li .meta,
      .xoo-wsc-modal #reviews #comments ol.commentlist li .meta,
      .footer_btn #reviews #comments ol.commentlist li .meta,
      .block_startseite #reviews #comments ol.commentlist li .meta {
        color: black;
        font-size: 0.75em; }
      .woocommerce #reviews #comments ol.commentlist li img.avatar,
      .woocommerce-page #reviews #comments ol.commentlist li img.avatar,
      .xoo-wsc-modal #reviews #comments ol.commentlist li img.avatar,
      .footer_btn #reviews #comments ol.commentlist li img.avatar,
      .block_startseite #reviews #comments ol.commentlist li img.avatar {
        float: left;
        position: absolute;
        top: 0;
        left: 0;
        padding: 3px;
        width: 32px;
        height: auto;
        background: #5a333d;
        border: 1px solid #502d36;
        margin: 0;
        box-shadow: none; }
      .woocommerce #reviews #comments ol.commentlist li .comment-text,
      .woocommerce-page #reviews #comments ol.commentlist li .comment-text,
      .xoo-wsc-modal #reviews #comments ol.commentlist li .comment-text,
      .footer_btn #reviews #comments ol.commentlist li .comment-text,
      .block_startseite #reviews #comments ol.commentlist li .comment-text {
        margin: 0 0 0 50px;
        border: 1px solid #502d36;
        border-radius: 4px;
        padding: 1em 1em 0;
        *zoom: 1; }
        .woocommerce #reviews #comments ol.commentlist li .comment-text::before, .woocommerce #reviews #comments ol.commentlist li .comment-text::after,
        .woocommerce-page #reviews #comments ol.commentlist li .comment-text::before,
        .woocommerce-page #reviews #comments ol.commentlist li .comment-text::after,
        .xoo-wsc-modal #reviews #comments ol.commentlist li .comment-text::before,
        .xoo-wsc-modal #reviews #comments ol.commentlist li .comment-text::after,
        .footer_btn #reviews #comments ol.commentlist li .comment-text::before,
        .footer_btn #reviews #comments ol.commentlist li .comment-text::after,
        .block_startseite #reviews #comments ol.commentlist li .comment-text::before,
        .block_startseite #reviews #comments ol.commentlist li .comment-text::after {
          content: ' ';
          display: table; }
        .woocommerce #reviews #comments ol.commentlist li .comment-text::after,
        .woocommerce-page #reviews #comments ol.commentlist li .comment-text::after,
        .xoo-wsc-modal #reviews #comments ol.commentlist li .comment-text::after,
        .footer_btn #reviews #comments ol.commentlist li .comment-text::after,
        .block_startseite #reviews #comments ol.commentlist li .comment-text::after {
          clear: both; }
        .woocommerce #reviews #comments ol.commentlist li .comment-text p,
        .woocommerce-page #reviews #comments ol.commentlist li .comment-text p,
        .xoo-wsc-modal #reviews #comments ol.commentlist li .comment-text p,
        .footer_btn #reviews #comments ol.commentlist li .comment-text p,
        .block_startseite #reviews #comments ol.commentlist li .comment-text p {
          margin: 0 0 1em; }
        .woocommerce #reviews #comments ol.commentlist li .comment-text p.meta,
        .woocommerce-page #reviews #comments ol.commentlist li .comment-text p.meta,
        .xoo-wsc-modal #reviews #comments ol.commentlist li .comment-text p.meta,
        .footer_btn #reviews #comments ol.commentlist li .comment-text p.meta,
        .block_startseite #reviews #comments ol.commentlist li .comment-text p.meta {
          font-size: 0.83em; }
    .woocommerce #reviews #comments ol.commentlist ul.children,
    .woocommerce-page #reviews #comments ol.commentlist ul.children,
    .xoo-wsc-modal #reviews #comments ol.commentlist ul.children,
    .footer_btn #reviews #comments ol.commentlist ul.children,
    .block_startseite #reviews #comments ol.commentlist ul.children {
      list-style: none outside;
      margin: 20px 0 0 50px; }
      .woocommerce #reviews #comments ol.commentlist ul.children .star-rating,
      .woocommerce-page #reviews #comments ol.commentlist ul.children .star-rating,
      .xoo-wsc-modal #reviews #comments ol.commentlist ul.children .star-rating,
      .footer_btn #reviews #comments ol.commentlist ul.children .star-rating,
      .block_startseite #reviews #comments ol.commentlist ul.children .star-rating {
        display: none; }
    .woocommerce #reviews #comments ol.commentlist #respond,
    .woocommerce-page #reviews #comments ol.commentlist #respond,
    .xoo-wsc-modal #reviews #comments ol.commentlist #respond,
    .footer_btn #reviews #comments ol.commentlist #respond,
    .block_startseite #reviews #comments ol.commentlist #respond {
      border: 1px solid #502d36;
      border-radius: 4px;
      padding: 1em 1em 0;
      margin: 20px 0 0 50px; }
  .woocommerce #reviews #comments .commentlist > li::before,
  .woocommerce-page #reviews #comments .commentlist > li::before,
  .xoo-wsc-modal #reviews #comments .commentlist > li::before,
  .footer_btn #reviews #comments .commentlist > li::before,
  .block_startseite #reviews #comments .commentlist > li::before {
    content: ""; }
  .woocommerce .star-rating,
  .woocommerce-page .star-rating,
  .xoo-wsc-modal .star-rating,
  .footer_btn .star-rating,
  .block_startseite .star-rating {
    float: right;
    overflow: hidden;
    position: relative;
    height: 1em;
    line-height: 1;
    font-size: 1em;
    width: 5.4em;
    font-family: "star"; }
    .woocommerce .star-rating::before,
    .woocommerce-page .star-rating::before,
    .xoo-wsc-modal .star-rating::before,
    .footer_btn .star-rating::before,
    .block_startseite .star-rating::before {
      content: "\73\73\73\73\73";
      color: #392127;
      float: left;
      top: 0;
      left: 0;
      position: absolute; }
    .woocommerce .star-rating span,
    .woocommerce-page .star-rating span,
    .xoo-wsc-modal .star-rating span,
    .footer_btn .star-rating span,
    .block_startseite .star-rating span {
      overflow: hidden;
      float: left;
      top: 0;
      left: 0;
      position: absolute;
      padding-top: 1.5em; }
    .woocommerce .star-rating span::before,
    .woocommerce-page .star-rating span::before,
    .xoo-wsc-modal .star-rating span::before,
    .footer_btn .star-rating span::before,
    .block_startseite .star-rating span::before {
      content: "\53\53\53\53\53";
      top: 0;
      position: absolute;
      left: 0; }
  .woocommerce .woocommerce-product-rating,
  .woocommerce-page .woocommerce-product-rating,
  .xoo-wsc-modal .woocommerce-product-rating,
  .footer_btn .woocommerce-product-rating,
  .block_startseite .woocommerce-product-rating {
    *zoom: 1;
    line-height: 2;
    display: block; }
    .woocommerce .woocommerce-product-rating::before, .woocommerce .woocommerce-product-rating::after,
    .woocommerce-page .woocommerce-product-rating::before,
    .woocommerce-page .woocommerce-product-rating::after,
    .xoo-wsc-modal .woocommerce-product-rating::before,
    .xoo-wsc-modal .woocommerce-product-rating::after,
    .footer_btn .woocommerce-product-rating::before,
    .footer_btn .woocommerce-product-rating::after,
    .block_startseite .woocommerce-product-rating::before,
    .block_startseite .woocommerce-product-rating::after {
      content: ' ';
      display: table; }
    .woocommerce .woocommerce-product-rating::after,
    .woocommerce-page .woocommerce-product-rating::after,
    .xoo-wsc-modal .woocommerce-product-rating::after,
    .footer_btn .woocommerce-product-rating::after,
    .block_startseite .woocommerce-product-rating::after {
      clear: both; }
    .woocommerce .woocommerce-product-rating .star-rating,
    .woocommerce-page .woocommerce-product-rating .star-rating,
    .xoo-wsc-modal .woocommerce-product-rating .star-rating,
    .footer_btn .woocommerce-product-rating .star-rating,
    .block_startseite .woocommerce-product-rating .star-rating {
      margin: 0.5em 4px 0 0;
      float: left; }
  .woocommerce .products .star-rating,
  .woocommerce-page .products .star-rating,
  .xoo-wsc-modal .products .star-rating,
  .footer_btn .products .star-rating,
  .block_startseite .products .star-rating {
    display: block;
    height: 17px;
    margin: -17px 0 0 0;
    float: none; }
  .woocommerce .hreview-aggregate .star-rating,
  .woocommerce-page .hreview-aggregate .star-rating,
  .xoo-wsc-modal .hreview-aggregate .star-rating,
  .footer_btn .hreview-aggregate .star-rating,
  .block_startseite .hreview-aggregate .star-rating {
    margin: 10px 0 0; }
  .woocommerce #review_form #respond,
  .woocommerce-page #review_form #respond,
  .xoo-wsc-modal #review_form #respond,
  .footer_btn #review_form #respond,
  .block_startseite #review_form #respond {
    *zoom: 1;
    position: static;
    margin: 0;
    width: auto;
    padding: 0;
    background: transparent none;
    border: 0; }
    .woocommerce #review_form #respond::before, .woocommerce #review_form #respond::after,
    .woocommerce-page #review_form #respond::before,
    .woocommerce-page #review_form #respond::after,
    .xoo-wsc-modal #review_form #respond::before,
    .xoo-wsc-modal #review_form #respond::after,
    .footer_btn #review_form #respond::before,
    .footer_btn #review_form #respond::after,
    .block_startseite #review_form #respond::before,
    .block_startseite #review_form #respond::after {
      content: ' ';
      display: table; }
    .woocommerce #review_form #respond::after,
    .woocommerce-page #review_form #respond::after,
    .xoo-wsc-modal #review_form #respond::after,
    .footer_btn #review_form #respond::after,
    .block_startseite #review_form #respond::after {
      clear: both; }
    .woocommerce #review_form #respond p,
    .woocommerce-page #review_form #respond p,
    .xoo-wsc-modal #review_form #respond p,
    .footer_btn #review_form #respond p,
    .block_startseite #review_form #respond p {
      margin: 0 0 10px; }
    .woocommerce #review_form #respond .form-submit input,
    .woocommerce-page #review_form #respond .form-submit input,
    .xoo-wsc-modal #review_form #respond .form-submit input,
    .footer_btn #review_form #respond .form-submit input,
    .block_startseite #review_form #respond .form-submit input {
      left: auto; }
    .woocommerce #review_form #respond textarea,
    .woocommerce-page #review_form #respond textarea,
    .xoo-wsc-modal #review_form #respond textarea,
    .footer_btn #review_form #respond textarea,
    .block_startseite #review_form #respond textarea {
      box-sizing: border-box;
      width: 100%; }
  .woocommerce p.stars a,
  .woocommerce-page p.stars a,
  .xoo-wsc-modal p.stars a,
  .footer_btn p.stars a,
  .block_startseite p.stars a {
    position: relative;
    height: 1em;
    width: 1em;
    text-indent: -999em;
    display: inline-block;
    text-decoration: none; }
    .woocommerce p.stars a::before,
    .woocommerce-page p.stars a::before,
    .xoo-wsc-modal p.stars a::before,
    .footer_btn p.stars a::before,
    .block_startseite p.stars a::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1em;
      height: 1em;
      line-height: 1;
      font-family: "WooCommerce";
      content: "\e021";
      text-indent: 0; }
    .woocommerce p.stars a:hover ~ a::before,
    .woocommerce-page p.stars a:hover ~ a::before,
    .xoo-wsc-modal p.stars a:hover ~ a::before,
    .footer_btn p.stars a:hover ~ a::before,
    .block_startseite p.stars a:hover ~ a::before {
      content: "\e021"; }
  .woocommerce p.stars:hover a::before,
  .woocommerce-page p.stars:hover a::before,
  .xoo-wsc-modal p.stars:hover a::before,
  .footer_btn p.stars:hover a::before,
  .block_startseite p.stars:hover a::before {
    content: "\e020"; }
  .woocommerce p.stars.selected a.active::before,
  .woocommerce-page p.stars.selected a.active::before,
  .xoo-wsc-modal p.stars.selected a.active::before,
  .footer_btn p.stars.selected a.active::before,
  .block_startseite p.stars.selected a.active::before {
    content: "\e020"; }
  .woocommerce p.stars.selected a.active ~ a::before,
  .woocommerce-page p.stars.selected a.active ~ a::before,
  .xoo-wsc-modal p.stars.selected a.active ~ a::before,
  .footer_btn p.stars.selected a.active ~ a::before,
  .block_startseite p.stars.selected a.active ~ a::before {
    content: "\e021"; }
  .woocommerce p.stars.selected a:not(.active)::before,
  .woocommerce-page p.stars.selected a:not(.active)::before,
  .xoo-wsc-modal p.stars.selected a:not(.active)::before,
  .footer_btn p.stars.selected a:not(.active)::before,
  .block_startseite p.stars.selected a:not(.active)::before {
    content: "\e020"; }
  .woocommerce table.shop_attributes,
  .woocommerce-page table.shop_attributes,
  .xoo-wsc-modal table.shop_attributes,
  .footer_btn table.shop_attributes,
  .block_startseite table.shop_attributes {
    border: 0;
    border-top: 1px dotted rgba(0, 0, 0, 0.1);
    margin-bottom: 1.618em;
    width: 100%; }
    .woocommerce table.shop_attributes th,
    .woocommerce-page table.shop_attributes th,
    .xoo-wsc-modal table.shop_attributes th,
    .footer_btn table.shop_attributes th,
    .block_startseite table.shop_attributes th {
      width: 150px;
      font-weight: 700;
      padding: 8px;
      border-top: 0;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
      margin: 0;
      line-height: 1.5; }
    .woocommerce table.shop_attributes td,
    .woocommerce-page table.shop_attributes td,
    .xoo-wsc-modal table.shop_attributes td,
    .footer_btn table.shop_attributes td,
    .block_startseite table.shop_attributes td {
      font-style: normal;
      padding: 0;
      border-top: 0;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
      margin: 0;
      line-height: 1.5; }
      .woocommerce table.shop_attributes td p,
      .woocommerce-page table.shop_attributes td p,
      .xoo-wsc-modal table.shop_attributes td p,
      .footer_btn table.shop_attributes td p,
      .block_startseite table.shop_attributes td p {
        margin: 0;
        padding: 8px 0; }
    .woocommerce table.shop_attributes tr:nth-child(even) td,
    .woocommerce table.shop_attributes tr:nth-child(even) th,
    .woocommerce-page table.shop_attributes tr:nth-child(even) td,
    .woocommerce-page table.shop_attributes tr:nth-child(even) th,
    .xoo-wsc-modal table.shop_attributes tr:nth-child(even) td,
    .xoo-wsc-modal table.shop_attributes tr:nth-child(even) th,
    .footer_btn table.shop_attributes tr:nth-child(even) td,
    .footer_btn table.shop_attributes tr:nth-child(even) th,
    .block_startseite table.shop_attributes tr:nth-child(even) td,
    .block_startseite table.shop_attributes tr:nth-child(even) th {
      background: rgba(0, 0, 0, 0.025); }
  .woocommerce table.shop_table,
  .woocommerce-page table.shop_table,
  .xoo-wsc-modal table.shop_table,
  .footer_btn table.shop_table,
  .block_startseite table.shop_table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 0; }
    .woocommerce table.shop_table th,
    .woocommerce-page table.shop_table th,
    .xoo-wsc-modal table.shop_table th,
    .footer_btn table.shop_table th,
    .block_startseite table.shop_table th {
      font-weight: 700;
      padding: 9px 12px;
      line-height: 1.5em;
      border: none;
      background-color: #eae1de;
      color: black;
      font-size: 18px; }
    .woocommerce table.shop_table tr.packing-select th,
    .woocommerce-page table.shop_table tr.packing-select th,
    .xoo-wsc-modal table.shop_table tr.packing-select th,
    .footer_btn table.shop_table tr.packing-select th,
    .block_startseite table.shop_table tr.packing-select th {
      background-color: #5a333d; }
      .woocommerce table.shop_table tr.packing-select th p#gift_fee_field,
      .woocommerce-page table.shop_table tr.packing-select th p#gift_fee_field,
      .xoo-wsc-modal table.shop_table tr.packing-select th p#gift_fee_field,
      .footer_btn table.shop_table tr.packing-select th p#gift_fee_field,
      .block_startseite table.shop_table tr.packing-select th p#gift_fee_field {
        font-size: inherit;
        color: #fff; }
    .woocommerce table.shop_table td,
    .woocommerce-page table.shop_table td,
    .xoo-wsc-modal table.shop_table td,
    .footer_btn table.shop_table td,
    .block_startseite table.shop_table td {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 9px 12px;
      vertical-align: middle;
      line-height: 1.5em;
      border: none;
      font-size: 18px; }
      .woocommerce table.shop_table td small,
      .woocommerce-page table.shop_table td small,
      .xoo-wsc-modal table.shop_table td small,
      .footer_btn table.shop_table td small,
      .block_startseite table.shop_table td small {
        font-weight: normal; }
      .woocommerce table.shop_table td del,
      .woocommerce-page table.shop_table td del,
      .xoo-wsc-modal table.shop_table td del,
      .footer_btn table.shop_table td del,
      .block_startseite table.shop_table td del {
        font-weight: normal; }
    .woocommerce table.shop_table li,
    .woocommerce-page table.shop_table li,
    .xoo-wsc-modal table.shop_table li,
    .footer_btn table.shop_table li,
    .block_startseite table.shop_table li {
      text-align: right; }
    .woocommerce table.shop_table tbody:first-child tr:first-child th,
    .woocommerce table.shop_table tbody:first-child tr:first-child td,
    .woocommerce-page table.shop_table tbody:first-child tr:first-child th,
    .woocommerce-page table.shop_table tbody:first-child tr:first-child td,
    .xoo-wsc-modal table.shop_table tbody:first-child tr:first-child th,
    .xoo-wsc-modal table.shop_table tbody:first-child tr:first-child td,
    .footer_btn table.shop_table tbody:first-child tr:first-child th,
    .footer_btn table.shop_table tbody:first-child tr:first-child td,
    .block_startseite table.shop_table tbody:first-child tr:first-child th,
    .block_startseite table.shop_table tbody:first-child tr:first-child td {
      border-top: 0;
      border-bottom: 0; }
    .woocommerce table.shop_table tr,
    .woocommerce-page table.shop_table tr,
    .xoo-wsc-modal table.shop_table tr,
    .footer_btn table.shop_table tr,
    .block_startseite table.shop_table tr {
      border: none;
      background-color: none; }
    .woocommerce table.shop_table tfoot td,
    .woocommerce table.shop_table tfoot th,
    .woocommerce table.shop_table tbody th,
    .woocommerce-page table.shop_table tfoot td,
    .woocommerce-page table.shop_table tfoot th,
    .woocommerce-page table.shop_table tbody th,
    .xoo-wsc-modal table.shop_table tfoot td,
    .xoo-wsc-modal table.shop_table tfoot th,
    .xoo-wsc-modal table.shop_table tbody th,
    .footer_btn table.shop_table tfoot td,
    .footer_btn table.shop_table tfoot th,
    .footer_btn table.shop_table tbody th,
    .block_startseite table.shop_table tfoot td,
    .block_startseite table.shop_table tfoot th,
    .block_startseite table.shop_table tbody th {
      font-weight: 700;
      border-bottom: none;
      border-left: none;
      border-right: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .woocommerce table.my_account_orders,
  .woocommerce-page table.my_account_orders,
  .xoo-wsc-modal table.my_account_orders,
  .footer_btn table.my_account_orders,
  .block_startseite table.my_account_orders {
    font-size: 1rem; }
    .woocommerce table.my_account_orders th,
    .woocommerce table.my_account_orders td,
    .woocommerce-page table.my_account_orders th,
    .woocommerce-page table.my_account_orders td,
    .xoo-wsc-modal table.my_account_orders th,
    .xoo-wsc-modal table.my_account_orders td,
    .footer_btn table.my_account_orders th,
    .footer_btn table.my_account_orders td,
    .block_startseite table.my_account_orders th,
    .block_startseite table.my_account_orders td {
      padding: 4px 8px;
      vertical-align: middle; }
    .woocommerce table.my_account_orders .button,
    .woocommerce-page table.my_account_orders .button,
    .xoo-wsc-modal table.my_account_orders .button,
    .footer_btn table.my_account_orders .button,
    .block_startseite table.my_account_orders .button {
      white-space: nowrap; }
  .woocommerce table.woocommerce-MyAccount-downloads td,
  .woocommerce table.woocommerce-MyAccount-downloads th,
  .woocommerce-page table.woocommerce-MyAccount-downloads td,
  .woocommerce-page table.woocommerce-MyAccount-downloads th,
  .xoo-wsc-modal table.woocommerce-MyAccount-downloads td,
  .xoo-wsc-modal table.woocommerce-MyAccount-downloads th,
  .footer_btn table.woocommerce-MyAccount-downloads td,
  .footer_btn table.woocommerce-MyAccount-downloads th,
  .block_startseite table.woocommerce-MyAccount-downloads td,
  .block_startseite table.woocommerce-MyAccount-downloads th {
    vertical-align: top;
    text-align: center; }
    .woocommerce table.woocommerce-MyAccount-downloads td:first-child,
    .woocommerce table.woocommerce-MyAccount-downloads th:first-child,
    .woocommerce-page table.woocommerce-MyAccount-downloads td:first-child,
    .woocommerce-page table.woocommerce-MyAccount-downloads th:first-child,
    .xoo-wsc-modal table.woocommerce-MyAccount-downloads td:first-child,
    .xoo-wsc-modal table.woocommerce-MyAccount-downloads th:first-child,
    .footer_btn table.woocommerce-MyAccount-downloads td:first-child,
    .footer_btn table.woocommerce-MyAccount-downloads th:first-child,
    .block_startseite table.woocommerce-MyAccount-downloads td:first-child,
    .block_startseite table.woocommerce-MyAccount-downloads th:first-child {
      text-align: left; }
    .woocommerce table.woocommerce-MyAccount-downloads td:last-child,
    .woocommerce table.woocommerce-MyAccount-downloads th:last-child,
    .woocommerce-page table.woocommerce-MyAccount-downloads td:last-child,
    .woocommerce-page table.woocommerce-MyAccount-downloads th:last-child,
    .xoo-wsc-modal table.woocommerce-MyAccount-downloads td:last-child,
    .xoo-wsc-modal table.woocommerce-MyAccount-downloads th:last-child,
    .footer_btn table.woocommerce-MyAccount-downloads td:last-child,
    .footer_btn table.woocommerce-MyAccount-downloads th:last-child,
    .block_startseite table.woocommerce-MyAccount-downloads td:last-child,
    .block_startseite table.woocommerce-MyAccount-downloads th:last-child {
      text-align: left; }
    .woocommerce table.woocommerce-MyAccount-downloads td .woocommerce-MyAccount-downloads-file::before,
    .woocommerce table.woocommerce-MyAccount-downloads th .woocommerce-MyAccount-downloads-file::before,
    .woocommerce-page table.woocommerce-MyAccount-downloads td .woocommerce-MyAccount-downloads-file::before,
    .woocommerce-page table.woocommerce-MyAccount-downloads th .woocommerce-MyAccount-downloads-file::before,
    .xoo-wsc-modal table.woocommerce-MyAccount-downloads td .woocommerce-MyAccount-downloads-file::before,
    .xoo-wsc-modal table.woocommerce-MyAccount-downloads th .woocommerce-MyAccount-downloads-file::before,
    .footer_btn table.woocommerce-MyAccount-downloads td .woocommerce-MyAccount-downloads-file::before,
    .footer_btn table.woocommerce-MyAccount-downloads th .woocommerce-MyAccount-downloads-file::before,
    .block_startseite table.woocommerce-MyAccount-downloads td .woocommerce-MyAccount-downloads-file::before,
    .block_startseite table.woocommerce-MyAccount-downloads th .woocommerce-MyAccount-downloads-file::before {
      content: "\2193";
      display: inline-block; }
  .woocommerce td.product-name a,
  .woocommerce-page td.product-name a,
  .xoo-wsc-modal td.product-name a,
  .footer_btn td.product-name a,
  .block_startseite td.product-name a {
    text-decoration: none; }
  .woocommerce td.product-name .units-info,
  .woocommerce-page td.product-name .units-info,
  .xoo-wsc-modal td.product-name .units-info,
  .footer_btn td.product-name .units-info,
  .block_startseite td.product-name .units-info {
    font-size: 15px; }
  .woocommerce td.product-name dl.variation,
  .woocommerce td.product-name .wc-item-meta,
  .woocommerce-page td.product-name dl.variation,
  .woocommerce-page td.product-name .wc-item-meta,
  .xoo-wsc-modal td.product-name dl.variation,
  .xoo-wsc-modal td.product-name .wc-item-meta,
  .footer_btn td.product-name dl.variation,
  .footer_btn td.product-name .wc-item-meta,
  .block_startseite td.product-name dl.variation,
  .block_startseite td.product-name .wc-item-meta {
    list-style: none outside; }
    .woocommerce td.product-name dl.variation dt,
    .woocommerce td.product-name dl.variation .wc-item-meta-label,
    .woocommerce td.product-name .wc-item-meta dt,
    .woocommerce td.product-name .wc-item-meta .wc-item-meta-label,
    .woocommerce-page td.product-name dl.variation dt,
    .woocommerce-page td.product-name dl.variation .wc-item-meta-label,
    .woocommerce-page td.product-name .wc-item-meta dt,
    .woocommerce-page td.product-name .wc-item-meta .wc-item-meta-label,
    .xoo-wsc-modal td.product-name dl.variation dt,
    .xoo-wsc-modal td.product-name dl.variation .wc-item-meta-label,
    .xoo-wsc-modal td.product-name .wc-item-meta dt,
    .xoo-wsc-modal td.product-name .wc-item-meta .wc-item-meta-label,
    .footer_btn td.product-name dl.variation dt,
    .footer_btn td.product-name dl.variation .wc-item-meta-label,
    .footer_btn td.product-name .wc-item-meta dt,
    .footer_btn td.product-name .wc-item-meta .wc-item-meta-label,
    .block_startseite td.product-name dl.variation dt,
    .block_startseite td.product-name dl.variation .wc-item-meta-label,
    .block_startseite td.product-name .wc-item-meta dt,
    .block_startseite td.product-name .wc-item-meta .wc-item-meta-label {
      float: left;
      clear: both;
      margin-right: 0.25em;
      display: inline-block;
      list-style: none outside; }
    .woocommerce td.product-name dl.variation dd,
    .woocommerce td.product-name .wc-item-meta dd,
    .woocommerce-page td.product-name dl.variation dd,
    .woocommerce-page td.product-name .wc-item-meta dd,
    .xoo-wsc-modal td.product-name dl.variation dd,
    .xoo-wsc-modal td.product-name .wc-item-meta dd,
    .footer_btn td.product-name dl.variation dd,
    .footer_btn td.product-name .wc-item-meta dd,
    .block_startseite td.product-name dl.variation dd,
    .block_startseite td.product-name .wc-item-meta dd {
      margin: 0; }
    .woocommerce td.product-name dl.variation p, .woocommerce td.product-name dl.variation:last-child,
    .woocommerce td.product-name .wc-item-meta p,
    .woocommerce td.product-name .wc-item-meta:last-child,
    .woocommerce-page td.product-name dl.variation p,
    .woocommerce-page td.product-name dl.variation:last-child,
    .woocommerce-page td.product-name .wc-item-meta p,
    .woocommerce-page td.product-name .wc-item-meta:last-child,
    .xoo-wsc-modal td.product-name dl.variation p,
    .xoo-wsc-modal td.product-name dl.variation:last-child,
    .xoo-wsc-modal td.product-name .wc-item-meta p,
    .xoo-wsc-modal td.product-name .wc-item-meta:last-child,
    .footer_btn td.product-name dl.variation p,
    .footer_btn td.product-name dl.variation:last-child,
    .footer_btn td.product-name .wc-item-meta p,
    .footer_btn td.product-name .wc-item-meta:last-child,
    .block_startseite td.product-name dl.variation p,
    .block_startseite td.product-name dl.variation:last-child,
    .block_startseite td.product-name .wc-item-meta p,
    .block_startseite td.product-name .wc-item-meta:last-child {
      margin-bottom: 0; }
  .woocommerce td.product-name p.backorder_notification,
  .woocommerce-page td.product-name p.backorder_notification,
  .xoo-wsc-modal td.product-name p.backorder_notification,
  .footer_btn td.product-name p.backorder_notification,
  .block_startseite td.product-name p.backorder_notification {
    font-size: 0.83em; }
  .woocommerce td.product-quantity,
  .woocommerce-page td.product-quantity,
  .xoo-wsc-modal td.product-quantity,
  .footer_btn td.product-quantity,
  .block_startseite td.product-quantity {
    min-width: 80px; }
  .woocommerce ul.cart_list,
  .woocommerce ul.product_list_widget,
  .woocommerce-page ul.cart_list,
  .woocommerce-page ul.product_list_widget,
  .xoo-wsc-modal ul.cart_list,
  .xoo-wsc-modal ul.product_list_widget,
  .footer_btn ul.cart_list,
  .footer_btn ul.product_list_widget,
  .block_startseite ul.cart_list,
  .block_startseite ul.product_list_widget {
    list-style: none outside;
    padding: 0;
    margin: 0; }
    .woocommerce ul.cart_list li,
    .woocommerce ul.product_list_widget li,
    .woocommerce-page ul.cart_list li,
    .woocommerce-page ul.product_list_widget li,
    .xoo-wsc-modal ul.cart_list li,
    .xoo-wsc-modal ul.product_list_widget li,
    .footer_btn ul.cart_list li,
    .footer_btn ul.product_list_widget li,
    .block_startseite ul.cart_list li,
    .block_startseite ul.product_list_widget li {
      padding: 4px 0;
      margin: 0;
      *zoom: 1;
      list-style: none; }
      .woocommerce ul.cart_list li::before, .woocommerce ul.cart_list li::after,
      .woocommerce ul.product_list_widget li::before,
      .woocommerce ul.product_list_widget li::after,
      .woocommerce-page ul.cart_list li::before,
      .woocommerce-page ul.cart_list li::after,
      .woocommerce-page ul.product_list_widget li::before,
      .woocommerce-page ul.product_list_widget li::after,
      .xoo-wsc-modal ul.cart_list li::before,
      .xoo-wsc-modal ul.cart_list li::after,
      .xoo-wsc-modal ul.product_list_widget li::before,
      .xoo-wsc-modal ul.product_list_widget li::after,
      .footer_btn ul.cart_list li::before,
      .footer_btn ul.cart_list li::after,
      .footer_btn ul.product_list_widget li::before,
      .footer_btn ul.product_list_widget li::after,
      .block_startseite ul.cart_list li::before,
      .block_startseite ul.cart_list li::after,
      .block_startseite ul.product_list_widget li::before,
      .block_startseite ul.product_list_widget li::after {
        content: ' ';
        display: table; }
      .woocommerce ul.cart_list li::after,
      .woocommerce ul.product_list_widget li::after,
      .woocommerce-page ul.cart_list li::after,
      .woocommerce-page ul.product_list_widget li::after,
      .xoo-wsc-modal ul.cart_list li::after,
      .xoo-wsc-modal ul.product_list_widget li::after,
      .footer_btn ul.cart_list li::after,
      .footer_btn ul.product_list_widget li::after,
      .block_startseite ul.cart_list li::after,
      .block_startseite ul.product_list_widget li::after {
        clear: both; }
      .woocommerce ul.cart_list li a,
      .woocommerce ul.product_list_widget li a,
      .woocommerce-page ul.cart_list li a,
      .woocommerce-page ul.product_list_widget li a,
      .xoo-wsc-modal ul.cart_list li a,
      .xoo-wsc-modal ul.product_list_widget li a,
      .footer_btn ul.cart_list li a,
      .footer_btn ul.product_list_widget li a,
      .block_startseite ul.cart_list li a,
      .block_startseite ul.product_list_widget li a {
        display: block;
        font-weight: 700; }
      .woocommerce ul.cart_list li img,
      .woocommerce ul.product_list_widget li img,
      .woocommerce-page ul.cart_list li img,
      .woocommerce-page ul.product_list_widget li img,
      .xoo-wsc-modal ul.cart_list li img,
      .xoo-wsc-modal ul.product_list_widget li img,
      .footer_btn ul.cart_list li img,
      .footer_btn ul.product_list_widget li img,
      .block_startseite ul.cart_list li img,
      .block_startseite ul.product_list_widget li img {
        float: right;
        margin-left: 4px;
        width: 32px;
        height: auto;
        box-shadow: none; }
      .woocommerce ul.cart_list li dl,
      .woocommerce ul.product_list_widget li dl,
      .woocommerce-page ul.cart_list li dl,
      .woocommerce-page ul.product_list_widget li dl,
      .xoo-wsc-modal ul.cart_list li dl,
      .xoo-wsc-modal ul.product_list_widget li dl,
      .footer_btn ul.cart_list li dl,
      .footer_btn ul.product_list_widget li dl,
      .block_startseite ul.cart_list li dl,
      .block_startseite ul.product_list_widget li dl {
        margin: 0;
        padding-left: 1em;
        border-left: 2px solid rgba(0, 0, 0, 0.1);
        *zoom: 1; }
        .woocommerce ul.cart_list li dl::before, .woocommerce ul.cart_list li dl::after,
        .woocommerce ul.product_list_widget li dl::before,
        .woocommerce ul.product_list_widget li dl::after,
        .woocommerce-page ul.cart_list li dl::before,
        .woocommerce-page ul.cart_list li dl::after,
        .woocommerce-page ul.product_list_widget li dl::before,
        .woocommerce-page ul.product_list_widget li dl::after,
        .xoo-wsc-modal ul.cart_list li dl::before,
        .xoo-wsc-modal ul.cart_list li dl::after,
        .xoo-wsc-modal ul.product_list_widget li dl::before,
        .xoo-wsc-modal ul.product_list_widget li dl::after,
        .footer_btn ul.cart_list li dl::before,
        .footer_btn ul.cart_list li dl::after,
        .footer_btn ul.product_list_widget li dl::before,
        .footer_btn ul.product_list_widget li dl::after,
        .block_startseite ul.cart_list li dl::before,
        .block_startseite ul.cart_list li dl::after,
        .block_startseite ul.product_list_widget li dl::before,
        .block_startseite ul.product_list_widget li dl::after {
          content: ' ';
          display: table; }
        .woocommerce ul.cart_list li dl::after,
        .woocommerce ul.product_list_widget li dl::after,
        .woocommerce-page ul.cart_list li dl::after,
        .woocommerce-page ul.product_list_widget li dl::after,
        .xoo-wsc-modal ul.cart_list li dl::after,
        .xoo-wsc-modal ul.product_list_widget li dl::after,
        .footer_btn ul.cart_list li dl::after,
        .footer_btn ul.product_list_widget li dl::after,
        .block_startseite ul.cart_list li dl::after,
        .block_startseite ul.product_list_widget li dl::after {
          clear: both; }
        .woocommerce ul.cart_list li dl dt,
        .woocommerce ul.cart_list li dl dd,
        .woocommerce ul.product_list_widget li dl dt,
        .woocommerce ul.product_list_widget li dl dd,
        .woocommerce-page ul.cart_list li dl dt,
        .woocommerce-page ul.cart_list li dl dd,
        .woocommerce-page ul.product_list_widget li dl dt,
        .woocommerce-page ul.product_list_widget li dl dd,
        .xoo-wsc-modal ul.cart_list li dl dt,
        .xoo-wsc-modal ul.cart_list li dl dd,
        .xoo-wsc-modal ul.product_list_widget li dl dt,
        .xoo-wsc-modal ul.product_list_widget li dl dd,
        .footer_btn ul.cart_list li dl dt,
        .footer_btn ul.cart_list li dl dd,
        .footer_btn ul.product_list_widget li dl dt,
        .footer_btn ul.product_list_widget li dl dd,
        .block_startseite ul.cart_list li dl dt,
        .block_startseite ul.cart_list li dl dd,
        .block_startseite ul.product_list_widget li dl dt,
        .block_startseite ul.product_list_widget li dl dd {
          display: inline-block;
          float: left;
          margin-bottom: 1em; }
        .woocommerce ul.cart_list li dl dt,
        .woocommerce ul.product_list_widget li dl dt,
        .woocommerce-page ul.cart_list li dl dt,
        .woocommerce-page ul.product_list_widget li dl dt,
        .xoo-wsc-modal ul.cart_list li dl dt,
        .xoo-wsc-modal ul.product_list_widget li dl dt,
        .footer_btn ul.cart_list li dl dt,
        .footer_btn ul.product_list_widget li dl dt,
        .block_startseite ul.cart_list li dl dt,
        .block_startseite ul.product_list_widget li dl dt {
          font-weight: 700;
          padding: 0 0 0.25em;
          margin: 0 4px 0 0;
          clear: left; }
        .woocommerce ul.cart_list li dl dd,
        .woocommerce ul.product_list_widget li dl dd,
        .woocommerce-page ul.cart_list li dl dd,
        .woocommerce-page ul.product_list_widget li dl dd,
        .xoo-wsc-modal ul.cart_list li dl dd,
        .xoo-wsc-modal ul.product_list_widget li dl dd,
        .footer_btn ul.cart_list li dl dd,
        .footer_btn ul.product_list_widget li dl dd,
        .block_startseite ul.cart_list li dl dd,
        .block_startseite ul.product_list_widget li dl dd {
          padding: 0 0 0.25em; }
          .woocommerce ul.cart_list li dl dd p:last-child,
          .woocommerce ul.product_list_widget li dl dd p:last-child,
          .woocommerce-page ul.cart_list li dl dd p:last-child,
          .woocommerce-page ul.product_list_widget li dl dd p:last-child,
          .xoo-wsc-modal ul.cart_list li dl dd p:last-child,
          .xoo-wsc-modal ul.product_list_widget li dl dd p:last-child,
          .footer_btn ul.cart_list li dl dd p:last-child,
          .footer_btn ul.product_list_widget li dl dd p:last-child,
          .block_startseite ul.cart_list li dl dd p:last-child,
          .block_startseite ul.product_list_widget li dl dd p:last-child {
            margin-bottom: 0; }
      .woocommerce ul.cart_list li .star-rating,
      .woocommerce ul.product_list_widget li .star-rating,
      .woocommerce-page ul.cart_list li .star-rating,
      .woocommerce-page ul.product_list_widget li .star-rating,
      .xoo-wsc-modal ul.cart_list li .star-rating,
      .xoo-wsc-modal ul.product_list_widget li .star-rating,
      .footer_btn ul.cart_list li .star-rating,
      .footer_btn ul.product_list_widget li .star-rating,
      .block_startseite ul.cart_list li .star-rating,
      .block_startseite ul.product_list_widget li .star-rating {
        float: none; }
  .woocommerce.widget_shopping_cart .total,
  .woocommerce .widget_shopping_cart .total,
  .woocommerce-page.widget_shopping_cart .total,
  .woocommerce-page .widget_shopping_cart .total,
  .xoo-wsc-modal.widget_shopping_cart .total,
  .xoo-wsc-modal .widget_shopping_cart .total,
  .footer_btn.widget_shopping_cart .total,
  .footer_btn .widget_shopping_cart .total,
  .block_startseite.widget_shopping_cart .total,
  .block_startseite .widget_shopping_cart .total {
    border-top: 3px double #5a333d;
    padding: 4px 0 0; }
    .woocommerce.widget_shopping_cart .total strong,
    .woocommerce .widget_shopping_cart .total strong,
    .woocommerce-page.widget_shopping_cart .total strong,
    .woocommerce-page .widget_shopping_cart .total strong,
    .xoo-wsc-modal.widget_shopping_cart .total strong,
    .xoo-wsc-modal .widget_shopping_cart .total strong,
    .footer_btn.widget_shopping_cart .total strong,
    .footer_btn .widget_shopping_cart .total strong,
    .block_startseite.widget_shopping_cart .total strong,
    .block_startseite .widget_shopping_cart .total strong {
      min-width: 40px;
      display: inline-block; }
  .woocommerce.widget_shopping_cart .cart_list li,
  .woocommerce .widget_shopping_cart .cart_list li,
  .woocommerce-page.widget_shopping_cart .cart_list li,
  .woocommerce-page .widget_shopping_cart .cart_list li,
  .xoo-wsc-modal.widget_shopping_cart .cart_list li,
  .xoo-wsc-modal .widget_shopping_cart .cart_list li,
  .footer_btn.widget_shopping_cart .cart_list li,
  .footer_btn .widget_shopping_cart .cart_list li,
  .block_startseite.widget_shopping_cart .cart_list li,
  .block_startseite .widget_shopping_cart .cart_list li {
    padding-left: 2em;
    position: relative;
    padding-top: 0; }
    .woocommerce.widget_shopping_cart .cart_list li a.remove,
    .woocommerce .widget_shopping_cart .cart_list li a.remove,
    .woocommerce-page.widget_shopping_cart .cart_list li a.remove,
    .woocommerce-page .widget_shopping_cart .cart_list li a.remove,
    .xoo-wsc-modal.widget_shopping_cart .cart_list li a.remove,
    .xoo-wsc-modal .widget_shopping_cart .cart_list li a.remove,
    .footer_btn.widget_shopping_cart .cart_list li a.remove,
    .footer_btn .widget_shopping_cart .cart_list li a.remove,
    .block_startseite.widget_shopping_cart .cart_list li a.remove,
    .block_startseite .widget_shopping_cart .cart_list li a.remove {
      position: absolute;
      top: 0;
      left: 0; }
  .woocommerce.widget_shopping_cart .buttons,
  .woocommerce .widget_shopping_cart .buttons,
  .woocommerce-page.widget_shopping_cart .buttons,
  .woocommerce-page .widget_shopping_cart .buttons,
  .xoo-wsc-modal.widget_shopping_cart .buttons,
  .xoo-wsc-modal .widget_shopping_cart .buttons,
  .footer_btn.widget_shopping_cart .buttons,
  .footer_btn .widget_shopping_cart .buttons,
  .block_startseite.widget_shopping_cart .buttons,
  .block_startseite .widget_shopping_cart .buttons {
    *zoom: 1; }
    .woocommerce.widget_shopping_cart .buttons::before, .woocommerce.widget_shopping_cart .buttons::after,
    .woocommerce .widget_shopping_cart .buttons::before,
    .woocommerce .widget_shopping_cart .buttons::after,
    .woocommerce-page.widget_shopping_cart .buttons::before,
    .woocommerce-page.widget_shopping_cart .buttons::after,
    .woocommerce-page .widget_shopping_cart .buttons::before,
    .woocommerce-page .widget_shopping_cart .buttons::after,
    .xoo-wsc-modal.widget_shopping_cart .buttons::before,
    .xoo-wsc-modal.widget_shopping_cart .buttons::after,
    .xoo-wsc-modal .widget_shopping_cart .buttons::before,
    .xoo-wsc-modal .widget_shopping_cart .buttons::after,
    .footer_btn.widget_shopping_cart .buttons::before,
    .footer_btn.widget_shopping_cart .buttons::after,
    .footer_btn .widget_shopping_cart .buttons::before,
    .footer_btn .widget_shopping_cart .buttons::after,
    .block_startseite.widget_shopping_cart .buttons::before,
    .block_startseite.widget_shopping_cart .buttons::after,
    .block_startseite .widget_shopping_cart .buttons::before,
    .block_startseite .widget_shopping_cart .buttons::after {
      content: ' ';
      display: table; }
    .woocommerce.widget_shopping_cart .buttons::after,
    .woocommerce .widget_shopping_cart .buttons::after,
    .woocommerce-page.widget_shopping_cart .buttons::after,
    .woocommerce-page .widget_shopping_cart .buttons::after,
    .xoo-wsc-modal.widget_shopping_cart .buttons::after,
    .xoo-wsc-modal .widget_shopping_cart .buttons::after,
    .footer_btn.widget_shopping_cart .buttons::after,
    .footer_btn .widget_shopping_cart .buttons::after,
    .block_startseite.widget_shopping_cart .buttons::after,
    .block_startseite .widget_shopping_cart .buttons::after {
      clear: both; }
    .woocommerce.widget_shopping_cart .buttons a,
    .woocommerce .widget_shopping_cart .buttons a,
    .woocommerce-page.widget_shopping_cart .buttons a,
    .woocommerce-page .widget_shopping_cart .buttons a,
    .xoo-wsc-modal.widget_shopping_cart .buttons a,
    .xoo-wsc-modal .widget_shopping_cart .buttons a,
    .footer_btn.widget_shopping_cart .buttons a,
    .footer_btn .widget_shopping_cart .buttons a,
    .block_startseite.widget_shopping_cart .buttons a,
    .block_startseite .widget_shopping_cart .buttons a {
      margin-right: 5px;
      margin-bottom: 5px; }
  .woocommerce form .form-row,
  .woocommerce-page form .form-row,
  .xoo-wsc-modal form .form-row,
  .footer_btn form .form-row,
  .block_startseite form .form-row {
    padding: 3px;
    margin: 0 0 5px; }
    .woocommerce form .form-row [placeholder]:focus::-webkit-input-placeholder,
    .woocommerce-page form .form-row [placeholder]:focus::-webkit-input-placeholder,
    .xoo-wsc-modal form .form-row [placeholder]:focus::-webkit-input-placeholder,
    .footer_btn form .form-row [placeholder]:focus::-webkit-input-placeholder,
    .block_startseite form .form-row [placeholder]:focus::-webkit-input-placeholder {
      transition: opacity 0.5s 0.5s ease;
      opacity: 0; }
    .woocommerce form .form-row label,
    .woocommerce-page form .form-row label,
    .xoo-wsc-modal form .form-row label,
    .footer_btn form .form-row label,
    .block_startseite form .form-row label {
      line-height: 2; }
    .woocommerce form .form-row label.checkbox,
    .woocommerce-page form .form-row label.checkbox,
    .xoo-wsc-modal form .form-row label.checkbox,
    .footer_btn form .form-row label.checkbox,
    .block_startseite form .form-row label.checkbox {
      line-height: 0; }
    .woocommerce form .form-row label.hidden,
    .woocommerce-page form .form-row label.hidden,
    .xoo-wsc-modal form .form-row label.hidden,
    .footer_btn form .form-row label.hidden,
    .block_startseite form .form-row label.hidden {
      visibility: hidden; }
    .woocommerce form .form-row label.inline,
    .woocommerce-page form .form-row label.inline,
    .xoo-wsc-modal form .form-row label.inline,
    .footer_btn form .form-row label.inline,
    .block_startseite form .form-row label.inline {
      display: inline; }
    .woocommerce form .form-row .woocommerce-input-wrapper .description,
    .woocommerce-page form .form-row .woocommerce-input-wrapper .description,
    .xoo-wsc-modal form .form-row .woocommerce-input-wrapper .description,
    .footer_btn form .form-row .woocommerce-input-wrapper .description,
    .block_startseite form .form-row .woocommerce-input-wrapper .description {
      background: #1e85be;
      color: #fff;
      border-radius: 3px;
      padding: 1em;
      margin: 0.5em 0 0;
      clear: both;
      display: none;
      position: relative; }
      .woocommerce form .form-row .woocommerce-input-wrapper .description a,
      .woocommerce-page form .form-row .woocommerce-input-wrapper .description a,
      .xoo-wsc-modal form .form-row .woocommerce-input-wrapper .description a,
      .footer_btn form .form-row .woocommerce-input-wrapper .description a,
      .block_startseite form .form-row .woocommerce-input-wrapper .description a {
        color: #fff;
        text-decoration: underline;
        border: 0;
        box-shadow: none; }
      .woocommerce form .form-row .woocommerce-input-wrapper .description::before,
      .woocommerce-page form .form-row .woocommerce-input-wrapper .description::before,
      .xoo-wsc-modal form .form-row .woocommerce-input-wrapper .description::before,
      .footer_btn form .form-row .woocommerce-input-wrapper .description::before,
      .block_startseite form .form-row .woocommerce-input-wrapper .description::before {
        left: 50%;
        top: 0%;
        margin-top: -4px;
        transform: translateX(-50%) rotate(180deg);
        content: "";
        position: absolute;
        border-width: 4px 6px 0 6px;
        border-style: solid;
        border-color: #1e85be transparent transparent transparent;
        z-index: 100;
        display: block; }
    .woocommerce form .form-row select,
    .woocommerce-page form .form-row select,
    .xoo-wsc-modal form .form-row select,
    .footer_btn form .form-row select,
    .block_startseite form .form-row select {
      cursor: pointer;
      margin: 0; }
    .woocommerce form .form-row .required,
    .woocommerce-page form .form-row .required,
    .xoo-wsc-modal form .form-row .required,
    .footer_btn form .form-row .required,
    .block_startseite form .form-row .required {
      color: #333333;
      font-weight: 700;
      border: 0 !important;
      text-decoration: none; }
    .woocommerce form .form-row .optional,
    .woocommerce-page form .form-row .optional,
    .xoo-wsc-modal form .form-row .optional,
    .footer_btn form .form-row .optional,
    .block_startseite form .form-row .optional {
      visibility: visible; }
    .woocommerce form .form-row .input-checkbox,
    .woocommerce-page form .form-row .input-checkbox,
    .xoo-wsc-modal form .form-row .input-checkbox,
    .footer_btn form .form-row .input-checkbox,
    .block_startseite form .form-row .input-checkbox {
      display: inline;
      margin: -2px 8px 0 0;
      text-align: center;
      vertical-align: middle; }
    .woocommerce form .form-row input.input-text,
    .woocommerce form .form-row textarea,
    .woocommerce-page form .form-row input.input-text,
    .woocommerce-page form .form-row textarea,
    .xoo-wsc-modal form .form-row input.input-text,
    .xoo-wsc-modal form .form-row textarea,
    .footer_btn form .form-row input.input-text,
    .footer_btn form .form-row textarea,
    .block_startseite form .form-row input.input-text,
    .block_startseite form .form-row textarea {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      outline: 0;
      line-height: normal; }
    .woocommerce form .form-row textarea,
    .woocommerce-page form .form-row textarea,
    .xoo-wsc-modal form .form-row textarea,
    .footer_btn form .form-row textarea,
    .block_startseite form .form-row textarea {
      height: 4em;
      line-height: 1.5;
      display: block;
      box-shadow: none; }
    .woocommerce form .form-row .select2-container,
    .woocommerce-page form .form-row .select2-container,
    .xoo-wsc-modal form .form-row .select2-container,
    .footer_btn form .form-row .select2-container,
    .block_startseite form .form-row .select2-container {
      width: 100%;
      line-height: 2em; }
    .woocommerce form .form-row.woocommerce-invalid label,
    .woocommerce-page form .form-row.woocommerce-invalid label,
    .xoo-wsc-modal form .form-row.woocommerce-invalid label,
    .footer_btn form .form-row.woocommerce-invalid label,
    .block_startseite form .form-row.woocommerce-invalid label {
      color: #333333; }
    .woocommerce form .form-row.woocommerce-invalid .select2-container,
    .woocommerce form .form-row.woocommerce-invalid input.input-text,
    .woocommerce form .form-row.woocommerce-invalid select,
    .woocommerce-page form .form-row.woocommerce-invalid .select2-container,
    .woocommerce-page form .form-row.woocommerce-invalid input.input-text,
    .woocommerce-page form .form-row.woocommerce-invalid select,
    .xoo-wsc-modal form .form-row.woocommerce-invalid .select2-container,
    .xoo-wsc-modal form .form-row.woocommerce-invalid input.input-text,
    .xoo-wsc-modal form .form-row.woocommerce-invalid select,
    .footer_btn form .form-row.woocommerce-invalid .select2-container,
    .footer_btn form .form-row.woocommerce-invalid input.input-text,
    .footer_btn form .form-row.woocommerce-invalid select,
    .block_startseite form .form-row.woocommerce-invalid .select2-container,
    .block_startseite form .form-row.woocommerce-invalid input.input-text,
    .block_startseite form .form-row.woocommerce-invalid select {
      border-color: #333333; }
    .woocommerce form .form-row.woocommerce-validated .select2-container,
    .woocommerce form .form-row.woocommerce-validated input.input-text,
    .woocommerce form .form-row.woocommerce-validated select,
    .woocommerce-page form .form-row.woocommerce-validated .select2-container,
    .woocommerce-page form .form-row.woocommerce-validated input.input-text,
    .woocommerce-page form .form-row.woocommerce-validated select,
    .xoo-wsc-modal form .form-row.woocommerce-validated .select2-container,
    .xoo-wsc-modal form .form-row.woocommerce-validated input.input-text,
    .xoo-wsc-modal form .form-row.woocommerce-validated select,
    .footer_btn form .form-row.woocommerce-validated .select2-container,
    .footer_btn form .form-row.woocommerce-validated input.input-text,
    .footer_btn form .form-row.woocommerce-validated select,
    .block_startseite form .form-row.woocommerce-validated .select2-container,
    .block_startseite form .form-row.woocommerce-validated input.input-text,
    .block_startseite form .form-row.woocommerce-validated select {
      border-color: #6cd153; }
    .woocommerce form .form-row ::-webkit-input-placeholder,
    .woocommerce-page form .form-row ::-webkit-input-placeholder,
    .xoo-wsc-modal form .form-row ::-webkit-input-placeholder,
    .footer_btn form .form-row ::-webkit-input-placeholder,
    .block_startseite form .form-row ::-webkit-input-placeholder {
      line-height: normal; }
    .woocommerce form .form-row :-moz-placeholder,
    .woocommerce-page form .form-row :-moz-placeholder,
    .xoo-wsc-modal form .form-row :-moz-placeholder,
    .footer_btn form .form-row :-moz-placeholder,
    .block_startseite form .form-row :-moz-placeholder {
      line-height: normal; }
    .woocommerce form .form-row :-ms-input-placeholder,
    .woocommerce-page form .form-row :-ms-input-placeholder,
    .xoo-wsc-modal form .form-row :-ms-input-placeholder,
    .footer_btn form .form-row :-ms-input-placeholder,
    .block_startseite form .form-row :-ms-input-placeholder {
      line-height: normal; }
  .woocommerce form.login,
  .woocommerce form.checkout_coupon,
  .woocommerce form.register,
  .woocommerce-page form.login,
  .woocommerce-page form.checkout_coupon,
  .woocommerce-page form.register,
  .xoo-wsc-modal form.login,
  .xoo-wsc-modal form.checkout_coupon,
  .xoo-wsc-modal form.register,
  .footer_btn form.login,
  .footer_btn form.checkout_coupon,
  .footer_btn form.register,
  .block_startseite form.login,
  .block_startseite form.checkout_coupon,
  .block_startseite form.register {
    border: 1px solid #392127;
    padding: 20px;
    margin: 0 0 4em 0;
    text-align: left;
    border-radius: 0px; }
    .woocommerce form.login label,
    .woocommerce form.checkout_coupon label,
    .woocommerce form.register label,
    .woocommerce-page form.login label,
    .woocommerce-page form.checkout_coupon label,
    .woocommerce-page form.register label,
    .xoo-wsc-modal form.login label,
    .xoo-wsc-modal form.checkout_coupon label,
    .xoo-wsc-modal form.register label,
    .footer_btn form.login label,
    .footer_btn form.checkout_coupon label,
    .footer_btn form.register label,
    .block_startseite form.login label,
    .block_startseite form.checkout_coupon label,
    .block_startseite form.register label {
      font-size: 20px; }
    .woocommerce form.login #username,
    .woocommerce form.login #password,
    .woocommerce form.login .lost_password,
    .woocommerce form.checkout_coupon #username,
    .woocommerce form.checkout_coupon #password,
    .woocommerce form.checkout_coupon .lost_password,
    .woocommerce form.register #username,
    .woocommerce form.register #password,
    .woocommerce form.register .lost_password,
    .woocommerce-page form.login #username,
    .woocommerce-page form.login #password,
    .woocommerce-page form.login .lost_password,
    .woocommerce-page form.checkout_coupon #username,
    .woocommerce-page form.checkout_coupon #password,
    .woocommerce-page form.checkout_coupon .lost_password,
    .woocommerce-page form.register #username,
    .woocommerce-page form.register #password,
    .woocommerce-page form.register .lost_password,
    .xoo-wsc-modal form.login #username,
    .xoo-wsc-modal form.login #password,
    .xoo-wsc-modal form.login .lost_password,
    .xoo-wsc-modal form.checkout_coupon #username,
    .xoo-wsc-modal form.checkout_coupon #password,
    .xoo-wsc-modal form.checkout_coupon .lost_password,
    .xoo-wsc-modal form.register #username,
    .xoo-wsc-modal form.register #password,
    .xoo-wsc-modal form.register .lost_password,
    .footer_btn form.login #username,
    .footer_btn form.login #password,
    .footer_btn form.login .lost_password,
    .footer_btn form.checkout_coupon #username,
    .footer_btn form.checkout_coupon #password,
    .footer_btn form.checkout_coupon .lost_password,
    .footer_btn form.register #username,
    .footer_btn form.register #password,
    .footer_btn form.register .lost_password,
    .block_startseite form.login #username,
    .block_startseite form.login #password,
    .block_startseite form.login .lost_password,
    .block_startseite form.checkout_coupon #username,
    .block_startseite form.checkout_coupon #password,
    .block_startseite form.checkout_coupon .lost_password,
    .block_startseite form.register #username,
    .block_startseite form.register #password,
    .block_startseite form.register .lost_password {
      font-size: 18px; }
    .woocommerce form.login .form-row-wide,
    .woocommerce form.checkout_coupon .form-row-wide,
    .woocommerce form.register .form-row-wide,
    .woocommerce-page form.login .form-row-wide,
    .woocommerce-page form.checkout_coupon .form-row-wide,
    .woocommerce-page form.register .form-row-wide,
    .xoo-wsc-modal form.login .form-row-wide,
    .xoo-wsc-modal form.checkout_coupon .form-row-wide,
    .xoo-wsc-modal form.register .form-row-wide,
    .footer_btn form.login .form-row-wide,
    .footer_btn form.checkout_coupon .form-row-wide,
    .footer_btn form.register .form-row-wide,
    .block_startseite form.login .form-row-wide,
    .block_startseite form.checkout_coupon .form-row-wide,
    .block_startseite form.register .form-row-wide {
      margin-bottom: 10px; }
  .woocommerce #region-info,
  .woocommerce-page #region-info,
  .xoo-wsc-modal #region-info,
  .footer_btn #region-info,
  .block_startseite #region-info {
    color: #333333;
    font-weight: bold; }
  .woocommerce ul#shipping_method,
  .woocommerce-page ul#shipping_method,
  .xoo-wsc-modal ul#shipping_method,
  .footer_btn ul#shipping_method,
  .block_startseite ul#shipping_method {
    list-style: none outside;
    margin: 0;
    padding: 0;
    font-weight: 300; }
    .woocommerce ul#shipping_method li,
    .woocommerce-page ul#shipping_method li,
    .xoo-wsc-modal ul#shipping_method li,
    .footer_btn ul#shipping_method li,
    .block_startseite ul#shipping_method li {
      margin: 0 0 0.5em;
      line-height: 1.5em;
      list-style: none outside; }
      .woocommerce ul#shipping_method li input,
      .woocommerce-page ul#shipping_method li input,
      .xoo-wsc-modal ul#shipping_method li input,
      .footer_btn ul#shipping_method li input,
      .block_startseite ul#shipping_method li input {
        margin: 0.4375em 0.4375em 0 0;
        vertical-align: top; }
      .woocommerce ul#shipping_method li label,
      .woocommerce-page ul#shipping_method li label,
      .xoo-wsc-modal ul#shipping_method li label,
      .footer_btn ul#shipping_method li label,
      .block_startseite ul#shipping_method li label {
        display: inline; }
    .woocommerce ul#shipping_method .amount,
    .woocommerce-page ul#shipping_method .amount,
    .xoo-wsc-modal ul#shipping_method .amount,
    .footer_btn ul#shipping_method .amount,
    .block_startseite ul#shipping_method .amount {
      font-weight: 700; }
  .woocommerce p.woocommerce-shipping-contents,
  .woocommerce-page p.woocommerce-shipping-contents,
  .xoo-wsc-modal p.woocommerce-shipping-contents,
  .footer_btn p.woocommerce-shipping-contents,
  .block_startseite p.woocommerce-shipping-contents {
    margin: 0; }
  .woocommerce #calc_shipping_city_field,
  .woocommerce-page #calc_shipping_city_field,
  .xoo-wsc-modal #calc_shipping_city_field,
  .footer_btn #calc_shipping_city_field,
  .block_startseite #calc_shipping_city_field {
    display: none !important; }
  .woocommerce ul.order_details,
  .woocommerce-page ul.order_details,
  .xoo-wsc-modal ul.order_details,
  .footer_btn ul.order_details,
  .block_startseite ul.order_details {
    *zoom: 1;
    margin: 0 0 3em;
    list-style: none; }
    .woocommerce ul.order_details::before, .woocommerce ul.order_details::after,
    .woocommerce-page ul.order_details::before,
    .woocommerce-page ul.order_details::after,
    .xoo-wsc-modal ul.order_details::before,
    .xoo-wsc-modal ul.order_details::after,
    .footer_btn ul.order_details::before,
    .footer_btn ul.order_details::after,
    .block_startseite ul.order_details::before,
    .block_startseite ul.order_details::after {
      content: ' ';
      display: table; }
    .woocommerce ul.order_details::after,
    .woocommerce-page ul.order_details::after,
    .xoo-wsc-modal ul.order_details::after,
    .footer_btn ul.order_details::after,
    .block_startseite ul.order_details::after {
      clear: both; }
    .woocommerce ul.order_details li,
    .woocommerce-page ul.order_details li,
    .xoo-wsc-modal ul.order_details li,
    .footer_btn ul.order_details li,
    .block_startseite ul.order_details li {
      float: left;
      margin-right: 2em;
      text-transform: uppercase;
      font-size: 0.715em;
      line-height: 1;
      border-right: 1px dashed #392127;
      padding-right: 2em;
      margin-left: 0;
      padding-left: 0;
      list-style-type: none; }
      .woocommerce ul.order_details li strong,
      .woocommerce-page ul.order_details li strong,
      .xoo-wsc-modal ul.order_details li strong,
      .footer_btn ul.order_details li strong,
      .block_startseite ul.order_details li strong {
        display: block;
        font-size: 1.4em;
        text-transform: none;
        line-height: 1.5; }
      .woocommerce ul.order_details li:last-of-type,
      .woocommerce-page ul.order_details li:last-of-type,
      .xoo-wsc-modal ul.order_details li:last-of-type,
      .footer_btn ul.order_details li:last-of-type,
      .block_startseite ul.order_details li:last-of-type {
        border: none; }
  .woocommerce .wc-bacs-bank-details-account-name,
  .woocommerce-page .wc-bacs-bank-details-account-name,
  .xoo-wsc-modal .wc-bacs-bank-details-account-name,
  .footer_btn .wc-bacs-bank-details-account-name,
  .block_startseite .wc-bacs-bank-details-account-name {
    font-weight: bold; }
  .woocommerce .woocommerce-order-downloads,
  .woocommerce .woocommerce-customer-details,
  .woocommerce .woocommerce-order-details,
  .woocommerce-page .woocommerce-order-downloads,
  .woocommerce-page .woocommerce-customer-details,
  .woocommerce-page .woocommerce-order-details,
  .xoo-wsc-modal .woocommerce-order-downloads,
  .xoo-wsc-modal .woocommerce-customer-details,
  .xoo-wsc-modal .woocommerce-order-details,
  .footer_btn .woocommerce-order-downloads,
  .footer_btn .woocommerce-customer-details,
  .footer_btn .woocommerce-order-details,
  .block_startseite .woocommerce-order-downloads,
  .block_startseite .woocommerce-customer-details,
  .block_startseite .woocommerce-order-details {
    margin-bottom: 2em; }
    .woocommerce .woocommerce-order-downloads *:last-child,
    .woocommerce .woocommerce-customer-details *:last-child,
    .woocommerce .woocommerce-order-details *:last-child,
    .woocommerce-page .woocommerce-order-downloads *:last-child,
    .woocommerce-page .woocommerce-customer-details *:last-child,
    .woocommerce-page .woocommerce-order-details *:last-child,
    .xoo-wsc-modal .woocommerce-order-downloads *:last-child,
    .xoo-wsc-modal .woocommerce-customer-details *:last-child,
    .xoo-wsc-modal .woocommerce-order-details *:last-child,
    .footer_btn .woocommerce-order-downloads *:last-child,
    .footer_btn .woocommerce-customer-details *:last-child,
    .footer_btn .woocommerce-order-details *:last-child,
    .block_startseite .woocommerce-order-downloads *:last-child,
    .block_startseite .woocommerce-customer-details *:last-child,
    .block_startseite .woocommerce-order-details *:last-child {
      margin-bottom: 0; }
  .woocommerce .woocommerce-customer-details address,
  .woocommerce-page .woocommerce-customer-details address,
  .xoo-wsc-modal .woocommerce-customer-details address,
  .footer_btn .woocommerce-customer-details address,
  .block_startseite .woocommerce-customer-details address {
    font-style: normal;
    margin-bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-width: 2px;
    border-right-width: 2px;
    text-align: left;
    width: 100%;
    border-radius: 5px;
    padding: 6px 12px; }
  .woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone,
  .woocommerce .woocommerce-customer-details .woocommerce-customer-details--email,
  .woocommerce-page .woocommerce-customer-details .woocommerce-customer-details--phone,
  .woocommerce-page .woocommerce-customer-details .woocommerce-customer-details--email,
  .xoo-wsc-modal .woocommerce-customer-details .woocommerce-customer-details--phone,
  .xoo-wsc-modal .woocommerce-customer-details .woocommerce-customer-details--email,
  .footer_btn .woocommerce-customer-details .woocommerce-customer-details--phone,
  .footer_btn .woocommerce-customer-details .woocommerce-customer-details--email,
  .block_startseite .woocommerce-customer-details .woocommerce-customer-details--phone,
  .block_startseite .woocommerce-customer-details .woocommerce-customer-details--email {
    margin-bottom: 0;
    padding-left: 1.5em; }
  .woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone::before,
  .woocommerce-page .woocommerce-customer-details .woocommerce-customer-details--phone::before,
  .xoo-wsc-modal .woocommerce-customer-details .woocommerce-customer-details--phone::before,
  .footer_btn .woocommerce-customer-details .woocommerce-customer-details--phone::before,
  .block_startseite .woocommerce-customer-details .woocommerce-customer-details--phone::before {
    font-family: 'WooCommerce';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: 0.618em;
    content: "";
    text-decoration: none;
    margin-left: -1.5em;
    line-height: 1.75;
    position: absolute; }
  .woocommerce .woocommerce-customer-details .woocommerce-customer-details--email::before,
  .woocommerce-page .woocommerce-customer-details .woocommerce-customer-details--email::before,
  .xoo-wsc-modal .woocommerce-customer-details .woocommerce-customer-details--email::before,
  .footer_btn .woocommerce-customer-details .woocommerce-customer-details--email::before,
  .block_startseite .woocommerce-customer-details .woocommerce-customer-details--email::before {
    font-family: 'WooCommerce';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: 0.618em;
    content: "";
    text-decoration: none;
    margin-left: -1.5em;
    line-height: 1.75;
    position: absolute; }
  .woocommerce .woocommerce-widget-layered-nav-list,
  .woocommerce-page .woocommerce-widget-layered-nav-list,
  .xoo-wsc-modal .woocommerce-widget-layered-nav-list,
  .footer_btn .woocommerce-widget-layered-nav-list,
  .block_startseite .woocommerce-widget-layered-nav-list {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none outside; }
    .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item,
    .woocommerce-page .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item,
    .xoo-wsc-modal .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item,
    .footer_btn .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item,
    .block_startseite .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item {
      *zoom: 1;
      padding: 0 0 1px;
      list-style: none; }
      .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::before, .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
      .woocommerce-page .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::before,
      .woocommerce-page .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
      .xoo-wsc-modal .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::before,
      .xoo-wsc-modal .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
      .footer_btn .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::before,
      .footer_btn .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
      .block_startseite .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::before,
      .block_startseite .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after {
        content: ' ';
        display: table; }
      .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
      .woocommerce-page .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
      .xoo-wsc-modal .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
      .footer_btn .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after,
      .block_startseite .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item::after {
        clear: both; }
      .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
      .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span,
      .woocommerce-page .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
      .woocommerce-page .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span,
      .xoo-wsc-modal .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
      .xoo-wsc-modal .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span,
      .footer_btn .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
      .footer_btn .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span,
      .block_startseite .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a,
      .block_startseite .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item span {
        padding: 1px 0; }
    .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before,
    .woocommerce-page .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before,
    .xoo-wsc-modal .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before,
    .footer_btn .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before,
    .block_startseite .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a::before {
      font-family: 'WooCommerce';
      speak: none;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      margin-right: 0.618em;
      content: "";
      text-decoration: none;
      color: #333333; }
  .woocommerce .woocommerce-widget-layered-nav-dropdown__submit,
  .woocommerce-page .woocommerce-widget-layered-nav-dropdown__submit,
  .xoo-wsc-modal .woocommerce-widget-layered-nav-dropdown__submit,
  .footer_btn .woocommerce-widget-layered-nav-dropdown__submit,
  .block_startseite .woocommerce-widget-layered-nav-dropdown__submit {
    margin-top: 1em; }
  .woocommerce .widget_layered_nav_filters ul,
  .woocommerce-page .widget_layered_nav_filters ul,
  .xoo-wsc-modal .widget_layered_nav_filters ul,
  .footer_btn .widget_layered_nav_filters ul,
  .block_startseite .widget_layered_nav_filters ul {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none outside;
    overflow: hidden;
    zoom: 1; }
    .woocommerce .widget_layered_nav_filters ul li,
    .woocommerce-page .widget_layered_nav_filters ul li,
    .xoo-wsc-modal .widget_layered_nav_filters ul li,
    .footer_btn .widget_layered_nav_filters ul li,
    .block_startseite .widget_layered_nav_filters ul li {
      float: left;
      padding: 0 1em 1px 1px;
      list-style: none; }
      .woocommerce .widget_layered_nav_filters ul li a,
      .woocommerce-page .widget_layered_nav_filters ul li a,
      .xoo-wsc-modal .widget_layered_nav_filters ul li a,
      .footer_btn .widget_layered_nav_filters ul li a,
      .block_startseite .widget_layered_nav_filters ul li a {
        text-decoration: none; }
        .woocommerce .widget_layered_nav_filters ul li a::before,
        .woocommerce-page .widget_layered_nav_filters ul li a::before,
        .xoo-wsc-modal .widget_layered_nav_filters ul li a::before,
        .footer_btn .widget_layered_nav_filters ul li a::before,
        .block_startseite .widget_layered_nav_filters ul li a::before {
          font-family: 'WooCommerce';
          speak: none;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          margin-right: 0.618em;
          content: "";
          text-decoration: none;
          color: #333333;
          vertical-align: inherit;
          margin-right: 0.5em; }
  .woocommerce .widget_price_filter .price_slider,
  .woocommerce-page .widget_price_filter .price_slider,
  .xoo-wsc-modal .widget_price_filter .price_slider,
  .footer_btn .widget_price_filter .price_slider,
  .block_startseite .widget_price_filter .price_slider {
    margin-bottom: 1em; }
  .woocommerce .widget_price_filter .price_slider_amount,
  .woocommerce-page .widget_price_filter .price_slider_amount,
  .xoo-wsc-modal .widget_price_filter .price_slider_amount,
  .footer_btn .widget_price_filter .price_slider_amount,
  .block_startseite .widget_price_filter .price_slider_amount {
    text-align: right;
    line-height: 2.4;
    font-size: 0.8751em; }
    .woocommerce .widget_price_filter .price_slider_amount .button,
    .woocommerce-page .widget_price_filter .price_slider_amount .button,
    .xoo-wsc-modal .widget_price_filter .price_slider_amount .button,
    .footer_btn .widget_price_filter .price_slider_amount .button,
    .block_startseite .widget_price_filter .price_slider_amount .button {
      font-size: 1.15em;
      float: left; }
  .woocommerce .widget_price_filter .ui-slider,
  .woocommerce-page .widget_price_filter .ui-slider,
  .xoo-wsc-modal .widget_price_filter .ui-slider,
  .footer_btn .widget_price_filter .ui-slider,
  .block_startseite .widget_price_filter .ui-slider {
    position: relative;
    text-align: left;
    margin-left: 0.5em;
    margin-right: 0.5em; }
  .woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
  .woocommerce-page .widget_price_filter .ui-slider .ui-slider-handle,
  .xoo-wsc-modal .widget_price_filter .ui-slider .ui-slider-handle,
  .footer_btn .widget_price_filter .ui-slider .ui-slider-handle,
  .block_startseite .widget_price_filter .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1em;
    height: 1em;
    background-color: #eae1de;
    border-radius: 1em;
    cursor: ew-resize;
    outline: none;
    top: -0.3em;
    /* rtl:ignore */
    margin-left: -0.5em; }
  .woocommerce .widget_price_filter .ui-slider .ui-slider-range,
  .woocommerce-page .widget_price_filter .ui-slider .ui-slider-range,
  .xoo-wsc-modal .widget_price_filter .ui-slider .ui-slider-range,
  .footer_btn .widget_price_filter .ui-slider .ui-slider-range,
  .block_startseite .widget_price_filter .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: 0.7em;
    display: block;
    border: 0;
    border-radius: 1em;
    background-color: #eae1de; }
  .woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content,
  .woocommerce-page .widget_price_filter .price_slider_wrapper .ui-widget-content,
  .xoo-wsc-modal .widget_price_filter .price_slider_wrapper .ui-widget-content,
  .footer_btn .widget_price_filter .price_slider_wrapper .ui-widget-content,
  .block_startseite .widget_price_filter .price_slider_wrapper .ui-widget-content {
    border-radius: 1em;
    background-color: #af8c81;
    border: 0; }
  .woocommerce .widget_price_filter .ui-slider-horizontal,
  .woocommerce-page .widget_price_filter .ui-slider-horizontal,
  .xoo-wsc-modal .widget_price_filter .ui-slider-horizontal,
  .footer_btn .widget_price_filter .ui-slider-horizontal,
  .block_startseite .widget_price_filter .ui-slider-horizontal {
    height: 0.5em; }
  .woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range,
  .woocommerce-page .widget_price_filter .ui-slider-horizontal .ui-slider-range,
  .xoo-wsc-modal .widget_price_filter .ui-slider-horizontal .ui-slider-range,
  .footer_btn .widget_price_filter .ui-slider-horizontal .ui-slider-range,
  .block_startseite .widget_price_filter .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%; }
  .woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range-min,
  .woocommerce-page .widget_price_filter .ui-slider-horizontal .ui-slider-range-min,
  .xoo-wsc-modal .widget_price_filter .ui-slider-horizontal .ui-slider-range-min,
  .footer_btn .widget_price_filter .ui-slider-horizontal .ui-slider-range-min,
  .block_startseite .widget_price_filter .ui-slider-horizontal .ui-slider-range-min {
    left: -1px; }
  .woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range-max,
  .woocommerce-page .widget_price_filter .ui-slider-horizontal .ui-slider-range-max,
  .xoo-wsc-modal .widget_price_filter .ui-slider-horizontal .ui-slider-range-max,
  .footer_btn .widget_price_filter .ui-slider-horizontal .ui-slider-range-max,
  .block_startseite .widget_price_filter .ui-slider-horizontal .ui-slider-range-max {
    right: -1px; }
  .woocommerce .widget_rating_filter ul,
  .woocommerce-page .widget_rating_filter ul,
  .xoo-wsc-modal .widget_rating_filter ul,
  .footer_btn .widget_rating_filter ul,
  .block_startseite .widget_rating_filter ul {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none outside; }
    .woocommerce .widget_rating_filter ul li,
    .woocommerce-page .widget_rating_filter ul li,
    .xoo-wsc-modal .widget_rating_filter ul li,
    .footer_btn .widget_rating_filter ul li,
    .block_startseite .widget_rating_filter ul li {
      *zoom: 1;
      padding: 0 0 1px;
      list-style: none; }
      .woocommerce .widget_rating_filter ul li::before, .woocommerce .widget_rating_filter ul li::after,
      .woocommerce-page .widget_rating_filter ul li::before,
      .woocommerce-page .widget_rating_filter ul li::after,
      .xoo-wsc-modal .widget_rating_filter ul li::before,
      .xoo-wsc-modal .widget_rating_filter ul li::after,
      .footer_btn .widget_rating_filter ul li::before,
      .footer_btn .widget_rating_filter ul li::after,
      .block_startseite .widget_rating_filter ul li::before,
      .block_startseite .widget_rating_filter ul li::after {
        content: ' ';
        display: table; }
      .woocommerce .widget_rating_filter ul li::after,
      .woocommerce-page .widget_rating_filter ul li::after,
      .xoo-wsc-modal .widget_rating_filter ul li::after,
      .footer_btn .widget_rating_filter ul li::after,
      .block_startseite .widget_rating_filter ul li::after {
        clear: both; }
      .woocommerce .widget_rating_filter ul li a,
      .woocommerce-page .widget_rating_filter ul li a,
      .xoo-wsc-modal .widget_rating_filter ul li a,
      .footer_btn .widget_rating_filter ul li a,
      .block_startseite .widget_rating_filter ul li a {
        padding: 1px 0;
        text-decoration: none; }
      .woocommerce .widget_rating_filter ul li .star-rating,
      .woocommerce-page .widget_rating_filter ul li .star-rating,
      .xoo-wsc-modal .widget_rating_filter ul li .star-rating,
      .footer_btn .widget_rating_filter ul li .star-rating,
      .block_startseite .widget_rating_filter ul li .star-rating {
        float: none;
        display: inline-block; }
    .woocommerce .widget_rating_filter ul li.chosen a::before,
    .woocommerce-page .widget_rating_filter ul li.chosen a::before,
    .xoo-wsc-modal .widget_rating_filter ul li.chosen a::before,
    .footer_btn .widget_rating_filter ul li.chosen a::before,
    .block_startseite .widget_rating_filter ul li.chosen a::before {
      font-family: 'WooCommerce';
      speak: none;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      margin-right: 0.618em;
      content: "";
      text-decoration: none;
      color: #333333; }
  .woocommerce .woocommerce-form-login,
  .woocommerce .woocommerce-form-register,
  .woocommerce-page .woocommerce-form-login,
  .woocommerce-page .woocommerce-form-register,
  .xoo-wsc-modal .woocommerce-form-login,
  .xoo-wsc-modal .woocommerce-form-register,
  .footer_btn .woocommerce-form-login,
  .footer_btn .woocommerce-form-register,
  .block_startseite .woocommerce-form-login,
  .block_startseite .woocommerce-form-register {
    height: 400px; }
    .woocommerce .woocommerce-form-login .woocommerce-form-login__submit,
    .woocommerce .woocommerce-form-register .woocommerce-form-login__submit,
    .woocommerce-page .woocommerce-form-login .woocommerce-form-login__submit,
    .woocommerce-page .woocommerce-form-register .woocommerce-form-login__submit,
    .xoo-wsc-modal .woocommerce-form-login .woocommerce-form-login__submit,
    .xoo-wsc-modal .woocommerce-form-register .woocommerce-form-login__submit,
    .footer_btn .woocommerce-form-login .woocommerce-form-login__submit,
    .footer_btn .woocommerce-form-register .woocommerce-form-login__submit,
    .block_startseite .woocommerce-form-login .woocommerce-form-login__submit,
    .block_startseite .woocommerce-form-register .woocommerce-form-login__submit {
      float: left;
      margin-right: 1em; }
    .woocommerce .woocommerce-form-login .woocommerce-form-login__rememberme,
    .woocommerce .woocommerce-form-register .woocommerce-form-login__rememberme,
    .woocommerce-page .woocommerce-form-login .woocommerce-form-login__rememberme,
    .woocommerce-page .woocommerce-form-register .woocommerce-form-login__rememberme,
    .xoo-wsc-modal .woocommerce-form-login .woocommerce-form-login__rememberme,
    .xoo-wsc-modal .woocommerce-form-register .woocommerce-form-login__rememberme,
    .footer_btn .woocommerce-form-login .woocommerce-form-login__rememberme,
    .footer_btn .woocommerce-form-register .woocommerce-form-login__rememberme,
    .block_startseite .woocommerce-form-login .woocommerce-form-login__rememberme,
    .block_startseite .woocommerce-form-register .woocommerce-form-login__rememberme {
      display: inline-block; }
    .woocommerce .woocommerce-form-login .show-password-input,
    .woocommerce .woocommerce-form-register .show-password-input,
    .woocommerce-page .woocommerce-form-login .show-password-input,
    .woocommerce-page .woocommerce-form-register .show-password-input,
    .xoo-wsc-modal .woocommerce-form-login .show-password-input,
    .xoo-wsc-modal .woocommerce-form-register .show-password-input,
    .footer_btn .woocommerce-form-login .show-password-input,
    .footer_btn .woocommerce-form-register .show-password-input,
    .block_startseite .woocommerce-form-login .show-password-input,
    .block_startseite .woocommerce-form-register .show-password-input {
      top: auto; }
    .woocommerce .woocommerce-form-login input#reg_email,
    .woocommerce .woocommerce-form-register input#reg_email,
    .woocommerce-page .woocommerce-form-login input#reg_email,
    .woocommerce-page .woocommerce-form-register input#reg_email,
    .xoo-wsc-modal .woocommerce-form-login input#reg_email,
    .xoo-wsc-modal .woocommerce-form-register input#reg_email,
    .footer_btn .woocommerce-form-login input#reg_email,
    .footer_btn .woocommerce-form-register input#reg_email,
    .block_startseite .woocommerce-form-login input#reg_email,
    .block_startseite .woocommerce-form-register input#reg_email {
      font-size: 18px; }

.entry-content .woocommerce-form-register p {
  font-size: 18px;
  line-height: 30px; }
  .entry-content .woocommerce-form-register p.terms-privacy-policy {
    margin-bottom: 30px; }

.woocommerce-ResetPassword {
  font-size: 18px; }
  .woocommerce-ResetPassword input#user_login {
    font-size: 18px; }

.woocommerce-no-js form.woocommerce-form-login,
.woocommerce-no-js form.woocommerce-form-coupon {
  display: block !important; }

.woocommerce-no-js .woocommerce-form-login-toggle,
.woocommerce-no-js .woocommerce-form-coupon-toggle,
.woocommerce-no-js .showcoupon {
  display: none !important; }

.woocommerce-checkout-review-order {
  margin-top: 2em; }

.woocommerce-message,
.woocommerce-error,
.woocommerce-info {
  padding: 1em 2em 1em 3.5em !important;
  margin: 0 0 2em;
  position: relative;
  background-color: #fff;
  color: black;
  border: 2px solid #5a333d !important;
  list-style: none outside;
  font-size: 15px !important;
  *zoom: 1;
  width: auto;
  word-wrap: break-word; }
  .woocommerce-message::before, .woocommerce-message::after,
  .woocommerce-error::before,
  .woocommerce-error::after,
  .woocommerce-info::before,
  .woocommerce-info::after {
    content: ' ';
    display: table; }
  .woocommerce-message::after,
  .woocommerce-error::after,
  .woocommerce-info::after {
    clear: both; }
  .woocommerce-message::before,
  .woocommerce-error::before,
  .woocommerce-info::before {
    font-family: "WooCommerce";
    content: "\e028";
    display: inline-block;
    position: absolute;
    top: 1em;
    left: 1.5em; }
  .woocommerce-message .button,
  .woocommerce-error .button,
  .woocommerce-info .button {
    float: right; }
  .woocommerce-message a,
  .woocommerce-message a:visited,
  .woocommerce-error a,
  .woocommerce-error a:visited,
  .woocommerce-info a,
  .woocommerce-info a:visited {
    color: black !important; }
  .woocommerce-message li,
  .woocommerce-error li,
  .woocommerce-info li {
    list-style: none outside !important;
    padding-left: 0 !important;
    margin-left: 0 !important; }

/**
 * Right to left styles
 */
.rtl.woocommerce .price_label,
.rtl.woocommerce .price_label span {
  /* rtl:ignore */
  direction: ltr;
  unicode-bidi: embed; }

.woocommerce-message {
  border-top-color: #d6c5bf; }
  .woocommerce-message::before {
    content: "\e015";
    color: #d6c5bf; }

.woocommerce-info {
  border-top-color: #d6c5bf; }
  .woocommerce-info::before {
    color: #d6c5bf; }

.woocommerce-error {
  border-top-color: #d6c5bf; }
  .woocommerce-error::before {
    content: "\e016";
    color: #d6c5bf; }

/**
 * Account page
 */
.woocommerce-account .woocommerce {
  *zoom: 1; }
  .woocommerce-account .woocommerce::before, .woocommerce-account .woocommerce::after {
    content: ' ';
    display: table; }
  .woocommerce-account .woocommerce::after {
    clear: both; }

.woocommerce-account .addresses .title {
  *zoom: 1; }
  .woocommerce-account .addresses .title::before, .woocommerce-account .addresses .title::after {
    content: ' ';
    display: table; }
  .woocommerce-account .addresses .title::after {
    clear: both; }
  .woocommerce-account .addresses .title h3 {
    float: left; }
  .woocommerce-account .addresses .title .edit {
    float: right; }

.woocommerce-account ol.commentlist.notes li.note p.meta {
  font-weight: 700;
  margin-bottom: 0; }

.woocommerce-account ol.commentlist.notes li.note .description p:last-child {
  margin-bottom: 0; }

.woocommerce-account ul.digital-downloads {
  margin-left: 0;
  padding-left: 0; }
  .woocommerce-account ul.digital-downloads li {
    list-style: none;
    margin-left: 0;
    padding-left: 0; }
    .woocommerce-account ul.digital-downloads li::before {
      font-family: 'WooCommerce';
      speak: none;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      margin-right: 0.618em;
      content: "";
      text-decoration: none; }
    .woocommerce-account ul.digital-downloads li .count {
      float: right; }

/**
 * Cart/checkout page
 */
.woocommerce-cart table.cart .product-thumbnail,
.woocommerce-checkout table.cart .product-thumbnail,
#add_payment_method table.cart .product-thumbnail {
  min-width: 150px; }

.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img,
#add_payment_method table.cart img {
  width: 150px;
  box-shadow: none;
  margin-bottom: 0; }

.woocommerce-cart table.cart th,
.woocommerce-cart table.cart td,
.woocommerce-checkout table.cart th,
.woocommerce-checkout table.cart td,
#add_payment_method table.cart th,
#add_payment_method table.cart td {
  vertical-align: middle; }

.woocommerce-cart table.cart td.actions .coupon .input-text,
.woocommerce-checkout table.cart td.actions .coupon .input-text,
#add_payment_method table.cart td.actions .coupon .input-text {
  float: left;
  box-sizing: border-box;
  border: 1px solid #392127;
  padding: 6px 6px 5px;
  margin: 0 4px 0 0;
  outline: 0; }

.woocommerce-cart table.cart input,
.woocommerce-checkout table.cart input,
#add_payment_method table.cart input {
  margin: 0;
  vertical-align: middle; }

.woocommerce-cart .wc-proceed-to-checkout,
.woocommerce-checkout .wc-proceed-to-checkout,
#add_payment_method .wc-proceed-to-checkout {
  *zoom: 1;
  padding: 1em 0; }
  .woocommerce-cart .wc-proceed-to-checkout::before, .woocommerce-cart .wc-proceed-to-checkout::after,
  .woocommerce-checkout .wc-proceed-to-checkout::before,
  .woocommerce-checkout .wc-proceed-to-checkout::after,
  #add_payment_method .wc-proceed-to-checkout::before,
  #add_payment_method .wc-proceed-to-checkout::after {
    content: ' ';
    display: table; }
  .woocommerce-cart .wc-proceed-to-checkout::after,
  .woocommerce-checkout .wc-proceed-to-checkout::after,
  #add_payment_method .wc-proceed-to-checkout::after {
    clear: both; }
  .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
  #add_payment_method .wc-proceed-to-checkout a.checkout-button {
    display: block;
    text-align: center;
    margin-bottom: 1em;
    font-size: 20px !important;
    padding: 1em !important; }

.woocommerce-cart .cart-collaterals .shipping-calculator-button,
.woocommerce-checkout .cart-collaterals .shipping-calculator-button,
#add_payment_method .cart-collaterals .shipping-calculator-button {
  float: none;
  margin-top: 0.5em;
  display: inline-block; }

.woocommerce-cart .cart-collaterals .shipping-calculator-button::after,
.woocommerce-checkout .cart-collaterals .shipping-calculator-button::after,
#add_payment_method .cart-collaterals .shipping-calculator-button::after {
  font-family: 'WooCommerce';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-left: 0.618em;
  content: "";
  text-decoration: none; }

.woocommerce-cart .cart-collaterals .shipping-calculator-form,
.woocommerce-checkout .cart-collaterals .shipping-calculator-form,
#add_payment_method .cart-collaterals .shipping-calculator-form {
  margin: 1em 0 0 0;
  display: block !important; }

.woocommerce-cart .cart-collaterals .cart_totals p small,
.woocommerce-checkout .cart-collaterals .cart_totals p small,
#add_payment_method .cart-collaterals .cart_totals p small {
  color: black;
  font-size: 0.83em; }

.woocommerce-cart .cart-collaterals .cart_totals table,
.woocommerce-checkout .cart-collaterals .cart_totals table,
#add_payment_method .cart-collaterals .cart_totals table {
  border-collapse: separate;
  margin: 0 0 6px;
  padding: 0; }
  .woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th,
  .woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td,
  .woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child th,
  .woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child td,
  #add_payment_method .cart-collaterals .cart_totals table tr:first-child th,
  #add_payment_method .cart-collaterals .cart_totals table tr:first-child td {
    border-top: 0; }
  .woocommerce-cart .cart-collaterals .cart_totals table th,
  .woocommerce-checkout .cart-collaterals .cart_totals table th,
  #add_payment_method .cart-collaterals .cart_totals table th {
    width: 35%; }
  .woocommerce-cart .cart-collaterals .cart_totals table td,
  .woocommerce-cart .cart-collaterals .cart_totals table th,
  .woocommerce-checkout .cart-collaterals .cart_totals table td,
  .woocommerce-checkout .cart-collaterals .cart_totals table th,
  #add_payment_method .cart-collaterals .cart_totals table td,
  #add_payment_method .cart-collaterals .cart_totals table th {
    vertical-align: top;
    border-left: 0;
    border-right: 0;
    line-height: 1.5em; }
  .woocommerce-cart .cart-collaterals .cart_totals table td,
  .woocommerce-checkout .cart-collaterals .cart_totals table td,
  #add_payment_method .cart-collaterals .cart_totals table td {
    text-align: right; }
  .woocommerce-cart .cart-collaterals .cart_totals table small,
  .woocommerce-checkout .cart-collaterals .cart_totals table small,
  #add_payment_method .cart-collaterals .cart_totals table small {
    color: black; }
  .woocommerce-cart .cart-collaterals .cart_totals table select,
  .woocommerce-checkout .cart-collaterals .cart_totals table select,
  #add_payment_method .cart-collaterals .cart_totals table select {
    width: 100%; }

.woocommerce-cart .cart-collaterals .cart_totals .discount td,
.woocommerce-checkout .cart-collaterals .cart_totals .discount td,
#add_payment_method .cart-collaterals .cart_totals .discount td {
  color: black; }

.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th,
.woocommerce-checkout .cart-collaterals .cart_totals tr td,
.woocommerce-checkout .cart-collaterals .cart_totals tr th,
#add_payment_method .cart-collaterals .cart_totals tr td,
#add_payment_method .cart-collaterals .cart_totals tr th {
  border-top: 1px solid #5a333d; }

.woocommerce-cart .cart-collaterals .cart_totals .woocommerce-shipping-destination,
.woocommerce-checkout .cart-collaterals .cart_totals .woocommerce-shipping-destination,
#add_payment_method .cart-collaterals .cart_totals .woocommerce-shipping-destination {
  margin-bottom: 0; }

.woocommerce-cart .cart-collaterals .cross-sells ul.products li.product,
.woocommerce-checkout .cart-collaterals .cross-sells ul.products li.product,
#add_payment_method .cart-collaterals .cross-sells ul.products li.product {
  margin-top: 0; }

.woocommerce-cart .checkout .col-2 h3#ship-to-different-address,
.woocommerce-checkout .checkout .col-2 h3#ship-to-different-address,
#add_payment_method .checkout .col-2 h3#ship-to-different-address {
  float: left;
  clear: none; }

.woocommerce-cart .checkout .col-2 .notes,
.woocommerce-checkout .checkout .col-2 .notes,
#add_payment_method .checkout .col-2 .notes {
  clear: left; }

.woocommerce-cart .checkout .col-2 .form-row-first,
.woocommerce-checkout .checkout .col-2 .form-row-first,
#add_payment_method .checkout .col-2 .form-row-first {
  clear: left; }

.woocommerce-cart .checkout .create-account small,
.woocommerce-checkout .checkout .create-account small,
#add_payment_method .checkout .create-account small {
  font-size: 11px;
  color: black;
  font-weight: normal; }

.woocommerce-cart .checkout div.shipping-address,
.woocommerce-checkout .checkout div.shipping-address,
#add_payment_method .checkout div.shipping-address {
  padding: 0;
  clear: left;
  width: 100%; }

.woocommerce-cart .checkout .shipping_address,
.woocommerce-checkout .checkout .shipping_address,
#add_payment_method .checkout .shipping_address {
  clear: both; }

.woocommerce-cart #payment,
.woocommerce-checkout #payment,
#add_payment_method #payment {
  background: none;
  border-radius: 0;
  border: 1px solid #eae1de; }
  .woocommerce-cart #payment ul.payment_methods,
  .woocommerce-checkout #payment ul.payment_methods,
  #add_payment_method #payment ul.payment_methods {
    *zoom: 1;
    text-align: left;
    padding: 1em;
    border-bottom: 1px solid #392127;
    margin: 0;
    list-style: none outside; }
    .woocommerce-cart #payment ul.payment_methods::before, .woocommerce-cart #payment ul.payment_methods::after,
    .woocommerce-checkout #payment ul.payment_methods::before,
    .woocommerce-checkout #payment ul.payment_methods::after,
    #add_payment_method #payment ul.payment_methods::before,
    #add_payment_method #payment ul.payment_methods::after {
      content: ' ';
      display: table; }
    .woocommerce-cart #payment ul.payment_methods::after,
    .woocommerce-checkout #payment ul.payment_methods::after,
    #add_payment_method #payment ul.payment_methods::after {
      clear: both; }
    .woocommerce-cart #payment ul.payment_methods li,
    .woocommerce-checkout #payment ul.payment_methods li,
    #add_payment_method #payment ul.payment_methods li {
      line-height: 2;
      text-align: left;
      margin: 0;
      font-weight: normal;
      font-size: 18px; }
      .woocommerce-cart #payment ul.payment_methods li p,
      .woocommerce-checkout #payment ul.payment_methods li p,
      #add_payment_method #payment ul.payment_methods li p {
        font-size: 18px; }
      .woocommerce-cart #payment ul.payment_methods li .input-radio,
      .woocommerce-checkout #payment ul.payment_methods li .input-radio,
      #add_payment_method #payment ul.payment_methods li .input-radio {
        height: 20px;
        width: 20px; }
      .woocommerce-cart #payment ul.payment_methods li input,
      .woocommerce-checkout #payment ul.payment_methods li input,
      #add_payment_method #payment ul.payment_methods li input {
        margin: 0 1em 0 0; }
      .woocommerce-cart #payment ul.payment_methods li img,
      .woocommerce-checkout #payment ul.payment_methods li img,
      #add_payment_method #payment ul.payment_methods li img {
        vertical-align: middle;
        margin: -2px 0 0 0.5em;
        padding: 0;
        position: relative;
        box-shadow: none; }
      .woocommerce-cart #payment ul.payment_methods li img + img,
      .woocommerce-checkout #payment ul.payment_methods li img + img,
      #add_payment_method #payment ul.payment_methods li img + img {
        margin-left: 2px; }
    .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice),
    .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice),
    #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice) {
      *zoom: 1; }
      .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::before, .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
      .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::before,
      .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after,
      #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::before,
      #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after {
        content: ' ';
        display: table; }
      .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice)::after,
      .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice)::after,
      #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice)::after {
        clear: both; }
  .woocommerce-cart #payment div.form-row,
  .woocommerce-checkout #payment div.form-row,
  #add_payment_method #payment div.form-row {
    padding: 1em; }
  .woocommerce-cart #payment div.payment_box,
  .woocommerce-checkout #payment div.payment_box,
  #add_payment_method #payment div.payment_box {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    font-size: 0.92em;
    border-radius: 0;
    line-height: 1.5;
    background-color: #eae1de;
    color: black; }
    .woocommerce-cart #payment div.payment_box input.input-text,
    .woocommerce-cart #payment div.payment_box textarea,
    .woocommerce-checkout #payment div.payment_box input.input-text,
    .woocommerce-checkout #payment div.payment_box textarea,
    #add_payment_method #payment div.payment_box input.input-text,
    #add_payment_method #payment div.payment_box textarea {
      border-color: #29171c;
      border-top-color: #190e11; }
    .woocommerce-cart #payment div.payment_box ::-webkit-input-placeholder,
    .woocommerce-checkout #payment div.payment_box ::-webkit-input-placeholder,
    #add_payment_method #payment div.payment_box ::-webkit-input-placeholder {
      color: #190e11; }
    .woocommerce-cart #payment div.payment_box :-moz-placeholder,
    .woocommerce-checkout #payment div.payment_box :-moz-placeholder,
    #add_payment_method #payment div.payment_box :-moz-placeholder {
      color: #190e11; }
    .woocommerce-cart #payment div.payment_box :-ms-input-placeholder,
    .woocommerce-checkout #payment div.payment_box :-ms-input-placeholder,
    #add_payment_method #payment div.payment_box :-ms-input-placeholder {
      color: #190e11; }
    .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods,
    .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods,
    #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods {
      list-style: none outside;
      margin: 0; }
      .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
      .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
      .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
      .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new,
      #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token,
      #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new {
        margin: 0 0 0.5em; }
        .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
        .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
        .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
        .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label,
        #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label,
        #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label {
          cursor: pointer; }
      .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
      .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput,
      #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput {
        vertical-align: middle;
        margin: -3px 1em 0 0;
        position: relative; }
    .woocommerce-cart #payment div.payment_box .wc-credit-card-form,
    .woocommerce-checkout #payment div.payment_box .wc-credit-card-form,
    #add_payment_method #payment div.payment_box .wc-credit-card-form {
      border: 0;
      padding: 0;
      margin: 1em 0 0; }
    .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number,
    .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry,
    .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc,
    .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number,
    .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry,
    .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc,
    #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number,
    #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry,
    #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc {
      font-size: 1.5em;
      padding: 8px;
      background-repeat: no-repeat;
      background-position: right 0.618em center;
      background-size: 32px 20px; }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.visa,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.visa,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.visa,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.visa {
        background-image: url("../images/icons/credit-cards/visa.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.mastercard,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard {
        background-image: url("../images/icons/credit-cards/mastercard.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.laser,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.laser,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.laser,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.laser {
        background-image: url("../images/icons/credit-cards/laser.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.dinersclub,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub {
        background-image: url("../images/icons/credit-cards/diners.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.maestro,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.maestro,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.maestro,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.maestro {
        background-image: url("../images/icons/credit-cards/maestro.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.jcb,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.jcb,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.jcb,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.jcb {
        background-image: url("../images/icons/credit-cards/jcb.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.amex,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.amex,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.amex,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.amex {
        background-image: url("../images/icons/credit-cards/amex.svg"); }
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number.discover,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
      .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.discover,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
      .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number.discover,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
      #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc.discover {
        background-image: url("../images/icons/credit-cards/discover.svg"); }
    .woocommerce-cart #payment div.payment_box span.help,
    .woocommerce-checkout #payment div.payment_box span.help,
    #add_payment_method #payment div.payment_box span.help {
      font-size: 0.857em;
      color: black;
      font-weight: normal; }
    .woocommerce-cart #payment div.payment_box .form-row,
    .woocommerce-checkout #payment div.payment_box .form-row,
    #add_payment_method #payment div.payment_box .form-row {
      margin: 0 0 1em; }
    .woocommerce-cart #payment div.payment_box p:last-child,
    .woocommerce-checkout #payment div.payment_box p:last-child,
    #add_payment_method #payment div.payment_box p:last-child {
      margin-bottom: 0; }
    .woocommerce-cart #payment div.payment_box::before,
    .woocommerce-checkout #payment div.payment_box::before,
    #add_payment_method #payment div.payment_box::before {
      content: "";
      display: block;
      border: 1em solid #eae1de;
      /* arrow size / color */
      border-right-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
      position: absolute;
      top: -0.75em;
      left: 0;
      margin: -1em 0 0 2em; }
  .woocommerce-cart #payment .payment_method_paypal .about_paypal,
  .woocommerce-checkout #payment .payment_method_paypal .about_paypal,
  #add_payment_method #payment .payment_method_paypal .about_paypal {
    float: right;
    line-height: 52px;
    font-size: 0.83em; }
  .woocommerce-cart #payment .payment_method_paypal img,
  .woocommerce-checkout #payment .payment_method_paypal img,
  #add_payment_method #payment .payment_method_paypal img {
    max-height: 52px;
    vertical-align: middle; }

.woocommerce-terms-and-conditions {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.05); }

.woocommerce-invalid #terms {
  outline: 2px solid red;
  outline-offset: 2px; }

/**
 * Password strength meter
 */
.woocommerce-password-strength {
  text-align: center;
  font-weight: 600;
  padding: 3px 0.5em;
  font-size: 1em; }
  .woocommerce-password-strength.strong {
    background-color: #c1e1b9;
    border-color: #83c373; }
  .woocommerce-password-strength.short {
    background-color: #f1adad;
    border-color: #e35b5b; }
  .woocommerce-password-strength.bad {
    background-color: #fbc5a9;
    border-color: #f78b53; }
  .woocommerce-password-strength.good {
    background-color: #ffe399;
    border-color: #ffc733; }

.woocommerce-password-hint {
  margin: 0.5em 0 0;
  display: block; }

/**
 * Twenty Eleven specific styles
 */
#content.twentyeleven .woocommerce-pagination a {
  font-size: 1em;
  line-height: 1; }

/**
 * Twenty Thirteen specific styles
 */
.single-product .twentythirteen .entry-summary,
.single-product .twentythirteen #reply-title,
.single-product .twentythirteen #respond #commentform {
  padding: 0; }

.single-product .twentythirteen p.stars {
  clear: both; }

.twentythirteen .woocommerce-breadcrumb {
  padding-top: 40px; }

/**
 * Twenty Fourteen specific styles
 */
.twentyfourteen ul.products li.product {
  margin-top: 0 !important; }

/**
 * Twenty Sixteen specific styles
 */
body:not(.search-results) .twentysixteen .entry-summary {
  color: inherit;
  font-size: inherit;
  line-height: inherit; }

.twentysixteen .price ins {
  background: inherit;
  color: inherit; }

.woocommerce #order_comments,
.woocommerce-page #order_comments,
.woocommerce-cart #order_comments,
.footer_btn #order_comments {
  padding-top: 10px; }

.woocommerce a.button,
.woocommerce-page a.button,
.woocommerce-cart a.button,
.footer_btn a.button {
  font-size: 20px;
  color: black;
  background-color: #eae1de;
  font-weight: 300; }
  .woocommerce a.button:hover,
  .woocommerce-page a.button:hover,
  .woocommerce-cart a.button:hover,
  .footer_btn a.button:hover {
    color: #fff;
    background-color: #5a333d;
    transition: 0.5s; }

.woocommerce #content,
.woocommerce-page #content,
.woocommerce-cart #content,
.footer_btn #content {
  font-size: 1.2rem;
  padding: 0 10px; }
  .woocommerce #content ul li,
  .woocommerce-page #content ul li,
  .woocommerce-cart #content ul li,
  .footer_btn #content ul li {
    margin-bottom: 0; }
  .woocommerce #content ul.products li.product a.button,
  .woocommerce-page #content ul.products li.product a.button,
  .woocommerce-cart #content ul.products li.product a.button,
  .footer_btn #content ul.products li.product a.button {
    position: relative; }

.woocommerce section,
.woocommerce-page section,
.woocommerce-cart section,
.footer_btn section {
  padding: 0; }

.woocommerce h1,
.woocommerce .h1,
.woocommerce-page h1,
.woocommerce-page .h1,
.woocommerce-cart h1,
.woocommerce-cart .h1,
.footer_btn h1,
.footer_btn .h1 {
  color: black !important;
  text-align: left; }
  @media screen and (max-width: 1080px) {
    .woocommerce h1,
    .woocommerce .h1,
    .woocommerce-page h1,
    .woocommerce-page .h1,
    .woocommerce-cart h1,
    .woocommerce-cart .h1,
    .footer_btn h1,
    .footer_btn .h1 {
      font-size: 50px !important; } }
  @media screen and (max-width: 768px) {
    .woocommerce h1,
    .woocommerce .h1,
    .woocommerce-page h1,
    .woocommerce-page .h1,
    .woocommerce-cart h1,
    .woocommerce-cart .h1,
    .footer_btn h1,
    .footer_btn .h1 {
      font-size: 35px !important;
      line-height: 40px; } }

.woocommerce h2,
.woocommerce .h2,
.woocommerce-page h2,
.woocommerce-page .h2,
.woocommerce-cart h2,
.woocommerce-cart .h2,
.footer_btn h2,
.footer_btn .h2 {
  font-size: 2rem !important;
  color: black !important; }

.woocommerce h3,
.woocommerce .h3,
.woocommerce-page h3,
.woocommerce-page .h3,
.woocommerce-cart h3,
.woocommerce-cart .h3,
.footer_btn h3,
.footer_btn .h3 {
  font-size: 1.7rem !important;
  color: black !important; }

.woocommerce address,
.woocommerce-page address,
.woocommerce-cart address,
.footer_btn address {
  font-size: 1.8rem;
  font-style: normal; }
  .woocommerce address p,
  .woocommerce-page address p,
  .woocommerce-cart address p,
  .footer_btn address p {
    font-size: 1em; }

.woocommerce a.edit,
.woocommerce-page a.edit,
.woocommerce-cart a.edit,
.footer_btn a.edit {
  font-size: 1rem; }

.single-product .related {
  clear: both; }
  .single-product .related h2 {
    margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .single-product .related > h2 {
      padding: 0 20px; } }

div.summary {
  font-size: 1.25rem; }

#place_order {
  font-size: 2.3rem !important;
  text-align: center;
  margin: 1em auto 4em auto;
  display: block;
  max-width: 450px;
  width: 100%;
  height: 100%;
  text-transform: uppercase; }

#coupon_code {
  width: auto !important;
  font-size: 1.6rem; }

.xoo-wsc-product {
  font-size: 1rem;
  position: relative; }

a.xoo-wsc-remove {
  position: absolute;
  right: 1em;
  text-indent: -999999px; }
  a.xoo-wsc-remove::before {
    content: "x";
    position: absolute;
    right: 1rem;
    color: #333333;
    font-size: 1rem;
    text-indent: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    line-height: 2;
    font-weight: bold;
    transition: all 0.2s ease; }
  a.xoo-wsc-remove:hover::before {
    background: #333333;
    color: #fff; }

.xoo-wsc-footer-b .button {
  margin-top: 0.5em !important; }

.xoo-wsc-subtotal span {
  display: none; }

.xoo-wsc-subtotal .woocommerce-Price-amount {
  display: inline; }
  .xoo-wsc-subtotal .woocommerce-Price-amount::before {
    content: "Gesamt: € "; }

.order_review_heading {
  margin-top: 2em; }

#e_deliverydate_field,
.woocommerce-additional-fields__field-wrapper {
  background: #eae1de;
  padding: 0.2em 1em 0.7em;
  color: black; }
  #e_deliverydate_field .orddd_lite_field_note,
  .woocommerce-additional-fields__field-wrapper .orddd_lite_field_note {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    padding-top: 0.5em;
    display: block; }

#e_deliverydate_field .woocommerce-input-wrapper {
  position: relative;
  display: inline-block; }
  #e_deliverydate_field .woocommerce-input-wrapper input {
    cursor: pointer; }
  #e_deliverydate_field .woocommerce-input-wrapper::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f073";
    position: absolute;
    right: 10px;
    top: 10px;
    color: #385a68;
    pointer-events: none;
    cursor: pointer; }

#wc-stripe-payment-request-wrapper::after {
  content: ""; }

.brand-logo {
  display: none; }
  .brand-logo img {
    width: 100%;
    height: auto; }

.xoo-wsc-items-count {
  font-size: 16px; }

.xoo-wsc-img-col {
  width: 25%; }

.yith-wcbr-brands {
  display: none; }

@media screen and (min-width: 1030px) {
  .product .product_meta {
    margin-top: 0; } }

@media screen and (min-width: 1030px) {
  .yith-wcbr-brands-logo {
    position: absolute;
    left: 0;
    top: -48px;
    z-index: -1; } }

.yith-wcbr-brands-logo img {
  max-height: 100px;
  width: auto;
  height: auto; }

.product p.wc-gzd-additional-info {
  font-size: 12px; }

@media screen and (max-width: 8px) {
  .post-type-archive #inner-content,
  .tax-product_cat #inner-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .woocommerce .add_to_cart_button,
  .woocommerce-page .add_to_cart_button {
    font-size: 1.6rem !important; }
  .woocommerce ul.products.columns-4 li.product,
  .woocommerce-page ul.products.columns-4 li.product {
    width: 30.75%; }
  ul.products li.first {
    clear: none !important; }
  ul.products li.last {
    margin: 0 3.8% 2.992em 0 !important; }
  ul.products li:nth-child(3n+1) {
    clear: both !important; }
  ul.products li:nth-child(3n+3) {
    margin-right: 0 !important; } }

.sku_wrapper,
.product-units,
.posted_in {
  font-family: HarmoniaSansProCyr, mono;
  line-height: 1; }

.woocommerce-page a.button.termin_btn,
.woocommerce-page a.cat_termin,
.footer_btn a.button.termin_btn,
.footer_btn a.cat_termin {
  background-color: #eae1de;
  font-size: 20px;
  color: black;
  font-weight: 300;
  border-radius: 0; }
  .woocommerce-page a.button.termin_btn:hover,
  .woocommerce-page a.cat_termin:hover,
  .footer_btn a.button.termin_btn:hover,
  .footer_btn a.cat_termin:hover {
    color: #fff;
    background-color: #5a333d;
    box-shadow: none; }

.block_startseite .button_block a {
  background-color: #5a333d;
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  border-radius: 0;
  transition: all 0.5s ease; }
  .block_startseite .button_block a:hover {
    color: #fff;
    background-color: #5a333d;
    box-shadow: none; }

.footer_btn {
  padding: 20px 0;
  text-align: center; }

tr td select#menge {
  width: 210px; }

tr td a.reset_variations {
  display: none !important; }

.woocommerce h1.shop_title,
.woocommerce-page h1.shop_title,
.xoo-wsc-modal h1.shop_title {
  margin-bottom: 10px;
  max-width: 80%;
  line-height: 1; }
  @media screen and (max-width: 768px) {
    .woocommerce h1.shop_title,
    .woocommerce-page h1.shop_title,
    .xoo-wsc-modal h1.shop_title {
      padding: 20px; } }

.woocommerce .text_shop_page,
.woocommerce-page .text_shop_page,
.xoo-wsc-modal .text_shop_page {
  max-width: 60%; }
  @media screen and (max-width: 768px) {
    .woocommerce .text_shop_page,
    .woocommerce-page .text_shop_page,
    .xoo-wsc-modal .text_shop_page {
      padding: 20px;
      max-width: 100%; } }
  .woocommerce .text_shop_page h2,
  .woocommerce-page .text_shop_page h2,
  .xoo-wsc-modal .text_shop_page h2 {
    font-weight: 300;
    font-size: 20px !important;
    margin-bottom: 50px;
    line-height: 1.5; }

.woocommerce div.product .woocommerce-tabs ul.tabs,
.woocommerce-page div.product .woocommerce-tabs ul.tabs,
.xoo-wsc-modal div.product .woocommerce-tabs ul.tabs {
  margin-bottom: 0; }
  .woocommerce div.product .woocommerce-tabs ul.tabs.wc-tabs li,
  .woocommerce-page div.product .woocommerce-tabs ul.tabs.wc-tabs li,
  .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs.wc-tabs li {
    margin-top: 5px; }
  .woocommerce div.product .woocommerce-tabs ul.tabs::after, .woocommerce div.product .woocommerce-tabs ul.tabs::before,
  .woocommerce-page div.product .woocommerce-tabs ul.tabs::after,
  .woocommerce-page div.product .woocommerce-tabs ul.tabs::before,
  .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs::after,
  .xoo-wsc-modal div.product .woocommerce-tabs ul.tabs::before {
    display: none; }

#content ul.products {
  display: flex;
  flex-wrap: wrap; }
  #content ul.products li.product {
    position: relative;
    padding: 5px;
    padding-bottom: 6rem !important;
    margin-bottom: 10rem; }
    #content ul.products li.product .price {
      color: black;
      line-height: 1; }
    #content ul.products li.product .tax-info {
      line-height: 1; }
    #content ul.products li.product a > img {
      display: block;
      margin: 0 auto;
      width: auto;
      height: 100%;
      max-height: 300px;
      object-fit: contain; }
      @media screen and (max-width: 768px) {
        #content ul.products li.product a > img {
          max-height: 200px; } }

#content .woocommerce li.product .added_to_cart.wc-forward {
  position: absolute;
  bottom: -6rem;
  line-height: 1; }

#content .woocommerce li.product .button {
  position: absolute !important;
  bottom: 0; }

#content .slick-slider li.product {
  position: relative; }
  #content .slick-slider li.product .added_to_cart.wc-forward {
    left: 50%;
    transform: translateX(-50%);
    width: 90%; }
  #content .slick-slider li.product .button {
    left: 50%;
    transform: translateX(-50%);
    width: 90%; }

.woocommerce #sidebar {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  padding: 80px 40px 0 50px;
  background-color: #eae1de;
  z-index: 990;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.5s ease-in; }
  @media screen and (min-width: 767px) and (max-width: 1800px) {
    .woocommerce #sidebar {
      animation: shakeX 3s ease-in-out infinite alternate;
      transform: translateX(calc(100% - 40px));
      border-bottom: 40px solid #5a333d; } }
  @media screen and (max-width: 767px) {
    .woocommerce #sidebar {
      transform: translateX(100%); } }
  .woocommerce #sidebar form input {
    border: none;
    border-radius: 0; }
  .woocommerce #sidebar .misha-cart {
    color: black;
    text-decoration: none;
    font-size: 1.2rem;
    width: 26px;
    height: 30px;
    text-align: center;
    position: relative;
    line-height: 15px;
    padding: 0 .4em 0 15px;
    margin-left: -46px;
    margin-bottom: -30px;
    padding-top: 10px; }
    .woocommerce #sidebar .misha-cart i {
      position: absolute;
      left: 2px;
      top: -3px;
      color: #5a333d;
      z-index: -1;
      font-size: 24px;
      padding-top: 10px; }
  .woocommerce #sidebar .widget_nav_menu {
    border: none; }
  .woocommerce #sidebar li#yith_woocommerce_ajax_search-2 input#yith-searchsubmit {
    width: 100px;
    font-size: 15px;
    color: #eae1de;
    background-color: #fff;
    border: 0;
    padding: 5px 0;
    margin-top: 10px; }
    .woocommerce #sidebar li#yith_woocommerce_ajax_search-2 input#yith-searchsubmit:hover {
      transition: 0.5s; }
  .woocommerce #sidebar li#yith_woocommerce_ajax_search-2 input#yith-s {
    height: 40px;
    max-width: 100%;
    width: 220px; }
  .woocommerce #sidebar h3 {
    font-family: HarmoniaSansProCyr, mono;
    font-size: 15px !important;
    text-transform: uppercase;
    color: black !important;
    letter-spacing: 3px;
    margin-bottom: 5px; }
  .woocommerce #sidebar .bapf_ckbox_square input[type="checkbox"] {
    border: 1px solid black;
    cursor: pointer;
    border-radius: 0; }
    .woocommerce #sidebar .bapf_ckbox_square input[type="checkbox"]:checked {
      background-color: black;
      background-clip: content-box; }
  .woocommerce #sidebar input[type='checkbox'] {
    /* remove browser chrome */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none; }
  .woocommerce #sidebar .bapf_body ul li {
    margin-bottom: 10px !important;
    color: black;
    font-size: 1.6rem; }
  .woocommerce #sidebar ul#menu-sidebar-menue li {
    display: block;
    text-align: left;
    margin: 5px 0; }
    .woocommerce #sidebar ul#menu-sidebar-menue li a {
      color: black;
      font-size: 1.6rem;
      line-height: 2rem; }
      .woocommerce #sidebar ul#menu-sidebar-menue li a:hover {
        font-weight: 300;
        text-decoration: underline; }

.woocommerce.header-sticky #sidebar {
  padding-top: 80px; }

.woocommerce .sidebar_toggle_button {
  height: 20px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  display: none;
  background-color: #5a333d;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  z-index: 9990;
  transition: right 0.5s ease; }
  .woocommerce .sidebar_toggle_button .cls-1 {
    fill: #fff; }
  .woocommerce .sidebar_toggle_button.sidebar_close_button {
    display: none; }
  @media screen and (min-width: 767px) and (max-width: 1800px) {
    .woocommerce .sidebar_toggle_button.sidebar_show_button {
      animation: shakeX 3s ease-in-out infinite alternate; } }

@media screen and (max-width: 767px) {
  .woocommerce .sidebar_toggle_button {
    right: 0;
    bottom: 50%;
    display: block; } }

@media screen and (min-width: 767px) and (max-width: 1800px) {
  .woocommerce .sidebar_toggle_button {
    display: block;
    right: 0; } }

.woocommerce.sidebar-open #sidebar {
  animation: open 0.3s linear;
  transform: translateX(0);
  transition: all 0.2s ease-out;
  overflow-y: scroll; }

.woocommerce.sidebar-open .sidebar_toggle_button {
  bottom: 50%; }

.woocommerce.sidebar-open .sidebar_show_button {
  display: none; }

.woocommerce.sidebar-open .sidebar_close_button {
  display: block; }

.footer_btn #content button.button,
.footer_btn .fb-content button.button,
.coupon #content button.button,
.coupon .fb-content button.button,
.woocommerce-page #content button.button,
.woocommerce-page .fb-content button.button,
.woocommerce-cart #content button.button,
.woocommerce-cart .fb-content button.button {
  background-color: #eae1de;
  color: black;
  font-size: 20px;
  font-weight: 300; }
  .footer_btn #content button.button:hover,
  .footer_btn .fb-content button.button:hover,
  .coupon #content button.button:hover,
  .coupon .fb-content button.button:hover,
  .woocommerce-page #content button.button:hover,
  .woocommerce-page .fb-content button.button:hover,
  .woocommerce-cart #content button.button:hover,
  .woocommerce-cart .fb-content button.button:hover {
    background-color: #5a333d;
    color: #fff;
    font-size: 20px;
    transition: 0.5s; }

.footer_btn #content a.button.alt,
.footer_btn .fb-content a.button.alt,
.coupon #content a.button.alt,
.coupon .fb-content a.button.alt,
.woocommerce-page #content a.button.alt,
.woocommerce-page .fb-content a.button.alt,
.woocommerce-cart #content a.button.alt,
.woocommerce-cart .fb-content a.button.alt {
  height: 100%; }

.footer_btn #content table.cart .product-thumbnail img,
.footer_btn #content table.cart .wc-gzd-product-name-left img,
.footer_btn #content table.shop_table .product-thumbnail img,
.footer_btn #content table.shop_table .wc-gzd-product-name-left img,
.footer_btn #content .woocommerce-checkout-review-order-table .product-thumbnail img,
.footer_btn #content .woocommerce-checkout-review-order-table .wc-gzd-product-name-left img,
.footer_btn .fb-content table.cart .product-thumbnail img,
.footer_btn .fb-content table.cart .wc-gzd-product-name-left img,
.footer_btn .fb-content table.shop_table .product-thumbnail img,
.footer_btn .fb-content table.shop_table .wc-gzd-product-name-left img,
.footer_btn .fb-content .woocommerce-checkout-review-order-table .product-thumbnail img,
.footer_btn .fb-content .woocommerce-checkout-review-order-table .wc-gzd-product-name-left img,
.coupon #content table.cart .product-thumbnail img,
.coupon #content table.cart .wc-gzd-product-name-left img,
.coupon #content table.shop_table .product-thumbnail img,
.coupon #content table.shop_table .wc-gzd-product-name-left img,
.coupon #content .woocommerce-checkout-review-order-table .product-thumbnail img,
.coupon #content .woocommerce-checkout-review-order-table .wc-gzd-product-name-left img,
.coupon .fb-content table.cart .product-thumbnail img,
.coupon .fb-content table.cart .wc-gzd-product-name-left img,
.coupon .fb-content table.shop_table .product-thumbnail img,
.coupon .fb-content table.shop_table .wc-gzd-product-name-left img,
.coupon .fb-content .woocommerce-checkout-review-order-table .product-thumbnail img,
.coupon .fb-content .woocommerce-checkout-review-order-table .wc-gzd-product-name-left img,
.woocommerce-page #content table.cart .product-thumbnail img,
.woocommerce-page #content table.cart .wc-gzd-product-name-left img,
.woocommerce-page #content table.shop_table .product-thumbnail img,
.woocommerce-page #content table.shop_table .wc-gzd-product-name-left img,
.woocommerce-page #content .woocommerce-checkout-review-order-table .product-thumbnail img,
.woocommerce-page #content .woocommerce-checkout-review-order-table .wc-gzd-product-name-left img,
.woocommerce-page .fb-content table.cart .product-thumbnail img,
.woocommerce-page .fb-content table.cart .wc-gzd-product-name-left img,
.woocommerce-page .fb-content table.shop_table .product-thumbnail img,
.woocommerce-page .fb-content table.shop_table .wc-gzd-product-name-left img,
.woocommerce-page .fb-content .woocommerce-checkout-review-order-table .product-thumbnail img,
.woocommerce-page .fb-content .woocommerce-checkout-review-order-table .wc-gzd-product-name-left img,
.woocommerce-cart #content table.cart .product-thumbnail img,
.woocommerce-cart #content table.cart .wc-gzd-product-name-left img,
.woocommerce-cart #content table.shop_table .product-thumbnail img,
.woocommerce-cart #content table.shop_table .wc-gzd-product-name-left img,
.woocommerce-cart #content .woocommerce-checkout-review-order-table .product-thumbnail img,
.woocommerce-cart #content .woocommerce-checkout-review-order-table .wc-gzd-product-name-left img,
.woocommerce-cart .fb-content table.cart .product-thumbnail img,
.woocommerce-cart .fb-content table.cart .wc-gzd-product-name-left img,
.woocommerce-cart .fb-content table.shop_table .product-thumbnail img,
.woocommerce-cart .fb-content table.shop_table .wc-gzd-product-name-left img,
.woocommerce-cart .fb-content .woocommerce-checkout-review-order-table .product-thumbnail img,
.woocommerce-cart .fb-content .woocommerce-checkout-review-order-table .wc-gzd-product-name-left img {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 100%;
  max-height: 160px; }

.footer_btn #content table.cart .product-subtotal,
.footer_btn #content table.cart .product-total,
.footer_btn #content table.shop_table .product-subtotal,
.footer_btn #content table.shop_table .product-total,
.footer_btn #content .woocommerce-checkout-review-order-table .product-subtotal,
.footer_btn #content .woocommerce-checkout-review-order-table .product-total,
.footer_btn .fb-content table.cart .product-subtotal,
.footer_btn .fb-content table.cart .product-total,
.footer_btn .fb-content table.shop_table .product-subtotal,
.footer_btn .fb-content table.shop_table .product-total,
.footer_btn .fb-content .woocommerce-checkout-review-order-table .product-subtotal,
.footer_btn .fb-content .woocommerce-checkout-review-order-table .product-total,
.coupon #content table.cart .product-subtotal,
.coupon #content table.cart .product-total,
.coupon #content table.shop_table .product-subtotal,
.coupon #content table.shop_table .product-total,
.coupon #content .woocommerce-checkout-review-order-table .product-subtotal,
.coupon #content .woocommerce-checkout-review-order-table .product-total,
.coupon .fb-content table.cart .product-subtotal,
.coupon .fb-content table.cart .product-total,
.coupon .fb-content table.shop_table .product-subtotal,
.coupon .fb-content table.shop_table .product-total,
.coupon .fb-content .woocommerce-checkout-review-order-table .product-subtotal,
.coupon .fb-content .woocommerce-checkout-review-order-table .product-total,
.woocommerce-page #content table.cart .product-subtotal,
.woocommerce-page #content table.cart .product-total,
.woocommerce-page #content table.shop_table .product-subtotal,
.woocommerce-page #content table.shop_table .product-total,
.woocommerce-page #content .woocommerce-checkout-review-order-table .product-subtotal,
.woocommerce-page #content .woocommerce-checkout-review-order-table .product-total,
.woocommerce-page .fb-content table.cart .product-subtotal,
.woocommerce-page .fb-content table.cart .product-total,
.woocommerce-page .fb-content table.shop_table .product-subtotal,
.woocommerce-page .fb-content table.shop_table .product-total,
.woocommerce-page .fb-content .woocommerce-checkout-review-order-table .product-subtotal,
.woocommerce-page .fb-content .woocommerce-checkout-review-order-table .product-total,
.woocommerce-cart #content table.cart .product-subtotal,
.woocommerce-cart #content table.cart .product-total,
.woocommerce-cart #content table.shop_table .product-subtotal,
.woocommerce-cart #content table.shop_table .product-total,
.woocommerce-cart #content .woocommerce-checkout-review-order-table .product-subtotal,
.woocommerce-cart #content .woocommerce-checkout-review-order-table .product-total,
.woocommerce-cart .fb-content table.cart .product-subtotal,
.woocommerce-cart .fb-content table.cart .product-total,
.woocommerce-cart .fb-content table.shop_table .product-subtotal,
.woocommerce-cart .fb-content table.shop_table .product-total,
.woocommerce-cart .fb-content .woocommerce-checkout-review-order-table .product-subtotal,
.woocommerce-cart .fb-content .woocommerce-checkout-review-order-table .product-total {
  text-align: right; }

.footer_btn #content table.cart tfoot td,
.footer_btn #content table.shop_table tfoot td,
.footer_btn #content .woocommerce-checkout-review-order-table tfoot td,
.footer_btn .fb-content table.cart tfoot td,
.footer_btn .fb-content table.shop_table tfoot td,
.footer_btn .fb-content .woocommerce-checkout-review-order-table tfoot td,
.coupon #content table.cart tfoot td,
.coupon #content table.shop_table tfoot td,
.coupon #content .woocommerce-checkout-review-order-table tfoot td,
.coupon .fb-content table.cart tfoot td,
.coupon .fb-content table.shop_table tfoot td,
.coupon .fb-content .woocommerce-checkout-review-order-table tfoot td,
.woocommerce-page #content table.cart tfoot td,
.woocommerce-page #content table.shop_table tfoot td,
.woocommerce-page #content .woocommerce-checkout-review-order-table tfoot td,
.woocommerce-page .fb-content table.cart tfoot td,
.woocommerce-page .fb-content table.shop_table tfoot td,
.woocommerce-page .fb-content .woocommerce-checkout-review-order-table tfoot td,
.woocommerce-cart #content table.cart tfoot td,
.woocommerce-cart #content table.shop_table tfoot td,
.woocommerce-cart #content .woocommerce-checkout-review-order-table tfoot td,
.woocommerce-cart .fb-content table.cart tfoot td,
.woocommerce-cart .fb-content table.shop_table tfoot td,
.woocommerce-cart .fb-content .woocommerce-checkout-review-order-table tfoot td {
  text-align: right; }

#billing_last_name_field {
  float: left; }

.woocommerce-shipping-fields {
  margin-bottom: 30px; }
  .woocommerce-shipping-fields .woocommerce-form__label span {
    margin-left: 5px;
    font-size: 1.7rem; }

.woocommerce-shipping-fields label,
.woocommerce-shipping-fields input,
.woocommerce-shipping-fields select,
.woocommerce-shipping-fields textarea,
.woocommerce-shipping-fields .woocommerce-input-wrapper,
.woocommerce-billing-fields__field-wrapper label,
.woocommerce-billing-fields__field-wrapper input,
.woocommerce-billing-fields__field-wrapper select,
.woocommerce-billing-fields__field-wrapper textarea,
.woocommerce-billing-fields__field-wrapper .woocommerce-input-wrapper,
.woocommerce-additional-fields__field-wrapper label,
.woocommerce-additional-fields__field-wrapper input,
.woocommerce-additional-fields__field-wrapper select,
.woocommerce-additional-fields__field-wrapper textarea,
.woocommerce-additional-fields__field-wrapper .woocommerce-input-wrapper,
.woocommerce-MyAccount-content label,
.woocommerce-MyAccount-content input,
.woocommerce-MyAccount-content select,
.woocommerce-MyAccount-content textarea,
.woocommerce-MyAccount-content .woocommerce-input-wrapper {
  font-size: 15px; }

.woocommerce-shipping-fields textarea,
.woocommerce-billing-fields__field-wrapper textarea,
.woocommerce-additional-fields__field-wrapper textarea,
.woocommerce-MyAccount-content textarea {
  line-height: 1; }

.woocommerce-shipping-fields input#billing_first_name,
.woocommerce-shipping-fields input#billing_last_name,
.woocommerce-billing-fields__field-wrapper input#billing_first_name,
.woocommerce-billing-fields__field-wrapper input#billing_last_name,
.woocommerce-additional-fields__field-wrapper input#billing_first_name,
.woocommerce-additional-fields__field-wrapper input#billing_last_name,
.woocommerce-MyAccount-content input#billing_first_name,
.woocommerce-MyAccount-content input#billing_last_name {
  width: 100%; }

.woocommerce-shipping-fields p#billing_last_name_field,
.woocommerce-billing-fields__field-wrapper p#billing_last_name_field,
.woocommerce-additional-fields__field-wrapper p#billing_last_name_field,
.woocommerce-MyAccount-content p#billing_last_name_field {
  float: right; }

.woocommerce-shipping-fields td.woocommerce-orders-table__cell-order-actions a.button,
.woocommerce-billing-fields__field-wrapper td.woocommerce-orders-table__cell-order-actions a.button,
.woocommerce-additional-fields__field-wrapper td.woocommerce-orders-table__cell-order-actions a.button,
.woocommerce-MyAccount-content td.woocommerce-orders-table__cell-order-actions a.button {
  width: 100%;
  margin: 10px 0;
  text-align: center; }

form.checkout .woocommerce-billing-fields .woocommerce-billing-fields__field-wrapper select,
form.checkout .woocommerce-billing-fields .woocommerce-billing-fields__field-wrapper input,
form.checkout .woocommerce-billing-fields .woocommerce-shipping-fields__field-wrapper select,
form.checkout .woocommerce-billing-fields .woocommerce-shipping-fields__field-wrapper input,
form.checkout .woocommerce-shipping-fields .woocommerce-billing-fields__field-wrapper select,
form.checkout .woocommerce-shipping-fields .woocommerce-billing-fields__field-wrapper input,
form.checkout .woocommerce-shipping-fields .woocommerce-shipping-fields__field-wrapper select,
form.checkout .woocommerce-shipping-fields .woocommerce-shipping-fields__field-wrapper input {
  max-width: 100%; }

.woocommerce .woocommerce-MyAccount-content address {
  font-size: 18px;
  line-height: 1.5; }

.woocommerce .woocommerce-MyAccount-content em {
  font-size: 15px; }

.woocommerce .woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address .woocommerce-Address-title h3 {
  margin-bottom: 0px; }

.woocommerce .woocommerce-MyAccount-content .woocommerce-Addresses .woocommerce-Address .woocommerce-Address-title a {
  font-size: 15px; }

.woocommerce .woocommerce-MyAccount-content .edit-account fieldset {
  margin-bottom: 30px; }

.woocommerce-edit-address .hentry h1.page-title,
.woocommerce-account .hentry h1.page-title {
  text-align: center; }

.wc-gzd-checkbox-placeholder p.checkbox-legal {
  margin-bottom: 30px;
  line-height: 30px; }
  .wc-gzd-checkbox-placeholder p.checkbox-legal .woocommerce-gzd-legal-checkbox-text {
    font-size: 20px; }

.wc-gzd-product-name-right {
  font-size: 15px; }
  .wc-gzd-product-name-right p.wc-gzd-cart-info {
    font-size: 15px; }

.woocommerce-checkout .article-header .page-title {
  text-align: center; }

.woocommerce-checkout td.product-name div.wc-gzd-product-name-left {
  width: 100%; }

.page-bezahlmoeglichkeiten .woocommerce-gzd li {
  line-height: 40px; }
  .page-bezahlmoeglichkeiten .woocommerce-gzd li label {
    color: #5a333d; }
  .page-bezahlmoeglichkeiten .woocommerce-gzd li img {
    display: none; }

div.woocommerce nav.woocommerce-MyAccount-navigation {
  width: max-content; }
  div.woocommerce nav.woocommerce-MyAccount-navigation ul {
    margin-top: 0; }
    div.woocommerce nav.woocommerce-MyAccount-navigation ul li a {
      text-decoration: none; }
      div.woocommerce nav.woocommerce-MyAccount-navigation ul li a:hover {
        text-decoration: underline; }

div.woocommerce .woocommerce-MyAccount-content {
  width: 80%; }

.archive .woocommerce-products-header h1,
.archive .woocommerce-products-header h2 {
  margin-bottom: 20px; }

.archive .woocommerce-products-header .bild_marke {
  float: right;
  width: 20%; }

.archive .woocommerce-products-header p {
  font-size: 18px; }

.archive .woocommerce-products-header ul li {
  font-size: 18px; }

.archive .woocommerce-products-header .marke_beschreibung {
  font-size: 18px;
  margin: 50px 0; }

.woocommerce span.onsale {
  color: #fff; }

.woocommerce #content div.product div.summary .pricebox .price del,
.woocommerce div.product div.summary .pricebox .price del,
.woocommerce-page #content div.product div.summary .pricebox .price del,
.woocommerce-page div.product div.summary .pricebox .price del {
  color: #eae1de; }

#gotomenu {
  margin-bottom: 20px; }
  #gotomenu ul {
    text-align: center !important;
    margin: 0;
    padding: 0; }
    #gotomenu ul li {
      font-size: 16px;
      display: inline;
      padding: 15px; }

#content .wcpscwc-product-slider button {
  background-color: #5a333d !important; }

/*********************
BASE (MOBILE) SIZE
This are the mobile styles. It's what people see on their phones. If
you set a great foundation, you won't need to add too many styles in
the other stylesheets. Remember, keep it light: Speed is Important.
*********************/
/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/
[data-icon]:before {
  font-family: 'anna-kopp-webfont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: attr(data-icon);
  color: #5a333d; }

.oeffnungszeiten [data-icon]:before {
  color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'anna-kopp-webfont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-alone {
  display: inline-block;
  /* Chrome 19 was weird with clickability without this */ }

.screen-reader-text {
  /* Reusable, toolbox kind of class */
  position: absolute;
  top: -9999px;
  left: -9999px; }

.icon-ak-sign:before {
  content: "\e600"; }

.icon-arrow-down:before {
  content: "\e601"; }

.icon-arrow-left:before {
  content: "\e602"; }

.icon-arrow-left2:before {
  content: "\e603"; }

.icon-arrow-right:before {
  content: "\e604"; }

.icon-arrow-right2:before {
  content: "\e605"; }

.icon-arrow-up:before {
  content: "\e606"; }

.icon-facebook:before {
  content: "\e607"; }

.icon-phone:before {
  content: "\e608"; }

.icon-search:before {
  content: "\e609"; }

/*********************
GENERAL STYLES
*********************/
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */ }

body {
  font-family: HarmoniaSansProCyr, mono;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
  line-height: 1.5;
  color: black;
  background-color: #fff;
  -webkit-font-smoothing: antialiased; }
  body.menu-open {
    overflow: hidden; }

/*********************
LAYOUT & GRID STYLES
*********************/
/********************
WORDPRESS BODY CLASSES
style a page via class
********************/
/* for sites that are read right to left (i.e. hebrew) */
/* home page */
/* blog template page */
/* archive page */
/* date archive page */
/* replace the number to the corresponding page number */
/* search page */
.search-results {
  /* search result page */ }

.search-results article {
  border-bottom: 1px solid #919191; }

.search-results article .search-title {
  font-size: 23px;
  font-size: 2.3rem;
  line-height: 36px;
  line-height: 3.6rem; }

.search-results article .article-footer {
  font-size: 15px;
  font-size: 1.5rem; }

.search-results .pagination {
  font-size: 17px;
  font-size: 1.7rem; }

/* search result page */
/* no results search page */
/* individual paged search (i.e. body.search-paged-3) */
/* 404 page */
/* single post page */
/* individual post page by id (i.e. body.postid-73) */
/* individual paged single (i.e. body.single-paged-3) */
/* attatchment page */
/* individual attatchment page (i.e. body.attachmentid-763) */
/* style mime type pages */
/* author page */
/* user nicename (i.e. body.author-samueladams) */
/* paged author archives (i.e. body.author-paged-4) for page 4 */
/* category page */
/* individual category page (i.e. body.category-6) */
/* replace the number to the corresponding page number */
/* tag page */
/* individual tag page (i.e. body.tag-news) */
/* replace the number to the corresponding page number */
/* custom page template page */
/* individual page template (i.e. body.page-template-contact-php */
/* replace the number to the corresponding page number */
/* parent page template */
/* child page template */
/* replace the number to the corresponding page number */
/* if user is logged in */
/* paged items like search results or archives */
/* individual paged (i.e. body.paged-3) */
/*********************
LAYOUT & GRID STYLES
*********************/
/*********************
LINK STYLES
*********************/
a,
a:visited {
  color: black;
  /* on hover */
  /* on click */
  /* mobile tap color */ }
  a:hover, a:focus,
  a:visited:hover,
  a:visited:focus {
    color: black; }
  a:link,
  a:visited:link {
    /*
        this highlights links on iPhones/iPads.
        so it basically works like the :hover selector
        for mobile devices.
        */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3); }

#slideshow {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

#slideshow div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: no-repeat 50% 0;
  -webkit-background-size: cover;
  -moz-background-size: cover; }

#supersized {
  position: static !important;
  top: 0;
  left: 0; }

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  text-rendering: optimizelegibility;
  font-family: PPEditorialNew-Regular, sans-serif;
  font-weight: 400;
  /*
    if you're going to use webfonts, be sure to check your weights
    http://css-tricks.com/watch-your-font-weight/
    */
  /* removing text decoration from all headline links */ }
  h1 a,
  .h1 a,
  h2 a,
  .h2 a,
  h3 a,
  .h3 a,
  h4 a,
  .h4 a,
  h5 a,
  .h5 a {
    text-decoration: none; }

h1,
.h1 {
  font-size: 2em;
  line-height: 1.333em; }

h2,
.h2 {
  font-size: 1.5em;
  line-height: 1.4em;
  margin-bottom: 0.375em; }

h3,
.h3 {
  font-size: 1.125em; }

h4,
.h4 {
  font-size: 1em;
  font-weight: 700; }

h5,
.h5 {
  font-size: 0.846em;
  line-height: 2.09em;
  text-transform: uppercase;
  letter-spacing: 2px; }

h6,
.h5 {
  font-size: 2rem;
  margin-bottom: -20px;
  margin-top: 0; }

/*********************
HEADER STYLES
*********************/
#container {
  position: relative;
  padding-top: 100px; }

.header {
  height: 100px;
  width: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  z-index: 500;
  border-bottom: 1px solid black;
  transition: all .3s ease; }
  .header #logo {
    width: 200px;
    max-width: 200px;
    transition: all .3s ease;
    margin: 20px auto 0 auto; }
    .header #logo svg {
      width: 100%;
      height: auto !important; }
  .header nav {
    display: none; }
    .header nav > ul {
      margin: 0;
      padding: 0; }
      .header nav > ul > li {
        float: left;
        font-size: 1.9rem;
        margin: 0; }
        .header nav > ul > li > a {
          color: black;
          padding: 0; }
          .header nav > ul > li > a:visited {
            color: black; }
          .header nav > ul > li > a:hover {
            color: black; }
          .header nav > ul > li > a:active {
            color: black; }
    .header nav#header-left {
      left: 3.2rem; }
      .header nav#header-left > ul > li {
        margin-right: 1.1em; }
    .header nav#header-right {
      right: 3.2rem; }
      .header nav#header-right > ul > li {
        margin-left: 1.1em; }
  .header #inner-header {
    position: relative;
    height: 100%;
    /*********************
        BEGIN: Menu Test 2020
        *********************/
    /*********************
        END: Menu Test 2020
        *********************/ }
    .header #inner-header button {
      position: absolute;
      top: -14px;
      right: -30px;
      display: inline-block;
      width: 140px;
      height: 140px;
      float: right;
      color: black;
      border: none;
      overflow: hidden;
      background: transparent;
      outline: none;
      transition: top 0.5s ease; }
      .header #inner-header button span {
        display: none; }
      .header #inner-header button .svg-rect-middle {
        transition: all 1s ease; }
      .header #inner-header button:hover .svg-rect-middle {
        width: 50px;
        x: 75px; }
      .header #inner-header button svg {
        position: absolute;
        right: 0;
        transition: all 0.5s ease; }
        .header #inner-header button svg.svg-bottom {
          top: -6%; }
        .header #inner-header button svg.svg-middle {
          bottom: 0; }
        .header #inner-header button svg.svg-top {
          top: 6%; }
        .header #inner-header button svg .svg-rect {
          fill: black; }
  .header.sticky {
    position: fixed;
    top: 0 !important;
    left: 0;
    padding-top: 0px !important;
    max-height: 72px;
    width: 100%;
    padding-top: 40px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5) 3px 3px 3px #888; }
    .header.sticky #logo {
      width: 200px;
      max-width: 130px;
      margin-top: 16px; }
      .header.sticky #logo svg {
        width: 100%;
        height: fit-content; }
    .header.sticky nav {
      top: 35px; }
    .header.sticky #inner-header:after {
      display: none; }
    .header.sticky #inner-header button {
      top: -34px; }
  .menu-open .header {
    height: 100vh; }
    .menu-open .header #inner-header {
      height: calc(100vh);
      background-color: #fff; }
    .menu-open .header #logo {
      position: fixed;
      left: 50%;
      top: 0;
      transform: translateX(-50%); }
    .menu-open .header.header nav > ul > li {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .menu-open .header #inner-header {
      padding-top: 170px; }
    .menu-open .header #top-nav,
    .menu-open .header #menu-header {
      height: 100vh !important; }
    .menu-open .header nav {
      width: 100%;
      display: block;
      background-color: #fff;
      left: 0; }
      .menu-open .header nav ul.top-nav > li {
        display: block;
        text-align: center;
        font-size: 2em;
        float: none; }
    .menu-open .header .sub-menu {
      position: relative;
      margin-top: 0.2em;
      visibility: visible;
      padding: 0.2em;
      margin-bottom: 0.5em;
      background-color: #5a333d;
      color: #fff; }
      .menu-open .header .sub-menu:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #5a333d;
        position: absolute;
        left: 50%;
        top: -5px;
        transform: translateX(-50%);
        display: block; }
      .menu-open .header .sub-menu li {
        display: inline-block; }
        .menu-open .header .sub-menu li a {
          padding: 0.2em 1em;
          color: #fff; }
    .menu-open .header .button svg.svg-bottom {
      transform: rotate(-225deg);
      top: 0 !important; }
    .menu-open .header .button svg.svg-middle {
      right: -100% !important; }
    .menu-open .header .button svg.svg-top {
      transform: rotate(225deg);
      top: 0 !important; }

#topslider {
  width: 100%;
  position: relative; }
  #topslider .slides li {
    background-color: black; }
    #topslider .slides li .slide {
      width: 100% !important; }
  #topslider img {
    width: 100% !important;
    height: auto !important; }
  #topslider .slide-data,
  #topslider .flex-direction-nav {
    display: none; }
  #topslider .flexslider {
    border: none;
    margin: 0; }
  #topslider .flex-control-nav {
    position: absolute;
    bottom: 25px;
    z-index: 100; }
    #topslider .flex-control-nav li {
      margin: 0 4px !important; }
    #topslider .flex-control-nav a {
      width: 15px;
      height: 15px;
      background: #fff;
      box-shadow: none 3px 3px 3px #888; }
    #topslider .flex-control-nav .flex-active {
      background: black; }

#underslider {
  background-color: black; }
  #underslider .directionNav a {
    display: none; }
    #underslider .directionNav a.prev, #underslider .directionNav a.next {
      display: block; }
  #underslider .widget_rpswidget {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0; }
    #underslider .widget_rpswidget #rps {
      border: 0; }
      #underslider .widget_rpswidget #rps .slider {
        background: none; }
        #underslider .widget_rpswidget #rps .slider .col {
          border: none; }
          #underslider .widget_rpswidget #rps .slider .col .post-title {
            display: none; }
          #underslider .widget_rpswidget #rps .slider .col .slider-content {
            position: relative;
            font-size: 2.7rem;
            color: #fff;
            font-family: PPEditorialNew-Regular, sans-serif;
            text-decoration: underline;
            text-align: center;
            height: 270px;
            padding: 20px 0 75px 0; }
            #underslider .widget_rpswidget #rps .slider .col .slider-content a {
              display: none;
              position: absolute;
              bottom: 25px;
              width: 210px;
              height: 35px;
              border: 1px solid #fff;
              margin-left: -105px;
              left: 50%;
              padding-left: 25px;
              font-size: 2.3rem;
              color: #fff;
              text-decoration: none;
              font-style: normal;
              text-align: left;
              transition: background-color 0.5s ease-out, color 0.5s ease-out; }
              #underslider .widget_rpswidget #rps .slider .col .slider-content a:visited {
                color: #fff; }
              #underslider .widget_rpswidget #rps .slider .col .slider-content a:hover {
                color: #fff; }
              #underslider .widget_rpswidget #rps .slider .col .slider-content a:active {
                color: #fff; }
              #underslider .widget_rpswidget #rps .slider .col .slider-content a:hover {
                background-color: #fff;
                color: black;
                transition: background-color 0.5s ease-in, color 0.5s ease-in; }
              #underslider .widget_rpswidget #rps .slider .col .slider-content a:after {
                font-family: 'anna-kopp-webfont';
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e604";
                font-size: 1.5rem;
                margin-left: 15px; }

#underslider .widget_rpswidget #rps .slider .col .slider-content a.show {
  display: block; }

#underslider .widget_rpswidget #rps .directionNav {
  font-size: 46px;
  font-size: 4.6rem; }

#underslider .widget_rpswidget #rps .directionNav a {
  position: absolute;
  top: 50%;
  margin-top: -23px;
  color: #fff;
  -webkit-transition: color 0.5s ease;
  transition: color 0.5s ease;
  text-decoration: none; }

#underslider .widget_rpswidget #rps .directionNav a:visited {
  color: #fff; }

#underslider .widget_rpswidget #rps .directionNav a:hover {
  color: #333333; }

#underslider .widget_rpswidget #rps .directionNav a:active {
  color: #fff; }

/*
all navs have a .nav class applied via
the wp_menu function; this is so we can
easily write one group of styles for
the navs on the site so our css is cleaner
and more scalable.
*/
.d-none {
  display: none !important; }

.nav {
  border-bottom: 0;
  margin: 0;
  /* end .menu li */
  /* highlight current page */
  /* end current highlighters */ }
  .nav li {
    /*
        so you really have to rethink your dropdowns for mobile.
        you don't want to have it expand too much because the
        screen is so small. How you manage your menu should
        depend on the project. Here's some great info on it:
        http://www.alistapart.com/articles/organizing-mobile/
        */ }
    .nav li a {
      display: block;
      text-decoration: none;
      /*
            remember this is for mobile ONLY, so there's no need
            to even declare hover styles here, you can do it in
            the style.scss file where it's relevant. We want to
            keep this file as small as possible!
            */ }
    .nav li.current-menu-item {
      text-decoration: underline !important;
      color: black;
      text-underline-offset: 3px; }
    .nav li ul.sub-menu li a,
    .nav li ul.children li a {
      padding-left: 30px; }

/* end .nav */
/*********************
POSTS & CONTENT STYLES
*********************/
#content {
  /*********************
      BILDERGALERIE
      *********************/ }
  #content .inner {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    width: 90vw; }
  #content ul {
    list-style-type: square;
    text-align: left;
    padding: 0 0 0 25px;
    line-height: 31px;
    line-height: 3.1rem; }
    #content ul li {
      margin-bottom: 10px; }
  #content .behandlungen {
    font-size: 23px;
    font-size: 2.3rem;
    line-height: 30px;
    line-height: 3rem; }
    #content .behandlungen .odd {
      background-color: #eae1de;
      color: black; }
      #content .behandlungen .odd div.preis {
        border-color: black; }
    #content .behandlungen .even {
      background-color: #5a333d; }
    #content .behandlungen article {
      padding: 20px;
      margin-bottom: 10px;
      position: relative;
      color: #fff; }
    #content .behandlungen header h2 {
      margin-top: 0;
      font-size: 2.6rem;
      line-height: 1.25;
      font-family: PPEditorialNew-Regular, sans-serif; }
      #content .behandlungen header h2:after {
        display: none; }
    #content .behandlungen header h4 {
      font-size: 2.3rem;
      font-weight: normal;
      font-family: HarmoniaSansProCyr, mono;
      text-align: center;
      text-transform: none;
      margin: -27px 0 30px 0;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
    #content .behandlungen section {
      text-align: center; }
      #content .behandlungen section h6 {
        font-size: 23px;
        font-size: 2.3rem;
        margin: 20px auto 10px 270px;
        text-align: left; }
      #content .behandlungen section ul {
        list-style-type: square;
        text-align: left;
        padding: 0 0 0 25px;
        margin: 0 0 0 270px;
        line-height: 31px;
        line-height: 3.1rem; }
        #content .behandlungen section ul li {
          margin-bottom: 10px; }
      #content .behandlungen section p:last-child {
        margin-bottom: 0; }
    #content .behandlungen footer {
      padding: 0;
      height: 65px;
      text-align: center;
      margin-top: 30px; }
      #content .behandlungen footer div.preis {
        display: inline;
        border: 2px solid #fff;
        font-size: 3.5rem;
        line-height: 4.3rem;
        margin: 0 auto;
        padding: 0px 15px;
        padding-top: 0.2em; }
    #content .behandlungen img {
      position: absolute;
      z-index: 100; }
    #content .behandlungen .format-image {
      background: none;
      padding: 0;
      margin-top: -10px;
      margin-bottom: 30px;
      clear: both; }
      #content .behandlungen .format-image section {
        padding: 0;
        text-align: left; }
        #content .behandlungen .format-image section img {
          position: static;
          margin: 0;
          display: block;
          float: left; }
      #content .behandlungen .format-image footer {
        height: 50px;
        clear: both; }
        #content .behandlungen .format-image footer a {
          position: relative;
          display: block;
          height: 31px;
          width: 210px;
          margin: 65px auto 0 auto;
          border: 1px solid #a2a2a2;
          background-color: #fff;
          font-family: HarmoniaSansProCyr, mono;
          font-size: 20px;
          font-size: 2rem;
          color: #a2a2a2;
          text-decoration: none;
          color: #a2a2a2; }
          #content .behandlungen .format-image footer a:visited {
            color: #a2a2a2; }
          #content .behandlungen .format-image footer a:hover {
            color: black; }
          #content .behandlungen .format-image footer a:active {
            color: #a2a2a2; }
          #content .behandlungen .format-image footer a:before {
            position: absolute;
            font-size: 51px;
            font-size: 5.1rem;
            width: 15px;
            left: 97px;
            top: -26px;
            z-index: -1;
            font-family: 'anna-kopp-webfont';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e606"; }
  .category-bildergalerie #content article.format-gallery {
    background: none;
    padding: 0 0 50px 0;
    margin-bottom: 50px;
    border-bottom: 1px solid #919191;
    position: relative; }
    .category-bildergalerie #content article.format-gallery .entry-title {
      width: 300px;
      text-align: center;
      position: absolute;
      left: 0;
      top: 10px;
      color: #fff;
      font-family: HarmoniaSansProCyr, mono;
      font-size: 40px;
      font-size: 4rem;
      line-height: 45px;
      line-height: 4.5rem;
      z-index: 150;
      cursor: pointer; }
      .category-bildergalerie #content article.format-gallery .entry-title:after {
        background-color: #fff; }
      .category-bildergalerie #content article.format-gallery .entry-title .more-button {
        bottom: -80px; }
    .category-bildergalerie #content article.format-gallery footer {
      display: none; }
    .category-bildergalerie #content article.format-gallery .gallery dl.gallery-item {
      width: 25%;
      float: left;
      height: 300px;
      margin: 0;
      padding: 0;
      text-align: left; }
      .category-bildergalerie #content article.format-gallery .gallery dl.gallery-item img {
        margin: 0;
        padding: 0; }
  #content #gallery-1 img {
    border: 20px solid white; }

.hentry header {
  padding: 0; }
  .hentry header p {
    display: none; }

.hentry footer {
  padding: 1.5em; }
  .hentry footer p {
    margin: 0; }

/* end .hentry */
.single-title,
.page-title,
.entry-title,
.archive-title {
  margin: 45px 0;
  font-family: PPEditorialNew-Regular, sans-serif;
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
  text-transform: uppercase; }
  .single-title:after,
  .page-title:after,
  .entry-title:after,
  .archive-title:after {
    display: block;
    width: 110px;
    height: 3px;
    content: "";
    margin: 20px auto 0 auto;
    position: relative; }

.article-header h4 {
  font-size: 23px;
  font-size: 2.3rem;
  font-weight: normal;
  font-family: HarmoniaSansProCyr, mono;
  text-align: center;
  text-transform: uppercase;
  margin: -27px 0 30px 0; }

.category_description {
  font-size: 23px;
  font-size: 2.3rem;
  line-height: 36px;
  line-height: 3.6rem;
  text-align: center; }

.category_description p {
  margin: 0 0 30px; }

.page-produkte .single-title,
.page-produkte .page-title,
.page-produkte .entry-title,
.page-child .single-title,
.page-child .page-title,
.page-child .entry-title {
  color: black; }

img.logo {
  float: none;
  margin: 0 !important; }

img.bild_darunter {
  clear: both; }

/* want to style individual post classes? Booya! */
/* post by id (i.e. post-3) */
/* general post style */
/* general article on a page style */
/* general style on an attatchment */
/* sticky post style */
/* hentry class */
/* style by category (i.e. category-videos) */
/* style by tag (i.e. tag-news) */
/* post meta */
.byline {
  color: #9fa6b4;
  font-style: normal;
  margin: 0 !important; }

/* entry content */
.entry-content {
  padding: 0;
  font-size: 1.6rem;
  line-height: 1.25em;
  position: relative;
  /*
    		image alignment on a screen this size may be
    		a bit difficult. It's set to start aligning
    		and floating images at the next breakpoint,
    		but it's up to you. Feel free to change it up.
    		*/ }
  .entry-content p {
    margin: 0 0 28px; }
    .entry-content p img {
      margin-bottom: 0; }
  .entry-content hr {
    border: 0;
    border-bottom: 1px solid #919191;
    clear: both; }
  .entry-content strong {
    font-weight: 600; }
  .entry-content table {
    width: 100%;
    border: 1px solid #eaedf2;
    margin-bottom: 1.5em; }
    .entry-content table caption {
      margin: 0 0 7px;
      font-size: 0.75em;
      color: #9fa6b4;
      text-transform: uppercase;
      letter-spacing: 1px; }
  .entry-content tr {
    border-bottom: 1px solid #eaedf2; }
    .entry-content tr:nth-child(even) {
      background-color: #f8f9fa; }
  .entry-content td {
    padding: 7px;
    border-right: 1px solid #eaedf2; }
    .entry-content td:last-child {
      border-right: 0; }
  .entry-content th {
    background-color: #f8f9fa;
    border-bottom: 1px solid #eaedf2;
    border-right: 1px solid #eaedf2; }
    .entry-content th:last-child {
      border-right: 0; }
  .entry-content blockquote {
    margin: 0 0 1.5em 0.75em;
    padding: 0 0 0 0.75em;
    border-left: 3px solid black;
    font-style: normal;
    color: #9fa6b4; }
  .entry-content dd {
    margin-left: 0;
    font-size: 0.9em;
    color: #787878;
    margin-bottom: 1.5em; }
  .entry-content img {
    margin: 0 0 1.5em 0;
    max-width: 100%;
    height: auto; }
  .entry-content .more-toggle {
    position: relative !important;
    margin: 35px auto 5px auto; }
  .entry-content .more-link {
    display: block;
    width: fit-content;
    height: unset;
    border: 1px solid black;
    font-family: PPEditorialNew-Regular, sans-serif;
    padding: .618em 2.5rem;
    cursor: pointer;
    font-size: 2.3rem;
    color: black;
    text-decoration: none;
    text-align: left;
    transition: background-color 0.5s ease-out, color 0.5s ease-out; }
    .entry-content .more-link:visited {
      color: black; }
    .entry-content .more-link:hover {
      color: black; }
    .entry-content .more-link:active {
      color: black; }
    .entry-content .more-link:hover {
      background-color: black;
      color: #fff;
      transition: background-color 0.5s ease-in, color 0.5s ease-in; }
    .entry-content .more-link:after {
      font-family: 'anna-kopp-webfont';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e604";
      font-size: 1.5rem;
      margin-left: 15px; }
  .entry-content .size-auto,
  .entry-content .size-full,
  .entry-content .size-large,
  .entry-content .size-medium,
  .entry-content .size-thumbnail {
    max-width: 100%;
    height: auto; }
  .entry-content pre {
    background: black;
    color: #f8f9fa;
    font-size: 0.9em;
    padding: 1.5em;
    margin: 0 0 1.5em;
    border-radius: 3px; }
  .entry-content .images img {
    margin: 0; }
  .home .entry-content .images {
    text-align: right;
    float: right;
    width: 30%; }

/* end .entry-content */
.wp-caption {
  max-width: 100%;
  background: #eee;
  padding: 5px;
  /* images inside wp-caption */ }
  .wp-caption img {
    max-width: 100%;
    margin-bottom: 0;
    width: 100%; }
  .wp-caption p.wp-caption-text {
    font-size: 0.85em;
    margin: 4px 0 7px;
    text-align: center; }

/* end .wp-caption */
/* image gallery styles */
/* end .gallery */
/* gallery caption styles */
.tags {
  margin: 0; }

/******************************************************************
PAGE NAVI STYLES
******************************************************************/
.pagination,
.wp-prev-next {
  margin: 1.5em 0; }

.pagination {
  text-align: center; }
  .pagination ul {
    display: inline-block;
    background-color: #fff;
    white-space: nowrap;
    padding: 0;
    clear: both;
    border-radius: 3px; }
  .pagination li {
    padding: 0;
    margin: 0;
    float: left;
    display: inline;
    overflow: hidden;
    border-right: 1px solid #eaedf2; }
  .pagination a,
  .pagination span {
    margin: 0;
    text-decoration: none;
    padding: 0;
    line-height: 1em;
    font-size: 1em;
    font-weight: normal;
    padding: 0.75em;
    min-width: 1em;
    display: block;
    color: black; }
    .pagination a:hover, .pagination a:focus,
    .pagination span:hover,
    .pagination span:focus {
      background-color: #333333;
      color: #fff; }
  .pagination .current {
    cursor: default;
    color: black; }
    .pagination .current:hover, .pagination .current:focus {
      background-color: #fff;
      color: black; }

/* end .bones_page_navi */
/* fallback previous & next links */
.wp-prev-next .prev-link {
  float: left; }

.wp-prev-next .next-link {
  float: right; }

/* end .wp-prev-next */
/******************************************************************
COMMENT STYLES
******************************************************************/
/* h3 comment title */
#comments-title {
  padding: 0.75em;
  margin: 0;
  border-top: 1px solid #f8f9fa;
  /* number of comments span */ }

.commentlist {
  margin: 0;
  list-style-type: none; }

.comment {
  position: relative;
  clear: both;
  overflow: hidden;
  padding: 1.5em;
  border-bottom: 1px solid #f8f9fa;
  /* vcard */
  /* end .commentlist .vcard */
  /* end children */
  /* general comment classes */ }
  .comment .comment-author {
    padding: 7px;
    border: 0; }
  .comment .vcard {
    margin-left: 50px; }
    .comment .vcard cite.fn {
      font-weight: 700;
      font-style: normal; }
    .comment .vcard time {
      display: block;
      font-size: 0.9em;
      font-style: normal; }
      .comment .vcard time a {
        color: #9fa6b4;
        text-decoration: none; }
        .comment .vcard time a:hover {
          text-decoration: none;
          font-weight: bold; }
    .comment .vcard .avatar {
      position: absolute;
      left: 16px;
      border-radius: 50%; }
  .comment:last-child {
    margin-bottom: 0; }
  .comment .children {
    margin: 0;
    /* variations */
    /* change number for different depth */ }
  .comment[class*=depth-] {
    margin-top: 1.1em; }
  .comment.depth-1 {
    margin-left: 0;
    margin-top: 0; }
  .comment:not(.depth-1) {
    margin-top: 0;
    margin-left: 7px;
    padding: 7px; }
  .comment.odd {
    background-color: #fff; }
  .comment.even {
    background: #f8f9fa; }

/* comment meta */
/* comment content */
.comment_content p {
  margin: 0.7335em 0 1.5em;
  font-size: 1em;
  line-height: 1.5em; }

/* end .commentlist .comment_content */
/* comment reply link */
.comment-reply-link {
  font-size: 0.9em;
  float: right; }

/* end .commentlist .comment-reply-link */
/* edit comment link */
.comment-edit-link {
  font-style: normal;
  margin: 0 7px;
  text-decoration: none;
  font-size: 0.9em; }

/******************************************************************
COMMENT FORM STYLES
******************************************************************/
.comment-respond {
  padding: 1.5em;
  border-top: 1px solid #f8f9fa; }

#reply-title {
  margin: 0; }

.logged-in-as {
  color: #9fa6b4;
  font-style: normal;
  margin: 0; }
  .logged-in-as a {
    color: black; }

.comment-form-comment {
  margin: 1.5em 0 0.75em; }

.form-allowed-tags {
  padding: 1.5em;
  background-color: #f8f9fa;
  font-size: 0.9em; }

/* comment submit button */
#submit {
  float: right;
  font-size: 1em; }

/* comment form title */
#comment-form-title {
  margin: 0 0 1.1em; }

/* cancel comment reply link */
/* logged in comments */
/* allowed tags */
#allowed_tags {
  margin: 1.5em 10px 0.7335em 0; }

/* no comments */
.nocomments {
  margin: 0 20px 1.1em; }

/*********************
SIDEBARS & ASIDES
*********************/
.sidebar,
#top {
  width: 1000px;
  margin: 0 auto;
  padding: 100px 0 60px 0; }
  .page-kontakt .sidebar#sidebar1, .page-kontakt
  #top#sidebar1 {
    display: none; }
  .sidebar#sidebar1 img,
  #top#sidebar1 img {
    width: 243px !important;
    height: 351px !important; }
  .sidebar#sidebar1 a > span,
  #top#sidebar1 a > span {
    width: 243px;
    height: 351px;
    top: -168px; }
    @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      .sidebar#sidebar1 a > span,
      #top#sidebar1 a > span {
        top: -169px !important; } }
    .sidebar#sidebar1 a > span span,
    #top#sidebar1 a > span span {
      font-size: 1.8rem; }
  .sidebar ul li,
  #top ul li {
    /* deep nesting */ }
    .sidebar ul li:first-child,
    #top ul li:first-child {
      text-align: left; }
    .sidebar ul li:last-child,
    #top ul li:last-child {
      text-align: right; }
    .sidebar ul li a,
    #top ul li a {
      position: relative; }
      .sidebar ul li a img,
      #top ul li a img {
        border: 15px solid black;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        -ms-box-sizing: content-box;
        box-sizing: content-box; }
      .sidebar ul li a > span,
      #top ul li a > span {
        display: block;
        position: absolute;
        left: 20px;
        padding: 100px 30px 0 30px;
        text-align: center;
        transition-property: background-color;
        transition-duration: 0.2s;
        transition-timing-function: ease-out; }
        .sidebar ul li a > span:hover,
        #top ul li a > span:hover {
          background-color: rgba(0, 0, 0, 0.7);
          transition-property: background-color;
          transition-duration: 0.2s;
          transition-timing-function: ease-out; }
        .sidebar ul li a > span span,
        #top ul li a > span span {
          border: 2px solid black;
          padding: 0;
          width: 176px;
          height: 42px;
          display: block;
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
          color: black; }

#top li {
  height: 280px !important; }
  #top li a > span {
    padding: 90px 0px !important;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    width: 170px;
    height: 246px;
    top: -117px;
    background-color: rgba(0, 0, 0, 0.5); }
    #top li a > span:hover {
      background-color: rgba(0, 0, 0, 0.9) !important; }
    @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      #top li a > span {
        top: -117px !important; } }
  #top li img {
    width: 170px !important;
    height: 246px !important; }

#top li.current-menu-item span:after {
  display: block;
  width: 84px;
  height: 8px;
  content: "";
  margin: 10px auto 0px auto;
  position: relative; }

#top img {
  width: 100px !important; }

#top li {
  width: 25% !important; }

.widget ul li {
  /* deep nesting */ }

.no-widgets {
  display: none; }

/*********************
AFTER CONTENT MENU STYLES
*********************/
.widget_nav_menu {
  width: 100%;
  height: 32px;
  border: 1px solid black;
  text-align: center;
  color: black;
  margin: 0 0 45px 0;
  font-size: 2rem;
  line-height: 3rem; }
  .widget_nav_menu ul {
    margin: 0;
    padding: 0; }
  .widget_nav_menu li {
    display: inline-block;
    margin: 0 13px; }
    .widget_nav_menu li.current_page_item {
      font-weight: 700; }
  .widget_nav_menu a {
    color: black;
    text-decoration: none; }
    .widget_nav_menu a:visited {
      color: black; }
    .widget_nav_menu a:hover {
      color: black; }
    .widget_nav_menu a:active {
      color: black; }
    .widget_nav_menu a:hover {
      font-weight: 700; }

/*********************tig
ÖFFNUNGSZEITEN
*********************/
.oeffnungszeiten {
  margin: 0 auto 50px auto; }
  .oeffnungszeiten a {
    color: black;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%); }
    .oeffnungszeiten a:visited {
      color: black; }
    .oeffnungszeiten a:hover {
      color: black; }
    .oeffnungszeiten a:active {
      color: black; }
  .oeffnungszeiten .widgettitle {
    display: none; }
  .oeffnungszeiten .text {
    display: block;
    width: 250px;
    border: 1px solid black;
    height: 70px;
    text-align: center;
    font-size: 18px;
    line-height: 7rem;
    float: left; }
    .oeffnungszeiten .text:after {
      content: "";
      display: block;
      border-bottom: 1px solid black;
      margin: -23px auto 0 auto;
      width: 93%; }
  .oeffnungszeiten .icon {
    display: block;
    border: none;
    width: 70px;
    height: 70px;
    float: left;
    background-color: #5a333d;
    text-align: center;
    font-size: 3rem;
    line-height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; }

/*********************
Behandlungen Quatrate
*********************/
#menu-behandlungen-quatrate li {
  width: 33.3%; }

/*********************
Image Menu Styles
*********************/
#before-footer,
#menu-behandlungen-quatrate {
  margin-bottom: 50px; }
  #before-footer li#menu-item-204,
  #menu-behandlungen-quatrate li#menu-item-204 {
    display: none; }
  #before-footer li,
  #menu-behandlungen-quatrate li {
    float: left;
    position: relative;
    overflow: hidden; }
    #before-footer li a,
    #menu-behandlungen-quatrate li a {
      color: #fff;
      overflow: hidden;
      width: 100%;
      word-break: break-all;
      hyphens: auto;
      text-align: center; }
      #before-footer li a > span,
      #menu-behandlungen-quatrate li a > span {
        display: block;
        position: absolute;
        width: 100%;
        top: 40px;
        font-family: PPEditorialNew-Regular, sans-serif;
        font-size: 1.5rem;
        word-break: break-all;
        hyphens: auto;
        text-align: center;
        font-weight: 900;
        font-style: normal;
        z-index: 10;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); }
        #before-footer li a > span span.more-button,
        #menu-behandlungen-quatrate li a > span span.more-button {
          display: none; }
      #before-footer li a .mobile_hide,
      #menu-behandlungen-quatrate li a .mobile_hide {
        font-size: 3.5rem; }
  #before-footer img,
  #menu-behandlungen-quatrate img {
    width: 100% !important;
    height: auto !important;
    transition: all 2s ease-out;
    margin: 0; }

/*********************
FOOTER STYLES
*********************/
.footer {
  border-top: 1px solid black; }
  .footer .row1 {
    padding: 23px 55px;
    color: black; }
    .footer .row1 h4 {
      font-size: 2rem;
      line-height: 2rem;
      font-family: PPEditorialNew-Regular, sans-serif;
      font-style: normal;
      font-weight: 400;
      margin: 0 0 10px 0; }
    .footer .row1 .widget_text {
      font-size: 16px;
      line-height: 3rem; }
      .footer .row1 .widget_text p {
        margin: 0; }
      .footer .row1 .widget_text span {
        font-size: 50%;
        vertical-align: super; }
    .footer .row1 .center {
      text-align: center;
      position: relative;
      margin-top: 100px; }
      .footer .row1 .center .ak-sign {
        position: absolute;
        left: 50%;
        top: -48px;
        width: 140px;
        margin-left: -38px;
        display: block;
        padding: 7px 15px;
        text-decoration: none;
        background-color: #fff;
        font-size: 4.7rem;
        color: black; }
        .footer .row1 .center .ak-sign:visited {
          color: black; }
        .footer .row1 .center .ak-sign:hover {
          color: #333333; }
        .footer .row1 .center .ak-sign:active {
          color: black; }
      .footer .row1 .center .gototop {
        position: relative;
        display: block;
        height: 31px;
        width: 133px;
        margin: 50px auto 0 auto;
        border: 1px solid #a2a2a2;
        background-color: #fff;
        font-family: PPEditorialNew-Regular, sans-serif;
        font-size: 2rem;
        color: #a2a2a2;
        text-decoration: none; }
        .footer .row1 .center .gototop:visited {
          color: #a2a2a2; }
        .footer .row1 .center .gototop:hover {
          color: black; }
        .footer .row1 .center .gototop:active {
          color: #a2a2a2; }
        .footer .row1 .center .gototop:hover {
          border-color: black; }
        .footer .row1 .center .gototop:before {
          position: absolute;
          font-size: 5.1rem;
          width: 15px;
          left: 59px;
          top: -26px;
          z-index: -1;
          font-family: 'anna-kopp-webfont';
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e606"; }
    .footer .row1 #facebook {
      text-align: center;
      margin-top: 10px; }
      .footer .row1 #facebook h4 {
        text-align: center; }
      .footer .row1 #facebook a {
        display: inline-block;
        width: 76px;
        height: 76px;
        text-decoration: none;
        font-size: 7.2rem;
        line-height: 7rem;
        color: black; }
        .footer .row1 #facebook a:visited {
          color: black; }
        .footer .row1 #facebook a:hover {
          color: #333333; }
        .footer .row1 #facebook a:active {
          color: black; }
  .footer .row2 {
    background-color: #eae1de;
    color: black;
    padding: 0 33px;
    text-transform: uppercase;
    clear: both; }
    .footer .row2 .widgettitle {
      display: none; }
    .footer .row2 .widget_text {
      padding: 1em 0;
      font-size: 1.6rem;
      line-height: 1.25em; }
    .footer .row2 .widget_search {
      margin-top: 0px;
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 20px; }
      .footer .row2 .widget_search ::-webkit-input-placeholder {
        color: #fff; }
      .footer .row2 .widget_search :-moz-placeholder {
        color: #fff; }
      .footer .row2 .widget_search ::-moz-placeholder {
        color: #fff; }
      .footer .row2 .widget_search :-ms-input-placeholder {
        color: #fff; }
      .footer .row2 .widget_search label {
        display: none; }
      .footer .row2 .widget_search form {
        position: relative; }
      .footer .row2 .widget_search input#s {
        background-color: black;
        border: 1px solid #fff;
        border-radius: 0;
        height: 31px;
        width: 178px;
        font-size: 1.5rem;
        line-height: 3rem;
        text-transform: uppercase;
        color: #fff;
        float: right;
        padding-right: 30px; }
        .footer .row2 .widget_search input#s:focus {
          outline: none; }
      .footer .row2 .widget_search #searchsubmit {
        position: absolute;
        top: 0;
        right: 3px;
        width: 30px;
        height: 30px;
        background: none;
        border: 0;
        color: #fff;
        font-size: 1.7rem;
        font-family: 'anna-kopp-webfont';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .footer .row2 address {
      font-style: normal; }
    .footer .row2 a {
      color: black;
      text-decoration: none; }
      .footer .row2 a:hover {
        font-weight: bold;
        color: #5a333d; }
  .footer nav {
    margin: 20px 0 40px 0;
    text-align: center; }
    .footer nav li {
      margin: 0 7px;
      display: inline-block;
      font-size: 1.6rem;
      color: black;
      text-transform: uppercase; }
      .footer nav li a {
        color: black;
        text-decoration: none; }
        .footer nav li a:visited {
          color: black; }
        .footer nav li a:hover {
          color: black; }
        .footer nav li a:active {
          color: black; }

.kamillen_klein,
.kamillen_gross {
  position: absolute; }

.kamillen_klein {
  top: -130px;
  left: 0; }

.kamillen_gross {
  top: -250px;
  right: -60px; }

#content nf-form-content button, #content .nf-form-content input[type="button"], #content .nf-form-content input[type="submit"] {
  background: #5a333d;
  border: 0;
  color: #fff;
  transition: all .5s; }
  #content nf-form-content button:hover, #content .nf-form-content input[type="button"]:hover, #content .nf-form-content input[type="submit"]:hover {
    background: #5a333d;
    color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5) 3px 3px 3px #888; }

#content .gwolle-gb .button.btn.btn-default, #content .gwolle_gb_submit.button.btn.btn-primary {
  margin: 0;
  line-height: 1;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  overflow: visible;
  padding: .618em 1em;
  left: auto;
  display: inline-block;
  background-color: #eae1de;
  box-shadow: none;
  text-shadow: none;
  color: #000;
  border: unset;
  transition: .5s;
  font-size: 20px; }

#content .gwolle-gb form.gwolle-gb-write {
  margin-bottom: 30px; }

#content .gwolle-gb div.input input[type="text"], #content .gwolle-gb div.input input[type="email"], #content .gwolle-gb div.input input[type="url"], #content .gwolle-gb div.input textarea, #content .gwolle-gb div.input select {
  width: 99%;
  border: 2px solid #5a333d;
  font-size: 1.6rem; }

#content .gwolle-gb .button.btn.btn-default:hover {
  background-color: #eae1de; }

#content .gwolle-gb .gwolle-gb-write-button {
  margin-top: 0em;
  margin-bottom: 2em;
  text-align: center; }

#content .gwolle-gb .gb-entry:not(.gb-entry-count_1) {
  border-top: 2px dotted black !important; }

#content .gwolle-gb .gb-entry {
  padding: 20 0 !important; }

#content .gwolle-gb form.gwolle-gb-write button.gb-notice-dismiss {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: #fff;
  background-color: #5a333d;
  display: inline-block;
  speak: none;
  line-height: 16px;
  height: auto;
  width: auto;
  text-align: center;
  font-size: 16px;
  padding: 6px 10px 10px; }

#content .gwolle_gb_messages {
  border: unset;
  font-size: 1.6rem;
  background: #000;
  color: #fff;
  padding: 2em; }

#content .gwolle_gb_messages p {
  font-size: 1.6rem;
  margin-bottom: 0; }

#content .gwolle-gb {
  margin-bottom: 3em; }

#content .slick-slider li, #content .slick-slider ul {
  text-align: center !important; }

#instagram-feed #sb_instagram .sbi_follow_btn a, #instagram-feed #sb_instagram .sbi_load_btn {
  color: black;
  background-color: #eae1de; }
  #instagram-feed #sb_instagram .sbi_follow_btn a:hover, #instagram-feed #sb_instagram .sbi_load_btn:hover {
    background-color: #5a333d;
    color: #fff;
    box-shadow: unset; }

/*********************
LARGER MOBILE DEVICES
This is for devices like the Galaxy Note or something that's
larger than an iPhone but smaller than a tablet. Let's call them
tweeners.
*********************/
@media only screen and (min-width: 481px) {
  /******************************************************************
Site Name:
Author:

Stylesheet: 481px and Up Stylesheet

This stylesheet is loaded for larger devices. It's set to
481px because at 480px it would load on a landscaped iPhone.
This isn't ideal because then you would be loading all those
extra styles on that same mobile connection.

A word of warning. This size COULD be a larger mobile device,
so you still want to keep it pretty light and simply expand
upon your base.scss styles.

******************************************************************/
  /*
IMPORTANT NOTE ABOUT SASS 3.3 & UP
You can't use @extend within media queries
anymore, so just be aware that if you drop
them in here, they won't work.
*/
  /*********************
NAVIGATION STYLES
*********************/
  /* .menu is clearfixed inside mixins.scss */
  .menu {
    /* end .menu ul */ }
    .menu ul {
      /* end .menu ul li */
      /* highlight current page */
      /* end current highlighters */ }
      .menu ul li {
        /*
				plan your menus and drop-downs wisely.
				*/ }
        .menu ul li a {
          /*
					you can use hover styles here even though this size
					has the possibility of being a mobile device.
					*/ }
  /* end .menu */
  /*********************
POSTS & CONTENT STYLES
*********************/
  /* entry content */
  .entry-content {
    /* at this larger size, we can start to align images */ }
    .entry-content .alignleft, .entry-content img.alignleft {
      margin-right: 1.5em;
      display: inline;
      float: left; }
    .entry-content .alignright, .entry-content img.alignright {
      margin-left: 1.5em;
      display: inline;
      float: right; }
    .entry-content .aligncenter, .entry-content img.aligncenter {
      margin-right: auto;
      margin-left: auto;
      display: block;
      clear: both; }
  /* end .entry-content */
  /*********************
FOOTER STYLES
*********************/
  /*
check your menus here. do they look good?
do they need tweaking?
*/
  /* end .footer-links */ }

/*********************
TABLET & SMALLER LAPTOPS
This is the average viewing window. So Desktops, Laptops, and
in general anyone not viewing on a mobile device. Here's where
you can add resource intensive styles.
*********************/
@media only screen and (min-width: 768px) {
  /******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet

Here's where you can start getting into the good stuff.
This size will work on iPads, other tablets, and desktops.
So you can start working with more styles, background images,
and other resources. You'll also notice the grid starts to
come into play. Have fun!

******************************************************************/
  /*********************
GENERAL STYLES
*********************/
  /*********************
LAYOUT & GRID STYLES
*********************/
  .wrap {
    max-width: 1400px;
    margin: 0 auto; }
  #content {
    width: 100%;
    min-width: 1000px; }
  /*********************
HEADER STYLES
*********************/
  /*********************
NAVIGATION STYLES
*********************/
  .nav {
    border: 0;
    /* end .menu ul li */
    /* highlight current page */
    /* end current highlighters */ }
    .nav ul {
      background: #eae1de;
      margin-top: 5px;
      padding: 18px; }
    .nav li {
      /*
		plan your menus and drop-downs wisely.
		*/
      /* showing sub-menus */ }
      .nav li a {
        border-bottom: 0;
        /*
			you can use hover styles here even though this size
			has the possibility of being a mobile device.
			*/ }
        .nav li a:hover, .nav li a:focus {
          text-decoration: none;
          color: #5a333d; }
      .nav li ul.sub-menu,
      .nav li ul.children {
        margin-top: 0;
        border-top: 0;
        position: absolute;
        visibility: hidden;
        z-index: 8999; }
        .nav li ul.sub-menu li,
        .nav li ul.children li {
          margin: 2px 0;
          /*
				if you need to go deeper, go nuts
				just remember deeper menus suck
				for usability. k, bai.
				*/ }
          .nav li ul.sub-menu li a,
          .nav li ul.children li a {
            padding-left: 10px;
            padding-right: 10px;
            border-right: 0;
            display: block;
            color: black;
            font-weight: 400;
            font-size: 1.7rem; }
          .nav li ul.sub-menu li:last-child a,
          .nav li ul.children li:last-child a {
            border-bottom: 0; }
          .nav li ul.sub-menu li ul,
          .nav li ul.children li ul {
            top: 0;
            left: 100%; }
      .nav li:hover > ul {
        top: auto;
        visibility: visible; }
  /* end .nav */
  /*********************
SIDEBARS & ASIDES
*********************/
  .sidebar {
    margin-top: 2.2em; }
  .widget ul li {
    margin-bottom: 0.75em;
    /* deep nesting */ }
    .widget ul li ul {
      margin-top: 0.75em;
      padding-left: 1em; }
  /* links widget */
  /* meta widget */
  /* pages widget */
  /* recent-posts widget */
  /* archives widget */
  /* tag-cloud widget */
  /* calendar widget */
  /* category widget */
  /* recent-comments widget */
  /* search widget */
  /* text widget */
  /*********************
FOOTER STYLES
*********************/
  /*
you'll probably need to do quite a bit
of overriding here if you styled them for
mobile. Make sure to double check these!
*/
  .footer-links ul li {
    /*
			be careful with the depth of your menus.
			it's very rare to have multi-depth menus in
			the footer.
			*/ }
  /* end .footer-links */ }

/*********************
DESKTOP
This is the average viewing window. So Desktops, Laptops, and
in general anyone not viewing on a mobile device. Here's where
you can add resource intensive styles.
*********************/
@media only screen and (min-width: 1030px) {
  /******************************************************************
Site Name:
Author:

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop.

******************************************************************/
  /*********************
HEADER
*********************/
  #topslider {
    max-height: 100%;
    max-width: 2500px;
    margin: auto; }
  /*********************
CONTENT
*********************/
  .entry-content {
    padding: 0;
    font-size: 1.9rem;
    line-height: 1.25em;
    position: relative; }
    .entry-content .text-content {
      width: 100%;
      margin: 0 auto; }
    .entry-content p img {
      margin-bottom: 28px; }
  #container {
    padding-top: 133px; }
  /*********************
HEADER INNER
*********************/
  .header {
    height: 134px; }
    .header nav {
      position: absolute;
      top: 94px;
      transition: all .3s ease; }
      .header nav a:hover {
        text-decoration: underline;
        text-underline-offset: 3px; }
    .header #logo {
      width: 260px;
      max-width: 260px;
      margin: 30px auto 0 auto; }
    .header nav {
      display: block; }
    .header #showMenu {
      display: none !important; }
    .header.sticky {
      height: 100px;
      max-height: 100px; }
      .header.sticky #logo {
        max-width: 200px; }
  #content .inner {
    width: 90vw; }
  .single-title,
  .page-title,
  .entry-title,
  .archive-title {
    font-size: 4rem;
    line-height: 5rem; }
  /*********************
SIDEBARS
*********************/
  .sidebar ul li,
  #top ul li {
    width: 33%;
    float: left;
    height: 390px;
    position: relative;
    width: 33%;
    text-align: center; }
  /*********************tig
ÖFFNUNGSZEITEN
*********************/
  .oeffnungszeiten {
    margin: 0 auto 50px auto; }
    .oeffnungszeiten a {
      display: inline;
      position: static;
      transform: none; }
    .oeffnungszeiten .widgettitle {
      display: none; }
    .oeffnungszeiten .text {
      display: block;
      width: 375px;
      border: 1px solid black;
      height: 90px;
      text-align: center;
      font-size: 3.1rem;
      line-height: 9rem;
      float: left; }
      .oeffnungszeiten .text:after {
        content: "";
        display: block;
        border-bottom: 1px solid black;
        margin: -23px auto 0 auto;
        width: 93%; }
    .oeffnungszeiten .icon {
      display: block;
      border: none;
      width: 90px;
      height: 90px;
      float: right;
      background-color: #5a333d;
      text-align: center;
      font-size: 4.5rem;
      line-height: 9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff; }
  /*********************
Behandlungen Quatrate
*********************/
  #before-footer,
  #menu-behandlungen-quatrate {
    margin-bottom: 50px; }
    #before-footer li#menu-item-204,
    #menu-behandlungen-quatrate li#menu-item-204 {
      display: block; }
    #before-footer li,
    #menu-behandlungen-quatrate li {
      position: relative;
      overflow: hidden; }
      #before-footer li.col-1-2,
      #menu-behandlungen-quatrate li.col-1-2 {
        width: 50%; }
      #before-footer li.col-2-2,
      #menu-behandlungen-quatrate li.col-2-2 {
        width: 100%; }
      #before-footer li:hover img,
      #menu-behandlungen-quatrate li:hover img {
        transform: scale(1.03);
        transition: all 1s ease-in; }
      #before-footer li a,
      #menu-behandlungen-quatrate li a {
        color: #fff;
        overflow: hidden;
        width: 100%;
        word-break: break-all;
        hyphens: auto;
        text-align: center; }
        #before-footer li a > span,
        #menu-behandlungen-quatrate li a > span {
          top: 70px;
          font-size: 5.5rem; }
          #before-footer li a > span span.more-button,
          #menu-behandlungen-quatrate li a > span span.more-button {
            display: block;
            position: absolute;
            bottom: -70px;
            width: 200px;
            height: 50px;
            font-family: PPEditorialNew-Regular, sans-serif;
            margin-left: -100px;
            left: 50%;
            padding-left: 15px;
            background-color: #fff;
            font-size: 2.3rem;
            line-height: 5rem;
            color: black;
            text-shadow: none;
            text-decoration: none;
            font-style: normal;
            text-align: left;
            font-weight: normal;
            transition: background-color 0.5s ease-out, color 0.5s ease-out; }
            #before-footer li a > span span.more-button:visited,
            #menu-behandlungen-quatrate li a > span span.more-button:visited {
              color: black; }
            #before-footer li a > span span.more-button:hover,
            #menu-behandlungen-quatrate li a > span span.more-button:hover {
              color: black; }
            #before-footer li a > span span.more-button:active,
            #menu-behandlungen-quatrate li a > span span.more-button:active {
              color: black; }
            #before-footer li a > span span.more-button:hover,
            #menu-behandlungen-quatrate li a > span span.more-button:hover {
              background-color: black;
              color: #fff;
              transition: background-color 0.5s ease-in, color 0.5s ease-in; }
            #before-footer li a > span span.more-button:after,
            #menu-behandlungen-quatrate li a > span span.more-button:after {
              font-family: 'anna-kopp-webfont';
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              /* Better Font Rendering =========== */
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\e604";
              font-size: 1.5rem;
              margin-left: 15px; }
  /*********************
ÖFFNUNGSZEITEN
*********************/
  .oeffnungszeiten {
    width: 475px; }
  /*********************
FOOTER
*********************/
  .footer #inner-footer {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    width: 90vw; }
    .footer #inner-footer .row1 {
      height: 180px; }
      .footer #inner-footer .row1 .widget_text {
        width: 33%;
        float: left;
        font-size: 1.8rem; }
      .footer #inner-footer .row1 .center {
        width: 33%;
        float: left;
        margin-top: 0; }
      .footer #inner-footer .row1 #facebook {
        width: 33%;
        float: right;
        text-align: right; }
        .footer #inner-footer .row1 #facebook h4 {
          font-size: 3rem;
          line-height: 3rem;
          float: left;
          text-align: right;
          width: 266px; }
    .footer #inner-footer .row2 {
      height: 76px; }
      .footer #inner-footer .row2 .widget_text {
        width: 100%;
        float: left;
        padding: 0;
        line-height: 7rem;
        text-align: center; }
      .footer #inner-footer .row2 .widget_search {
        width: 20%;
        float: right;
        display: inline;
        position: static;
        transform: none;
        margin-bottom: 0;
        margin-top: 20px; }
  .woocommerce div.product,
  .woocommerce #content div.product,
  .woocommerce-page div.product,
  .woocommerce-page #content div.product {
    position: relative; }
    .woocommerce div.product div.images,
    .woocommerce #content div.product div.images,
    .woocommerce-page div.product div.images,
    .woocommerce-page #content div.product div.images {
      width: 33.33%; }
      .woocommerce div.product div.images a,
      .woocommerce #content div.product div.images a,
      .woocommerce-page div.product div.images a,
      .woocommerce-page #content div.product div.images a {
        display: block; }
        .woocommerce div.product div.images a img,
        .woocommerce #content div.product div.images a img,
        .woocommerce-page div.product div.images a img,
        .woocommerce-page #content div.product div.images a img {
          height: 580px;
          width: 400px;
          object-fit: contain;
          padding: 20px; }
    .woocommerce div.product .flex-row,
    .woocommerce #content div.product .flex-row,
    .woocommerce-page div.product .flex-row,
    .woocommerce-page #content div.product .flex-row {
      display: flex;
      flex-wrap: wrap; }
      .woocommerce div.product .flex-row .flex-col,
      .woocommerce #content div.product .flex-row .flex-col,
      .woocommerce-page div.product .flex-row .flex-col,
      .woocommerce-page #content div.product .flex-row .flex-col {
        margin-right: 10px; }
      .woocommerce div.product .flex-row .quantity,
      .woocommerce #content div.product .flex-row .quantity,
      .woocommerce-page div.product .flex-row .quantity,
      .woocommerce-page #content div.product .flex-row .quantity {
        margin: 0; }
    .woocommerce div.product div.summary,
    .woocommerce #content div.product div.summary,
    .woocommerce-page div.product div.summary,
    .woocommerce-page #content div.product div.summary {
      width: 66.66%;
      position: relative; }
      .woocommerce div.product div.summary label,
      .woocommerce #content div.product div.summary label,
      .woocommerce-page div.product div.summary label,
      .woocommerce-page #content div.product div.summary label {
        font-weight: 300; }
      .woocommerce div.product div.summary input,
      .woocommerce div.product div.summary select,
      .woocommerce #content div.product div.summary input,
      .woocommerce #content div.product div.summary select,
      .woocommerce-page div.product div.summary input,
      .woocommerce-page div.product div.summary select,
      .woocommerce-page #content div.product div.summary input,
      .woocommerce-page #content div.product div.summary select {
        width: 100%; }
      .woocommerce div.product div.summary form,
      .woocommerce #content div.product div.summary form,
      .woocommerce-page div.product div.summary form,
      .woocommerce-page #content div.product div.summary form {
        margin: 0; }
      .woocommerce div.product div.summary .pricebox,
      .woocommerce div.product div.summary .variations,
      .woocommerce div.product div.summary .single_add_to_cart_button,
      .woocommerce #content div.product div.summary .pricebox,
      .woocommerce #content div.product div.summary .variations,
      .woocommerce #content div.product div.summary .single_add_to_cart_button,
      .woocommerce-page div.product div.summary .pricebox,
      .woocommerce-page div.product div.summary .variations,
      .woocommerce-page div.product div.summary .single_add_to_cart_button,
      .woocommerce-page #content div.product div.summary .pricebox,
      .woocommerce-page #content div.product div.summary .variations,
      .woocommerce-page #content div.product div.summary .single_add_to_cart_button {
        position: absolute;
        bottom: 0;
        margin: 0; }
      .woocommerce div.product div.summary .quantity,
      .woocommerce #content div.product div.summary .quantity,
      .woocommerce-page div.product div.summary .quantity,
      .woocommerce-page #content div.product div.summary .quantity {
        width: 80px; }
        .woocommerce div.product div.summary .quantity::before,
        .woocommerce #content div.product div.summary .quantity::before,
        .woocommerce-page div.product div.summary .quantity::before,
        .woocommerce-page #content div.product div.summary .quantity::before {
          content: "Anzahl";
          display: block; }
      .woocommerce div.product div.summary .variations,
      .woocommerce #content div.product div.summary .variations,
      .woocommerce-page div.product div.summary .variations,
      .woocommerce-page #content div.product div.summary .variations {
        display: block;
        width: 36%;
        left: 11%; }
        .woocommerce div.product div.summary .variations .reset_variations,
        .woocommerce #content div.product div.summary .variations .reset_variations,
        .woocommerce-page div.product div.summary .variations .reset_variations,
        .woocommerce-page #content div.product div.summary .variations .reset_variations {
          position: absolute;
          bottom: -1em; }
        .woocommerce div.product div.summary .variations td,
        .woocommerce div.product div.summary .variations tr,
        .woocommerce div.product div.summary .variations tbody,
        .woocommerce #content div.product div.summary .variations td,
        .woocommerce #content div.product div.summary .variations tr,
        .woocommerce #content div.product div.summary .variations tbody,
        .woocommerce-page div.product div.summary .variations td,
        .woocommerce-page div.product div.summary .variations tr,
        .woocommerce-page div.product div.summary .variations tbody,
        .woocommerce-page #content div.product div.summary .variations td,
        .woocommerce-page #content div.product div.summary .variations tr,
        .woocommerce-page #content div.product div.summary .variations tbody {
          display: block;
          width: 100%;
          padding: 0; }
      .woocommerce div.product div.summary .single_add_to_cart_button,
      .woocommerce #content div.product div.summary .single_add_to_cart_button,
      .woocommerce-page div.product div.summary .single_add_to_cart_button,
      .woocommerce-page #content div.product div.summary .single_add_to_cart_button {
        width: 28%;
        right: 24%; }
      .woocommerce div.product div.summary .pricebox,
      .woocommerce #content div.product div.summary .pricebox,
      .woocommerce-page div.product div.summary .pricebox,
      .woocommerce-page #content div.product div.summary .pricebox {
        right: 0;
        width: 21%; }
        .woocommerce div.product div.summary .pricebox .price,
        .woocommerce #content div.product div.summary .pricebox .price,
        .woocommerce-page div.product div.summary .pricebox .price,
        .woocommerce-page #content div.product div.summary .pricebox .price {
          color: #fff; }
          .woocommerce div.product div.summary .pricebox .price .woocommerce-Price-amount,
          .woocommerce #content div.product div.summary .pricebox .price .woocommerce-Price-amount,
          .woocommerce-page div.product div.summary .pricebox .price .woocommerce-Price-amount,
          .woocommerce-page #content div.product div.summary .pricebox .price .woocommerce-Price-amount {
            color: black; }
          .woocommerce div.product div.summary .pricebox .price .woocommerce-Price-amount + .woocommerce-Price-amount bdi,
          .woocommerce #content div.product div.summary .pricebox .price .woocommerce-Price-amount + .woocommerce-Price-amount bdi,
          .woocommerce-page div.product div.summary .pricebox .price .woocommerce-Price-amount + .woocommerce-Price-amount bdi,
          .woocommerce-page #content div.product div.summary .pricebox .price .woocommerce-Price-amount + .woocommerce-Price-amount bdi {
            display: none; }
          .woocommerce div.product div.summary .pricebox .price .woocommerce-Price-amount + .woocommerce-Price-amount::before,
          .woocommerce #content div.product div.summary .pricebox .price .woocommerce-Price-amount + .woocommerce-Price-amount::before,
          .woocommerce-page div.product div.summary .pricebox .price .woocommerce-Price-amount + .woocommerce-Price-amount::before,
          .woocommerce-page #content div.product div.summary .pricebox .price .woocommerce-Price-amount + .woocommerce-Price-amount::before {
            content: "ab ";
            color: black;
            float: left;
            font-size: 14px;
            line-height: 1;
            margin: 0;
            text-indent: 0;
            display: inline-block;
            position: absolute;
            display: block;
            width: 100%;
            height: auto;
            top: -9px; }
        .woocommerce div.product div.summary .pricebox .wc-gzd-additional-info,
        .woocommerce #content div.product div.summary .pricebox .wc-gzd-additional-info,
        .woocommerce-page div.product div.summary .pricebox .wc-gzd-additional-info,
        .woocommerce-page #content div.product div.summary .pricebox .wc-gzd-additional-info {
          line-height: 1;
          height: 1em; }
          .woocommerce div.product div.summary .pricebox .wc-gzd-additional-info.shipping-costs-info,
          .woocommerce #content div.product div.summary .pricebox .wc-gzd-additional-info.shipping-costs-info,
          .woocommerce-page div.product div.summary .pricebox .wc-gzd-additional-info.shipping-costs-info,
          .woocommerce-page #content div.product div.summary .pricebox .wc-gzd-additional-info.shipping-costs-info {
            display: block; }
      .woocommerce div.product div.summary .product_meta .infos,
      .woocommerce #content div.product div.summary .product_meta .infos,
      .woocommerce-page div.product div.summary .product_meta .infos,
      .woocommerce-page #content div.product div.summary .product_meta .infos {
        position: absolute;
        left: -50%;
        top: 400px;
        transform: rotate(-90deg);
        transform-origin: 0 0; }
  .woocommerce #content div.product div.images,
  .woocommerce div.product div.images,
  .woocommerce-page #content div.product div.images,
  .woocommerce-page div.product div.images {
    width: 400px; }
  #content ul {
    padding-left: 5px; } }

/*********************
LARGE VIEWING SIZE‚
This is for the larger monitors and possibly full screen viewers.
*********************/
@media only screen and (min-width: 1240px) {
  /******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
  .wrap {
    width: 1400px; }
  /*********************
UNDERSLIDER
*********************/
  #underslider .widget_rpswidget {
    width: 1400px; }
  .entry-content .more-link {
    position: absolute;
    margin-left: -105px;
    left: 44%; }
  /*********************
Sidebar
*********************/ }

/*********************
RETINA (2x RESOLUTION DEVICES)
This applies to the retina iPhone (4s) and iPad (2,3) along with
other displays with a 2x resolution. You can also create a media
query for retina AND a certain size if you want. Go Nuts.
*********************/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  /******************************************************************
Site Name: 
Author: 

Stylesheet: Retina Screens & Devices Stylesheet

When handling retina screens you need to make adjustments, especially
if you're not using font icons. Here you can add them in one neat
place.

******************************************************************/
  /* 

EXAMPLE 
Let's say you have an image and you need to make sure it looks ok
on retina screens. Let's say we have an icon which dimension are
24px x 24px. In your regular stylesheets, it would look something
like this:

.icon {
	width: 24px;
	height: 24px;
	background: url(img/test.png) no-repeat;
}

For retina screens, we have to make some adjustments, so that image
doesn't look blurry. So, taking into account the image above and the
dimensions, this is what we would put in our retina stylesheet:

.icon {
	background: url(img/test@2x.png) no-repeat;
	background-size: 24px 24px;
}

So, you would create the same icon, but at double the resolution, meaning 
it would be 48px x 48px. You'd name it the same, but with a @2x at the end
(this is pretty standard practice). Set the background image so it matches
the original dimensions and you are good to go. 

*/ }

/*********************
PRINT STYLESHEET
Feel free to customize this. Remember to add things that won't make
sense to print at the bottom. Things like nav, ads, and forms should
be set to display none.
*********************/
@media print {
  /******************************************************************
Site Name:
Author:

Stylesheet: Print Stylesheet

This is the print stylesheet. There's probably not a lot
of reasons to edit this stylesheet. If you want to
though, go for it.

******************************************************************/
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  a,
  a:visited {
    color: #444 !important;
    text-decoration: underline; }
    a:after, a:visited:after {
      content: " (" attr(href) ")"; }
    a abbr[title]:after, a:visited abbr[title]:after {
      content: " (" attr(title) ")"; }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .sidebar,
  .page-navigation,
  .wp-prev-next,
  .respond-form,
  nav {
    display: none; } }
