$pink: #5a333d;

/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
@mixin clearfix {
    *zoom: 1;

    &::before,
    &::after {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius($radius: 4px) {
    border-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_right($radius: 4px) {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_left($radius: 4px) {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_bottom($radius: 4px) {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_top($radius: 4px) {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin opacity($opacity: 0.75) {
    opacity: $opacity;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin box_shadow($shadow_x: 3px, $shadow_y: 3px, $shadow_rad: 3px, $shadow_in: 3px, $shadow_color: #888) {
    box-shadow: $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin inset_box_shadow($shadow_x: 3px, $shadow_y: 3px, $shadow_rad: 3px, $shadow_in: 3px, $shadow_color: #888) {
    box-shadow: inset $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin text_shadow($shadow_x: 3px, $shadow_y: 3px, $shadow_rad: 3px, $shadow_color: #fff) {
    text-shadow: $shadow_x $shadow_y $shadow_rad $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin vertical_gradient($from: #000, $to: #fff) {
    background-color: $from;
    background: -webkit-linear-gradient($from, $to);
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin transition($selector: all, $animation: ease-in-out, $duration: 0.2s) {
    transition: $selector $animation $duration;
}

/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
@mixin scale($ratio: 1.5) {
    -webkit-transform: scale($ratio);
    transform: scale($ratio);
}

/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
@mixin borderbox {
    box-sizing: border-box;
}

@mixin darkorlighttextshadow($a, $opacity: 0.8) {
    @if lightness($a) >= 65% {
        @include text_shadow(0, -1px, 0, rgba(0, 0, 0, $opacity));
    }
    @else {
        @include text_shadow(0, 1px, 0, rgba(255, 255, 255, $opacity));
    }
}

/**
 * Objects
 */
@mixin menu {
    @include clearfix;

    li {
        display: inline-block;
    }
}

@mixin mediaright {
    @include clearfix;

    img {
        float: right;
        height: auto;
    }
}

@mixin medialeft {
    @include clearfix;

    img {
        float: right;
        height: auto;
    }
}

@mixin ir {
    display: block;
    text-indent: -9999px;
    position: relative;
    height: 1em;
    width: 1em;
}

@mixin icon($glyph: "") {
    font-family: 'WooCommerce';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin: 0;
    text-indent: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    content: $glyph;
}

@mixin icon_dashicons($glyph: "") {
    font-family: 'Dashicons';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    text-indent: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    content: $glyph;
}

@mixin iconbefore($glyph: "") {
    font-family: 'WooCommerce';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: 0.618em;
    content: $glyph;
    text-decoration: none;
}

@mixin iconbeforedashicons($glyph: "") {
    font-family: 'Dashicons';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    content: $glyph;
    text-decoration: none;
}

@mixin iconafter($glyph: "") {
    font-family: 'WooCommerce';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-left: 0.618em;
    content: $glyph;
    text-decoration: none;
}

@mixin loader {
    &::before {
        height: 1em;
        width: 1em;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -0.5em;
        margin-top: -0.5em;
        content: '';
        animation: spin 1s ease-in-out infinite;
        background: url("../images/icons/loader.svg") center center;
        background-size: cover;
        line-height: 1;
        text-align: center;
        font-size: 2em;
        color: rgba(#000, 0.75);
    }
}

p.demo_store,
.woocommerce-store-notice {
    position: fixed;
    top: auto !important;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    font-size: 2em;
    padding: 1em 0;
    text-align: center;
    background-color: $primary;
    color: $primarytext;
    z-index: 99998;
    box-shadow: 0 1px 1em rgba(0, 0, 0, 0.2);
    display: block;

    a,
    a:visited {
        color: $primarytext;
        text-decoration: underline;
    }
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.admin-bar p.demo_store {
    top: auto !important;
}

/**
 * Utility classes
 */
.clear {
    clear: both;
}

/**
 * Main WooCommerce styles
 */
.woocommerce,
.woocommerce-page,
.xoo-wsc-modal,
.footer_btn,
.block_startseite {
    .blockUI.blockOverlay {
        position: relative;
    }

    .loader {}

    a.remove {
        display: block;
        font-size: 30px;
        height: 1em;
        width: 1em;
        text-align: center;
        line-height: 1.1;
        border-radius: 100%;
        color: $secondary !important; // Required for default theme compatibility
        text-decoration: none;
        font-weight: 700;
        border: 0;

        &:hover {
            color: #fff !important; // Required for default theme compatibility
            background: $secondary;
            font-size: 30px;
            font-weight: 300;
        }
    }

    small.note {
        display: block;
        color: $subtext;
        font-size: 0.857em;
        margin-top: 10px;
    }

    .woocommerce-breadcrumb {
        @include clearfix;

        margin: 0 0 1em;
        padding: 0;
        font-size: 1.1rem;
        color: $subtext;
        font-family: $mono;
        margin-bottom: 50px;

        @media screen and (max-width: 768px) {
            padding: 0 20px;
        }

        a {
            color: $subtext;
        }
    }

    .quantity .qty {
        font-size: 20px;
        text-align: center;
    }

    .quantity-label {
        display: none;
    }

    /**
     * Product Page
     */
    div.product {
        margin-bottom: 0;
        position: relative;

        .product_title {
            clear: none;
            margin-top: 0;
            padding: 0;
        }

        span.price,
        p.price {
            color: $highlight;
            font-size: 30px;
            letter-spacing: 0.01em;
            margin: 0;

            .woocommerce-Price-currencySymbol {
                margin-right: 0.2em;
            }

            ins {
                background: inherit;
                font-weight: 700;
                display: inline-block;
            }

            del {
                opacity: 0.5;
                display: inline-block;
            }
        }

        p.stock {
            font-size: 0.92em;
        }

        .stock {
            color: $highlight;
        }

        .out-of-stock {
            color: red;
        }

        .woocommerce-product-rating {
            margin-bottom: 1.618em;
        }

        div.images {
            margin-bottom: 2em;

            @media screen and (min-width: 768px) and (max-width: 1240px) {
                width: 31% !important;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
                box-shadow: none;

                @media screen and (max-width: 1030px) {
                    &.wp-post-image {
                        height: 300px !important;
                        width: auto;
                        margin: 0 auto 30px auto;
                    }
                }
            }

            div.thumbnails {
                padding-top: 1em;
            }

            &.woocommerce-product-gallery {
                position: relative;
            }

            .woocommerce-product-gallery__wrapper {
                transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
                margin: 0;
                padding: 0;
            }

            .woocommerce-product-gallery__wrapper .zoomImg {
                background-color: #fff;
                opacity: 0;
            }

            .woocommerce-product-gallery__image--placeholder {
                border: 1px solid #f2f2f2;
            }

            .woocommerce-product-gallery__image:nth-child(n+2) {
                width: 25%;
                display: inline-block;
            }

            .woocommerce-product-gallery__trigger {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
                font-size: 2em;
                z-index: 9;
                width: 36px;
                height: 36px;
                background: #fff;
                text-indent: -9999px;
                border-radius: 100%;
                box-sizing: content-box;

                &::before {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border: 2px solid $black;
                    border-radius: 100%;
                    position: absolute;
                    top: 9px;
                    left: 9px;
                    box-sizing: content-box;
                }

                &::after {
                    content: "";
                    display: block;
                    width: 2px;
                    height: 8px;
                    background: $black;
                    border-radius: 6px;
                    position: absolute;
                    top: 19px;
                    left: 22px;
                    transform: rotate(-45deg);
                    box-sizing: content-box;
                }
            }

            .flex-control-thumbs {
                overflow: hidden;
                zoom: 1;
                margin: 0;
                padding: 0;

                li {
                    width: 25%;
                    float: left;
                    margin: 0;
                    list-style: none;

                    img {
                        cursor: pointer;
                        opacity: 0.5;
                        margin: 0;

                        &.flex-active,
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .woocommerce-product-gallery--columns-3 {
            .flex-control-thumbs li:nth-child(3n+1) {
                clear: left;
            }
        }

        .woocommerce-product-gallery--columns-4 {
            .flex-control-thumbs li:nth-child(4n+1) {
                clear: left;
            }
        }

        .woocommerce-product-gallery--columns-5 {
            .flex-control-thumbs li:nth-child(5n+1) {
                clear: left;
            }
        }

        div.summary {
            margin-bottom: 2em;

            @media screen and (min-width: 768px) and (max-width: 1240px) {
                width: 65% !important;
            }

            @media screen and (max-width: 768px) {
                padding: 20px;
            }
        }

        div.social {
            text-align: right;
            margin: 0 0 1em;

            span {
                margin: 0 0 0 2px;

                span {
                    margin: 0;
                }

                .stButton .chicklets {
                    padding-left: 16px;
                    width: 0;
                }
            }

            iframe {
                float: left;
                margin-top: 3px;
            }
        }

        .woocommerce-tabs {
            ul.tabs {
                list-style: none;
                padding: 0 0 0 1em;
                margin: 0 0 1.618em;
                overflow: hidden;
                position: relative;

                li {
                    background-color: $secondary;
                    display: inline-block;
                    position: relative;
                    z-index: 0;
                    border-radius: 4px 4px 0 0;
                    margin: 0 -5px;
                    padding: 0 1em;
                    background: $primary;
                    border-left: 1px solid $black;
                    border-right: 1px solid $black;
                    border-top: 1px solid $black;
                    border-bottom: 0;
                    border-radius: 0;

                    a {
                        font-family: $mono;
                        display: inline-block;
                        padding: 0.5em 0;
                        font-weight: 700;
                        color: $black;
                        text-decoration: none;
                        font-weight: 300;
                        font-size: 14px;

                        &:hover {
                            text-decoration: none;
                            color: $white;
                        }

                        &:focus {
                            outline: 0;
                        }
                    }

                    &.active {
                        background: $contentbg;
                        z-index: 2;
                        border-bottom-color: $contentbg;
                        border-radius: 0;

                        a {
                            color: $black;
                            text-shadow: inherit;
                        }

                        &::before {
                            box-shadow: 2px 2px 0 $contentbg;
                            display: none;
                        }

                        &::after {
                            box-shadow: -2px 2px 0 $contentbg;
                            display: none;
                        }
                    }

                    &::before,
                    &::after {
                        border: 1px solid darken($secondary, 10%);
                        position: absolute;
                        bottom: -1px;
                        width: 5px;
                        height: 5px;
                        content: " ";
                        box-sizing: border-box;
                    }

                    &::before {
                        left: -5px;
                        border-bottom-right-radius: 4px;
                        border-width: 0 1px 1px 0;
                        box-shadow: 2px 2px 0 $secondary;
                        display: none;
                    }

                    &::after {
                        right: -5px;
                        border-bottom-left-radius: 4px;
                        border-width: 0 0 1px 1px;
                        box-shadow: -2px 2px 0 $secondary;
                        display: none;
                    }
                }

                &::before {
                    position: absolute;
                    content: " ";
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    border-bottom: none;
                    z-index: 1;
                }
            }

            .panel {
                margin: 0 0 2em;
                padding: 15px;
                border: 1px solid black;

                @media screen and (max-width: 768px) {
                    border-left-width: 0;
                    border-right-width: 0;
                }

                h2 {
                    font-size: 20px !important;
                    margin-bottom: 10px;
                }

                th {
                    font-size: 15px !important;
                }

                p {
                    line-height: 25px;
                    font-size: 15px;
                }

                th {
                    text-align: left;
                }

                td {
                    p {
                        padding: 8px;
                    }
                }
            }
        }

        p.cart {
            margin-bottom: 2em;

            @include clearfix;
        }

        form.cart {
            margin-bottom: 2em;

            @include clearfix;

            div.quantity {
                float: left;
                margin: 0 4px 0 0;
            }

            .button {
                margin-top: 36px;

                @media screen and (max-width: 1029px) {
                    margin-top: 0;
                }
            }

            table {
                border-width: 0 0 1px;

                td {
                    padding-left: 0;
                }

                div.quantity {
                    float: none;
                    margin: 0;
                }

                small.stock {
                    display: block;
                    float: none;
                }
            }

            .variations {
                margin-bottom: 1em;
                border: 0;
                width: 100%;

                td,
                th {
                    border: 0;
                    vertical-align: top;
                    line-height: 2em;
                }

                label {
                    font-weight: 700;
                }

                select {
                    max-width: 100%;
                    min-width: 75%;
                    display: inline-block;
                    margin-right: 1em;
                }

                td.label {
                    padding-right: 1em;
                }
            }

            .woocommerce-variation-description p {
                margin-bottom: 1em;
                display: none;
            }

            .reset_variations {
                visibility: hidden;
                font-size: 0.83em;
            }

            .wc-no-matching-variations {
                display: none;
            }

            .button {
                vertical-align: middle;
                float: left;
            }

            .group_table {
                td.woocommerce-grouped-product-list-item__label {
                    padding-right: 1em;
                    padding-left: 1em;
                }

                td {
                    vertical-align: top;
                    padding-bottom: 0.5em;
                    border: 0;
                }

                td:first-child {
                    width: 4em;
                    text-align: center;
                }

                .wc-grouped-product-add-to-cart-checkbox {
                    display: inline-block;
                    width: auto;
                    margin: 0 auto;
                    transform: scale(1.5, 1.5);
                }
            }
        }
    }

    span.onsale {
        min-height: 3.236em;
        min-width: 3.236em;
        padding: 0.202em;
        font-size: 1em;
        font-weight: 700;
        position: absolute;
        text-align: center;
        line-height: 3.236;
        top: -0.5em;
        left: -0.5em;
        margin: 0;
        border-radius: 100%;
        background-color: $highlight;
        color: $white;
        font-size: 0.857em;
        z-index: 9;
    }

    .onsale {
        width: 80px;
        height: 80px;
        font-size: 16px !important;
        line-height: 72px !important;
        font-weight: normal !important;
        background-color: $pink !important;
    }

    /**
     * Product loops
     */
    .products ul,
    ul.products {
        margin: 0 0 1em;
        padding: 0;
        list-style: none outside;
        clear: both;

        @include clearfix;

        li {
            list-style: none outside;
        }
    }

    ul.products li.product {
        .shipping-costs-info {
            display: none;
        }

        .onsale {
            top: 0;
            right: 0;
            left: auto;
            margin: -0.5em -0.5em 0 0;
        }

        h2.woocommerce-loop-product_title {
            // height: 2.2em;
            font-size: 18px !important;
            line-height: 1.1em;
            font-family: $mono;

            // @media screen and (max-width: 768px) {
            //     height: 3.3em;
            // }
       
        }

        h3,
        .woocommerce-loop-product__title,
        .woocommerce-loop-category__title {
            padding: 0.5em 0;
            margin: 0;
            font-size: 1.2rem !important;
            hyphens: auto;
            height: 4em;

            mark {
                vertical-align: super;
                font-size: smaller;
                color: $blue;
                font-family: $sans-serif;
                background: none;
            }
        }

        a {
            text-decoration: none;
        }

        a img {
            width: 100%;
            height: auto;
            display: block;
            margin: 0 0 1em;
            box-shadow: none;
            max-height: 200px;
            width: auto;
            margin: auto;
        }

        strong {
            display: block;
        }

        .woocommerce-placeholder {
            border: 1px solid #f2f2f2;
        }

        .star-rating {
            font-size: 0.857em;
        }

        .button {
            margin-top: 1em;
        }

        .price {
            color: $primary;
            display: block;
            font-size: 2rem;
            font-weight: 300;
            letter-spacing: 0.01em;

            del {
                color: inherit;
                opacity: 0.5;
                display: inline-block;
            }

            ins {
                background: none;
                font-weight: 700;
                display: inline-block;
            }

            .from {
                font-size: 0.67em;
                margin: -2px 0 0 0;
                text-transform: uppercase;
                color: rgba(desaturate($highlight, 75%), 0.5);
            }
        }
    }

    .woocommerce-result-count {
        margin: 0 0 3em;
        font-size: 12px;
    }

    .woocommerce-ordering {
        margin: 0 0 3em;
        font-size: 15px;
        float: left;

        select.orderby {
            vertical-align: top;
            font-size: 1.8rem;
            background-color: $primary;
            margin-top: 1em;
        }
    }

    .aws-container .aws-search-form .aws-form-btn {
        background-color: $primary;
    }

    .aws-container .aws-search-field {
        color: $black;
        font-size: 1.6rem;
        border: 1px solid $primary;
        outline: 0;
    }

    nav.woocommerce-pagination {
        text-align: center;

        ul {
            display: inline-block;
            white-space: nowrap;
            padding: 0;
            clear: both;
            border: none;
            border-right: 0;
            margin: 1px;

            li {
                border-right: 1px solid darken($secondary, 10%);
                padding: 0;
                margin: 0;
                float: left;
                display: inline;
                overflow: hidden;

                a,
                span {
                    margin: 0;
                    text-decoration: none;
                    padding: 0;
                    line-height: 1;
                    font-size: 1em;
                    font-weight: normal;
                    padding: 0.5em;
                    min-width: 1em;
                    display: block;
                }

                span.current,
                a:hover,
                a:focus {
                    background: $secondary;
                    color: #fff;
                }
            }
        }
    }

    /**
     * Buttons
     */
    a.button,
    button.button,
    input.button,
    .button_block a,
    #respond input#submit,
    .sidebar [type="submit"] {
        font-size: 1.25rem;
        margin: 0;
        line-height: 1;
        cursor: pointer;
        position: relative;
        text-decoration: none;
        overflow: visible;
        padding: 0.618em 1em;
        left: auto;
        color: $white;
        background-color: $akk-violet;
        border: 0;
        display: inline-block;
        background-image: none;
        box-shadow: none;
        text-shadow: none;
        border-radius: 0;

        @media screen and (max-width: 768px) {
            // padding: 0.4em 0.5em !important;
            font-size: 1.6rem !important;
        }

        &.loading {
            opacity: 0.25;
            padding-right: 2.618em;

            &::after {
                font-family: "WooCommerce";
                content: "\e01c";
                vertical-align: top;
                font-weight: 400;
                position: absolute;
                top: 0.618em;
                right: 1em;
                animation: spin 2s linear infinite;
            }
        }

        &.added::after {
            font-family: "WooCommerce";
            content: "\e017";
            margin-left: 0.53em;
            vertical-align: bottom;
        }

        &:hover {
            background-color: darken($secondary, 5%);
            text-decoration: none;
            background-image: none;
            color: $secondarytext;
        }

        &.alt {
            background-color: $primary;
            color: $primarytext;
            -webkit-font-smoothing: antialiased;
            color: $black;
            height: 45px;
            border-radius: 0;
            font-size: 20px;
            font-weight: 300;

            &:hover {
                background-color: $secondary;
                color: $white;

                // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                transition: 0.5s;
            }

            &.disabled,
            &:disabled,
            &:disabled[disabled],
            &.disabled:hover,
            &:disabled:hover,
            &:disabled[disabled]:hover {
                background-color: $primary;
                color: $black;
                font-size: 20px;
                font-weight: 300;
            }
        }

        &:disabled,
        &.disabled,
        &:disabled[disabled] {
            color: $white;
            cursor: not-allowed;
            opacity: 0.5;
            padding: 0.618em 1em;

            &:hover {
                color: $white;
                background-color: $primary;
            }
        }
    }

    .cart .button,
    .cart input.button {
        float: none;
    }

    a.added_to_cart {
        display: inline-block;
        background-color: $primary;
        color: $black;
        margin-top: 10px;
        padding: .618em 1em;

        @media screen and (min-width: 768px) {
            min-width: 227px;
        }

        &:hover,
        &:active,
        &:focus {
            color: $black;

            // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       
        }
    }

    /**
     * Reviews
     */
    #reviews {
        h2 small {
            float: right;
            color: $subtext;
            font-size: 15px;
            margin: 10px 0 0;

            a {
                text-decoration: none;
                color: $subtext;
            }
        }

        h3 {
            margin: 0;
        }

        #respond {
            margin: 0;
            border: 0;
            padding: 0;
        }

        #comment {
            height: 75px;
        }

        #comments {
            .add_review {
                @include clearfix;
            }

            h2 {
                clear: none;
            }

            ol.commentlist {
                @include clearfix;

                margin: 0;
                width: 100%;
                background: none;
                list-style: none;

                li {
                    padding: 0;
                    margin: 0 0 20px;
                    border: 0;
                    position: relative;
                    background: 0;
                    border: 0;

                    .meta {
                        color: $subtext;
                        font-size: 0.75em;
                    }

                    img.avatar {
                        float: left;
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 3px;
                        width: 32px;
                        height: auto;
                        background: $secondary;
                        border: 1px solid darken($secondary, 3%);
                        margin: 0;
                        box-shadow: none;
                    }

                    .comment-text {
                        margin: 0 0 0 50px;
                        border: 1px solid darken($secondary, 3%);
                        border-radius: 4px;
                        padding: 1em 1em 0;

                        @include clearfix;

                        p {
                            margin: 0 0 1em;
                        }

                        p.meta {
                            font-size: 0.83em;
                        }
                    }
                }

                ul.children {
                    list-style: none outside;
                    margin: 20px 0 0 50px;

                    .star-rating {
                        display: none;
                    }
                }

                #respond {
                    border: 1px solid darken($secondary, 3%);
                    border-radius: 4px;
                    padding: 1em 1em 0;
                    margin: 20px 0 0 50px;
                }
            }

            .commentlist > li::before {
                content: "";
            }
        }
    }

    /**
     * Star ratings
     */
    .star-rating {
        float: right;
        overflow: hidden;
        position: relative;
        height: 1em;
        line-height: 1;
        font-size: 1em;
        width: 5.4em;
        font-family: "star";

        &::before {
            content: "\73\73\73\73\73";
            color: darken($secondary, 10%);
            float: left;
            top: 0;
            left: 0;
            position: absolute;
        }

        span {
            overflow: hidden;
            float: left;
            top: 0;
            left: 0;
            position: absolute;
            padding-top: 1.5em;
        }

        span::before {
            content: "\53\53\53\53\53";
            top: 0;
            position: absolute;
            left: 0;
        }
    }

    .woocommerce-product-rating {
        @include clearfix;

        line-height: 2;
        display: block;

        .star-rating {
            margin: 0.5em 4px 0 0;
            float: left;
        }
    }

    .products .star-rating {
        display: block;
        height: 17px;
        margin: -17px 0 0 0;
        float: none;
    }

    .hreview-aggregate .star-rating {
        margin: 10px 0 0;
    }

    #review_form #respond {
        @include clearfix;

        position: static;
        margin: 0;
        width: auto;
        padding: 0;
        background: transparent none;
        border: 0;

        p {
            margin: 0 0 10px;
        }

        .form-submit input {
            left: auto;
        }

        textarea {
            box-sizing: border-box;
            width: 100%;
        }
    }

    p.stars {
        a {
            position: relative;
            height: 1em;
            width: 1em;
            text-indent: -999em;
            display: inline-block;
            text-decoration: none;

            &::before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 1em;
                height: 1em;
                line-height: 1;
                font-family: "WooCommerce";
                content: "\e021";
                text-indent: 0;
            }

            &:hover ~ a::before {
                content: "\e021";
            }
        }

        &:hover a::before {
            content: "\e020";
        }

        &.selected {
            a.active {
                &::before {
                    content: "\e020";
                }

                ~ a::before {
                    content: "\e021";
                }
            }

            a:not(.active)::before {
                content: "\e020";
            }
        }
    }

    /**
     * Tables
     */
    table.shop_attributes {
        border: 0;
        border-top: 1px dotted rgba(0, 0, 0, 0.1);
        margin-bottom: 1.618em;
        width: 100%;

        th {
            width: 150px;
            font-weight: 700;
            padding: 8px;
            border-top: 0;
            border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
            margin: 0;
            line-height: 1.5;
        }

        td {
            font-style: normal;
            padding: 0;
            border-top: 0;
            border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
            margin: 0;
            line-height: 1.5;

            p {
                margin: 0;
                padding: 8px 0;
            }
        }

        tr:nth-child(even) td,
        tr:nth-child(even) th {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    table.shop_table {
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin: 0 -1px 24px 0;
        text-align: left;
        width: 100%;
        border-collapse: separate;
        border-radius: 0;

        th {
            font-weight: 700;
            padding: 9px 12px;
            line-height: 1.5em;
            border: none;
            background-color: $primary;
            color: $black;
            font-size: 18px;
        }

        tr.packing-select {
            th {
                background-color: $pink;

                p#gift_fee_field {
                    font-size: inherit;
                    color: $white;
                }
            }
        }

        td {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding: 9px 12px;
            vertical-align: middle;
            line-height: 1.5em;
            border: none;
            font-size: 18px;

            small {
                font-weight: normal;
            }

            del {
                font-weight: normal;
            }
        }

        li {
            text-align: right;
        }

        tbody:first-child tr:first-child {
            th,
            td {
                border-top: 0;
                border-bottom: 0;
            }
        }

        tr {
            border: none;
            background-color: none;
        }

        tfoot td,
        tfoot th,
        tbody th {
            font-weight: 700;
            border-bottom: none;
            border-left: none;
            border-right: none;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    table.my_account_orders {
        font-size: 1rem;

        th,
        td {
            padding: 4px 8px;
            vertical-align: middle;
        }

        .button {
            white-space: nowrap;
        }
    }

    table.woocommerce-MyAccount-downloads {
        td,
        th {
            vertical-align: top;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: left;
            }

            .woocommerce-MyAccount-downloads-file::before {
                content: "\2193";
                display: inline-block;
            }
        }
    }

    td.product-name {
        a {
            text-decoration: none;
        }

        .units-info {
            font-size: 15px;
        }

        dl.variation,
        .wc-item-meta {
            list-style: none outside;

            dt,
            .wc-item-meta-label {
                float: left;
                clear: both;
                margin-right: 0.25em;
                display: inline-block;
                list-style: none outside;
            }

            dd {
                margin: 0;
            }

            p,
            &:last-child {
                margin-bottom: 0;
            }
        }

        p.backorder_notification {
            font-size: 0.83em;
        }
    }

    td.product-quantity {
        min-width: 80px;
    }

    /**
     * Cart sidebar
     */
    ul.cart_list,
    ul.product_list_widget {
        list-style: none outside;
        padding: 0;
        margin: 0;

        li {
            padding: 4px 0;
            margin: 0;

            @include clearfix;

            list-style: none;

            a {
                display: block;
                font-weight: 700;
            }

            img {
                float: right;
                margin-left: 4px;
                width: 32px;
                height: auto;
                box-shadow: none;
            }

            dl {
                margin: 0;
                padding-left: 1em;
                border-left: 2px solid rgba(0, 0, 0, 0.1);

                @include clearfix;

                dt,
                dd {
                    display: inline-block;
                    float: left;
                    margin-bottom: 1em;
                }

                dt {
                    font-weight: 700;
                    padding: 0 0 0.25em;
                    margin: 0 4px 0 0;
                    clear: left;
                }

                dd {
                    padding: 0 0 0.25em;

                    p:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .star-rating {
                float: none;
            }
        }
    }

    &.widget_shopping_cart,
    .widget_shopping_cart {
        .total {
            border-top: 3px double $secondary;
            padding: 4px 0 0;

            strong {
                min-width: 40px;
                display: inline-block;
            }
        }

        .cart_list li {
            padding-left: 2em;
            position: relative;
            padding-top: 0;

            a.remove {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .buttons {
            @include clearfix;

            a {
                margin-right: 5px;
                margin-bottom: 5px;
            }
        }
    }

    /**
     * Forms
     */
    form .form-row {
        padding: 3px;
        margin: 0 0 5px;

        [placeholder]:focus::-webkit-input-placeholder {
            transition: opacity 0.5s 0.5s ease;
            opacity: 0;
        }

        label {
            line-height: 2;
        }

        label.checkbox {
            line-height: 0;
        }

        label.hidden {
            visibility: hidden;
        }

        label.inline {
            display: inline;
        }

        .woocommerce-input-wrapper {
            .description {
                background: #1e85be;
                color: #fff;
                border-radius: 3px;
                padding: 1em;
                margin: 0.5em 0 0;
                clear: both;
                display: none;
                position: relative;

                a {
                    color: #fff;
                    text-decoration: underline;
                    border: 0;
                    box-shadow: none;
                }

                &::before {
                    left: 50%;
                    top: 0%;
                    margin-top: -4px;
                    transform: translateX(-50%) rotate(180deg);
                    content: "";
                    position: absolute;
                    border-width: 4px 6px 0 6px;
                    border-style: solid;
                    border-color: #1e85be transparent transparent transparent;
                    z-index: 100;
                    display: block;
                }
            }
        }

        select {
            cursor: pointer;
            margin: 0;
        }

        .required {
            color: $red;
            font-weight: 700;
            border: 0 !important;
            text-decoration: none;

            // visibility: hidden; // Only show optional by default.
       
        }

        .optional {
            visibility: visible;
        }

        .input-checkbox {
            display: inline;
            margin: -2px 8px 0 0;
            text-align: center;
            vertical-align: middle;
        }

        input.input-text,
        textarea {
            box-sizing: border-box;
            width: 100%;
            margin: 0;
            outline: 0;
            line-height: normal;
        }

        textarea {
            height: 4em;
            line-height: 1.5;
            display: block;
            box-shadow: none;
        }

        .select2-container {
            width: 100%;
            line-height: 2em;
        }

        &.woocommerce-invalid {
            label {
                color: $red;
            }

            .select2-container,
            input.input-text,
            select {
                border-color: $red;
            }
        }

        &.woocommerce-validated {
            .select2-container,
            input.input-text,
            select {
                border-color: darken($green, 5%);
            }
        }

        ::-webkit-input-placeholder {
            line-height: normal;
        }

        :-moz-placeholder {
            line-height: normal;
        }

        :-ms-input-placeholder {
            line-height: normal;
        }
    }

    // .hentry {
    // 	h1.page-title {
    // 		text-align: center;
    // 	}
    // }

    form.login,
    form.checkout_coupon,
    form.register {
        border: 1px solid darken($secondary, 10%);
        padding: 20px;
        margin: 0 0 4em 0;
        text-align: left;
        border-radius: 0px;

        label {
            font-size: 20px;
        }

        #username,
        #password,
        .lost_password {
            font-size: 18px;
        }

        .form-row-wide {
            margin-bottom: 10px;
        }
    }

    #region-info {
        color: $red;
        font-weight: bold;
    }

    ul#shipping_method {
        list-style: none outside;
        margin: 0;
        padding: 0;
        font-weight: 300;

        li {
            margin: 0 0 0.5em;
            line-height: 1.5em;
            list-style: none outside;

            input {
                margin: 0.4375em 0.4375em 0 0;
                vertical-align: top;
            }

            label {
                display: inline;

                // &[for=shipping_method_0_local_pickup6]{
                // 	color: $red;
                // 	font-weight: bold;
                // }
           
            }
        }

        .amount {
            font-weight: 700;
        }
    }

    p.woocommerce-shipping-contents {
        margin: 0;
    }

    #calc_shipping_city_field {
        display: none !important;
    }

    /**
     * Order page
     */
    ul.order_details {
        @include clearfix;

        margin: 0 0 3em;
        list-style: none;

        li {
            float: left;
            margin-right: 2em;
            text-transform: uppercase;
            font-size: 0.715em;
            line-height: 1;
            border-right: 1px dashed darken($secondary, 10%);
            padding-right: 2em;
            margin-left: 0;
            padding-left: 0;
            list-style-type: none;

            strong {
                display: block;
                font-size: 1.4em;
                text-transform: none;
                line-height: 1.5;
            }

            &:last-of-type {
                border: none;
            }
        }
    }

    .wc-bacs-bank-details-account-name {
        font-weight: bold;
    }

    .woocommerce-order-downloads,
    .woocommerce-customer-details,
    .woocommerce-order-details {
        margin-bottom: 2em;

        *:last-child {
            margin-bottom: 0;
        }
    }

    .woocommerce-customer-details {
        address {
            font-style: normal;
            margin-bottom: 0;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom-width: 2px;
            border-right-width: 2px;
            text-align: left;
            width: 100%;
            border-radius: 5px;
            padding: 6px 12px;
        }

        .woocommerce-customer-details--phone,
        .woocommerce-customer-details--email {
            margin-bottom: 0;
            padding-left: 1.5em;
        }

        .woocommerce-customer-details--phone::before {
            @include iconbefore("");

            margin-left: -1.5em;
            line-height: 1.75;
            position: absolute;
        }

        .woocommerce-customer-details--email::before {
            @include iconbefore("");

            margin-left: -1.5em;
            line-height: 1.75;
            position: absolute;
        }
    }

    /**
     * Layered nav widget
     */
    .woocommerce-widget-layered-nav-list {
        margin: 0;
        padding: 0;
        border: 0;
        list-style: none outside;

        .woocommerce-widget-layered-nav-list__item {
            @include clearfix;

            padding: 0 0 1px;
            list-style: none;

            a,
            span {
                padding: 1px 0;
            }
        }

        .woocommerce-widget-layered-nav-list__item--chosen a::before {
            @include iconbefore("");

            color: $red;
        }
    }

    .woocommerce-widget-layered-nav-dropdown__submit {
        margin-top: 1em;
    }

    .widget_layered_nav_filters ul {
        margin: 0;
        padding: 0;
        border: 0;
        list-style: none outside;
        overflow: hidden;
        zoom: 1;

        li {
            float: left;
            padding: 0 1em 1px 1px;
            list-style: none;

            a {
                text-decoration: none;

                &::before {
                    @include iconbefore("");

                    color: $red;
                    vertical-align: inherit;
                    margin-right: 0.5em;
                }
            }
        }
    }

    /**
     * Price filter widget
     */
    .widget_price_filter {
        .price_slider {
            margin-bottom: 1em;
        }

        .price_slider_amount {
            text-align: right;
            line-height: 2.4;
            font-size: 0.8751em;

            .button {
                font-size: 1.15em;
                float: left;
            }
        }

        .ui-slider {
            position: relative;
            text-align: left;
            margin-left: 0.5em;
            margin-right: 0.5em;
        }

        .ui-slider .ui-slider-handle {
            position: absolute;
            z-index: 2;
            width: 1em;
            height: 1em;
            background-color: $primary;
            border-radius: 1em;
            cursor: ew-resize;
            outline: none;
            top: -0.3em;

            /* rtl:ignore */
            margin-left: -0.5em;
        }

        .ui-slider .ui-slider-range {
            position: absolute;
            z-index: 1;
            font-size: 0.7em;
            display: block;
            border: 0;
            border-radius: 1em;
            background-color: $primary;
        }

        .price_slider_wrapper .ui-widget-content {
            border-radius: 1em;
            background-color: darken($primary, 30%);
            border: 0;
        }

        .ui-slider-horizontal {
            height: 0.5em;
        }

        .ui-slider-horizontal .ui-slider-range {
            top: 0;
            height: 100%;
        }

        .ui-slider-horizontal .ui-slider-range-min {
            left: -1px;
        }

        .ui-slider-horizontal .ui-slider-range-max {
            right: -1px;
        }
    }

    /**
     * Rating Filter Widget
     */
    .widget_rating_filter ul {
        margin: 0;
        padding: 0;
        border: 0;
        list-style: none outside;

        li {
            @include clearfix;

            padding: 0 0 1px;
            list-style: none;

            a {
                padding: 1px 0;
                text-decoration: none;
            }

            .star-rating {
                float: none;
                display: inline-block;
            }
        }

        li.chosen a::before {
            @include iconbefore("");

            color: $red;
        }
    }

    .woocommerce-form-login,
    .woocommerce-form-register {
        height: 400px;

        .woocommerce-form-login__submit {
            float: left;
            margin-right: 1em;
        }

        .woocommerce-form-login__rememberme {
            display: inline-block;
        }

        .show-password-input {
            top: auto;
        }

        input#reg_email {
            font-size: 18px;
        }
    }
}

.entry-content {
    .woocommerce-form-register {
        p {
            font-size: 18px;
            line-height: 30px;

            &.terms-privacy-policy {
                margin-bottom: 30px;
            }
        }
    }
}

.woocommerce-ResetPassword {
    font-size: 18px;

    input#user_login {
        font-size: 18px;
    }
}

.woocommerce-no-js {
    form.woocommerce-form-login,
    form.woocommerce-form-coupon {
        display: block !important;
    }

    .woocommerce-form-login-toggle,
    .woocommerce-form-coupon-toggle,
    .showcoupon {
        display: none !important;
    }
}

.woocommerce-checkout-review-order {
    margin-top: 2em;
}

.woocommerce-message,
.woocommerce-error,
.woocommerce-info {
    padding: 1em 2em 1em 3.5em !important;
    margin: 0 0 2em;
    position: relative;
    background-color: $white;
    color: $black;
    border: 2px solid $pink !important;
    list-style: none outside;
    font-size: 15px !important;

    @include clearfix;

    width: auto;
    word-wrap: break-word;

    &::before {
        font-family: "WooCommerce";
        content: "\e028";
        display: inline-block;
        position: absolute;
        top: 1em;
        left: 1.5em;
    }

    .button {
        float: right;
    }

    a,
    a:visited {
        color: $black !important;
    }

    li {
        list-style: none outside !important; // Required for default theme compatibility
        padding-left: 0 !important; // Required for default theme compatibility
        margin-left: 0 !important; // Required for default theme compatibility
   
    }
}

/**
 * Right to left styles
 */
.rtl.woocommerce .price_label,
.rtl.woocommerce .price_label span {
    /* rtl:ignore */
    direction: ltr;
    unicode-bidi: embed;
}

.woocommerce-message {
    border-top-color: darken($primary, 10%);

    &::before {
        content: "\e015";
        color: darken($primary, 10%);
    }
}

.woocommerce-info {
    border-top-color: darken($primary, 10%);

    &::before {
        color: darken($primary, 10%);
    }
}

.woocommerce-error {
    border-top-color: darken($primary, 10%);

    &::before {
        content: "\e016";
        color: darken($primary, 10%);
    }
}

/**
 * Account page
 */
.woocommerce-account {
    .woocommerce {
        @include clearfix;
    }

    .addresses .title {
        @include clearfix;

        h3 {
            float: left;
        }

        .edit {
            float: right;
        }
    }

    ol.commentlist.notes li.note {
        p.meta {
            font-weight: 700;
            margin-bottom: 0;
        }

        .description p:last-child {
            margin-bottom: 0;
        }
    }

    ul.digital-downloads {
        margin-left: 0;
        padding-left: 0;

        li {
            list-style: none;
            margin-left: 0;
            padding-left: 0;

            &::before {
                @include iconbefore("");
            }

            .count {
                float: right;
            }
        }
    }
}

/**
 * Cart/checkout page
 */
.woocommerce-cart,
.woocommerce-checkout,
#add_payment_method {
    table.cart {
        .product-thumbnail {
            min-width: 150px;
        }

        img {
            width: 150px;
            box-shadow: none;
            margin-bottom: 0;
        }

        th,
        td {
            vertical-align: middle;
        }

        td.actions .coupon .input-text {
            float: left;
            box-sizing: border-box;
            border: 1px solid darken($secondary, 10%);
            padding: 6px 6px 5px;
            margin: 0 4px 0 0;
            outline: 0;
        }

        input {
            margin: 0;
            vertical-align: middle;
        }
    }

    .wc-proceed-to-checkout {
        @include clearfix;

        padding: 1em 0;

        a.checkout-button {
            display: block;
            text-align: center;
            margin-bottom: 1em;
            font-size: 20px !important;
            padding: 1em !important;
        }
    }

    .cart-collaterals {
        .shipping-calculator-button {
            float: none;
            margin-top: 0.5em;
            display: inline-block;
        }

        .shipping-calculator-button::after {
            @include iconafter("");
        }

        .shipping-calculator-form {
            margin: 1em 0 0 0;
            display: block !important;
        }

        .cart_totals {
            p small {
                color: $subtext;
                font-size: 0.83em;
            }

            table {
                border-collapse: separate;
                margin: 0 0 6px;
                padding: 0;

                tr:first-child {
                    th,
                    td {
                        border-top: 0;
                    }
                }

                th {
                    width: 35%;
                }

                td,
                th {
                    vertical-align: top;
                    border-left: 0;
                    border-right: 0;
                    line-height: 1.5em;
                }

                td {
                    text-align: right;
                }

                small {
                    color: $subtext;
                }

                select {
                    width: 100%;
                }
            }

            .discount td {
                color: $highlight;
            }

            tr td,
            tr th {
                border-top: 1px solid $secondary;
            }

            .woocommerce-shipping-destination {
                margin-bottom: 0;
            }
        }

        .cross-sells ul.products li.product {
            margin-top: 0;
        }
    }

    .checkout {
        .col-2 {
            h3#ship-to-different-address {
                float: left;
                clear: none;
            }

            .notes {
                clear: left;
            }

            .form-row-first {
                clear: left;
            }
        }

        .create-account small {
            font-size: 11px;
            color: $subtext;
            font-weight: normal;
        }

        div.shipping-address {
            padding: 0;
            clear: left;
            width: 100%;
        }

        .shipping_address {
            clear: both;
        }
    }

    #payment {
        background: none;
        border-radius: 0;
        border: 1px solid $primary;

        ul.payment_methods {
            @include clearfix;

            text-align: left;
            padding: 1em;
            border-bottom: 1px solid darken($secondary, 10%);
            margin: 0;
            list-style: none outside;

            li {
                line-height: 2;
                text-align: left;
                margin: 0;
                font-weight: normal;
                font-size: 18px;

                p {
                    font-size: 18px;
                }

                .input-radio {
                    height: 20px;
                    width: 20px;
                }

                input {
                    margin: 0 1em 0 0;
                }

                img {
                    vertical-align: middle;
                    margin: -2px 0 0 0.5em;
                    padding: 0;
                    position: relative;
                    box-shadow: none;
                }

                img + img {
                    margin-left: 2px;
                }
            }

            li:not(.woocommerce-notice) {
                @include clearfix;
            }
        }

        div.form-row {
            padding: 1em;
        }

        div.payment_box {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            padding: 1em;
            margin: 1em 0;
            font-size: 0.92em;
            border-radius: 0;
            line-height: 1.5;
            background-color: $primary;
            color: $black;

            input.input-text,
            textarea {
                border-color: darken($secondary, 15%);
                border-top-color: darken($secondary, 20%);
            }

            ::-webkit-input-placeholder {
                color: darken($secondary, 20%);
            }

            :-moz-placeholder {
                color: darken($secondary, 20%);
            }

            :-ms-input-placeholder {
                color: darken($secondary, 20%);
            }

            .woocommerce-SavedPaymentMethods {
                list-style: none outside;
                margin: 0;

                .woocommerce-SavedPaymentMethods-token,
                .woocommerce-SavedPaymentMethods-new {
                    margin: 0 0 0.5em;

                    label {
                        cursor: pointer;
                    }
                }

                .woocommerce-SavedPaymentMethods-tokenInput {
                    vertical-align: middle;
                    margin: -3px 1em 0 0;
                    position: relative;
                }
            }

            .wc-credit-card-form {
                border: 0;
                padding: 0;
                margin: 1em 0 0;
            }

            .wc-credit-card-form-card-number,
            .wc-credit-card-form-card-expiry,
            .wc-credit-card-form-card-cvc {
                font-size: 1.5em;
                padding: 8px;
                background-repeat: no-repeat;
                background-position: right 0.618em center;
                background-size: 32px 20px;

                &.visa {
                    background-image: url("../images/icons/credit-cards/visa.svg");
                }

                &.mastercard {
                    background-image: url("../images/icons/credit-cards/mastercard.svg");
                }

                &.laser {
                    background-image: url("../images/icons/credit-cards/laser.svg");
                }

                &.dinersclub {
                    background-image: url("../images/icons/credit-cards/diners.svg");
                }

                &.maestro {
                    background-image: url("../images/icons/credit-cards/maestro.svg");
                }

                &.jcb {
                    background-image: url("../images/icons/credit-cards/jcb.svg");
                }

                &.amex {
                    background-image: url("../images/icons/credit-cards/amex.svg");
                }

                &.discover {
                    background-image: url("../images/icons/credit-cards/discover.svg");
                }
            }

            span.help {
                font-size: 0.857em;
                color: $subtext;
                font-weight: normal;
            }

            .form-row {
                margin: 0 0 1em;
            }

            p:last-child {
                margin-bottom: 0;
            }

            &::before {
                content: "";
                display: block;
                border: 1em solid $primary;

                /* arrow size / color */
                border-right-color: transparent;
                border-left-color: transparent;
                border-top-color: transparent;
                position: absolute;
                top: -0.75em;
                left: 0;
                margin: -1em 0 0 2em;
            }
        }

        .payment_method_paypal {
            .about_paypal {
                float: right;
                line-height: 52px;
                font-size: 0.83em;
            }

            img {
                max-height: 52px;
                vertical-align: middle;
            }
        }
    }
}

.woocommerce-terms-and-conditions {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.05);
}

.woocommerce-invalid {
    #terms {
        outline: 2px solid red;
        outline-offset: 2px;
    }
}

/**
 * Password strength meter
 */
.woocommerce-password-strength {
    text-align: center;
    font-weight: 600;
    padding: 3px 0.5em;
    font-size: 1em;

    &.strong {
        background-color: #c1e1b9;
        border-color: #83c373;
    }

    &.short {
        background-color: #f1adad;
        border-color: #e35b5b;
    }

    &.bad {
        background-color: #fbc5a9;
        border-color: #f78b53;
    }

    &.good {
        background-color: #ffe399;
        border-color: #ffc733;
    }
}

.woocommerce-password-hint {
    margin: 0.5em 0 0;
    display: block;
}

// .woocommerce nav.woocommerce-pagination ul li .next{
// 	display: none;
// }

/**
 * Twenty Eleven specific styles
 */
#content.twentyeleven .woocommerce-pagination a {
    font-size: 1em;
    line-height: 1;
}

/**
 * Twenty Thirteen specific styles
 */
.single-product .twentythirteen {
    .entry-summary,
    #reply-title,
    #respond #commentform {
        padding: 0;
    }

    p.stars {
        clear: both;
    }
}

.twentythirteen .woocommerce-breadcrumb {
    padding-top: 40px;
}

/**
 * Twenty Fourteen specific styles
 */
.twentyfourteen ul.products li.product {
    margin-top: 0 !important;
}

/**
 * Twenty Sixteen specific styles
 */
body:not(.search-results) .twentysixteen .entry-summary {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
}

.twentysixteen .price ins {
    background: inherit;
    color: inherit;
}

.woocommerce,
.woocommerce-page,
.woocommerce-cart,
.footer_btn {
    #order_comments {
        padding-top: 10px;
    }

    a.button {
        font-size: 20px;
        color: $black;
        background-color: $primary;
        font-weight: 300;

        &:hover {
            // background-color: $akk-violet;
            // color: $white;
            color: $white;
            background-color: $secondary;
            transition: 0.5s;

            // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       
        }
    }

    #content {
        font-size: 1.2rem;
        padding: 0 10px;

        ul {
            li {
                margin-bottom: 0;
            }
        }

        ul.products {
            li.product {
                a.button {
                    position: relative;
                }
            }
        }
    }

    section {
        padding: 0;
    }

    h1,
    .h1 {
        // font-size: 75px !important;
        color: black !important;

        // line-height: 70px;
        text-align: left;

        @media screen and (max-width: 1080px) {
            font-size: 50px !important;
        }

        @media screen and (max-width: 768px) {
            font-size: 35px !important;
            line-height: 40px;
        }
    }

    h2,
    .h2 {
        font-size: 2rem !important;
        color: $black !important;
    }

    h3,
    .h3 {
        font-size: 1.7rem !important;
        color: $black !important;
    }

    address {
        font-size: 1.8rem;
        font-style: normal;

        p {
            font-size: 1em;
        }
    }

    a.edit {
        font-size: 1rem;
    }

    // p.demo_store,
    // .woocommerce-store-notice {
    // 	display: block !important;
    // }
}

.single-product {
    .related {
        clear: both;

        h2 {
            margin-bottom: 20px;
        }

        > h2 {
            @media screen and (max-width: 768px) {
                padding: 0 20px;
            }
        }
    }
}

div.summary {
    font-size: 1.25rem;
}

#place_order {
    font-size: 2.3rem !important;
    text-align: center;
    margin: 1em auto 4em auto;
    display: block;
    max-width: 450px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
}

#coupon_code {
    width: auto !important;
    font-size: 1.6rem;
}

.xoo-wsc-product {
    font-size: 1rem;
    position: relative;
}

a.xoo-wsc-remove {
    position: absolute;
    right: 1em;
    text-indent: -999999px;

    &::before {
        content: "x";
        position: absolute;
        right: 1rem;
        color: $red;
        font-size: 1rem;
        text-indent: 0;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        line-height: 2;
        font-weight: bold;
        transition: all 0.2s ease;
    }

    &:hover::before {
        background: $red;
        color: $white;
    }
}

.xoo-wsc-footer-b {
    .button {
        margin-top: 0.5em !important;
    }
}

.xoo-wsc-subtotal {
    span {
        display: none;
    }

    .woocommerce-Price-amount {
        display: inline;

        &::before {
            content: "Gesamt: € ";
        }
    }
}

.order_review_heading {
    margin-top: 2em;
}

#e_deliverydate_field,
.woocommerce-additional-fields__field-wrapper {
    background: $primary;
    padding: 0.2em 1em 0.7em;
    color: $black;

    .orddd_lite_field_note {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.2;
        padding-top: 0.5em;
        display: block;
    }
}

#e_deliverydate_field .woocommerce-input-wrapper {
    position: relative;
    display: inline-block;

    input {
        cursor: pointer;
    }

    &::before {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f073";
        position: absolute;
        right: 10px;
        top: 10px;
        color: $blue;
        pointer-events: none;
        cursor: pointer;
    }
}

#wc-stripe-payment-request-wrapper {
    &::after {
        content: "";
    }
}

.brand-logo {
    display: none;

    // position: absolute;
    // width: 70px;
    // height: auto;

    img {
        width: 100%;
        height: auto;
    }
}

.xoo-wsc-items-count {
    font-size: 16px;
}

.xoo-wsc-img-col {
    width: 25%;
}

.yith-wcbr-brands {
    display: none;
}

.product .product_meta {
    @media screen and (min-width: 1030px) {
        margin-top: 0;
    }
}

.yith-wcbr-brands-logo {
    // pointer-events: none;
    // cursor: text;

    @media screen and (min-width: 1030px) {
        position: absolute;
        left: 0;
        top: -48px;
        z-index: -1;
    }

    img {
        // width: 150px;
        // height: auto;
        max-height: 100px;
        width: auto;
        height: auto;
    }
}

.product p.wc-gzd-additional-info {
    font-size: 12px;
}

@media screen and (max-width: 8px) {
    .post-type-archive,
    .tax-product_cat {
        #inner-content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .woocommerce,
    .woocommerce-page {
        .add_to_cart_button {
            font-size: 1.6rem !important;
        }

        ul.products.columns-4 li.product {
            width: 30.75%;
        }
    }

    ul.products {
        li.first {
            clear: none !important;
        }

        li.last {
            margin: 0 3.8% 2.992em 0 !important;
        }

        li:nth-child(3n+1) {
            clear: both !important;
        }

        li:nth-child(3n+3) {
            margin-right: 0 !important;
        }
    }
}

// eigener Code

.sku_wrapper,
.product-units,
.posted_in {
    font-family: $mono;
    line-height: 1;
}

.woocommerce-page,
.footer_btn {
    a.button.termin_btn,
    a.cat_termin {
        background-color: $primary;
        font-size: 20px;
        color: $black;
        font-weight: 300;
        border-radius: 0;

        &:hover {
            color: $white;
            background-color: $secondary;
            box-shadow: none;

            // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       
        }
    }
}

.block_startseite {
    .button_block a {
        background-color: $akk-violet;
        font-size: 20px;
        color: $white;
        font-weight: 300;
        border-radius: 0;
        transition: all 0.5s ease;

        &:hover {
            color: $white;
            background-color: $akk-violet;
            box-shadow: none;

            // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       
        }
    }
}

.footer_btn {
    padding: 20px 0;
    text-align: center;
}

tr {
    td {
        select#menge {
            width: 210px;
        }

        a.reset_variations {
            display: none !important;
        }
    }
}

.woocommerce,
.woocommerce-page,
.xoo-wsc-modal {
    h1.shop_title {
        margin-bottom: 10px;
        max-width: 80%;
        line-height: 1;

        @media screen and (max-width: 768px) {
            padding: 20px;
        }
    }

    .text_shop_page {
        max-width: 60%;

        @media screen and (max-width: 768px) {
            padding: 20px;
            max-width: 100%;
        }

        h2 {
            font-weight: 300;
            font-size: 20px !important;
            margin-bottom: 50px;
            line-height: 1.5;
        }
    }

    div.product {
        .woocommerce-tabs {
            ul.tabs {
                margin-bottom: 0;

                &.wc-tabs {
                    li {
                        margin-top: 5px;
                    }

                    // height: 46px;
               
                }

                &::after,
                &::before {
                    display: none;
                }
            }
        }
    }
}

// Produkt Detail
.single-product {}

// Produikt Übersicht
#content {
    ul.products {
        display: flex;
        flex-wrap: wrap;

        li.product {
            position: relative;
            padding: 5px;
            padding-bottom: 6rem !important;
            margin-bottom: 10rem;

            .price {
                color: $black;
                line-height: 1;
            }

            .tax-info {
                line-height: 1;
            }

            a {
                > img {
                    display: block;
                    margin: 0 auto;
                    width: auto;
                    height: 100%;
                    max-height: 300px;
                    object-fit: contain;

                    @media screen and (max-width: 768px) {
                        max-height: 200px;
                    }
                }
            }
        }
    }

    .woocommerce {
        li.product {
            .added_to_cart.wc-forward {
                position: absolute;
                bottom: -6rem;
                line-height: 1;
            }

            .button {
                position: absolute !important;
                bottom: 0;
            }
        }
    }

    .slick-slider {
        li.product {
            position: relative;

            .added_to_cart.wc-forward {
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
            }

            .button {
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
            }
        }
    }
}

// sidebar css

.woocommerce {
    #sidebar {
        position: fixed;
        height: 100%;
        top: 0;
        right: 0;
        padding: 80px 40px 0 50px;
        background-color: $primary;
        z-index: 990;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;

        //animation "wackeln"
        transition: all 0.5s ease-in;

        @media screen and (min-width: 767px) and (max-width: 1800px) {
            animation: shakeX 3s ease-in-out infinite alternate;
            transform: translateX(calc(100% - 40px));
            border-bottom: 40px solid $pink;
        }

        @media screen and (max-width: 767px) {
            transform: translateX(100%);
        }

        form {
            input {
                border: none;
                border-radius: 0;
            }
        }

        .misha-cart {
            color: $black;
            text-decoration: none;
            font-size: 1.2rem;
            width: 26px;
            height: 30px;
            text-align: center;
            position: relative;
            line-height: 15px;
            padding: 0 .4em 0 15px;
            margin-left: -46px;
            margin-bottom: -30px;
            padding-top: 10px;

            i {
                position: absolute;
                left: 2px;
                top: -3px;
                color: $secondary;
                z-index: -1;
                font-size: 24px;
                padding-top: 10px;
            }
        }

        .widget_nav_menu {
            border: none;
        }

        // .sidebar_toggle_button {
        // 	height: 20px;
        // 	position: absolute;
        // 	left: 10px;
        // 	bottom: 20px;
        // 	display: none;
        // 	cursor: pointer;

        // 	.cls-1 {
        // 		fill: $white;
        // 	}
        // }

        // .sidebar_close_button {
        // 	display: none;
        // }

        li#yith_woocommerce_ajax_search-2 {
            input#yith-searchsubmit {
                width: 100px;
                font-size: 15px;
                color: $primary;
                background-color: $white;
                border: 0;
                padding: 5px 0;
                margin-top: 10px;

                &:hover {
                    // box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.1), 0 6px 20px 0 rgba(255, 255, 255, 0.1);
                    transition: 0.5s;
                }
            }

            input#yith-s {
                height: 40px;
                max-width: 100%;
                width: 220px;
            }
        }

        h3 {
            font-family: $mono;
            font-size: 15px !important;
            text-transform: uppercase;
            color: $black !important;
            letter-spacing: 3px;
            margin-bottom: 5px;
        }

        .bapf_ckbox_square input[type="checkbox"] {
            border: 1px solid $black;
            cursor: pointer;
            border-radius: 0;

            &:checked {
                background-color: $black;
                background-clip: content-box;
            }
        }

        input[type='checkbox'] {
            /* remove browser chrome */
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
        }

        .bapf_body {
            ul {
                li {
                    margin-bottom: 10px !important;
                    color: $black;
                    font-size: 1.6rem;
                }
            }
        }

        ul#menu-sidebar-menue {
            li {
                display: block;
                text-align: left;
                margin: 5px 0;

                a {
                    color: $black;
                    font-size: 1.6rem;
                    line-height: 2rem;

                    &:hover {
                        font-weight: 300;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &.header-sticky #sidebar {
        padding-top: 80px;
    }

    .sidebar_toggle_button {
        height: 20px;
        position: fixed;
        right: 0px;
        bottom: 0px;
        display: none;
        background-color: $pink;
        width: 40px;
        height: 40px;
        padding: 10px;
        cursor: pointer;
        z-index: 9990;
        transition: right 0.5s ease;

        .cls-1 {
            fill: $white;
        }

        &.sidebar_close_button {
            display: none;
        }

        &.sidebar_show_button {
            @media screen and (min-width: 767px) and (max-width: 1800px) {
                animation: shakeX 3s ease-in-out infinite alternate;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .sidebar_toggle_button {
            right: 0;
            bottom: 50%;
            display: block;
        }
    }

    @media screen and (min-width: 767px) and (max-width: 1800px) {
        .sidebar_toggle_button {
            display: block;
            right: 0;
        }
    }

    &.sidebar-open {
        #sidebar {
            animation: open 0.3s linear;
            transform: translateX(0);
            transition: all 0.2s ease-out;
            overflow-y: scroll;

            // .sidebar_close_button {
            // 	display: block;

            // }
       
        }

        .sidebar_toggle_button {
            bottom: 50%;
        }

        .sidebar_show_button {
            display: none;
        }

        .sidebar_close_button {
            display: block;
        }
    }
}

.footer_btn,
.coupon,
.woocommerce-page,
.woocommerce-cart {
    #content,
    .fb-content {
        button.button {
            background-color: $primary;
            color: $black;
            font-size: 20px;
            font-weight: 300;

            &:hover {
                background-color: $secondary;
                color: $white;
                font-size: 20px;

                // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                transition: 0.5s;
            }
        }

        a.button.alt {
            height: 100%;
        }

        table.cart,
        table.shop_table,
        .woocommerce-checkout-review-order-table {
            .product-thumbnail,
            .wc-gzd-product-name-left {
                //width: 100%;

                img {
                    display: block;
                    margin: 0 auto;
                    width: auto;
                    height: 100%;
                    max-height: 160px;
                }
            }

            .product-subtotal,
            .product-total {
                text-align: right;
            }

            tfoot {
                td {
                    text-align: right;
                }
            }
        }
    }
}

#billing_last_name_field {
    float: left;
}

.woocommerce-shipping-fields {
    margin-bottom: 30px;

    .woocommerce-form__label {
        span {
            margin-left: 5px;
            font-size: 1.7rem;
        }
    }
}

.woocommerce-shipping-fields,
.woocommerce-billing-fields__field-wrapper,
.woocommerce-additional-fields__field-wrapper,
.woocommerce-MyAccount-content {
    label,
    input,
    select,
    textarea,
    .woocommerce-input-wrapper {
        font-size: 15px;
    }

    textarea {
        line-height: 1;
    }

    input#billing_first_name,
    input#billing_last_name {
        width: 100%;
    }

    p#billing_last_name_field {
        float: right;
    }

    td.woocommerce-orders-table__cell-order-actions {
        a.button {
            width: 100%;
            margin: 10px 0;
            text-align: center;
        }
    }
}

form.checkout {
    .woocommerce-billing-fields,
    .woocommerce-shipping-fields {
        .woocommerce-billing-fields__field-wrapper,
        .woocommerce-shipping-fields__field-wrapper {
            select,
            input {
                max-width: 100%;
            }
        }
    }
}

.woocommerce {
    .woocommerce-MyAccount-content {
        // p {
        // 	font-size: 15px;
        // }

        address {
            font-size: 18px;
            line-height: 1.5;
        }

        em {
            font-size: 15px;
        }

        .woocommerce-Addresses {
            .woocommerce-Address {
                .woocommerce-Address-title {
                    h3 {
                        margin-bottom: 0px;
                    }

                    a {
                        font-size: 15px;
                    }
                }
            }
        }

        .edit-account {
            fieldset {
                margin-bottom: 30px;
            }
        }
    }
}

.woocommerce-edit-address,
.woocommerce-account {
    .hentry {
        h1.page-title {
            text-align: center;
        }
    }
}

.wc-gzd-checkbox-placeholder {
    p.checkbox-legal {
        margin-bottom: 30px;
        line-height: 30px;

        .woocommerce-gzd-legal-checkbox-text {
            font-size: 20px;
        }
    }
}

.wc-gzd-product-name-right {
    font-size: 15px;

    p.wc-gzd-cart-info {
        font-size: 15px;
    }
}

.woocommerce-checkout {
    .article-header {
        .page-title {
            text-align: center;
        }
    }

    td.product-name {
        div.wc-gzd-product-name-left {
            width: 100%;
        }
    }
}

.page-bezahlmoeglichkeiten {
    .woocommerce-gzd {
        li {
            line-height: 40px;

            label {
                color: $pink;
            }

            img {
                display: none;
            }
        }
    }
}

//Mein Konto Dashboard

div.woocommerce {
    nav.woocommerce-MyAccount-navigation {
        width: max-content;

        ul {
            margin-top: 0;

            li {
                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .woocommerce-MyAccount-content {
        width: 80%;
    }
}

.archive {
    .woocommerce-products-header {
        h1,
        h2 {
            margin-bottom: 20px;
        }

        .bild_marke {
            float: right;
            width: 20%;
        }

        p {
            font-size: 18px;
        }

        ul {
            li {
                font-size: 18px;
            }
        }

        .marke_beschreibung {
            font-size: 18px;
            margin: 50px 0;
        }
    }
}

.woocommerce {
    span.onsale {
        color: $white;
    }
}

.woocommerce #content div.product div.summary .pricebox .price,
.woocommerce div.product div.summary .pricebox .price,
.woocommerce-page #content div.product div.summary .pricebox .price,
.woocommerce-page div.product div.summary .pricebox .price {
    del {
        color: $primary;
    }
}

//Bildergalarie

#gotomenu {
    margin-bottom: 20px;

    ul {
        text-align: center !important;
        margin: 0;
        padding: 0;

        li {
            font-size: 16px;
            display: inline;
            padding: 15px;
        }
    }
}

// @for $var from 0 to 10 {
//   .mt-#{$var} {
//     margin-top: $var * 1em;
//   }
// }

#content {
    .wcpscwc-product-slider button {
        background-color: $secondary !important;
    }
}
