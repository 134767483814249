/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/

[data-icon]:before {
    @include icons;

    content: attr(data-icon);
    color: $secondary;
}

.oeffnungszeiten {
    [data-icon]:before {
        color: $white;
    }
}

[class^="icon-"],
[class*=" icon-"] {
    @include icons;
}

.icon-alone {
    display: inline-block;

    /* Chrome 19 was weird with clickability without this */
}

.screen-reader-text {
    /* Reusable, toolbox kind of class */
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.icon-ak-sign:before {
    content: "\e600";
}

.icon-arrow-down:before {
    content: "\e601";
}

.icon-arrow-left:before {
    content: "\e602";
}

.icon-arrow-left2:before {
    content: "\e603";
}

.icon-arrow-right:before {
    content: "\e604";
}

.icon-arrow-right2:before {
    content: "\e605";
}

.icon-arrow-up:before {
    content: "\e606";
}

.icon-facebook:before {
    content: "\e607";
}

.icon-phone:before {
    content: "\e608";
}

.icon-search:before {
    content: "\e609";
}

/*********************
GENERAL STYLES
*********************/

* {
    @include box-sizing;
}

html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale; /* Firefox */
    -webkit-font-smoothing: antialiased; /* WebKit  */
}

body {
    font-family: $mono;
    -moz-osx-font-smoothing: grayscale; /* Firefox */
    -webkit-font-smoothing: antialiased; /* WebKit  */

    line-height: 1.5;
    color: $text-color;
    background-color: $white;
    -webkit-font-smoothing: antialiased;

    &.menu-open {
        overflow: hidden;
    }
}

/*********************
LAYOUT & GRID STYLES
*********************/

.wrap {}

/********************
WORDPRESS BODY CLASSES
style a page via class
********************/

.rtl {}

/* for sites that are read right to left (i.e. hebrew) */
.home {}

/* home page */
.blog {}

/* blog template page */
.archive {}

/* archive page */
.date {}

/* date archive page */
.date-paged-1 {}

/* replace the number to the corresponding page number */
.search {}

/* search page */
.search-results {
    /* search result page */
}

.search-results article {
    border-bottom: 1px solid #919191;
}

.search-results article .search-title {
    font-size: 23px;
    font-size: 2.3rem;
    line-height: 36px;
    line-height: 3.6rem;
}

.search-results article .article-footer {
    font-size: 15px;
    font-size: 1.5rem;
}

.search-results .pagination {
    font-size: 17px;
    font-size: 1.7rem;
}

/* search result page */
.search-no-results {}

/* no results search page */
.search-paged-1 {}

/* individual paged search (i.e. body.search-paged-3) */
.error404 {}

/* 404 page */
.single {}

/* single post page */
.postid-1 {}

/* individual post page by id (i.e. body.postid-73) */
.single-paged-1 {}

/* individual paged single (i.e. body.single-paged-3) */
.attachment {}

/* attatchment page */
.attachmentid-1 {}

/* individual attatchment page (i.e. body.attachmentid-763) */
.attachment-mime-type {}

/* style mime type pages */
.author {}

/* author page */
.author-nicename {}

/* user nicename (i.e. body.author-samueladams) */
.author-paged-1 {}

/* paged author archives (i.e. body.author-paged-4) for page 4 */
.category {}

/* category page */
.category-1 {}

/* individual category page (i.e. body.category-6) */
.category-paged-1 {}

/* replace the number to the corresponding page number */
.tag {}

/* tag page */
.tag-slug {}

/* individual tag page (i.e. body.tag-news) */
.tag-paged-1 {}

/* replace the number to the corresponding page number */
.page-template {}

/* custom page template page */
.page-template-page-php {}

/* individual page template (i.e. body.page-template-contact-php */
.page-paged-1 {}

/* replace the number to the corresponding page number */
.page-parent {}

/* parent page template */
.page-child {}

/* child page template */
.parent-pageid-1 {}

/* replace the number to the corresponding page number */
.logged-in {}

/* if user is logged in */
.paged {}

/* paged items like search results or archives */
.paged-1 {}

/* individual paged (i.e. body.paged-3) */

/*********************
LAYOUT & GRID STYLES
*********************/

/*********************
LINK STYLES
*********************/

a,
a:visited {
    color: $link-color;

    /* on hover */
    &:hover,
    &:focus {
        color: $link-hover;
    }

    /* on click */
    &:active {}

    /* mobile tap color */
    &:link {
        /*
        this highlights links on iPhones/iPads.
        so it basically works like the :hover selector
        for mobile devices.
        */

        -webkit-tap-highlight-color: rgba($black, 0.3);
    }
}

#slideshow {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#slideshow div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: no-repeat 50% 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

#supersized {
    position: static !important;
    top: 0;
    left: 0;
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    text-rendering: optimizelegibility;
    font-family: $sans-serif;
    font-weight: 400;

    /*
    if you're going to use webfonts, be sure to check your weights
    http://css-tricks.com/watch-your-font-weight/
    */

    /* removing text decoration from all headline links */
    a {
        text-decoration: none;
    }
}

h1,
.h1 {
    font-size: 2em;
    line-height: 1.333em;
}

h2,
.h2 {
    font-size: 1.5em;
    line-height: 1.4em;
    margin-bottom: 0.375em;
}

h3,
.h3 {
    font-size: 1.125em;
}

h4,
.h4 {
    font-size: 1em;
    font-weight: 700;
}

h5,
.h5 {
    font-size: 0.846em;
    line-height: 2.09em;
    text-transform: uppercase;
    letter-spacing: 2px;
}

h6,
.h5 {
    font-size: 2rem;
    margin-bottom: -20px;
    margin-top: 0;
}

/*********************
HEADER STYLES
*********************/
#container {
    position: relative;
    padding-top: 100px;
}

.header {
    height: 100px;
    width: 100%;
    background: $white;
    position: absolute;
    top: 0;
    z-index: 500;
    border-bottom: 1px solid $black;
    transition: all .3s ease;

    #logo {
        width: 200px;
        max-width: 200px;
        transition: all .3s ease;
        margin: 20px auto 0 auto;

        svg {
            width: 100%;
            height: auto !important;
        }
    }

    nav {
        $offset: 3.2rem;
        $margin: 1.1em;

        display: none;

        > ul {
            margin: 0;
            padding: 0;

            > li {
                float: left;
                font-size: 1.9rem;
                margin: 0;

                > a {
                    @include link-colors($black, $black, $black, $black);

                    padding: 0;
                }
            }
        }

        &#header-left {
            left: $offset;

            > ul > li {
                margin-right: $margin;
            }
        }

        &#header-right {
            right: $offset;

            > ul > li {
                margin-left: $margin;
            }
        }
    }

    #inner-header {
        position: relative;
        height: 100%;

        // @media screen and (max-width: 768px) {
        //     overflow: hidden;
        // }

        /*********************
        BEGIN: Menu Test 2020
        *********************/

        // Start BUTTON
        button {
            position: absolute;
            top: -14px;
            right: -30px;
            display: inline-block;
            width: 140px;
            height: 140px;
            float: right;
            color: $black;
            border: none;
            overflow: hidden;
            background: transparent;
            outline: none;

            span {
                display: none;
            }

            .svg-rect-middle {
                transition: all 1s ease;
            }

            &:hover {
                .svg-rect-middle {
                    width: 50px;
                    x: 75px;
                }
            }

            transition: top 0.5s ease;

            svg {
                position: absolute;
                right: 0;

                &.svg-bottom {
                    top: -6%;
                }

                &.svg-middle {
                    bottom: 0;
                }

                &.svg-top {
                    top: 6%;
                }

                transition: all 0.5s ease;

                .svg-rect {
                    fill: $black;
                }
            }
        }

        // end BUTTON

        /*********************
        END: Menu Test 2020
        *********************/

        // &:after {
        //     width: 100%;
        //     height: 3px;
        //     display: block;
        //     content: "";
        //     margin-top: 14px;
        //     background: url("../images/dotted_border.png") left top repeat-x;
        // }
   
    }

    &.sticky {
        position: fixed;
        top: 0 !important;
        left: 0;
        padding-top: 0px !important;
        max-height: 72px;
        width: 100%;
        padding-top: 40px;

        @include box-shadow(0 0 10px 0 rgba($black, 0.5));

        #logo {
            width: 200px;
            max-width: 130px;
            margin-top: 16px;

            svg {
                width: 100%;
                height: fit-content;
            }
        }

        nav {
            top: 35px;
        }

        #inner-header:after {
            display: none;
        }

        #inner-header {
            button {
                top: -34px;
            }
        }
    }

    .menu-open & {
        height: 100vh;

        #inner-header {
            height: calc(100vh);
            background-color: #fff;
        }

        #logo {
            position: fixed;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }

        &.header nav > ul > li {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        #inner-header {
            padding-top: 170px;
        }

        #top-nav,
        #menu-header {
            height: 100vh !important;
        }

        nav {
            // top: 170px;
            width: 100%;
            display: block;

            // position: fixed;
            background-color: $white;
            left: 0;

            // padding-top: 10%;

            ul.top-nav > li {
                display: block;
                text-align: center;
                font-size: 2em;

                // height: 2em;
                float: none;
            }
        }

        .sub-menu {
            position: relative;
            margin-top: 0.2em;
            visibility: visible;
            padding: 0.2em;
            margin-bottom: 0.5em;
            background-color: $akk-violet;
            color: $white;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid $akk-violet;
                position: absolute;
                left: 50%;
                top: -5px;
                transform: translateX(-50%);
                display: block;
            }

            li {
                display: inline-block;

                a {
                    padding: 0.2em 1em;
                    color: $white;
                }
            }
        }

        .button {
            svg {
                &.svg-bottom {
                    transform: rotate(-225deg);
                    top: 0 !important;
                }

                &.svg-middle {
                    // @include rotate(45deg);
                    right: -100% !important;
                }

                &.svg-top {
                    transform: rotate(225deg);
                    top: 0 !important;
                }
            }
        }
    }

    // ACTIVE
}

#topslider {
    width: 100%;
    position: relative;

    // .slides, li, div{
    // 	height: 100%;
    // 	overflow: hidden;
    // }

    .slides li {
        background-color: $black;

        .slide {
            width: 100% !important;
        }
    }

    img {
        width: 100% !important;
        height: auto !important;
    }

    .slide-data,
    .flex-direction-nav {
        display: none;
    }

    .flexslider {
        border: none;
        margin: 0;
    }

    .flex-control-nav {
        position: absolute;
        bottom: 25px;
        z-index: 100;

        li {
            margin: 0 4px !important;
        }

        a {
            width: 15px;
            height: 15px;
            background: $white;

            @include box-shadow(none);
        }

        .flex-active {
            background: $black;
        }
    }
}

#underslider {
    background-color: $black;

    .directionNav {
        a {
            display: none;

            &.prev,
            &.next {
                display: block;
            }
        }
    }

    .widget_rpswidget {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 0;

        #rps {
            border: 0;

            .slider {
                background: none;

                .col {
                    border: none;

                    .post-title {
                        display: none;
                    }

                    .slider-content {
                        position: relative;
                        font-size: 2.7rem;
                        color: $white;
                        font-family: $serif;
                        text-decoration: underline;
                        text-align: center;
                        height: 270px;
                        padding: 20px 0 75px 0;

                        a {
                            display: none;
                            position: absolute;
                            bottom: 25px;
                            width: 210px;
                            height: 35px;
                            border: 1px solid $white;
                            margin-left: -105px;
                            left: 50%;
                            padding-left: 25px;
                            font-size: 2.3rem;

                            @include link-colors($white, $white, $white, $white);

                            text-decoration: none;
                            font-style: normal;
                            text-align: left;

                            @include buttonTransiton(ease-out);

                            &:hover {
                                background-color: $white;
                                color: $gold;

                                @include buttonTransiton(ease-in);
                            }

                            &:after {
                                @include icons;

                                content: "\e604";
                                font-size: 1.5rem;
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#underslider .widget_rpswidget #rps .slider .col .slider-content a.show {
    display: block;
}

#underslider .widget_rpswidget #rps .directionNav {
    font-size: 46px;
    font-size: 4.6rem;
}

#underslider .widget_rpswidget #rps .directionNav a {
    position: absolute;
    top: 50%;
    margin-top: -23px;
    color: #fff;
    -webkit-transition: color 0.5s ease;
    transition: color 0.5s ease;
    text-decoration: none;
}

#underslider .widget_rpswidget #rps .directionNav a:visited {
    color: #fff;
}

#underslider .widget_rpswidget #rps .directionNav a:hover {
    color: #333333;
}

#underslider .widget_rpswidget #rps .directionNav a:active {
    color: #fff;
}

/*
all navs have a .nav class applied via
the wp_menu function; this is so we can
easily write one group of styles for
the navs on the site so our css is cleaner
and more scalable.
*/

.d-none {
    display: none !important;
}

.nav {
    border-bottom: 0;
    margin: 0;

    li {
        a {
            display: block;
            text-decoration: none;

            /*
            remember this is for mobile ONLY, so there's no need
            to even declare hover styles here, you can do it in
            the style.scss file where it's relevant. We want to
            keep this file as small as possible!
            */
            &:hover,
            &:focus {}
        }

        &.current-menu-item {
            text-decoration: underline !important;
            color: $black;
            text-underline-offset: 3px;
        }

        &:first-child {}

        &:last-child {}

        /*
        so you really have to rethink your dropdowns for mobile.
        you don't want to have it expand too much because the
        screen is so small. How you manage your menu should
        depend on the project. Here's some great info on it:
        http://www.alistapart.com/articles/organizing-mobile/
        */
        ul.sub-menu,
        ul.children {
            li {
                a {
                    padding-left: 30px;

                    &:hover,
                    &:focus {}

                    &:link {}
                }

                &:first-child {}

                &:last-child {}
            }
        }
    }

    /* end .menu li */

    /* highlight current page */
    li.current-menu-item,
    li.current_page_item,
    li.current_page_ancestor {
        a {}
    }

    /* end current highlighters */
}

/* end .nav */

/*********************
POSTS & CONTENT STYLES
*********************/

#content {
    	/*********************
      BILDERGALERIE
      *********************/
    .inner {
        width: 90%;
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        width: 90vw;
    }

    ul {
        list-style-type: square;
        text-align: left;
        padding: 0 0 0 25px;
        line-height: 31px;
        line-height: 3.1rem;

        li {
            margin-bottom: 10px;
        }
    }

    .behandlungen {
        font-size: 23px;
        font-size: 2.3rem;
        line-height: 30px;
        line-height: 3rem;

        .odd {
            background-color: $primary;
            color: $black;

            div.preis {
                border-color: black;
            }
        }

        .even {
            background-color: $secondary;
        }

        article {
            padding: 20px;
            margin-bottom: 10px;
            position: relative;
            color: $white;
        }

        header {
            h2 {
                margin-top: 0;

                // font-family: sans-serif;
                font-size: 2.6rem;
                line-height: 1.25;
                font-family: $sans-serif;

                &:after {
                    display: none;
                }
            }

            h4 {
                font-size: 2.3rem;
                font-weight: normal;
                font-family: $mono;
                text-align: center;
                text-transform: none;
                margin: -27px 0 30px 0;
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        section {
            text-align: center;

            h6 {
                font-size: 23px;
                font-size: 2.3rem;
                margin: 20px auto 10px 270px;
                text-align: left;
            }

            ul {
                list-style-type: square;
                text-align: left;
                padding: 0 0 0 25px;
                margin: 0 0 0 270px;
                line-height: 31px;
                line-height: 3.1rem;

                li {
                    margin-bottom: 10px;
                }
            }

            p:last-child {
                margin-bottom: 0;
            }
        }

        footer {
            padding: 0;
            height: 65px;
            text-align: center;
            margin-top: 30px;

            div.preis {
                display: inline;
                border: 2px solid $white;
                font-size: 3.5rem;
                line-height: 4.3rem;
                margin: 0 auto;
                padding: 0px 15px;
                padding-top: 0.2em;
            }
        }

        img {
            position: absolute;
            z-index: 100;
        }

        .format-image {
            background: none;
            padding: 0;
            margin-top: -10px;
            margin-bottom: 30px;
            clear: both;

            section {
                padding: 0;
                text-align: left;

                img {
                    position: static;
                    margin: 0;
                    display: block;
                    float: left;
                }
            }

            footer {
                height: 50px;
                clear: both;

                a {
                    position: relative;
                    display: block;
                    height: 31px;
                    width: 210px;
                    margin: 65px auto 0 auto;
                    border: 1px solid $border-color;
                    background-color: #fff;
                    font-family: $mono;
                    font-size: 20px;
                    font-size: 2rem;
                    color: $border-color;
                    text-decoration: none;

                    @include link-colors($border-color, $black, $border-color, $border-color);

                    &:before {
                        position: absolute;
                        font-size: 51px;
                        font-size: 5.1rem;
                        width: 15px;
                        left: 97px;
                        top: -26px;
                        z-index: -1;
                        font-family: 'anna-kopp-webfont';
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;

                        /* Better Font Rendering =========== */
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: "\e606";
                    }
                }
            }
        }
    }

    .category-bildergalerie & {
        article.format-gallery {
            background: none;
            padding: 0 0 50px 0;
            margin-bottom: 50px;
            border-bottom: 1px solid #919191;
            position: relative;

            .entry-title {
                width: 300px;
                text-align: center;
                position: absolute;
                left: 0;
                top: 10px;
                color: #fff;
                font-family: $mono;
                font-size: 40px;
                font-size: 4rem;
                line-height: 45px;
                line-height: 4.5rem;
                z-index: 150;
                cursor: pointer;

                &:after {
                    background-color: #fff;
                }

                .more-button {
                    bottom: -80px;
                }
            }

            footer {
                display: none;
            }

            .gallery dl.gallery-item {
                width: 25%;
                float: left;
                height: 300px;
                margin: 0;
                padding: 0;
                text-align: left;

                img {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

    #gallery-1 img {
        border: 20px solid white;
    }
}

.hentry {
    header {
        padding: 0;

        p {
            display: none;
        }
    }

    footer {
        padding: 1.5em;

        p {
            margin: 0;
        }
    }
}

/* end .hentry */

.single-title,
.page-title,
.entry-title,
.archive-title {
    margin: 45px 0;
    font-family: $serif;
    font-size: 3rem;
    line-height: 4rem;
    text-align: center;
    text-transform: uppercase;

    &:after {
        display: block;
        width: 110px;
        height: 3px;
        content: "";
        margin: 20px auto 0 auto;
        position: relative;
    }
}

.article-header h4 {
    font-size: 23px;
    font-size: 2.3rem;
    font-weight: normal;
    font-family: $mono;
    text-align: center;
    text-transform: uppercase;
    margin: -27px 0 30px 0;
}

.category_description {
    font-size: 23px;
    font-size: 2.3rem;
    line-height: 36px;
    line-height: 3.6rem;
    text-align: center;
}

.category_description p {
    margin: 0 0 30px;
}

.page-produkte .single-title,
.page-produkte .page-title,
.page-produkte .entry-title,
.page-child .single-title,
.page-child .page-title,
.page-child .entry-title {
    color: $text-color;
}

img.logo {
    float: none;
    margin: 0 !important;
}

img.bild_darunter {
    clear: both;
}

.single-title {}

.page-title {}

.entry-title {}

.archive-title {}

/* want to style individual post classes? Booya! */
.post-id {}

/* post by id (i.e. post-3) */
.post {}

/* general post style */
.page {}

/* general article on a page style */
.attachment {}

/* general style on an attatchment */
.sticky {}

/* sticky post style */
.hentry {}

/* hentry class */
.category-slug {}

/* style by category (i.e. category-videos) */
.tag-slug {}

/* style by tag (i.e. tag-news) */

/* post meta */
.byline {
    color: $meta-gray;
    font-style: normal;
    margin: 0 !important;

    time {}
}

/* entry content */
.entry-content {
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.25em;
    position: relative;

    p {
        margin: 0 0 28px;

        img {
            margin-bottom: 0;
        }
    }

    hr {
        border: 0;
        border-bottom: 1px solid #919191;
        clear: both;
    }

    strong {
        font-weight: 600;
    }

    ul,
    ol,
    table,
    dl {}

    ul,
    ol {
        li {}
    }

    ul {
        li {}
    }

    ol {
        li {}
    }

    table {
        width: 100%;
        border: 1px solid $gray;
        margin-bottom: 1.5em;

        caption {
            margin: 0 0 7px;
            font-size: 0.75em;
            color: $meta-gray;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    tr {
        border-bottom: 1px solid $gray;

        &:nth-child(even) {
            background-color: $light-gray;
        }
    }

    td {
        padding: 7px;
        border-right: 1px solid $gray;

        &:last-child {
            border-right: 0;
        }
    }

    th {
        background-color: $light-gray;
        border-bottom: 1px solid $gray;
        border-right: 1px solid $gray;

        &:last-child {
            border-right: 0;
        }
    }

    blockquote {
        margin: 0 0 1.5em 0.75em;
        padding: 0 0 0 0.75em;
        border-left: 3px solid $black;
        font-style: normal;
        color: $meta-gray;

        &:before {}
    }

    dl {}

    dt {}

    dd {
        margin-left: 0;
        font-size: 0.9em;
        color: #787878;
        margin-bottom: 1.5em;
    }

    img {
        margin: 0 0 1.5em 0;
        max-width: 100%;
        height: auto;
    }

    .more-toggle {
        position: relative !important;
        margin: 35px auto 5px auto;
    }

    .more-link {
        display: block;
        width: fit-content;
        height: unset;
        border: 1px solid $text-color;
        font-family: $serif;
        padding: .618em 2.5rem;
        cursor: pointer;
        font-size: 2.3rem;

        @include link-colors($text-color, $black, $text-color, $text-color);

        text-decoration: none;
        text-align: left;

        @include buttonTransiton(ease-out);

        &:hover {
            background-color: $gold;
            color: $white;

            @include buttonTransiton(ease-in);
        }

        &:after {
            @include icons;

            content: "\e604";
            font-size: 1.5rem;
            margin-left: 15px;
        }
    }

    /*
    		image alignment on a screen this size may be
    		a bit difficult. It's set to start aligning
    		and floating images at the next breakpoint,
    		but it's up to you. Feel free to change it up.
    		*/
    .alignleft,
    img.alignleft {}

    .alignright,
    img.alignright {}

    .aligncenter,
    img.aligncenter {}

    .size-auto,
    .size-full,
    .size-large,
    .size-medium,
    .size-thumbnail {
        max-width: 100%;
        height: auto;
    }

    pre {
        background: $black;
        color: $light-gray;
        font-size: 0.9em;
        padding: 1.5em;
        margin: 0 0 1.5em;
        border-radius: 3px;
    }

    .images {
        img {
            margin: 0;
        }

        .home & {
            text-align: right;
            float: right;
            width: 30%;
        }
    }
}

/* end .entry-content */

.wp-caption {
    max-width: 100%;
    background: #eee;
    padding: 5px;

    /* images inside wp-caption */
    img {
        max-width: 100%;
        margin-bottom: 0;
        width: 100%;
    }

    p.wp-caption-text {
        font-size: 0.85em;
        margin: 4px 0 7px;
        text-align: center;
    }
}

/* end .wp-caption */

/* image gallery styles */
.gallery {
    dl {
        a {}

        img {}
    }

    dt {}

    dd {}
}

/* end .gallery */

/* gallery caption styles */
.gallery-caption {}

.size-full {}

.size-large {}

.size-medium {}

.size-thumbnail {}

// the tag output
.tags {
    margin: 0;
}

/******************************************************************
PAGE NAVI STYLES
******************************************************************/

.pagination,
.wp-prev-next {
    margin: 1.5em 0;
}

.pagination {
    text-align: center;

    ul {
        display: inline-block;
        background-color: $white;
        white-space: nowrap;
        padding: 0;
        clear: both;
        border-radius: 3px;
    }

    li {
        padding: 0;
        margin: 0;
        float: left;
        display: inline;
        overflow: hidden;
        border-right: 1px solid $gray;
    }

    a,
    span {
        margin: 0;
        text-decoration: none;
        padding: 0;
        line-height: 1em;
        font-size: 1em;
        font-weight: normal;
        padding: 0.75em;
        min-width: 1em;
        display: block;
        color: $black;

        &:hover,
        &:focus {
            background-color: #333333;
            color: $white;
        }
    }

    .current {
        cursor: default;
        color: $text-color;

        &:hover,
        &:focus {
            background-color: $white;
            color: $text-color;
        }
    }
}

/* end .bones_page_navi */

/* fallback previous & next links */
.wp-prev-next {
    ul {}

    .prev-link {
        float: left;
    }

    .next-link {
        float: right;
    }
}

/* end .wp-prev-next */

/******************************************************************
COMMENT STYLES
******************************************************************/

/* h3 comment title */
#comments-title {
    padding: 0.75em;
    margin: 0;
    border-top: 1px solid $light-gray;

    /* number of comments span */
    span {}
}

.comment-nav {
    ul {
        li {}
    }
}

.commentlist {
    margin: 0;
    list-style-type: none;
}

.comment {
    position: relative;
    clear: both;
    overflow: hidden;
    padding: 1.5em;
    border-bottom: 1px solid $light-gray;

    .comment-author {
        padding: 7px;
        border: 0;
    }

    /* vcard */
    .vcard {
        margin-left: 50px;

        cite.fn {
            font-weight: 700;
            font-style: normal;

            a.url {}
        }

        time {
            display: block;
            font-size: 0.9em;
            font-style: normal;

            a {
                color: $meta-gray;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                    font-weight: bold;
                }
            }
        }

        .photo {}

        .avatar {
            position: absolute;
            left: 16px;
            border-radius: 50%;
        }
    }

    /* end .commentlist .vcard */

    &:last-child {
        margin-bottom: 0;
    }

    .children {
        margin: 0;

        li {}

        /* variations */
        .comment {}

        .alt {}

        .odd {}

        .even {}

        .depth-1 {}

        /* change number for different depth */
        .byuser {}

        .bypostauthor {}

        .comment-author-admin {}
    }

    /* end children */

    &[class*=depth-] {
        margin-top: 1.1em;
    }

    &.depth-1 {
        margin-left: 0;
        margin-top: 0;
    }

    &:not(.depth-1) {
        margin-top: 0;
        margin-left: 7px;
        padding: 7px;
    }

    &.depth-2 {}

    &.depth-3 {}

    &.depth-4 {}

    &.depth-5 {}

    /* general comment classes */
    &.alt {}

    &.odd {
        background-color: $white;
    }

    &.even {
        background: $light-gray;
    }

    &.parent {}

    &.comment {}

    &.children {}

    &.pingback {}

    &.bypostauthor {}

    &.comment-author {}

    &.comment-author-admin {}

    &.thread-alt {}

    &.thread-odd {}

    &.thread-even {}
}

/* comment meta */
.comment-meta {
    a {}
}

.commentmetadata {
    a {}
}

/* comment content */
.comment_content {
    p {
        margin: 0.7335em 0 1.5em;
        font-size: 1em;
        line-height: 1.5em;
    }
}

/* end .commentlist .comment_content */

/* comment reply link */
.comment-reply-link {
    @extend .blue-btn;

    font-size: 0.9em;
    float: right;

    &:hover,
    &:focus {}
}

/* end .commentlist .comment-reply-link */

/* edit comment link */
.comment-edit-link {
    font-style: normal;
    margin: 0 7px;
    text-decoration: none;
    font-size: 0.9em;
}

/******************************************************************
COMMENT FORM STYLES
******************************************************************/

.comment-respond {
    @extend .cf;

    padding: 1.5em;
    border-top: 1px solid $light-gray;
}

#reply-title {
    margin: 0;
}

.logged-in-as {
    color: $meta-gray;
    font-style: normal;
    margin: 0;

    a {
        color: $text-color;
    }
}

.comment-form-comment {
    margin: 1.5em 0 0.75em;
}

.form-allowed-tags {
    padding: 1.5em;
    background-color: $light-gray;
    font-size: 0.9em;
}

/* comment submit button */
#submit {
    @extend .blue-btn;

    float: right;
    font-size: 1em;
}

/* comment form title */
#comment-form-title {
    margin: 0 0 1.1em;
}

/* cancel comment reply link */
#cancel-comment-reply {
    a {}
}

/* logged in comments */
.comments-logged-in-as {}

/* allowed tags */
#allowed_tags {
    margin: 1.5em 10px 0.7335em 0;
}

/* no comments */
.nocomments {
    margin: 0 20px 1.1em;
}

/*********************
SIDEBARS & ASIDES
*********************/

.sidebar,
#top {
    width: 1000px;
    margin: 0 auto;
    padding: 100px 0 60px 0;

    &#sidebar1 {
        .page-kontakt & {
            display: none;
        }

        img {
            width: 243px !important;
            height: 351px !important;
        }

        a > span {
            width: 243px;
            height: 351px;
            top: -168px;

            //Google Chrome
            @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
                top: -169px !important;
            }

            span {
                font-size: 1.8rem;
            }
        }
    }

    ul {
        li {
            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }

            a {
                position: relative;

                img {
                    border: 15px solid $gold;

                    @include box-sizing(content-box);
                }

                > span {
                    display: block;
                    position: absolute;
                    left: 20px;
                    padding: 100px 30px 0 30px;
                    text-align: center;
                    transition-property: background-color;
                    transition-duration: 0.2s;
                    transition-timing-function: ease-out;

                    &:hover {
                        background-color: rgba($black, 0.7);
                        transition-property: background-color;
                        transition-duration: 0.2s;
                        transition-timing-function: ease-out;
                    }

                    span {
                        border: 2px solid $text-color;
                        padding: 0;
                        width: 176px;
                        height: 42px;
                        display: block;
                        text-align: center;
                        text-transform: uppercase;
                        text-decoration: none;
                        color: $text-color;
                    }
                }
            }

            /* deep nesting */
            ul {}
        }

        &:last-child:after {
            @extend .clearfix;
        }
    }
}

#top {
    li {
        height: 280px !important;

        a > span {
            padding: 90px 0px !important;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            color: $text-color;
            width: 170px;
            height: 246px;
            top: -117px;
            background-color: rgba($black, 0.5);

            &:hover {
                background-color: rgba($black, 0.9) !important;
            }

            //Google Chrome
            @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
                top: -117px !important;
            }
        }

        img {
            width: 170px !important;
            height: 246px !important;
        }
    }

    li.current-menu-item span:after {
        display: block;
        width: 84px;
        height: 8px;
        content: "";
        margin: 10px auto 0px auto;
        position: relative;
    }

    img {
        width: 100px !important;
    }

    li {
        width: 25% !important;
    }
}

.widgettitle {}

.widget {
    ul {
        li {
            &:first-child {}

            &:last-child {}

            a {}

            /* deep nesting */
            ul {}
        }
    }
}

.no-widgets {
    display: none;
}

/*********************
AFTER CONTENT MENU STYLES
*********************/

.widget_nav_menu {
    width: 100%;
    height: 32px;
    border: 1px solid $text-color;
    text-align: center;
    color: $text-color;
    margin: 0 0 45px 0;
    font-size: 2rem;
    line-height: 3rem;

    ul {
        @include element-reset;
    }

    li {
        display: inline-block;
        margin: 0 13px;

        &.current_page_item {
            font-weight: 700;
        }
    }

    a {
        @include link-colors($text-color, darken($text-color, 20%), $text-color, $text-color);

        text-decoration: none;

        &:hover {
            font-weight: 700;
        }
    }
}

/*********************tig
ÖFFNUNGSZEITEN
*********************/

.oeffnungszeiten {
    margin: 0 auto 50px auto;

    a {
        @include link-colors($text-color, darken($text-color, 20%), $text-color, $text-color);

        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .widgettitle {
        display: none;
    }

    .text {
        display: block;
        width: 250px;
        border: 1px solid black;
        height: 70px;
        text-align: center;
        font-size: 18px;
        line-height: 7rem;
        float: left;

        &:after {
            content: "";
            display: block;
            border-bottom: 1px solid $text-color;
            margin: -23px auto 0 auto;
            width: 93%;
        }
    }

    .icon {
        display: block;
        border: none;
        width: 70px;
        height: 70px;
        float: left;
        background-color: $secondary;
        text-align: center;
        font-size: 3rem;
        line-height: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @include link-colors(#fff);
    }
}

/*********************
Behandlungen Quatrate
*********************/
#menu-behandlungen-quatrate {
    li {
        width: 33.3%;
    }
}

/*********************
Image Menu Styles
*********************/

#before-footer,
#menu-behandlungen-quatrate {
    margin-bottom: 50px;

    li#menu-item-204 {
        display: none;
    }

    li {
        float: left;
        position: relative;
        overflow: hidden;

        a {
            @include link-colors($white);

            overflow: hidden;
            width: 100%;

            @include hyphenation;

            text-align: center;

            > span {
                display: block;
                position: absolute;
                width: 100%;
                top: 40px;
                font-family: $serif;
                font-size: 1.5rem;

                @include hyphenation;

                text-align: center;
                font-weight: 900;
                font-style: normal;
                z-index: 10;
                text-shadow: 0px 0px 20px rgba(black, 0.3);

                span.more-button {
                    display: none;

                    // position: absolute;
                    // bottom: -70px;
                    // width: 200px;
                    // height: 50px;
                    // font-family: $serif;
                    // margin-left: -100px;
                    // left: 50%;
                    // padding-left: 15px;
                    // background-color: $white;

                    // font-size: 2.3rem;
                    // line-height: 5rem;
                    // @include link-colors($text-color, $black, $text-color, $text-color);
                    // text-shadow: none;
                    // text-decoration: none;
                    // font-style: normal
                    // text-align: left;
                    // font-weight: normal;

                    // @include buttonTransiton(ease-out);

                    // &:hover {
                    // 	background-color: $gold;
                    // 	color: $white;

                    // 	@include buttonTransiton(ease-in);
                    // }

                    // &:after {
                    // 	@include icons;
                    // 	content: "\e604";
                    // 	font-size: 1.5rem;
                    // 	margin-left: 15px;
               
                }
            }

            .mobile_hide {
                font-size: 3.5rem;
            }
        }
    }

    img {
        width: 100% !important;
        height: auto !important;
        transition: all 2s ease-out;
        margin: 0;
    }
}

/*********************
FOOTER STYLES
*********************/

.footer {
    border-top: 1px solid $black;

    #inner-footer {
        // &:before {
        //     width: 100%;
        //     height: 3px;
        //     display: block;
        //     content: "";
        //     background: url("../images/dotted_border.png") left top repeat-x;
        // }
   
    }

    .row1 {
        padding: 23px 55px;
        color: $gold;

        h4 {
            font-size: 2rem;
            line-height: 2rem;
            font-family: $serif;
            font-style: normal;
            font-weight: 400;
            margin: 0 0 10px 0;
        }

        .widget_text {
            font-size: 16px;
            line-height: 3rem;

            p {
                margin: 0;
            }

            span {
                font-size: 50%;
                vertical-align: super;
            }
        }

        .center {
            text-align: center;
            position: relative;
            margin-top: 100px;

            .ak-sign {
                position: absolute;
                left: 50%;
                top: -48px;
                width: 140px;
                margin-left: -38px;
                display: block;
                padding: 7px 15px;
                text-decoration: none;
                background-color: $white;
                font-size: 4.7rem;

                @include link-colors($gold, $light-gold, $gold, $gold);
            }

            .gototop {
                position: relative;
                display: block;
                height: 31px;
                width: 133px;
                margin: 50px auto 0 auto;
                border: 1px solid $border-color;
                background-color: $white;
                font-family: $sans-serif;
                font-size: 2rem;

                @include link-colors($border-color, $black, $border-color, $border-color);

                text-decoration: none;

                &:hover {
                    border-color: $black;
                }

                &:before {
                    position: absolute;
                    font-size: 5.1rem;
                    width: 15px;
                    left: 59px;
                    top: -26px;
                    z-index: -1;

                    @include icons;

                    content: "\e606";
                }
            }
        }

        #facebook {
            text-align: center;
            margin-top: 10px;

            h4 {
                text-align: center;
            }

            a {
                display: inline-block;
                width: 76px;
                height: 76px;
                text-decoration: none;
                font-size: 7.2rem;
                line-height: 7rem;

                @include link-colors($gold, $light-gold, $gold, $gold);
            }
        }
    }

    .row2 {
        background-color: $akk-beige;
        color: $black;
        padding: 0 33px;
        text-transform: uppercase;
        clear: both;

        @extend .clearfix;

        .widgettitle {
            display: none;
        }

        .widget_text {
            padding: 1em 0;
            font-size: 1.6rem;
            line-height: 1.25em;
        }

        .widget_search {
            margin-top: 0px;
            display: inline-block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 20px;

            @include placeholder {
                color: $white;
            }


            label {
                display: none;
            }

            form {
                position: relative;
            }

            input#s {
                background-color: $gold;
                border: 1px solid $white;

                @include border-radius(0);

                height: 31px;
                width: 178px;
                font-size: 1.5rem;
                line-height: 3rem;
                text-transform: uppercase;
                color: $white;
                float: right;
                padding-right: 30px;

                &:focus {
                    outline: none;
                }
            }

            #searchsubmit {
                position: absolute;
                top: 0;
                right: 3px;
                width: 30px;
                height: 30px;
                background: none;
                border: 0;
                color: $white;
                font-size: 1.7rem;

                @include icons;
            }
        }

        address {
            font-style: normal;
        }

        a {
            @include link-colors($black);

            text-decoration: none;

            &:hover {
                font-weight: bold;
                color: $akk-violet;
            }
        }
    }

    nav {
        margin: 20px 0 40px 0;
        text-align: center;

        li {
            margin: 0 7px;
            display: inline-block;
            font-size: 1.6rem;
            color: $text-color;
            text-transform: uppercase;

            a {
                @include link-colors($text-color, $text-color, $text-color, $text-color);

                text-decoration: none;
            }
        }
    }
}

// DEKORATION

.kamillen_klein,
.kamillen_gross {
    position: absolute;
}

.kamillen_klein {
    top: -130px;
    left: 0;
}

.kamillen_gross {
    top: -250px;
    right: -60px;
}

#content nf-form-content button, #content .nf-form-content input[type="button"], #content .nf-form-content input[type="submit"] {
    background: $secondary;
    border: 0;
    color: $white;
    transition: all .5s;

    &:hover {
        background: $secondary;
        color: $white;

        @include box-shadow(0 0 10px 0 rgba($black, 0.5));
    }
}

#content {
    .gwolle-gb .button.btn.btn-default, .gwolle_gb_submit.button.btn.btn-primary {
        margin: 0;
        line-height: 1;
        cursor: pointer;
        position: relative;
        text-decoration: none;
        overflow: visible;
        padding: .618em 1em;
        left: auto;
        display: inline-block;
        background-color: #eae1de;
        box-shadow: none;
        text-shadow: none;
        color: #000;
        border: unset;
        transition: .5s;
        font-size: 20px;
    }

    .gwolle-gb form.gwolle-gb-write {
        margin-bottom: 30px;
    }

    .gwolle-gb div.input input[type="text"], .gwolle-gb div.input input[type="email"], .gwolle-gb div.input input[type="url"], .gwolle-gb div.input textarea, .gwolle-gb div.input select {
        width: 99%;
        border: 2px solid $secondary;
        font-size: 1.6rem;
    }

    .gwolle-gb .button.btn.btn-default:hover {
        background-color: $primary;

        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   
    }

    .gwolle-gb .gwolle-gb-write-button {
        margin-top: 0em;
        margin-bottom: 2em;
        text-align: center;
    }

    .gwolle-gb .gb-entry:not(.gb-entry-count_1) {
        border-top: 2px dotted $black !important;
    }

    .gwolle-gb .gb-entry {
        padding: 20 0 !important;
    }

    .gwolle-gb form.gwolle-gb-write button.gb-notice-dismiss {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        border-radius: 0px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        color: #fff;
        background-color: $secondary;
        display: inline-block;
        speak: none;
        line-height: 16px;
        height: auto;
        width: auto;
        text-align: center;
        font-size: 16px;
        padding: 6px 10px 10px;
    }

    .gwolle_gb_messages {
        border: unset;
        font-size: 1.6rem;
        background: #000;
        color: #fff;
        padding: 2em;
    }

    .gwolle_gb_messages p {
        font-size: 1.6rem;
        margin-bottom: 0;
    }

    .gwolle-gb {
        margin-bottom: 3em;
    }
}

#content .slick-slider {
    li, ul {
        text-align: center !important;
    }
}

#instagram-feed #sb_instagram .sbi_follow_btn a, #instagram-feed #sb_instagram .sbi_load_btn {
    color: $black;
    background-color: $primary;

    &:hover {
        background-color: $secondary;
        color: $white;
        box-shadow: unset;
    }
}
