/******************************************************************
Site Name:
Author:

Stylesheet: Button Styles

Buttons are a pretty important part of your site's style, so it's
important to have a consistent baseline for them. Use this stylesheet
to create all kinds of buttons.

Helpful Links:
http://galp.in/blog/2011/08/02/the-ui-guide-part-1-buttons/

******************************************************************/

/*********************
BUTTON DEFAULTS
We're gonna use a placeholder selector here
so we can use common styles. We then use this
to load up the defaults in all our buttons.

Here's a quick video to show how it works:
http://www.youtube.com/watch?v=hwdVpKiJzac

*********************/

%btn {
  display: inline-block;
  position: relative;
  font-family: $sans-serif;
  text-decoration: none;
  color: $white;
  font-size: 0.9em;
  font-size: 34px;
  line-height: 34px;
  font-weight: normal;
  padding: 0 24px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  @include transition(background-color 0.14s ease-in-out);

  // hovering on the btn
  &:hover, &:focus {
    color: $white;
    text-decoration: none;
  } // end hover

  // clicking on the button
  &:active {
    top: 1px; // adds a tiny hop to the click
  } // end active

} // end .button

/*
An example button.
You can use this example if you want. Just replace all the variables
and it will create a button dependant on those variables.
*/
.blue-btn {
  @extend %btn; // here we load the btn defaults
  background-color: $blue;

  &:hover,
  &:focus {
    background-color: darken($blue, 4%);
  } // end hover

  &:active {
    background-color: darken($blue, 5%);
  } // end active

} // end blue button
