/******************************************************************
Site Name:
Author:

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop.

******************************************************************/

/*********************
HEADER
*********************/

#topslider {
    max-height: 100%;
    max-width: 2500px;
    margin: auto;
}

/*********************
CONTENT
*********************/
.entry-content {
    padding: 0;
    font-size: 1.9rem;
    line-height: 1.25em;
    position: relative;

    .text-content {
        width: 100%;
        margin: 0 auto;
    }

    p {
        img {
            margin-bottom: 28px;
        }
    }
}

#container {
    padding-top: 133px;
}

/*********************
HEADER INNER
*********************/
.header {
    height: 134px;

    nav {
        position: absolute;
        top: 94px;
        transition: all .3s ease;

        a {
            &:hover {
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }

    #logo {
        width: 260px;
        max-width: 260px;
        margin: 30px auto 0 auto;
    }

    nav {
        display: block;
    }

    #showMenu {
        display: none !important;
    }

    &.sticky {
        height: 100px;
        max-height: 100px;

        #logo {
            max-width: 200px;
        }
    }
}

#content {
    .inner {
        width: 90vw;
    }
}

.single-title,
.page-title,
.entry-title,
.archive-title {
    font-size: 4rem;
    line-height: 5rem;
}

/*********************
SIDEBARS
*********************/
.sidebar,
#top {
    ul {
        li {
            width: 33%;
            float: left;
            height: 390px;
            position: relative;
            width: 33%;
            text-align: center;
        }
    }
}

/*********************tig
ÖFFNUNGSZEITEN
*********************/

.oeffnungszeiten {
    margin: 0 auto 50px auto;

    a {
        display: inline;
        position: static;
        transform: none;
    }

    .widgettitle {
        display: none;
    }

    .text {
        display: block;
        width: 375px;
        border: 1px solid $text-color;
        height: 90px;
        text-align: center;
        font-size: 3.1rem;
        line-height: 9rem;
        float: left;

        &:after {
            content: "";
            display: block;
            border-bottom: 1px solid $text-color;
            margin: -23px auto 0 auto;
            width: 93%;
        }
    }

    .icon {
        display: block;
        border: none;
        width: 90px;
        height: 90px;
        float: right;
        background-color: $secondary;
        text-align: center;
        font-size: 4.5rem;
        line-height: 9rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @include link-colors(#fff);
    }
}

/*********************
Behandlungen Quatrate
*********************/

#before-footer,
#menu-behandlungen-quatrate {
    margin-bottom: 50px;

    li#menu-item-204 {
        display: block;
    }

    li {
        position: relative;
        overflow: hidden;

        &.col-1-2 {
            width: 50%;
        }

        &.col-2-2 {
            width: 100%;
        }

        &:hover img {
            // width: 104% !important;
            // margin-left: -2%;
            transform: scale(1.03);
            transition: all 1s ease-in;
        }

        a {
            @include link-colors($white);

            overflow: hidden;
            width: 100%;

            @include hyphenation;

            text-align: center;

            > span {
                top: 70px;
                font-size: 5.5rem;

                span.more-button {
                    display: block;
                    position: absolute;
                    bottom: -70px;
                    width: 200px;
                    height: 50px;
                    font-family: $serif;
                    margin-left: -100px;
                    left: 50%;
                    padding-left: 15px;
                    background-color: $white;
                    font-size: 2.3rem;
                    line-height: 5rem;

                    @include link-colors($text-color, $black, $text-color, $text-color);

                    text-shadow: none;
                    text-decoration: none;
                    font-style: normal;
                    text-align: left;
                    font-weight: normal;

                    @include buttonTransiton(ease-out);

                    &:hover {
                        background-color: $gold;
                        color: $white;

                        @include buttonTransiton(ease-in);
                    }

                    &:after {
                        @include icons;

                        content: "\e604";
                        font-size: 1.5rem;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

/*********************
ÖFFNUNGSZEITEN
*********************/

.oeffnungszeiten {
    width: 475px;
}

/*********************
FOOTER
*********************/
.footer {
    #inner-footer {
        position: relative;
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        width: 90vw;

        .row1 {
            height: 180px;

            .widget_text {
                width: 33%;
                float: left;
                font-size: 1.8rem;
            }

            .center {
                width: 33%;
                float: left;
                margin-top: 0;
            }

            #facebook {
                width: 33%;
                float: right;
                text-align: right;

                h4 {
                    font-size: 3rem;
                    line-height: 3rem;
                    float: left;
                    text-align: right;
                    width: 266px;
                }
            }
        }

        .row2 {
            height: 76px;

            .widget_text {
                width: 100%;
                float: left;
                padding: 0;
                line-height: 7rem;
                text-align: center;
            }

            .widget_search {
                width: 20%;
                float: right;
                display: inline;
                position: static;
                transform: none;
                margin-bottom: 0;
                margin-top: 20px;
            }
        }
    }
}

// WOOCOMMMERCE

.woocommerce,
.woocommerce-page {
    div.product,
    #content div.product {
        position: relative;

        div.images {
            width: 33.33%;

            a {
                display: block;

                img {
                    height: 580px;
                    width: 400px;
                    object-fit: contain;
                    padding: 20px;
                }
            }
        }

        .flex-row {
            display: flex;
            flex-wrap: wrap;

            .flex-col {
                margin-right: 10px;
            }

            .quantity {
                margin: 0;
            }
        }

        div.summary {
            width: 66.66%;
            position: relative;

            label {
                font-weight: 300;
            }

            input,
            select {
                width: 100%;
            }

            form {
                margin: 0;
            }

            .pricebox,
            .variations,
            .single_add_to_cart_button {
                position: absolute;
                bottom: 0;
                margin: 0;
            }

            .quantity {
                width: 80px;

                &::before {
                    content: "Anzahl";
                    display: block;
                }
            }

            .variations {
                display: block;
                width: 36%;
                left: 11%;

                .reset_variations {
                    position: absolute;
                    bottom: -1em;
                }

                td,
                tr,
                tbody {
                    display: block;
                    width: 100%;
                    padding: 0;
                }
            }

            .single_add_to_cart_button {
                width: 28%;
                right: 24%;
            }

            .pricebox {
                right: 0;
                width: 21%;

                .price {
                    color: $white;

                    .woocommerce-Price-amount {
                        color: $black;
                    }

                    .woocommerce-Price-amount + .woocommerce-Price-amount {
                        bdi {
                            display: none;
                        }

                        &::before {
                            content: "ab ";
                            color: $black;
                            float: left;
                            font-size: 14px;
                            line-height: 1;
                            margin: 0;
                            text-indent: 0;
                            display: inline-block;
                            position: absolute;
                            display: block;
                            width: 100%;
                            height: auto;
                            top: -9px;
                        }
                    }
                }

                .wc-gzd-additional-info {
                    line-height: 1;
                    height: 1em;

                    &.shipping-costs-info {
                        display: block;
                    }
                }
            }

            .product_meta .infos {
                position: absolute;
                left: -50%;
                top: 400px;
                transform: rotate(-90deg);
                transform-origin: 0 0;
            }
        }
    }
}

.woocommerce #content div.product div.images,
.woocommerce div.product div.images,
.woocommerce-page #content div.product div.images,
.woocommerce-page div.product div.images {
    width: 400px;
}

#content ul {
    padding-left: 5px;
}
