/******************************************************************
Site Name:
Author:

Stylesheet: Alert Styles

If you want to use these alerts in your design, you can. If not,
you can just remove this stylesheet.

******************************************************************/



// alerts and notices
%alert {
  margin: 10px;
  padding: 5px 18px;
  border: 1px solid;
}

.alert-help {
  @extend %alert;
  border-color: darken($alert-yellow, 5%);
  background: $alert-yellow;
}

.alert-info {
  @extend %alert;
  border-color: darken($alert-blue, 5%);
  background: $alert-blue;
}

.alert-error {
  @extend %alert;
  border-color: darken($alert-red, 5%);
  background: $alert-red;
}

.alert-success {
  @extend %alert;
  border-color: darken($alert-green, 5%);
  background: $alert-green;
}